import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {  faEdit, faRectangleList, faTrash
} from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
import Toman from "../Utilities/Toman";
import React from "react";
function CustomerTable(props) {
    const tableTitles = [
        {title:'کدآسان'},
        {title:'مشتری'},
        {title:'همراه'},
        {title:'کدملی'},
        {title:'استان'},
        {title:'شهر'},
        {title:'محله'},
        {title:'ت.ثبت نام'},
        {title:'سفارشات'},
        {title:'پ.سفارشات'},
        /*{title:'مانده حساب', isPrice: true},*/
        {title:'م.کیف پول', isPrice: true},
        {title:'امتیاز باشگاه'},
    ]

    function CustomerName({customer}){
        if ( customer.is_legal ){
            return "🏢 "+customer.legal.org_name
        }
        return customer.personal.first_name+" "+customer.personal.last_name
    }
    return (
        <table className="table-auto w-full block lg:table mt-4">
                <thead>
                <tr className="border-b border-b-gray-7">
                    {tableTitles.map((item, id) => (
                        <td className="text-13 text-gray-6 pb-2" key={id}>
                            {item.title}
                           {/* {item.isPrice?
                                <div className="flex justify-center mr-0.5">
                                    <span className="text-9">(</span>
                                    <Toman />
                                    <span className="text-9">)</span>
                                </div>
                                :''}*/}
                        </td>
                    ))}
                    <td></td>
                </tr>
                </thead>
                <tbody>
                {props.tableData.map(tableData => (
                        <tr className={`text-xs py-2 relative ${props.tableOptions.rowSatus?'before':''} ${tableData.checked ? 'checked' : ''}`} key={tableData.id}>
                           <td>{tableData.asan_id}</td>
                           <td className="text-right"><CustomerName customer={tableData}/></td>
                           <td>{tableData.contacts.cellphone}</td>
                           <td>{tableData.personal.national_code}</td>
                           <td>{tableData.main_address?.coordinates?.state?.faname}</td>
                           <td>{tableData.main_address?.coordinates?.city?.faname}</td>
                           <td>{tableData.main_address?.coordinates?.area?.faname}</td>
                           <td>{new Intl.DateTimeFormat('fa-IR').format((new Date(tableData.created_at)))}</td>
                           <td>{tableData.order_count.orders}</td>
                           <td>{tableData.order_count.preorders}</td>
{/*
                           <td>{tableData.remained}</td>
*/}
                           <td>{tableData.wallet_balance.toLocaleString()}</td>
                           <td>-</td>
                            <td>
                                <Link className="mx-1" to={`/customers/view/${tableData._id}`}  title="ویرایش"><FontAwesomeIcon className="bg-purple-2 text-white p-1 w-4 h-4 rounded" icon={faEdit} /></Link>
                                <Link className="mx-1" to={`/orders?customer_id=${tableData._id}`}  title="سفارشات"><FontAwesomeIcon className="bg-blue-1 text-white p-1 w-4 h-4 rounded" icon={faRectangleList} /></Link>
{/*                                <a className="mx-1" href="/" title="؟؟؟"><FontAwesomeIcon className="bg-blue-1 text-white p-1 w-4 h-4 rounded" icon={faRectangleList} /></a>
                                <a className="mx-1" href="/" title="؟؟؟"><FontAwesomeIcon className="bg-green-1 text-white p-1 w-4 h-4 rounded" icon={faRectangleList} /></a>
                                <a className="mx-1" href="/" title="حذف"><FontAwesomeIcon className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash} /></a>*/}
                            </td>
                        </tr>
                    )
                )}
                </tbody>
            </table>
    )
}

export default CustomerTable