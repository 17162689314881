import Toman from "../../Utilities/Toman";
import Modal from "../../Utilities/Modal";
import {useState} from "react";
import ApiRoute from "../../../helper/api";
import {formatNumberWithDots, getDateAsReadableFormat} from "../../../helper/general";
import ProductSelect from "../../Product/ProductSelect";
import SelectCoordinates from "../../Coordinates/SelectCoordinates";

export default function QuickShippingClassCheck({closeModal}){
    const [product , setProduct] = useState(null)
    const [coordinate , setCoordinate] = useState(null)
    const [data , setData] = useState(null)

    async function handleSubmit(){
        const res = await ApiRoute.shipment.quickCheck.fn({
            product: product,
            coordinate: coordinate
        })
        if (res?.status_code === 200){
            setData(res.results);
        }
    }

    return (
        <Modal isOpen={true} onClose={closeModal}>
            <div className="page-title mb-3"><span className="inline-block bg-white text-xs text-purple-1 pl-3">مشاهده کلاس حمل برای کالای خاص</span>
            </div>
            <div className="form grid-form grid grid-cols-1 text-xs gap-3 my-6">
                <div>
                    <label>محصول</label>
                    <ProductSelect allowOutStock={true} multiple={false} assignItem={ (s) => {
                        s.map( (p) => setProduct(p) )
                    } }/>
                </div>
                <div>
                    <label>موقعیت</label>
                    <div className={`mt-2`}>
                        <SelectCoordinates multiple={false} SetCoordinate={ (s) => {
                            setCoordinate({
                                state: s.state[0] ?? {},
                                city: s.city[0] ?? {},
                                area:s.area[0] ?? {},
                            })
                        } }/>
                    </div>
                </div>
            </div>

            {data && <div className="border border-gray-7 bg-gray-1 rounded-md p-3 text-xs flex gap-20">
                <div className="flex items-center">
                    <span className="text-gray-12">عنوان حمل و نقل:</span>
                    <span className="text-gray-5 px-1">{data.class[0]['class']['title'] ?? '-'}</span>
                </div>
                <div className="flex items-center">
                    <span className="text-gray-12">هزینه حمل و نقل:</span>
                    <span className="text-gray-5 px-1">{formatNumberWithDots(data.price)}</span>
                    <Toman/>
                </div>
                <div className="flex items-center">
                    <span className="text-gray-12">کرایه پرداخت در محل:</span>
                    <span className="text-gray-5 px-1">{formatNumberWithDots(data.class[0]['class']['cod_price'] ?? 0)}</span>
                    <Toman/>
                </div>
            </div>}

            <div className="flex justify-end mt-4">
                <button onClick={handleSubmit} className="btn-purple px-5 py-2">بررسی وضعیت</button>
            </div>
        </Modal>
    )
}