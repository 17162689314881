// store.js
import { createStore } from 'redux';

const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
const TOGGLE_SUBMENU = 'TOGGLE_SUBMENU';
const TOGGLE_LOADING = 'TOGGLE_LOADING';


const initialState = {
    toggle: false,
    loading: false,
    submenuToggled: []
};

const toggleReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                toggle: !state.toggle,
            };
        case TOGGLE_LOADING:
            return {
                ...state,
                loading: !state.loading,
            };
        case TOGGLE_SUBMENU:
            return {
                ...state,
                submenuToggled: toggleSubmenu(state.submenuToggled, action.payload),
            };
        default:
            return state;
    }
};
// Helper function to toggle submenus
const toggleSubmenu = (submenuToggled, submenuId) => {
    if (submenuToggled.includes(submenuId)) {
        // If submenu is already open, close it
        return submenuToggled.filter(id => id !== submenuId);
    } else {
        // If submenu is closed, open it
        return [...submenuToggled, submenuId];
    }
};

const store = createStore(toggleReducer);

export default store;