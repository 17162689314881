import React, {useEffect, useState} from 'react';

function SwitchToggle(props) {
    const [isToggled, setToggled] = useState(props.onOff);

    const toggleSwitch = () => {
        setToggled(!isToggled)
        props.onSwitch(!isToggled)
    };

    return (
        <div className="switch-toggle inline-flex mx-2">
            <label className={`switch ${isToggled ? 'on' : 'off'}`}>
              {props.disabled}
                <input type="checkbox" onChange={toggleSwitch} checked={isToggled} disabled={props.disabled} />
                <span className={`slider round `}></span>
            </label>
        </div>
    );
}

export default SwitchToggle;
