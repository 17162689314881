import { Link } from "react-router-dom"
function Pagination({Data = null,SetPageNumber}){
    if (!Data){return <></>}
    return(

        <>
            <div className="flex justify-between justify-center items-center mt-5 px-4">
                <div>تعداد کل : {Data.total.toLocaleString()}</div>
                <div>نمایش در هر صفحه : {Data.per_page}</div>
            </div>
            <div className="flex overflow-x-auto lg:justify-center items-center mt-5 mb-3">
                { Data.links ? Data.links.map( (item , index) => (
                    <div key={index} onClick={() => {
                        SetPageNumber(Url(item.url))
                    }} className={`mx-6 flex-shrink-0 flex hover:text-purple-1 cursor-pointer`+(item.active ? 'aspect-square bg-purple-1 text-white w-10 h-10 justify-center items-center' : '')}>
                        <Label label={item.label}/>
                    </div>
                ) ) : '1' }
            </div>
        </>

    )
}

function Label({label}){
    let txt = label
    if (label.includes("Next")){
        txt = "بعدی"
    }
    if (label.includes("Previous")){
        txt = "قبلی"
    }
    return (txt)
}
function Url(url){
   let _return;

    const regex = /(?<=page=)\d+/gm;
    const str = url;
    let m;
    while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }
        m.forEach((match, groupIndex) => {
           _return = match
        });
    }

    return _return
}

export default Pagination;