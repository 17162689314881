import CategorySelect from "../Product/Category/CategorySelect";

export default function RuleForm({editData, data}){
    return (<>

        {editData.price_rules?.length > 0 && editData.price_rules.map( (pr, index) => {
            if (pr.shop?.primary){
                return (
                    <div key={index}>
                        <div className="text-xs text-purple-1 mt-8 lg:mt-4 mb-2">{pr.shop.title}</div>
                        <div className="bg-gray-9 rounded-md p-3 text-xs">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 2xl:gap-10">
                                <div><label className="flex items-center">
                                    <span className="pl-2 text-gray-12">مرز حداقل</span>
                                    <input onKeyUp={ (e) => {
                                        pr.min = parseInt(e.target.value)
                                    } } dir={`ltr`} defaultValue={pr.min} className="bordered-input-7 text-left flex-auto" type="text" />
                                </label></div>
                                <div><label className="flex items-center">
                                    <span className="pl-2 text-gray-12">مرز حداکثر</span>
                                    <input onKeyUp={ (e) => {
                                        pr.max = parseInt(e.target.value)
                                    } } dir={`ltr`} defaultValue={pr.max} className="bordered-input-7 text-left flex-auto" type="text" />
                                </label></div>
                                <div><label className="flex items-center">
                                    <span className="pl-2 text-gray-12">کف قیمت</span>
                                    <input onKeyUp={ (e) => {
                                        pr.floor = e.target.value
                                    } } dir={`ltr`} defaultValue={pr.floor} className="bordered-input-7 text-left flex-auto" type="text" />
                                </label></div>
                                <div><label className="flex items-center">
                                    <span className="pl-2 text-gray-12">سقف قیمت</span>
                                    <input onKeyUp={ (e) => {
                                        pr.ceil = parseInt(e.target.value)
                                    } } dir={`ltr`} defaultValue={pr.ceil} className="bordered-input-7 text-left flex-auto" type="text" />
                                </label></div>
                                <div><label className="flex items-center">
                                    <span className="pl-2 text-gray-12">درصد پیش فروش</span>
                                    <input onKeyUp={ (e) => {
                                        pr.presell = parseInt(e.target.value)
                                    } } dir={`ltr`} defaultValue={pr.presell} className="bordered-input-7 text-left flex-auto" type="text" />
                                </label></div>
                            </div>
                        </div>
                    </div>
                )
            }
        } )}




        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">

            {editData.price_rules?.length > 0 && editData.price_rules.map( (pr, index) => {
                if (pr.shop?.primary === false){
                    return (
                        <div key={index}>
                            <div className="text-xs text-purple-1 mt-4 mb-2">{pr.shop.title}</div>
                            <div className="bg-gray-7 rounded-md p-2 text-xs">
                                <div className="grid grid-cols-1 xl:grid-cols-2 justify-between gap-3 2xl:gap-10">
                                    <div><label className="flex items-center gap-2">
                                        <span className="text-gray-12 w-max">کف قیمت</span>
                                        <input onKeyUp={ (e) => {
                                            pr.floor = e.target.value
                                        } } defaultValue={pr.floor} className="bordered-input flex-auto !border-gray-9 w-3/5" type="text" />
                                    </label></div>
                                    <div><label className="flex items-center gap-2">
                                        <span className="text-gray-12 w-max">درصد پیش فروش</span>
                                        <input onKeyUp={ (e) => {
                                            pr.presell = parseInt(e.target.value)
                                        } } defaultValue={pr.presell} className="bordered-input flex-auto !border-gray-9 w-2/5" type="text" />
                                    </label></div>
                                </div>
                            </div>
                        </div>
                    )
                }
            } )}
        </div>
    </>)
}