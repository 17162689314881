import Layout from "../../../components/Layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faFilter,
    faLocationPin,
    faPrint,
    faSortDown,
    faTimes,
    faTruckFast
} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import ApiRoute from "../../../helper/api";
import OrderKeyHeader from "../../../components/Order/Edit/OrderKeyHeader";
import OrderUserInfo from "../../../components/Order/Edit/OrderUserInfo";
import OrderDeliveryTime from "../../../components/Order/Edit/Sidebar/OrderDeliveryTime";

export default function OrderDeliveryTimeClass() {


    const navigate = useNavigate()
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [updateState, setUpdateState] = useState(0);

    const [data, setData] = useState(null);

    const [order,setOrder] = useState(null);

    const [modal, setModal] = useState(null)

    useEffect(() => {
        getData()
    }, [updateState]);

    useEffect(() => {
        if (data){
            setOrder(data)
        }
    }, [data]);


    async function getData(){
        const response = await ApiRoute.order.single.fn(params.get('id')+"?model=\\App\\OrderManager\\Model\\OrderExpanded")
        setData(response.results)
    }

    async function handleSubmit() {

    }

    return (
        <>
            <Layout>
                {order && <div>

                    <OrderKeyHeader order={order} setUpdateState={setUpdateState}/>

                    <div className={` grid grid-cols-1 lg:grid-cols-6 xl:grid-cols-4 gap-3`}>
                        <div className="col-span-full">

                            <div
                                className="main-content before-table-style before-purple border border-gray-7 relative rounded-md p-5 mt-4">
                            <OrderDeliveryTime
                                title={`ابتدا کلاس تحویل زمانی را مشخص کنید`}
                                event={ () => {
                                    navigate(`/orders/edit?id=${order._id}`)
                                } }
                                hideButton={true}
                                inlineMode={true}
                                order={order}
                                setUpdateState={setUpdateState}/>
                            </div>
                        </div>
                    </div>

                </div>}
            </Layout>
        </>
    )
}