import {useEffect, useState} from "react";
import Layout from "../../components/Layout";
import {Link, useNavigate} from "react-router-dom";
import SearchBox from "../../components/SearchBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faFilter, faTrash} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../components/Pagination";
import ShipmentClassTable from "../../components/Shipment/Table/ShipmentClassTable";
import ApiRoute, {shopId} from "../../helper/api";
import Modal from "../../components/Utilities/Modal";
import ShipmentClassFilterBox from "../../components/Shipment/ShipmentClassFilterBox";


export default function Shipping() {

    const navigate = useNavigate()
    const [createModal, setCreateModal] = useState(false)
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [title, setTitle] = useState('')
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'cellphone'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            const  res  = await ApiRoute.shipment.classes.index.fn({
                shop_id: shopId(),
                page : pageNumber
            },filterQuery)
            setData(res)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        filterQuery.quick_search = searchQuery
        setUpdateData(Date.now().toString())
    }, [searchQuery]);
    // watch filterQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [filterQuery]);

    async function handleCreate() {
        const res = await ApiRoute.shipment.classes.create.fn( {
            title: title,
        })
        if (res.results){
            setSearchQuery({
                term : res.results.title,
                by: ''
            })
            setCreateModal(false)
        }
    }


    return (
        <Layout>
            <div>
                <div className="flex items-center justify-between mb-3">
                    <div className="page-title flex-auto ml-3 md:ml-0">
                        <span className="inline-block bg-white pl-3">قوانین حمل و نقل</span>
                    </div>
                    <button className="btn-blue text-center px-6 md:hidden" onClick={() => setCreateModal(true)}>افزودن</button>
                </div>

                <div className="grid md:flex justify-between items-center gap-3">
                    <div className="flex gap-3">
                        <SearchBox SearchIn={false} SetSearchQuery={setSearchQuery}/>
                    </div>
                    <button className="btn-blue text-center hidden md:block" onClick={() => setCreateModal(true)}>افزودن</button>
                </div>

               <ShipmentClassFilterBox SetFilterQuery={setFilterQuery}/>

                <div className="main-content p-3 mt-3">
                    {data && <ShipmentClassTable SetUpdateData={setUpdateData} params={params} tableData={tableData}/>}
                    {data && <Pagination Data={paginationData} SetPageNumber={setPageNumber} />}
                </div>

                <Modal isOpen={createModal} onClose={() => {setCreateModal(false)}}>
                    <div>

                        <div className="page-title z-1 text-right text-sm">
                            <span className="inline-block bg-white pl-3 pr-1 mb-3 relative z-2">ایجاد کلاس حمل و نقل</span>
                        </div>

                        <div className={`flex gap-2 w-full md:flex-row flex-col`}>

                            <input onKeyUp={ (e) => { setTitle(e.target.value) } } className='px-3 py-2 border border-[#dfdfdf] w-full rounded-[8px] shadow flex-auto bg-white' type="text" placeholder="عنوان حمل و نقل ..."/>
                            <button onClick={handleCreate} className={`btn-green rounded`}>ایجاد</button>

                        </div>

                    </div>
                </Modal>


            </div>
        </Layout>
    )
}