import Layout from "../../components/Layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import Pagination from "../../components/Pagination";
import SearchBox from "../../components/SearchBox";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../helper/api";

function Templates(){

    const [newData, setNewData] = useState({
        title:'',
        slug:''
    })
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    // اضافه کردن state برای فیلترها
    const [filterOptions, setFilterOptions] = useState([
        { label: 'صفحات', value: 'page' },
        { label: 'لیست قیمت', value: 'price-list' },
        { label: 'برچسب محصولات', value: 'tag' },
        { label: 'دسته بندی محصولات', value: 'category' }
    ]);

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            const  res  = await ApiRoute.template.index.fn({
                shop_id: shopId(),
                status:selectedStatsTab,
                page : pageNumber
            },filterQuery)
            setData(res)
        })();
    }, [updateData]);

    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    // watch searchQuery and update updateData state
    useEffect(() => {
        filterQuery.quick_search = searchQuery
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    // watch filterQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [filterQuery]);

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);    
    
    useEffect(() => {
        filterQuery.type = selectedStatsTab
        setUpdateData(Date.now().toString())
    }, [selectedStatsTab]);

    // تابع برای تغییر فیلتر انتخاب شده
    const handleFilterChange = (value) => {
        setSelectedStatsTab(value);
        setUpdateData(Date.now().toString());
    }

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []
    const tableOptions= {rowSatus: true}

    async function handleCreate() {
        await ApiRoute.template.create.fn(newData)
        setUpdateData(Date.now().toString())
    }

    return(
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">قالب ها</span>
                </div>

                <div className="main-content mx-auto p-5 mt-6">
                    <div className="grid grid-cols-1 lg:grid-cols-9 gap-4">

                        <div className="lg:col-span-3 border border-gray-7 rounded-md p-2 pb-4 h-max form">
                            <div className="grid gap-5 justify-items-stretch">
                                <label className="!grid">
                                   <span className="text-right mb-2">عنوان قالب</span>
                                    <input onKeyUp={ e => {
                                        newData.title = e.target.value;
                                        setNewData(newData)
                                    } } className="!mr-0" type="text" placeholder="نام قالب صفحه"/>
                                </label>
                            
                                <button onClick={handleCreate} className="btn-green w-max rounded mr-auto py-2">افزودن</button>
                            </div>
                        </div>


                        <div className="lg:col-span-6 border border-gray-7 rounded-md p-2">
                            <SearchBox SetSearchQuery={setSearchQuery} SearchIn={false} />

                            <div className="lg:col-span-3 border border-gray-7 rounded-md p-2 pb-4 h-max form">
                            <div className="grid gap-5 justify-items-stretch">
                                <label className="!grid">
                                    <span className="text-right mb-2">فیلتر بر اساس نوع</span>
                                    <select onChange={e => handleFilterChange(e.target.value)} className="!mr-0">
                                        {filterOptions.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                </label>
                            </div>
                        </div>

                            <table className="table-borderd-tr table-auto w-full mt-2 mx-auto table text-xs">
                                <thead>
                                <tr>
                                    <td className="text-right pr-3">عنوان قالب</td> 
                                    <td></td>
                                </tr>
                                </thead>
                                <tbody>

                                {data && tableData.map( (item,index) => <tr key={index}>
                                    <td className="text-right pr-3">{item.title}</td>
                                    <td>
                                        <div className="flex justify-end items-center py-1">
                                            <Link className="mx-2" to={`/templates/edit?id=${item._id}`} title="ویرایش"><FontAwesomeIcon
                                                className="bg-purple-3 text-white p-1 w-4 h-4 rounded" icon={faEdit}/></Link>
                                            <button onClick={ () => {
                                                alert('پیاده سازی تمامی حذف ها در مراحل نهایی صورت خواهد گرفت')
                                            } } className="mx-2" title="حذف"><FontAwesomeIcon
                                                className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                                        </div>
                                    </td>

                                </tr>)}

                                </tbody>
                            </table>

                            {data && <Pagination SetPageNumber={setPageNumber} Data={paginationData} />}

                        </div>
                    </div>
                </div>


            </div>
        </Layout>
    )
}
export default Templates