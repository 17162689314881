import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding, faEdit} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

export default function OrderLegalInfo({order, setUpdateState}){
    const customer = order.customer
    return (
        <div
            className="main-content before-table-style before-purple border border-gray-7 relative rounded-md p-5 mt-4">
            <div className="page-title mb-3">
                                <span className="inline-block bg-white pl-3 text-sm">
                                    اطلاعات حقوقی
                                <Link to={`/customers/view/${customer._id}`}><FontAwesomeIcon className="mr-2 text-purple-1" icon={faBuilding}/></Link>
                                </span>
            </div>
            <div
                className="grid sm:grid-cols-2 xl:flex justify-between gap-3 lg:gap-5 text-xs mt-5">
                <div>
                    <span className="text-gray-2">نام:</span>
                    <span className="text-gray-5 mr-1">{order?.official?.org_name}</span>
                </div>

                <div>
                    <span className="text-gray-2">شماره ملی:</span>
                    <span className="text-gray-5 mr-1">{order?.official?.national_number}</span>
                </div>

                <div>
                    <span className="text-gray-2">کد اقتصادی:</span>
                    <span className="text-gray-5 mr-1">{order?.official?.economic_code}</span>
                </div>

                <div>
                    <span className="text-gray-2">شماره ثبت:</span>
                    <span className="text-gray-5 mr-1">{order?.official?.register_number}</span>
                </div>

                <div>
                    <span className="text-gray-2">شماره تماس:</span>
                    <span className="text-gray-5 mr-1">{order?.official?.phone}</span>
                </div>

                <div>
                    <span className="text-gray-2">استان/شهر:</span>
                    <span className="text-gray-5 mr-1">{order?.official?.state?.faname} / {order?.official?.city?.faname}</span>
                </div>
            </div>
        </div>
    )
}