import Layout from "../../components/Layout";
import SearchBox from "../../components/SearchBox";
import {Link} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import ApiRoute from "../../helper/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/free-solid-svg-icons/faRefresh";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import useToken from "../../useToken";

function ProfileEdit() {
    const {token , resetToken} = useToken()
    const search = window.location.search;
    const [data, setData] = useState(token?.employee ?? null);
    const [editData,setEditData] = useState({});
    useEffect(() => {
            setEditData({
                username : data.username,
                asan_id : data.asan_id,
                name : data.name,
            })

    },[data]);

    async function handleSubmit() {
        const res = await ApiRoute.auth.updateProfile.fn(data._id,editData)
        if (res?.status_code === 200){
            resetToken()
            window.location.replace("/")
        }
    }

    return (
        <Layout>
            {data && <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">ویرایش پروفایل</span>
                </div>

                <div className="border border-gray-7 rounded-md mx-auto p-4 grid grid-cols-1 md:grid-cols-3 xl:grid-cols-7 gap-5 text-xs mt-10">
                    <div><label className="!grid">
                        نام و نام خانوادگی
                        <input defaultValue={editData.name} onKeyUp={ (e) => {
                            editData.name = e.target.value;
                        } } className="bordered-input-7 mt-2" type="text"
                               placeholder="نام و نام خانوادگی را وارد کنید"/>
                    </label></div>
                    <div><label className="!grid">
                        نام کاربری
                        <input defaultValue={editData.username} onKeyUp={ (e) => {
                            editData.username = e.target.value;
                        } } className="bordered-input-7 mt-2" type="email" placeholder="نام کاربری را وارد کنید"/>
                    </label></div>
                    <div><label className="!grid">
                        پسورد
                        <input onKeyUp={ (e) => {
                            editData.password = e.target.value;
                        } } className="bordered-input-7 mt-2" type="password" placeholder="اگر نمیخواهید تغییر دهید، رها کنید"/>
                    </label></div>
                    <div><label className="!grid">
                        کد آسان
                        <input className="bordered-input-7 mt-2" type="text"/>
                    </label></div>
                </div>
                <div className="border border-gray-7 rounded-md mx-auto p-4 text-xs mt-4">

                    <div className="col-span-4 flex justify-center mx-auto gap-5 mt-4">
                        {/*<Link to="/employees" className="btn-gray w-28 py-1.5">لغو</Link>*/}
                        <button onClick={handleSubmit} className="btn-purple rounded  py-1.5">بروزرسانی و سینک مجدد</button>
                    </div>

                </div>
            </div>}
        </Layout>
    )
}

export default ProfileEdit