import React, {useState} from "react";
import ApiRoute from "../../helper/api";
import {toast, ToastContainer} from "react-toastify";
import {updateVersion} from "../../helper/general";

export default function Login({setToken}){

    const [formData, setFormData] = useState({
        username:"",
        password:""
    })
    async function handleSubmit(event){
        event.preventDefault();
        const res = await ApiRoute.auth.login.fn(formData)
        if (res?.status_code === 200){
            setToken(res.results)
            window.location.replace("/")
            localStorage.setItem("update",updateVersion())
        }

    }

    return (
        <>
            <div style={{display:"none"}} id={`loading-x`}>
                <div className="xloader"></div>
            </div>
            <ToastContainer/>
            <style dangerouslySetInnerHTML={{__html: `
  body { overflow:auto !important; }
`}} />
            <div className="flex md:absolute md:inset-0 md:m-auto min-h-full flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img className="mx-auto h-10 w-auto" src="https://media.entekhabcenter.com/storage/2022/09/15/1663269654.svg" alt="Your Company"/>
                        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">ورود به انتخاب سنتر</h2>
                </div>

                <div style={{
                    padding: "20px",
                    boxShadow: "0px 0px 84px #cccccc5e",
                    borderRadius: "7px",
                }} className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">نام کاربری</label>
                            <div className="mt-2">
                                <input onKeyUp={ (e) => { formData.username = e.target.value } } id="username" name="username" type="text" dir={`ltr`} autoComplete="username" required className="block text-left w-full rounded-md px-5 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">رمزعبور</label>
                            </div>
                            <div className="mt-2">
                                <input onKeyUp={ (e) => { formData.password = e.target.value } } id="password" name="password" type="password" dir={`ltr`} autoComplete="current-password" required className="block text-left w-full px-5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                            </div>
                        </div>

                        <div>
                            <button type="submit" className="flex w-full justify-center rounded-md bg-[#4f46e5] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#4f46e5b8] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">ورود به پنل</button>
                        </div>
                    </form>

                </div>
            </div>
        </>
    )
}