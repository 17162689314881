import Layout from "../../../components/Layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import Pagination from "../../../components/Pagination";
import SearchBox from "../../../components/SearchBox";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../../helper/api";
import {toast, ToastContainer} from "react-toastify";

function Attributes(){

    const [data,setData] = useState(null)
    const [loading,setLoading] = useState(true)
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [isLoading, setIsLoading] = useState(true)
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [newData , setNewData] = useState({
        is_public: false,
        name: "",
        label: "",
        group_label: "",
    })

    const [pageNumber, setPageNumber] = useState(1)
    const [paginationData, setPaginationData] = useState([])
    const [updateData, setUpdateData] = useState('0')
    const [filterQuery, setFilterQuery] = useState({})

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  response  = await ApiRoute.product.attributes.index.fn({
                shop_id: shopId(),
                page : pageNumber
            },filterQuery)

            setData(response.results.data)
            setPaginationData(response.results)

            setIsLoading(false)

        })();
    }, [updateData]);

    useEffect(() => {
        getData()
    }, []);

    async function getData(){
        const response = await ApiRoute.product.attributes.index.fn()
        setData(response.results.data)
        setPaginationData(response.results)
    }

    async function createData(){
        try {
            await ApiRoute.product.attributes.create.fn(newData)
            await getData()
            //toast.success("با موفقیت افزوده شد")
        }catch (e) {
            toast.error(e)
        }

    }

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);

    useEffect(() => {
        filterQuery.quick_search = searchQuery
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    async function updateByKey(item,v,key) {
        try {
            await ApiRoute.product.attributes.updateByKey.fn(item._id, key, v.target.value )
            //toast.success("با موفقیت انجام شد")
        }catch (e) {
            toast.error(e)
        }

    }

    return(
        <Layout>

            

            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">ویژگی محصولات</span>
                </div>

                <SearchBox searchIn={true} defaultSearchBy="label" SetSearchQuery={setSearchQuery}/>


                <div className="main-content mx-auto p-5 mt-6">
                    <div className="grid grid-cols-1 lg:grid-cols-7 gap-4">

                        <div className="lg:col-span-2 border border-gray-7 rounded-md p-2 pb-4 h-max form">
                            <div className="grid gap-5 justify-items-stretch">
                                <label className="!grid">
                                    <span className="text-right mb-2">عنوان ویژگی گروه</span>
                                    <input onChange={ (e) => {
                                        newData.group_label = e.target.value;
                                        setNewData(newData)
                                    } } className="!mr-0" type="text" placeholder="عنوان گروهی"/>
                                </label>
                                <label className="!grid">
                                   <span className="text-right mb-2">عنوان ویژگی</span>
                                    <input onChange={ (e) => {
                                        newData.label = e.target.value;
                                        setNewData(newData)
                                    } } className="!mr-0" type="text" placeholder="عنوان را وارد کنید"/>
                                </label>
                                <label className="!grid">
                                    <span className="text-right mb-2">نامک</span>
                                    <input onChange={ (e) => {
                                        newData.name = e.target.value;
                                        setNewData(newData)
                                    } } className="!mr-0 text-left" type="text" placeholder="نام را وارد کنید"/>
                                </label>
                                <label className="!grid">
                                   <span className="text-right mb-2">به عنوان فیلتر استفاده شود؟</span>
                                    <select onChange={ (e) => {
                                        newData.is_public = e.target.value;
                                        setNewData(newData)
                                    } } className="!mr-0">
                                        <option value="1">بله</option>
                                        <option value="0">خیر</option>
                                    </select>
                                </label>
                                <button onClick={createData} className="btn-green w-max rounded mr-auto py-2">افزودن</button>
                            </div>
                        </div>

                        {data && <div className="lg:col-span-5 border border-gray-7 rounded-md p-2">
                            {/*<SearchBox SearchIn={false} />*/}
                            <table className="table-borderd-tr table-auto w-full mt-2 mx-auto block md:table text-xs">
                                <thead>
                                <tr>
                                    <td>گروه ویژگی</td>
                                    <td>عنوان ویژگی</td>
                                    <td>نامک</td>
                                    <td>فیلتر</td>
                                    <td>نوع شرط</td>
                                    <td>پیکربندی</td>
                                </tr>
                                </thead>
                                <tbody>
                                {data.map( item => <tr key={item._id}>
                                    <td><input onChange={ async (e) => { await updateByKey(item,e , 'group_label') } } className="border border-gray-3 p-1 px-3 rounded" type="text" defaultValue={item.group_label}/></td>
                                    <td><input onChange={ async (e) => { await updateByKey(item,e , 'label') } } className="border border-gray-3 p-1 px-3 rounded" type="text" defaultValue={item.label}/></td>
                                    <td>{item.name}</td>
                                    <td>
                                        <select onChange={ async (e) => { await updateByKey(item,e , 'is_public') } } className="border border-gray-3 p-1 rounded">
                                            <option selected={!item.is_public == 0} value="0">خیر</option>
                                            <option selected={item.is_public == 1} value="1">بله</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select onChange={ async (e) => { await updateByKey(item,e , 'condition_type') } } className="border border-gray-3 p-1 rounded">
                                            <option value="and">با</option>
                                            <option value="or">یا</option>
                                        </select>
                                    </td>
                                    <td className="flex justify-center items-center py-1">
                                        <Link className="mx-2 leading-3 my-1" to={`/products/attributes/features?id=${item._id}`} title="مشخصات ویژگی"><FontAwesomeIcon
                                            className="bg-gray-3 text-white p-1 w-4 h-4 rounded" icon={faEye}/></Link>
                                    </td>
                                </tr> )}


                                </tbody>
                            </table>
                            <Pagination Data={paginationData} SetPageNumber={setPageNumber} />
                        </div>}
                    </div>
                </div>


            </div>

        </Layout>
    )
}
export default Attributes