import ProductSelect from "../../../Product/ProductSelect";
import Modal from "../../../Utilities/Modal";
import {useState} from "react";
import ApiRoute from "../../../../helper/api";
import CouponSelect from "../../../Common/CouponSelect";

export default function OrderItemCouponModal({order, setUpdateState , closeModal}){

    const [couponId , setCouponId] = useState()

    async function handleSubmit(){
        const res = await ApiRoute.order.item.addCoupon.fn(order._id , couponId)
        if (res?.status_code === 200){
            setUpdateState(Date.now())
            closeModal()
        }
    }

    return (
        <Modal isOpen={true} onClose={closeModal}>
            <div className="page-title mb-5"><span
                className="inline-block bg-white pl-3 text-sm text-purple-1">افزودن کوپن</span></div>
            <div className="form">
                <div className="flex flex-col text-sm mb-5 gap-4">
                    <div>
                        <CouponSelect multiple={false} fixed={true} assignItem={(s) => {
                            s.map( (item) => {
                                setCouponId(item._id)
                            } )
                        }}/>
                    </div>
                    <div>
                        <button onClick={handleSubmit} className="btn-purple py-2 w-full">افزودن</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}