import {useEffect, useRef, useState} from "react";
import ApiRoute from "../../helper/api";
import {faChevronDown, faClose, faCross, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function SelectCoordinates({defaultValue = {} , defaultValueMultiple = false, SetCoordinate = () => {} , multiple = false , refresh = '1'}) {
    const cacheKey = localStorage.getItem('coordinate_cache_key_a_3') ?? 'x-5'
    const [show , setShow] = useState(false)
    const [provinces , setProvinces] = useState([])
    const [coordinates , setCoordinates] = useState({})
    const [area , setArea] = useState(null)

    const [defaultValues , setDefaultValues] = useState([])
    const [updateData , setUpdateData] = useState(0)

    const [selected , setSelected] = useState([])
    const [selectedCity , setSelectedCity] = useState([])
    const [selectedArea , setSelectedArea] = useState([])

    const [provincesMenu , setProvincesMenu] = useState(false)
    const [cityMenu , setCityMenu] = useState(false)
    const [areaMenu , setAreaMenu] = useState(false)

    const searchRef = useRef(null);

    const classes = {
        popup_parent : 'fixed z-10 bg-[#ffffffd1] p-0 w-full h-full m-auto inset-0',
        popup_block:'bg-white fixed inset-0 w-[370px] border h-[250px] z-10 m-auto rounded overflow-auto',
        popup_item_parent:'w-full text-sm p-1 flex flex-col gap-1 overflow-auto',
        popup_item:'flex items-start hover:text-green-2 hover:bg-green-3 p-2 gap-2 text-right cursor-pointer',
        popup_item_active:'bg-green-2 text-[#fff]'
    }

    function getProvincesByDefaultValue(state){

        for (const stateElement of provinces) {
            if ( state.enname == stateElement.enname ){
                return stateElement;
            }
        }
        return state;
    }

    useEffect(() => {

            (async () => {
                await fetchProvinces()
            })();

        setDefaultValues(1)
    },[updateData])

    useEffect(() => {
        setDefaultValues(Date.now())
    },[refresh])

    function initDefaultValues(){
        if ( defaultValue !== undefined ) {
            if ( defaultValue.state != null ){

                if ( typeof defaultValue.state == 'object' ){

                    if (defaultValueMultiple){

                            let _states = defaultValue.state
                            let new_state = []
                            for (const _state of _states) {
                                new_state.push(getProvincesByDefaultValue(_state))
                            }

                            setSelected(new_state)

                    }else {
                        if (Object.keys(defaultValue.state).length > 0){
                            setSelected([getProvincesByDefaultValue(defaultValue.state)])
                        }else{
                            if (defaultValue.state.length > 0) {
                                let _states = defaultValue.state
                                let new_state = []
                                for (const _state of _states) {
                                    new_state.push(getProvincesByDefaultValue(_state))
                                }
                                setSelected(new_state)
                            }
                        }
                    }


                }


                if ( typeof defaultValue.city == 'object' ){

                    if (defaultValueMultiple){
                        setSelectedCity(defaultValue.city)
                    }else {
                        if (Object.keys(defaultValue.city).length > 0){
                            setSelectedCity([defaultValue.city])
                        }else{
                            if (defaultValue.city.length > 0) {
                                setSelectedCity(defaultValue.city)
                            }
                        }
                    }

                }

                if ( defaultValue.area && typeof defaultValue.area == 'object' ){

                    if (defaultValueMultiple){
                        setSelectedArea(defaultValue.area)
                    }else {
                        if (Object.keys(defaultValue.area).length > 0){
                            setSelectedArea([defaultValue.area])
                        }else{
                            if (defaultValue.area.length > 0) {
                                setSelectedArea(defaultValue.area)
                            }
                        }
                    }

                }

            }
        }
    }

    useEffect(() => {
       initDefaultValues()
    },[defaultValues])

    async function fetchProvinces(){
        if (provinces.length > 0){return;}
        if (localStorage.getItem(cacheKey) != null){
            setProvinces(JSON.parse(localStorage.getItem(cacheKey)))
            return;
        }
        const  res  = await ApiRoute.coordinate.search.fn()
        localStorage.setItem(cacheKey, JSON.stringify(res.results.data))
        setProvinces(res.results.data)
    }

    function has(item){
        for (const selectedElement of selected) {
            if ((selectedElement.uid === item.uid || selectedElement.enname === item.enname)){ return true }
        }
        return false
    }
    function remove(item){
        setSelected(selected.filter(xitem => xitem.uid !== item.uid))
    }

    function hasCity(item){
        for (const selectedElement of selectedCity) {
            if (selectedElement.uid === item.uid || selectedElement.enname === item.enname){ return true }
        }
        return false
    }
    function removeCity(item){
        setSelectedCity(selectedCity.filter(xitem => xitem.uid !== item.uid))
    }

    function hasArea(item){
        for (const selectedElement of selectedArea) {
            if (selectedElement.uid === item.uid){ return true }
        }
        return false
    }
    function removeArea(item){
        setSelectedArea(selectedArea.filter(xitem => xitem.uid !== item.uid))
    }

    function closeALl() {
        build();
        setCityMenu(false)
        setAreaMenu(false)
        setProvincesMenu(false)
    }

    function searchItems(term){
        searchRef.current.querySelectorAll('div div').forEach( (item) => {
            if (!item.parentElement.innerText.includes(term)){
                item.parentElement.style.display = 'none'
            }else{
                item.parentElement.style.display = 'flex'
            }
        } )
    }

/*    useEffect(() => {
    }, [selected,selectedCity,selectedArea]);*/

    function build(){
        let arr = {
            "state" : [],
            "city": [],
            "area": []
        };


        for (const selectedElement of selected) {
            arr.state.push({
                uid: selectedElement?.uid,
                faname: selectedElement?.faname,
                enname: selectedElement?.enname,
                parsimap_code: selectedElement?.parsimap_code
            })
        }
        for (const selectedElement of selectedCity) {
            try {
                arr.city.push({
                    uid: selectedElement?.uid,
                    faname: selectedElement?.faname,
                    enname: selectedElement?.enname,
                    parsimap_code: selectedElement?.parsimap_code
                })
            }catch (e) {}
        }
        for (const selectedElement of selectedArea) {
            try {
                arr.area.push({
                    uid: selectedElement?.uid,
                    faname: selectedElement?.faname,
                    enname: selectedElement?.enname,
                    parsimap_code: selectedElement?.parsimap_code
                })
            }catch (e) {}
        }

        SetCoordinate(arr)
    }

    function TemplateLayout({title , cleanLayout, children}){
        return (
            <div className={ (!cleanLayout ? `lg:w-[250px] w-full` : '') + ` text-right rtl `}>
                {title && title.length > 0 ? (<p className="mb-3">{title}</p>) : ''}
                <div className="bordered-input-7 relative bg-gray-1 w-full">
                    {children}
                </div>
            </div>
        )
    }
    function ProvincesTemplate(){
        return (
            <TemplateLayout>
                <div onClick={ e => {
                    setCityMenu(false)
                    setAreaMenu(false)
                    if( !provincesMenu ){
                        setProvincesMenu(true)
                    }else{
                        setProvincesMenu(false)
                    }
                } }>
                    <div className="flex items-center flex-wrap gap-2">
                        { selected.map( (item,index) => (
                            index < 4 && <div key={index} className="bg-[#90cf] flex items-center gap-1 text-white px-2 rounded">
                                <div>{item.faname}</div>
                            </div>
                        ) ) }
                        {selected.length > 4 && <div key={Date.now()} className="bg-[#90cf] flex items-center gap-1 text-white px-2 rounded">
                            <div>+{selected.length - 4}</div>
                        </div>}
                    </div>

                    {selected.length == 0 ? (<div>انتخاب استان</div>) : ''}

                    <div
                        className="absolute left-0 top-0 bg-[#f1f1f1] grid place-content-center h-full w-[25px]">
                        <FontAwesomeIcon icon={faChevronDown}/>
                    </div>
                </div>
            </TemplateLayout>
        )
    }

    function CityTemplate(){
        return (
            <TemplateLayout>
                <div onClick={ e => {
                    if (selected.length === 0) { return;}
                    setProvincesMenu(false)
                    setAreaMenu(false)
                    if (!cityMenu){
                        setCityMenu(true)
                    }else{
                        setCityMenu(false)
                    }
                } }>
                    <div className="flex items-center flex-wrap gap-2">
                        { selectedCity.map( (item,index) => (
                            index < 4 && <div key={index} className="bg-[#90cf] text-white px-2 rounded">{item.faname}</div>
                        ) ) }
                        {selectedCity.length > 4 && <div key={"c"+Date.now()} className="bg-[#90cf] flex items-center gap-1 text-white px-2 rounded">
                            <div>+{selectedCity.length - 4}</div>
                        </div>}
                    </div>

                    {selectedCity.length == 0 ? (<div>انتخاب شهر</div>) : ''}

                    <div
                        className="absolute left-0 top-0 bg-[#f1f1f1] grid place-content-center h-full w-[25px]">
                        <FontAwesomeIcon icon={faChevronDown}/>
                    </div>
                </div>
            </TemplateLayout>
        )
    }

    function AreaTemplate(){
        return (
           <TemplateLayout>
               <div onClick={ e => {
                   if (selectedCity.length === 0) { return;}
                   setProvincesMenu(false)
                   setCityMenu(false)
                   if (!areaMenu){
                       setAreaMenu(true)
                   }else{
                       setAreaMenu(false)
                   }
               } }>
                   <div className="flex items-center flex-wrap gap-2">
                       {  selectedArea.map((a,ai) => (
                           ai < 4 && <div key={ai} className="bg-[#90cf] text-white px-2 rounded">{a.faname}</div>
                       ))
                       }
                       {selectedArea.length > 4 && <div key={"a"+Date.now()} className="bg-[#90cf] flex items-center gap-1 text-white px-2 rounded">
                           <div>+{selectedArea.length - 4}</div>
                       </div>}
                   </div>

                   {selectedArea.length == 0 ? (<div>انتخاب منطقه</div>) : ''}

                   <div
                       className="absolute left-0 top-0 bg-[#f1f1f1] grid place-content-center h-full w-[25px]">
                       <FontAwesomeIcon icon={faChevronDown}/>
                   </div>

               </div>
           </TemplateLayout>
        )
    }

    function AreaPopupMenu(){
        if (areaMenu === false) return '';
        return (
            <div>
                <div onClick={e=>{
                    closeALl()
                }} className={classes.popup_parent}></div>
                <div className={classes.popup_block}>

                    <SearchItemInput/>

                    <div ref={searchRef} className={classes.popup_item_parent}>
                        {  selectedCity.map((c,ci) => {
                            let x = c?.areas;
                            if (defaultValueMultiple){
                                x = defaultValue.area
                            }
                            return x?.length > 0 ? x.map( (a,ai) => (
                                <div key={"a"+ci+ai}
                                     onClick={ e => {
                                         if (!hasArea(a)){
                                             if (multiple === true) {
                                                 setSelectedArea(selectedArea => [...selectedArea, a])
                                             }else{
                                                 setSelectedArea([a])
                                             }
                                         }else{
                                             removeArea(a)
                                         }
                                         setAreaMenu(false)
                                     }
                                     }
                                     className={( hasArea(a) ? classes.popup_item_active : '' )+classes.popup_item}>
                                    <div className="w-full">{a.faname}</div>
                                </div>
                            ) ) : (<div className="p-0 text-[red]">منطقه ای یافت نشد</div>)
                        })
                        }
                    </div>
                </div>

            </div>
        )
    }

    function CityPopupMenu(){
        if (cityMenu === false) return '';
        return (
            <div>
                <div onClick={e=>{
                    closeALl()
                }} className={classes.popup_parent}></div>
                <div className={classes.popup_block}>

                    <SearchItemInput/>

                    <div ref={searchRef} className={classes.popup_item_parent}>
                        { selected.map( (item,index) => (
                            item.cities.map((c,ci) => (
                                <div key={ci}
                                     onClick={ e => {
                                         if (!hasCity(c)){
                                             setSelectedArea([])
                                             if (multiple === true) {
                                                 setSelectedCity(selectedCity => [...selectedCity, c])
                                             }else {
                                                 setSelectedCity([c])
                                             }
                                         }else{
                                             setSelectedArea([])
                                             removeCity(c)
                                         }
                                         setCityMenu(false)
                                     }
                                     }
                                     className={( hasCity(c) ? classes.popup_item_active : '' )+classes.popup_item}>
                                    <div className="w-full">{c.faname}</div>
                                </div>
                            ))
                        )) }
                    </div>
                </div>

            </div>
        )
    }
    function ProvincesPopupMenu(){
        if (provincesMenu === false) return '';

        return (
            <div>
                <div onClick={e=>{
                    closeALl()
                }} className={classes.popup_parent}></div>
                <div className={classes.popup_block}>

                    <SearchItemInput/>

                    {provinces.length === 0 ? (<div className="p-3">در حال بارگیری ...</div>) : ''}

                    <div ref={searchRef} className={classes.popup_item_parent}>
                        { provinces && provinces.map( (item,index) => (
                            <div key={index}

                                 onClick={ e => {
                                     setCityMenu(false)
                                     setAreaMenu(false)
                                     if (!has(item)){
                                         setSelectedCity([])
                                         setSelectedArea([])
                                         if (multiple === true) {
                                             setSelected(selected => [...selected, item])
                                         }else{
                                             setSelected([item])
                                         }
                                     }else{
                                         if (selected.length == 1){
                                             setSelectedCity([])
                                             setSelectedArea([])
                                         }
                                         remove(item)
                                     }
                                     setProvincesMenu(false)
                                 } }
                                 className={( has(item) ? classes.popup_item_active : '' )+classes.popup_item}>
                                <div className="w-full">{item.faname}</div>
                            </div>
                        ) ) }
                    </div>
                </div>
            </div>
        )
    }

    function SearchItemInput(){
        return (
            <input type="text"
                   onChange={ e => { searchItems(e.target.value) } }
                   placeholder="جستجو"
                   className="px-3 m-0 w-full !mr-0 py-2 bg-gray-1"/>
        )
    }

    return (
        <>
            <div onClick={ e => { setShow(true) } } className="bordered-input-7 bg-[#fff] text-xs flex flex-wrap items-center relative w-full">
                { selected.length < 1 ? 'انتخاب موقعیت'  : '' }

                { selected.length > 0 ? 'استان: ' : '' }
                {
                    selected.map((s,i) => {
                        return ( i < 4 && <span key={i}>{s.faname}</span>)
                    })
                }
                {selected.length > 4 && <span className={`font-semibold mx-1`}>(+{selected.length - 4})</span>}
                { selectedCity.length > 0 ? '-  شهر: ' : '' }
                {
                    selectedCity.map((s,i) => {
                        return ( i < 4 && <span key={i}>{s.faname}</span>)
                    })
                }
                {selectedCity.length > 4 && <span className={`font-semibold mx-1`}>(+{selectedCity.length - 4})</span>}
                { selectedArea.length > 0 ? '-  منطقه : ' : '' }
                {
                    selectedArea.map((s,i) => {
                        return ( i < 4 && <span key={i}>{s.faname}</span>)
                    })
                }
                {selectedArea.length > 4 && <span className={`font-semibold mx-1`}>(+{selectedArea.length - 4})</span>}

            </div>

            { show ? (
                <div>
                    <div onClick={e=>{
                        closeALl()
                        setShow(false)
                    }} className="fixed z-10 bg-[#ffffffd1] w-full h-full m-auto inset-0"></div>
                    <div className='fixed lg:w-[800px] w-full lg:pt-0 pt-[100px] bg-[#fff] z-10 rounded p-3 border lg:h-[120px] inset-0 m-auto'>
                        <div className=" grid-cols-1 lg:grid-cols-3 grid gap-3">

                            <ProvincesTemplate/>
                            <CityTemplate/>
                            <AreaTemplate/>

                        </div>

                        <button onClick={ e => {
                            closeALl()
                            setShow(false)
                        } } className="bg-[#ffd9d9] rounded text-red mt-4 px-3 py-1">بستن</button>

                    </div>
                </div>
            ) : '' }

            <ProvincesPopupMenu/>
            <CityPopupMenu/>
            <AreaPopupMenu/>
        </>
    )


}



export default SelectCoordinates