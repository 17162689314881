import {Link} from "react-router-dom";
import React, {useState} from "react";
import {orderStatusLabel, orderStatusList} from "../../helper/general";
import SwitchToggle from "../Utilities/SwitchToggle";
import TransportSelect from "../Common/TransportSelect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd} from "@fortawesome/free-solid-svg-icons/faAdd";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

export default function ShopSmsEdit({notification_templates , shop}){
    const [update, setUpdate] = useState(0)
    const add = function (){
        notification_templates.push({
            title: '',
            condition:{
                status:'',
                payment_method:'',
                transport:'',
                delivery_method:'',
                order_type:'',
            },
            text:"متن پیامک ..."
        })
        setUpdate(Date.now())
    }
    const remove = function (index){
        notification_templates.splice(index,1)
        setUpdate(Date.now())
    }

    return (
        <div className="mt-5">

            <div className={`items-center justify-between flex`}>
                <span className="text-sm text-purple-1 p-2">تنظیمات اعلان های مشتری (پیامک)</span>
                <FontAwesomeIcon onClick={add} icon={faAdd} className={`text-blue-4 cursor-pointer`}/>
            </div>

            <div className="border border-gray-7 p-3 mt-2 rounded-md grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">

                {notification_templates?.length > 0 && notification_templates.map( (item,index) => <div key={index} className="border border-gray-7 rounded-md p-2 max-lg:mb-3">
                    <div className="text-xs p-2">
                        <input onKeyUp={ (e) => { item.title = e.target.value } } className={`input-x`} placeholder={`عنوان پیامک`} defaultValue={item?.title}/>
                    </div>
                    <div className={`flex p-2 text-[11px] flex-col gap-2`}>
                        <div className={`flex flex-col gap-0`}>
                            <span>وضعیت سفارش</span>
                            <select onChange={ (e) => { item.condition.status = e.target.value } } className={`input-x !text-[11px]`}>
                                {orderStatusList().map( (status) => <option selected={item.condition.status === status} className={`text-[11px]`} value={status}>{orderStatusLabel(status)}</option> )}
                            </select>
                        </div>
                        <div className={`flex flex-col gap-0`}>
                            <span>روش پرداخت</span>
                            <select onChange={ (e) => { item.condition.payment_method = e.target.value } } className={`input-x !text-[11px]`}>
                                <option value={''} selected={item.condition.payment_method?.length < 1}>فرقی نمیکند</option>
                                <option value={'shaparak'} selected={item.condition.payment_method === 'shaparak'}>شاپرک (آنلاین)</option>
                                <option value={'cod'} selected={item.condition.payment_method === 'cod'}>پرداخت در محل</option>
                            </select>
                        </div>
                        <div className={`flex flex-col gap-0`}>
                            <span>باربری</span>
                            <TransportSelect customDefaultValue={true} defaultValue={item.condition.transport} assignItem={ (v) => {
                                item.condition.transport = v
                            } }/>
                        </div>
                        <div className={`flex flex-col gap-0`}>
                            <span>نوع تحویل</span>
                            <select onChange={ (e) => { item.condition.delivery_method = e.target.value } } className={`input-x !text-[11px]`}>
                                <option value={''} selected={item.condition.delivery_method?.length < 1}>فرقی نمیکند</option>
                                <option value={'shipping_in_person'} selected={item.condition.delivery_method === 'shipping_in_person'}>حضوری</option>
                                <option value={'dispatch'} selected={item.condition.delivery_method === 'dispatch'}>غیر حضوری</option>
                            </select>
                        </div>
                        <div className={`flex flex-col gap-0`}>
                            <span>نوع سفارش</span>
                            <select onChange={ (e) => { item.condition.order_type = e.target.value } } className={`input-x !text-[11px]`}>
                                <option value={''} selected={item.condition.order_type?.length < 1}>فرقی نمیکند</option>
                                <option value={'order'} selected={item.condition.order_type === 'order'}>عادی</option>
                                <option value={'pre_order'} selected={item.condition.order_type === 'pre_order'}>پیش سفارش</option>
                            </select>
                        </div>
                    </div>
                    <textarea placeholder={`متن پیامک`} onKeyUp={ (e) => { item.text = e.target.value } } defaultValue={item.text} className="input-x min-h-[150px] !text-[11px]"></textarea>
                    <FontAwesomeIcon onClick={() => remove(index)} className={`text-red-1 cursor-pointer mt-4`} icon={faTrash}/>
                </div>)}

            </div>
        </div>
    )
}