import Layout from "../../components/Layout"
import Comment from "../../components/Comment";
import Pagination from "../../components/Pagination";
import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../helper/api";
import CommentFilterTab from "../../components/Comment/CommentFilterTab";

function Comments() {

    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({
        shop_id: shopId(),
    })

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('waiting')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  res  = await ApiRoute.comments.index.fn({
                quick_search : searchQuery,
                status:selectedStatsTab,
                page : pageNumber
            },filterQuery)
            setStatsTab(res.meta_data.stats)
            setData(res)
            setIsLoading(false)
        })();
    }, [updateData,selectedStatsTab]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">دیدگاه</span></div>

                <CommentFilterTab
                    selectedTab={selectedStatsTab}
                    setSelectedTab={setSelectedStatsTab}
                    options={statsTab}
                />

                <div className="lg:border border-gray-7 rounded-md lg:p-3 mt-4 grid gap-4">
                    {data && tableData.map( (comment) => <div  key={comment._id}><Comment setUpdateData={setUpdateData} comment={comment} /></div> )}
                    {data && <Pagination Data={paginationData} SetPageNumber={setPageNumber}/>}
                </div>
            </div>
        </Layout>
    );
}

export default Comments;