import Layout from "../../components/Layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faEdit} from "@fortawesome/free-solid-svg-icons";
import SwitchToggle from "../../components/Utilities/SwitchToggle";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../helper/api";

export default function Payments() {
    const navigate = useNavigate()
    const [createModal, setCreateModal] = useState(false)

    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  res  = await ApiRoute.payment.method.index.fn({
                shop_id: shopId(),
                quick_search : searchQuery,
                page : pageNumber
            },filterQuery)
            setData(res)
            setIsLoading(false)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">روش های پرداخت</span>
                </div>

                <div className="main-content mx-auto p-5 mt-6">
                    <table className="table-borderd-tr table-auto w-full mt-2 mx-auto text-xs block lg:table">
                        <thead>
                        <tr>
                            <td>عنوان</td>
                            <td className="min-w-[300px]">نامک</td>
                            <td className="min-w-[300px]">متد مادر</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>

                        { data && tableData.map( item =>
                            (
                                <tr key={item._id}>
                                    <td>{item.title}</td>
                                    <td>{item.name}</td>
                                    <td>{item.is_base ? "بله" : "خیر"}</td>
                                    <td className="flex justify-center items-center py-4">
                                        {item.is_base && <SwitchToggle  onSwitch={ async (c) => {
                                            await ApiRoute.payment.method.activeStatus.fn(item._id)
                                        } } onOff={item.active ?? false}/>}
                                        {item.is_base && <Link className="mx-2 leading-3 my-1" to={`/payment-methods/edit?id=${item._id}`} title="ویرایش"><FontAwesomeIcon
                                            className="bg-blue-2 text-white p-1 w-4 h-4 rounded" icon={faEdit}/></Link>}
                                    </td>
                                </tr>
                            )
                         ) }
                        </tbody>
                    </table>
                </div>
            </div>
        </Layout>
    )
}