import Layout from "../../components/Layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBarsStaggered, faCheckDouble, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import CustomMultiSelect from "../../components/Utilities/CustomMultiSelect";
import TimeTable from "../../components/Tables/TimeTable";
import ApiRoute, {shopId} from "../../helper/api";
import Pagination from "../../components/Pagination";
import SelectCoordinates from "../../components/Coordinates/SelectCoordinates";
import SwitchToggle from "../../components/Utilities/SwitchToggle";
import {faAdd} from "@fortawesome/free-solid-svg-icons/faAdd";
import { v4 as uuidv4 } from 'uuid';
import Modal from "../../components/Utilities/Modal";
import ShipmentClassFilterBox from "../../components/Shipment/ShipmentClassFilterBox";
import SearchBox from "../../components/SearchBox";
function SendDeliveryTime() {

    const navigate = useNavigate()
    const [createModal, setCreateModal] = useState(false)
    const [createDayModal, setCreateDayModal] = useState(false)
    const [selectedDay, setSelectedDay] = useState(null)
    const [selectedDayValue, setSelectedDayValue] = useState(0)
    const [newData, setNewData] = useState({
        title: ''
    })

    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState('0')
    const [update, setUpdate] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    const days = [
        {
            uid:"",
            day_code:0,
            day_label:"شنبه",
            picks:[]
        },
        {
            uid:"",
            day_code:1,
            day_label:"یکشنبه",
            picks:[]
        },
        {
            uid:"",
            day_code:2,
            day_label:"دوشنبه",
            picks:[]
        },
        {
            uid:"",
            day_code:3,
            day_label:"سه شنبه",
            picks:[]
        },
        {
            uid:"",
            day_code:4,
            day_label:"چهار شنبه",
            picks:[]
        },
        {
            uid:"",
            day_code:5,
            day_label:"پنج شنبه",
            picks:[]
        },
        {
            uid:"",
            day_code:6,
            day_label:"جمعه",
            picks:[]
        }
    ]

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  res  = await ApiRoute.shipment.delivery.index.fn({
                shop_id: shopId(),
                quick_search : searchQuery,
                page : pageNumber
            },filterQuery)
            setData(res)
            setIsLoading(false)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);
    useEffect(() => {
        filterQuery.quick_search = searchQuery
        setUpdateData(Date.now().toString())
    }, [filterQuery]);

    function addDay(){
        let selected = null;
        for (const d of days) {
            if (d.day_code == selectedDayValue){
                selected = d;
                selected.uid = uuidv4()
            }
        }
        tableData.map( (_item) => {
            if (_item._id === selectedDay._id){
                _item.periods.push(selected)
            }
        } )
        setUpdate(Date.now())
        setCreateDayModal(false)
    }
    async function handleCreate(){
        const res = await ApiRoute.shipment.delivery.create.fn(newData)
        if (res.status_code === 200){
            setUpdateData(Date.now().toString())
        }
    }
    async function handleUpdate(item){
        const res = await ApiRoute.shipment.delivery.update.fn(item._id, item)
    }

    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span
                    className="inline-block bg-white pl-3">کلاس های زمان ارسال</span>
                </div>

                <SearchBox searchIn={true} defaultSearchBy="title" SetSearchQuery={setSearchQuery}/>

                <ShipmentClassFilterBox SetFilterQuery={setFilterQuery}/>

                <div className="flex justify-between items-center px-2 py-2">
                    <span className="block text-right text-sm">لیست کلاس های زمان ارسال</span>
                    <button className="btn-purple py-2 w-36 mb-1" onClick={ () => {
                        setCreateModal(true)
                        setCreateDayModal(false)
                        setSelectedDay(null)
                    } }>افزودن</button>
                </div>

                {data && tableData.map( (item) => <div key={item._id} className="main-content mx-auto p-5">
                    <div className="grid grid-cols-1 lg:grid-cols-10 gap-3 items-end text-xs mb-6">
                        <div className="lg:col-span-3"><label className="!grid">
                            <span className="lg:text-center w-3/4 mb-3">عنوان</span>
                            <input onKeyUp={ (e) => { item.title = e.target.value } } className="bordered-input w-full" type="text"
                                   defaultValue={item.title}/>
                        </label></div>
                        <div className="lg:col-span-4">
                            <span className="lg:text-center w-3/4 mb-3">استان</span>
                            <SelectCoordinates defaultValueMultiple={true} multiple={true} defaultValue={ {
                                state: item.state,
                                city: item.city,
                                area:item?.area ?? []
                            } } SetCoordinate={ (v) =>{
                                item.state = v.state
                                item.city = v.city
                                item.area = v.area
                            } }/>
                        </div>
                        <div className={`flex items-center`}>
                            <span>مختص در محل</span>
                            <SwitchToggle  onSwitch={ async (c) => {
                                item.only_for_cod_payment = c
                            } } onOff={item.only_for_cod_payment}/>
                        </div>
                        <div className={`flex items-center`}>
                            <span>وضعیت</span>
                            <SwitchToggle  onSwitch={ async (c) => {
                                item.active = c
                            } } onOff={item.active}/>
                        </div>
                        <div className="pb-1 justify-end flex gap-2">
                            <button onClick={() => {
                                handleUpdate(item)
                            }} title="ویرایش و بروزرسانی"><FontAwesomeIcon
                                className="bg-green-1 text-white p-1 w-4 h-4 rounded" icon={faEdit}/></button>

                            <button onClick={ () => {setCreateDayModal(true); setSelectedDay(item)} } title="افزودن روز"><FontAwesomeIcon
                                className="bg-blue-1 text-white p-1 w-4 h-4 rounded" icon={faAdd}/></button>

                            <button onClick={ () => {
                                alert('پیاده سازی تمامی حذف ها در مراحل نهایی صورت خواهد گرفت')
                            } } title="؟؟؟"><FontAwesomeIcon
                                className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                        </div>
                    </div>
                    <TimeTable deliveryItem={item} />
                </div> )}

                {data && <Pagination SetPageNumber={setPageNumber} Data={paginationData}/>}

            </div>


            <Modal isOpen={createDayModal} onClose={() => {
                setCreateDayModal(false)
            }}
                   modalWidth="2xl:w-1/4">
                <div className="text-center text-13 mb-4">افزودن روز</div>
                <div className="grid gap-2">
                    <div>

                        <div className="flex items-center gap-2">
                            <select onChange={ (e) => {
                                setSelectedDayValue(e.target.value);
                            } } className={`input-x`}>
                                {days.map(day => <option value={day.day_code}>{day.day_label}</option>)}
                            </select>
                        </div>

                        <div className="flex gap-2 mt-4">
                            <button onClick={addDay} className="btn-green py-1 text-xs">افزودن</button>
                        </div>

                    </div>
                </div>

            </Modal>

            <Modal isOpen={createModal} onClose={() => {
                setCreateModal(false)
            }}
                   modalWidth="2xl:w-1/4">
                <div className="text-center mb-4">افزودن کلاس</div>
                <div className="grid gap-2">
                    <div>

                            <div><label>
                                <div className="mb-2 text-13">عنوان</div>
                                <input onKeyUp={ (e) => { newData.title = e.target.value } } className="bordered-input w-full" type="text"/>
                            </label></div>

                        <div className="flex gap-2 mt-4">
                            <button onClick={handleCreate} className="btn-green py-1 text-xs">افزودن</button>
                        </div>

                    </div>
                </div>

            </Modal>

        </Layout>
    )
}

export default SendDeliveryTime