import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faTrash, faEdit
} from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
import {getDateAsReadableFormat} from "../../helper/general";

function StoresTable(props) {
    const tableTitles = [
        'نام فروشگاه',
        'وضعیت',
        'رنج سفارش مشتریان',
        'رنج والد مشتریان',
        'تاریخ ایجاد فروشگاه',
    ]
    return (
        <table className="coupon-table table-auto w-full mt-4 block lg:table">
            <thead>
            <tr className="border-b border-b-gray-7">
                {tableTitles.map((item, id) => (
                    <td className="text-13 text-gray-6 pb-2" key={id}>{item}</td>
                ))}
                <td></td>
                <td></td>
            </tr>
            </thead>
            <tbody>
            {props.tableData.map((item, id) => (
                    <tr className={`text-xs py-2`} key={id}>
                        <td>{item.title}</td>
                        <td>{item?.active ? 'فعال' : 'غیرفعال' }</td>
                        <td>{item.order_key}</td>
                        <td>{item.customer_key}</td>
                        <td>{getDateAsReadableFormat(item.created_at)}</td>
                        <td className="flex justify-center items-center py-3">
                            <Link className="mx-2" to={`/stores/edit?id=${item._id}`} title="ویرایش"><FontAwesomeIcon
                                className="bg-purple-3 text-white p-1 w-4 h-4 rounded" icon={faEdit}/></Link>
                            <button className="mx-2" onClick={ () => {
                                alert('پیاده سازی تمامی حذف ها در مراحل نهایی صورت خواهد گرفت')
                            } } title="حذف"><FontAwesomeIcon
                                className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                        </td>

                    </tr>
                )
            )}
            </tbody>
        </table>
    )
}

export default StoresTable