import ProductSelect from "../../../Product/ProductSelect";
import Modal from "../../../Utilities/Modal";
import {useState} from "react";
import ApiRoute from "../../../../helper/api";
import CouponSelect from "../../../Common/CouponSelect";
import Toman from "../../../Utilities/Toman";
import {DatePicker} from "zaman";
import moment from "jalali-moment";
import {faNumberToEn} from "../../../../helper/general";
import CustomerAddressList from "../../../Customer/CustomerAddressList";

export default function OrderConvertToDispatchModal({order, setUpdateState , closeModal , event = null}){

    const [data , setData] = useState({
        address :null,
        shipping_in_person :true
    })
    const [selectedAddress , setSelectedAddress] = useState()

    async function handleSubmit(){
        const res = await ApiRoute.order.shipment.updateAddress.fn(order._id,selectedAddress.id,{
            address : selectedAddress,
            customer_id: order.customer_id
        })
        if(res?.status_code === 200){
            setUpdateState(Date.now())
            closeModal()
            if (event !== null){
                event()
            }
        }
    }

    return (
        <Modal isOpen={true} onClose={closeModal}>
            <div className="page-title mb-5"><span
                className="inline-block bg-white pl-3 text-sm text-purple-1">تبدیل سفارش به ارسالی</span>
            </div>
            <div className="form">
                <div className="form">
                    <CustomerAddressList setSelected={ (x) => {
                        setSelectedAddress(x)
                    } } selectAble={true} selected={selectedAddress} customClass={`main-content !shadow-none mx-auto p-0 mt-12`} customer={order.customer}/>

                </div>
                <div className="grid grid-cols-4 items-end gap-4">
                    <div className="col-span-1">
                        <button onClick={handleSubmit} className="btn-purple py-2 w-full">تبدیل به ارسالی</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}