import Layout from "../../components/Layout";
import {Link} from "react-router-dom";
import {Editor} from "@tinymce/tinymce-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";

function CreatePriceList() {
    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">صفحه جدید</span>
                </div>
                <div
                    className="border border-gray-7 rounded-md grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3 xl:gap-6 text-xs px-2 py-3 items-center mb-4">
                    <div><label className="flex items-center">
                        <span className="pl-2"> عنوان صفحه</span>
                        <input className="bordered-input-7 flex-auto" type="text"/>
                    </label></div>
                    <div><label className="flex items-center">
                        <span className="pl-2">نامک</span>
                        <input className="bordered-input-7 flex-auto" type="text"/>
                    </label></div>
                    <div className="sm:col-span-2 xl:col-span-1">
                        <div className="flex items-center gap-3">
                            <label className="flex items-center flex-auto">
                                <span className="pl-2">وضعیت</span>
                                <select className="bordered-input-7 flex-auto">
                                    <option value="">منتشر شده</option>
                                    <option value="">پیش نویس</option>
                                </select>
                            </label>

                            <Link to="" className="btn-purple w-max px-6 py-1.5">ویرایش</Link>
                            <Link to="" title="???"><FontAwesomeIcon
                                className="bg-gray-3 text-white p-1 mt-1 w-4 h-4 rounded" icon={faEye}/></Link>
                            <Link to="" title="???"><FontAwesomeIcon
                                className="bg-green-1 text-white p-1 mt-1 w-4 h-4 rounded"
                                icon={faUpRightFromSquare}/></Link>

                        </div>
                    </div>
                </div>
                
                <div
                    className="border border-gray-7 rounded-md grid grid-cols-1 sm:grid-cols-2 gap-3 xl:gap-6 text-xs px-2 py-3 items-center mb-4">
                    <div><label className="flex items-center">
                        <span className="pl-2">انتخاب قالب</span>
                        <input className="bordered-input-7 bg-gray-1 flex-auto" type="text" placeholder="جستجو" />
                    </label></div>
                </div>


                <div
                    className="border border-gray-7 rounded-md grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-5 gap-6 text-xs py-3 items-center px-6 xl:px-20 mt-4">
                    <div className="sm:col-span-2 xl:col-span-1">
                        <span className="block">تنظیم نحوه نمایش و فیلتر محصولات</span>
                    </div>
                    <div className="xl:col-span-2 grid lg:flex gap-5 items-center">
                            <span className="flex flex-auto items-center">
                             <span className="pl-2">برچسب</span>
                            <input className="bordered-input-7 flex-auto" type="text"/>
                        </span>
                    </div>
                    <div className="xl:col-span-2">
                        <label className="flex items-center">
                            <span className="pl-2">برند</span>
                            <input className="bordered-input-7 flex-auto" type="text"/>
                        </label>
                    </div>
                </div>

                <div className="border border-gray-7 rounded-md p-3 mt-4">
                    <div className="page-title mb-3"><span className="inline-block bg-white pl-3 text-xs">تنظیمات دکمه ها</span>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 2xl:grid-cols-9 gap-3 text-xs">
                        <div className="grid grid-rows-2 gap-2 border border-gray-7 rounded-md p-2">
                            <label className="flex items-center">
                                <span className="w-1/5">عنوان</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                            <label className="flex items-center">
                                <span className="w-1/5">لینک</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                        </div>
                        <div className="grid grid-rows-2 gap-2 border border-gray-7 rounded-md p-2">
                            <label className="flex items-center">
                                <span className="w-1/5">عنوان</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                            <label className="flex items-center">
                                <span className="w-1/5">لینک</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                        </div>
                        <div className="grid grid-rows-2 gap-2 border border-gray-7 rounded-md p-2">
                            <label className="flex items-center">
                                <span className="w-1/5">عنوان</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                            <label className="flex items-center">
                                <span className="w-1/5">لینک</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                        </div>
                        <div className="grid grid-rows-2 gap-2 border border-gray-7 rounded-md p-2">
                            <label className="flex items-center">
                                <span className="w-1/5">عنوان</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                            <label className="flex items-center">
                                <span className="w-1/5">لینک</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                        </div>
                        <div className="grid grid-rows-2 gap-2 border border-gray-7 rounded-md p-2">
                            <label className="flex items-center">
                                <span className="w-1/5">عنوان</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                            <label className="flex items-center">
                                <span className="w-1/5">لینک</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                        </div>
                        <div className="grid grid-rows-2 gap-2 border border-gray-7 rounded-md p-2">
                            <label className="flex items-center">
                                <span className="w-1/5">عنوان</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                            <label className="flex items-center">
                                <span className="w-1/5">لینک</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                        </div>
                        <div className="grid grid-rows-2 gap-2 border border-gray-7 rounded-md p-2">
                            <label className="flex items-center">
                                <span className="w-1/5">عنوان</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                            <label className="flex items-center">
                                <span className="w-1/5">لینک</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                        </div>
                        <div className="grid grid-rows-2 gap-2 border border-gray-7 rounded-md p-2">
                            <label className="flex items-center">
                                <span className="w-1/5">عنوان</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                            <label className="flex items-center">
                                <span className="w-1/5">لینک</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                        </div>
                        <div className="grid grid-rows-2 gap-2 border border-gray-7 rounded-md p-2">
                            <label className="flex items-center">
                                <span className="w-1/5">عنوان</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                            <label className="flex items-center">
                                <span className="w-1/5">لینک</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                        </div>
                        <div className="grid grid-rows-2 gap-2 border border-gray-7 rounded-md p-2">
                            <label className="flex items-center">
                                <span className="w-1/5">عنوان</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                            <label className="flex items-center">
                                <span className="w-1/5">لینک</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                        </div>
                        <div className="grid grid-rows-2 gap-2 border border-gray-7 rounded-md p-2">
                            <label className="flex items-center">
                                <span className="w-1/5">عنوان</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                            <label className="flex items-center">
                                <span className="w-1/5">لینک</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                        </div>
                        <div className="grid grid-rows-2 gap-2 border border-gray-7 rounded-md p-2">
                            <label className="flex items-center">
                                <span className="w-1/5">عنوان</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                            <label className="flex items-center">
                                <span className="w-1/5">لینک</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                        </div>
                        <div className="grid grid-rows-2 gap-2 border border-gray-7 rounded-md p-2">
                            <label className="flex items-center">
                                <span className="w-1/5">عنوان</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                            <label className="flex items-center">
                                <span className="w-1/5">لینک</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                        </div>
                        <div className="grid grid-rows-2 gap-2 border border-gray-7 rounded-md p-2">
                            <label className="flex items-center">
                                <span className="w-1/5">عنوان</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                            <label className="flex items-center">
                                <span className="w-1/5">لینک</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                        </div>
                        <div className="grid grid-rows-2 gap-2 border border-gray-7 rounded-md p-2">
                            <label className="flex items-center">
                                <span className="w-1/5">عنوان</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                            <label className="flex items-center">
                                <span className="w-1/5">لینک</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                        </div>
                        <div className="grid grid-rows-2 gap-2 border border-gray-7 rounded-md p-2">
                            <label className="flex items-center">
                                <span className="w-1/5">عنوان</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                            <label className="flex items-center">
                                <span className="w-1/5">لینک</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                        </div>
                        <div className="grid grid-rows-2 gap-2 border border-gray-7 rounded-md p-2">
                            <label className="flex items-center">
                                <span className="w-1/5">عنوان</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                            <label className="flex items-center">
                                <span className="w-1/5">لینک</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                        </div>
                        <div className="grid grid-rows-2 gap-2 border border-gray-7 rounded-md p-2">
                            <label className="flex items-center">
                                <span className="w-1/5">عنوان</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                            <label className="flex items-center">
                                <span className="w-1/5">لینک</span>
                                <input className="bordered-input-7 flex-auto w-4/5" type="text"/>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="border border-gray-7 rounded-md p-3 mt-4">
                    <div className="page-title mb-3"><span className="inline-block bg-white pl-3 text-xs">سئو</span>
                    </div>
                    <label className="flex items-center text-xs mb-4">
                        <span className="pl-2 w-16">عنوان</span>
                        <input className="bordered-input-7 flex-auto" type="text"/>
                    </label>
                    <label className="flex items-center text-xs">
                        <span className="pl-2 w-16">توضیحات</span>
                        <input className="bordered-input-7 flex-auto" type="text"/>
                    </label>
                </div>
                <div className="border border-gray-7 rounded-md p-3 mt-4">
                    <div className="page-title mb-3"><span className="inline-block bg-white pl-3 text-xs">پرسش و پاسخ اسکیما</span>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-1 lg:gap-y-4">
                        <div><label className="flex items-center text-xs">
                            <span className="pl-4">پرسش</span>
                            <input className="bordered-input-7 flex-auto" type="text"/>
                        </label></div>
                        <div className="max-lg:mb-6"><label className="flex items-center text-xs">
                            <span className="pl-4">پاسخ</span>
                            <textarea className="bordered-input-7 flex-auto" cols="30" rows="3"></textarea>
                        </label></div>


                        <div><label className="flex items-center text-xs">
                            <span className="pl-4">پرسش</span>
                            <input className="bordered-input-7 flex-auto" type="text"/>
                        </label></div>
                        <div className="max-lg:mb-6"><label className="flex items-center text-xs">
                            <span className="pl-4">پاسخ</span>
                            <textarea className="bordered-input-7 flex-auto" cols="30" rows="3"></textarea>
                        </label></div>


                        <div><label className="flex items-center text-xs">
                            <span className="pl-4">پرسش</span>
                            <input className="bordered-input-7 flex-auto" type="text"/>
                        </label></div>
                        <div className="max-lg:mb-6"><label className="flex items-center text-xs">
                            <span className="pl-4">پاسخ</span>
                            <textarea className="bordered-input-7 flex-auto" cols="30" rows="3"></textarea>
                        </label></div>


                        <div><label className="flex items-center text-xs">
                            <span className="pl-4">پرسش</span>
                            <input className="bordered-input-7 flex-auto" type="text"/>
                        </label></div>
                        <div><label className="flex items-center text-xs">
                            <span className="pl-4">پاسخ</span>
                            <textarea className="bordered-input-7 flex-auto" cols="30" rows="3"></textarea>
                        </label></div>
                    </div>

                </div>
            </div>


        </Layout>
    )
}

export default CreatePriceList