import {useRef, useState} from "react";
import {hasAccess} from "../../helper/permissions";

export default function InventoryFilterStateTab({options , selectedTab ,setSelectedTab}){
    const myRef = useRef(null);
    const filters = [
        {
            title: 'همه',
            quantity: options?.all ?? 0,
            hasAccess:true,
            name: '',
            active: true
        }, {
            title: 'موجود',
            name: 'in_stock',
            quantity: options?.in_stock ?? 0,
            hasAccess:true,
            active: false
        }, {
            title: 'کمبود موجودی',
            name: 'less_stock',
            quantity: options?.less_stock ?? 0,
            hasAccess:true,
            active: false
        },
        {
            title: 'ناموجود',
            name: 'out_stock',
            quantity: options?.out_stock ?? 0,
            hasAccess:true,
            active: false
        },
        {
            title: 'رزرو ها',
            name: 'reservation',
            hasAccess:hasAccess('order_reservation inventory'),
            quantity: options?.reservation ?? 0,
            active: false
        },
        {
            title: 'قیمت منقضی شده',
            name: 'expired_price',
            hasAccess:hasAccess('price_expiration inventory'),
            quantity: options?.expired_price ?? 0,
            active: false
        },
    ];

    function getFilters(){
        return filters
    }
    function active(item , el){
        setSelectedTab(item.name)
    }

    return(
        <>
            <div className="border-b border-b-gray-7 p-3 lg:p-2 max-lg:flex overflow-x-auto" ref={myRef}>
                {getFilters().filter( item => item.hasAccess ).map((item, id)=>(
                    <button
                        key={id}
                        onClick={ e => {
                            active(item , e)
                        } }
                        className={`text-xs w-max ml-4 whitespace-nowrap${selectedTab == item.name ? ' text-purple-1':''}`} key={id}>
                        {item.title}
                        &nbsp;
                        (
                        {item.quantity}
                        )
                    </button>
                ))}
            </div>
        </>
    )
}