// actions.js
export const toggleSidebar = () => ({
    type: 'TOGGLE_SIDEBAR',
});
export const toggleSubmenu = (submenuId) => ({
    type: 'TOGGLE_SUBMENU',
    payload: submenuId,
});

export const toggleLoading = () => ({
    type: 'TOGGLE_LOADING',
});
