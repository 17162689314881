import React, { useState } from 'react';

const InputRange = () => {
    const [value, setValue] = useState(0); // Initial value

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <div className="flex items-center">
            <div className="slidecontainer relative flex flex-auto items-center pl-14 py-0.5">
                <input
                    type="range"
                    id="myRange"
                    className="customSlider z-20"
                    name="rangeInput"
                    min={0}
                    max={5}
                    step={1} // Set your desired step value
                    value={value}
                    onChange={handleChange}
                />
                <div className="flex justify-between w-full mx-auto absolute left-0 right-0 pl-16 pr-2 z-10">
                    <span className="text-11 pt-0.5"> </span>
                    <span className="text-11 pt-0.5">|</span>
                    <span className="text-11 pt-0.5">|</span>
                    <span className="text-11 pt-0.5">|</span>
                    <span className="text-11 pt-0.5">|</span>
                    <span className="text-11 pt-0.5">|</span>
                </div>
            </div>
            <span className="font-sans font-bold mr-3">{value}</span>
        </div>
    );
};

export default InputRange;
