import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import Toman from "../Utilities/Toman";
import {useEffect, useState} from "react";
import ApiRoute from "../../helper/api";

function CustomerTransaction( {customer} ){

    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({})
    const [updateData, setUpdateData] = useState('0')

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const res  = await ApiRoute.payment.transactionsByCustomerAndType.fn(customer._id , 'wallet_deposit,wallet_withdraw')
            setData(res.results)
            setIsLoading(false)
        })();
    },[updateData]);

    function TypeOfTransaction({type}){
        switch (type){
            case 'PaymentGatewayWalletShopCredit': return 'واریز فروشگاه/سایت (ادمین)'
            case 'PaymentGatewayWalletDeposit': return 'واریز سیستمی'
            default: return type
        }
    }

    function TransactionDesc({type , reference_id, transaction_id}){
        switch (type){
            case 'PaymentGatewayWalletShopCredit': return `افزایش موجودی کیف پول از طریق سایت به شماره تراکنش ${transaction_id}`
            case 'PaymentGatewayWalletDeposit': return `افزایش موجودی کیف پول به صورت سیستمی به شماره تراکنش ${transaction_id}`
            default: return `افزایش موجودی کیف پول از طریق سایت به شماره تراکنش ${transaction_id}`
        }
    }
    return (
        <div className="main-content mx-auto p-5 mt-12">
            <div className="flex w-full justify-between">
                <span className="text-purple-1">کیف پول</span>
                <Link className="btn-outline-purple py-1 px-8" to={`/customers/wallet/deposit/${customer._id}`}>شارژ کیف پول</Link>
            </div>

            <div className="border border-gray-7 rounded-xl mt-3 p-3">
                <div className="grid gap-3 sm:flex w-full justify-between">
                    <span className="block text-purple-1 text-13">تراکنش های کیف پول مشتری</span>
                    <span className="block bg-purple-5 text-white text-xs rounded px-2 pt-1 pb-0.5"> موجودی کیف پول:{customer.wallet_balance.toLocaleString()} <Toman customClass={'fill-white d-inline-flex'}/></span>
                </div>

                {!isLoading ? (
                    <table className="table-auto w-full mx-auto mt-4 block md:table">
                        <tbody>
                        {data.data.map( (item,index) => (
                            <tr key={index} className="text-xs before checked">
                                <td className="py-3 pr-5 text-right">
                                    <span className="text-gray-2">نوع تراکنش:</span>
                                    <span className="text-gray-3 mr-1"><TypeOfTransaction type={item.payment_gateway}/></span>
                                </td>
                                <td className="py-3 pr-5 text-right">
                                    <div className="flex items-center">
                                        <span className="text-gray-2">مبلغ:</span>
                                        <span className="text-gray-3 flex items-center mr-1">{item.amount.toLocaleString()} <Toman customClass={'fill-gray-3 mr-0.5'}/></span>
                                    </div>
                                </td>
                                <td className="py-3 pr-5 text-right">
                                    <span className="text-gray-2">زمان تراکنش:</span>
                                    <span className="text-gray-3 mr-1">{new Intl.DateTimeFormat('fa-IR',{
                                        timeStyle: "medium",
                                        dateStyle: "medium",
                                    }).format((new Date(item.created_at)))}</span>
                                </td>
                                <td className="py-3 pr-5 text-right">
                                    <span className="text-gray-2">توضیحات:</span>
                                    <span className="text-gray-3 mr-1"><TransactionDesc type={item.payment_gateway} reference_id={item.reference_id} transaction_id={item.transaction_id}/></span>
                                </td>
                            </tr>
                        ) )}
                        </tbody>
                    </table>
                ) : ''}

                {/*<table className="table-auto w-full mx-auto mt-4 block md:table">
                    <tbody>
                    <tr className="text-xs before checked">
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">نوع تراکنش:</span>
                            <span className="text-gray-3 mr-1">واریز آنلاین</span>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <div className="flex items-center">
                                <span className="text-gray-2">مبلغ:</span>
                                <span className="text-gray-3 flex items-center mr-1">10,250,500 <Toman customClass={'fill-gray-3 mr-0.5'}/></span>
                            </div>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">زمان تراکنش:</span>
                            <span className="text-gray-3 mr-1">1402/01/31 17:20</span>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">توضیحات:</span>
                            <span className="text-gray-3 mr-1">افزایش موجودی کیف پول از طریق درگاه آنلاین به شماره تراکنش 156589</span>
                        </td>
                    </tr>
                    <tr className="text-xs before before-purple">
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">نوع تراکنش:</span>
                            <span className="text-gray-3 mr-1">هدیه</span>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <div className="flex items-center">
                                <span className="text-gray-2">مبلغ:</span>
                                <span className="text-gray-3 flex items-center mr-1">10,250,500 <Toman customClass={'fill-gray-3 mr-0.5'}/></span>
                            </div>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">زمان تراکنش:</span>
                            <span className="text-gray-3 mr-1">1402/01/31 17:20</span>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">توضیحات:</span>
                            <span className="text-gray-3 mr-1">هدیه خرید ماشین لباسشویی اسنوا مدل فلان در به صورت آنلاین</span>
                        </td>
                    </tr>
                    <tr className="text-xs before checked">
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">نوع تراکنش:</span>
                            <span className="text-gray-3 mr-1">واریز آنلاین</span>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <div className="flex items-center">
                                <span className="text-gray-2">مبلغ:</span>
                                <span className="text-gray-3 flex items-center mr-1">10,250,500 <Toman customClass={'fill-gray-3 mr-0.5'}/></span>
                            </div>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">زمان تراکنش:</span>
                            <span className="text-gray-3 mr-1">1402/01/31 17:20</span>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">توضیحات:</span>
                            <span className="text-gray-3 mr-1">افزایش موجودی کیف پول از طریق درگاه آنلاین به شماره تراکنش 156589</span>
                        </td>
                    </tr>
                    <tr className="text-xs before before-blue">
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">نوع تراکنش:</span>
                            <span className="text-gray-3 mr-1">واریز ادمین</span>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <div className="flex items-center">
                                <span className="text-gray-2">مبلغ:</span>
                                <span className="text-gray-3 flex items-center mr-1">10,250,500 <Toman customClass={'fill-gray-3 mr-0.5'}/></span>
                            </div>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">زمان تراکنش:</span>
                            <span className="text-gray-3 mr-1">1402/01/31 17:20</span>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">توضیحات:</span>
                            <span className="text-gray-3 mr-1">افزایش موجودی کیف پول از طریق درگاه آنلاین به شماره تراکنش 156589</span>
                        </td>
                    </tr>
                    <tr className="text-xs before checked">
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">نوع تراکنش:</span>
                            <span className="text-gray-3 mr-1">واریز ادمین</span>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <div className="flex items-center">
                                <span className="text-gray-2">مبلغ:</span>
                                <span className="text-gray-3 flex items-center mr-1">10,250,500 <Toman customClass={'fill-gray-3 mr-0.5'}/></span>
                            </div>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">زمان تراکنش:</span>
                            <span className="text-gray-3 mr-1">1402/01/31 17:20</span>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">توضیحات:</span>
                            <span className="text-gray-3 mr-1">افزایش موجودی کیف پول از طریق درگاه آنلاین به شماره تراکنش 156589</span>
                        </td>
                    </tr>
                    <tr className="text-xs before before-red">
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">نوع تراکنش:</span>
                            <span className="text-gray-3 mr-1">خرید کالا</span>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <div className="flex items-center">
                                <span className="text-gray-2">مبلغ:</span>
                                <span className="text-gray-3 flex items-center mr-1">10,250,500 <Toman customClass={'fill-gray-3 mr-0.5'}/></span>
                            </div>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">زمان تراکنش:</span>
                            <span className="text-gray-3 mr-1">1402/01/31 17:20</span>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">توضیحات:</span>
                            <span className="text-gray-3 mr-1">افزایش موجودی کیف پول از طریق درگاه آنلاین به شماره تراکنش 156589</span>
                        </td>
                    </tr>
                    <tr className="text-xs before checked">
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">نوع تراکنش:</span>
                            <span className="text-gray-3 mr-1">واریز آنلاین</span>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <div className="flex items-center">
                                <span className="text-gray-2">مبلغ:</span>
                                <span className="text-gray-3 flex items-center mr-1">10,250,500 <Toman customClass={'fill-gray-3 mr-0.5'}/></span>
                            </div>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">زمان تراکنش:</span>
                            <span className="text-gray-3 mr-1">1402/01/31 17:20</span>
                        </td>
                        <td className="py-3 pr-5 text-right">
                            <span className="text-gray-2">توضیحات:</span>
                            <span className="text-gray-3 mr-1">افزایش موجودی کیف پول از طریق درگاه آنلاین به شماره تراکنش 156589</span>
                        </td>
                    </tr>
                    </tbody>
                </table>*/}

                {/*<Pagination/>*/}
            </div>
        </div>
    )
}
export default CustomerTransaction