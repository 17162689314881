import { useState } from 'react';
import ApiRoute from "../../helper/api";
import AsyncSelect from "react-select/async";

export default function AttributeSelect({ onChange, defaultValue }) {
    const [selectedAttributeGroup, setSelectedAttributeGroup] = useState(defaultValue || null);
    
    const loadOptions = async (inputValue) => {
        try {
            const response = await ApiRoute.product.attributes.index.fn({
                label: inputValue
            });
            
            return response.results.data.map(item => ({
                label: item.label,
                _id: item._id
            }));
        } catch (error) {
            console.error('Error searching attributes:', error);
            return [];
        }
    };

    const handleChange = (selected) => {
        setSelectedAttributeGroup(selected);
        if (onChange) {
            onChange(selected);
        }
    };

    return (
        <AsyncSelect
            menuPosition='fixed'
            cacheOptions
            defaultOptions
            value={selectedAttributeGroup}
            defaultValue={defaultValue}
            onChange={handleChange}
            loadOptions={loadOptions}
            placeholder={' ویژگی را انتخاب کنید'}
            noOptionsMessage={() => 'موردی یافت نشد'}
            loadingMessage={() => 'در حال جستجو...'}
            getOptionValue={(option) => option._id}
            getOptionLabel={(option) => option.label}
            isMulti={false}
            isClearable={true}
        />
    );
}   