import {Calendar, DateObject} from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import "react-multi-date-picker/styles/layouts/mobile.css"
import {useState} from "react";
import SelectCoordinates from "../Coordinates/SelectCoordinates";
export default function ShipmentClassFilterBox(props) {
    const [status, setStatus] = useState('')
    const [legal, setLegal] = useState(null)
    const [coordinate, setCoordinate] = useState([])
    return (
        <div className={` `}>
            <div className="pt-3 pb-4">
                <div className="main-content p-3">
                    <div className="flex flex-wrap gap-3 text-xs">
                        <div>
                            <span className="block mb-1">موقعیت</span>
                            <SelectCoordinates multiple={false} SetCoordinate={ setCoordinate }/>
                        </div>


                        <div className="flex items-end">
                            <button onClick={ e => {
                                props.SetFilterQuery({
                                    coordinate:coordinate
                                })
                            } } className="btn-orange w-full py-2">اعمال فیلتر</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}