import '../css/AccessRestricted.css';
import {Link, useNavigate} from "react-router-dom";
export default function AccessRestricted(){
    const navigate = useNavigate();
    return (
        <div className={`access-restricted`}>
            <div className="message text-white">
                <h1>دسترسی به این صفحه/عمل محدود شده است</h1>
                <p>اگر فکر می کنید اشتباه است، لطفا با مدیر / تیم فنی چک کنید.</p>
                <Link to={`/`} className={`mt-3 underline`}>برگشت</Link>
            </div>
        </div>
    )
}