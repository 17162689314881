import {
    faBagShopping, faBars,
    faBox, faChartSimple, faCircleQuestion, faClock, faComments, faEdit, faFileExport, faFileLines,
    faGrip, faList, faLocation, faPager, faSackDollar, faScaleBalanced, faSliders,
    faStore, faTags, faSortDown,
    faTruck, faTruckFast, faUser, faUsers, faUserTie,
    faWarehouse,
    faNewspaper, faSync
} from "@fortawesome/free-solid-svg-icons";
import {connect} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {toggleSidebar, toggleSubmenu} from "./ReduxComponents/action";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useToken from "../useToken";
import {useEffect, useState} from "react";


function SideBar({sidebarToggle, onToggleSidebar, submenuToggled, onToggleSubmenu}) {
    const location = useLocation();
    const { token,resetToken } = useToken();

    const toggleSubMenu = (item) => {
        if (item.subMenus) {
            if (!sidebarToggle) onToggleSidebar();

            // Close any other open submenus
            const otherOpenSubmenus = submenuToggled.filter(id => id !== item.id);
            otherOpenSubmenus.forEach(id => onToggleSubmenu(id));

            // Open the clicked submenu
            onToggleSubmenu(item.id);
        } else {
            const otherOpenSubmenus = submenuToggled.filter(id => id !== item.id);
            otherOpenSubmenus.forEach(id => onToggleSubmenu(id));
        }
    };

    const hasPermission = function (scope = null){
        if (scope === null) return true;

         token.employee.permissions.map( permission => {
            if (permission.name === scope){
                console.log(permission.name)
                return true
            }
        } )
        return false;
    }

    const [menu,setMenu] = useState([
        {
            id: 1,
            title: 'پیشخوان',
            link: '/',
            icon: faGrip,
            has_permission:null,
        },
        {
            id: 2,
            title: 'سفارشات',
            link: '/orders',
            icon: faBagShopping,
            has_permission:"order"
        },
        {
            id: 3,
            title: 'پیش سفارشات',
            link: '/pre-order',
            icon: faBagShopping,
            has_permission:"order pre"
        },
        {
            id: 4,
            title: 'سفارشات در حال ارسال',
            link: '/shipping-order',
            icon: faTruck,
            has_permission:"shop shipment_orders"
        },
        {
            id: 5,
            title: 'فروشگاه ها',
            link: '#',
            icon: faStore,
            subMenus: [
                {
                    id: 1,
                    title: 'لیست فروشگاهها',
                    link: '/stores'
                },
                {
                    id: 2,
                    title: 'سفارشات',
                    link: '/stores/orders'
                }
            ],
            has_permission:"shop"

        },
        {
            id: 6,
            title: 'محصولات',
            link: '#',
            icon: faBox,
            has_permission:"product",
            subMenus: [
                {
                    id: 1,
                    title: 'لیست محصولات',
                    link: '/products'
                },
                {
                    id: 2,
                    title: 'دسته بندی ها',
                    link: '/products/categories'
                },
                {
                    id: 2,
                    title: 'برچسب ها',
                    link: '/products/tags'
                },
                {
                    id: 3,
                    title: 'ویژگی محصولات',
                    link: '/products/attributes'
                },
                /*                {
                                    id: 3,
                                    title: 'مرتب سازی ویژگی',
                                    link: '/products/attributes/sorting'
                                },*/
                {
                    id: 3,
                    title: 'پراکسی ویژگی',
                    link: '/products/attributes/proxy'
                },
                {
                    id: 3,
                    title: 'گروه های ویژگی',
                    link: '/products/attributes/groups'
                },
                {
                    id: 4,
                    title: 'اطلاع رسانی موجودی کالا',
                    link: '/alert-stock'
                },
                {
                    id: 5,
                    title: 'مدیریت موجودی',
                    link: '/manage-stock'
                },
            ]
        },
        {
            id: 7,
            title: 'انبار داری',
            link: '/inventory',
            icon: faWarehouse,
            has_permission:"inventory"
        },
        {
            id: 8,
            title: 'کمپین ها',
            link: '/campaigns',
            icon: faClock,
            has_permission:"campaigns"
        },
        {
            id: 9,
            title: 'کوپن ها',
            link: '/coupons',
            icon: faTags,
            has_permission:"discount"
        },
        {
            id: 10,
            title: 'مشتریان',
            link: '/customers',
            icon: faUser,
            has_permission:"customer"
        },
        /*{
            id: 11,
            title: 'مدیریت کاربران',
            link: '#',
            icon: faUsers,

            subMenus: [
                {
                    id: 1,
                    title: 'لیست کاربران',
                    link: '/users'
                },
                {
                    id: 2,
                    title: 'دسترسی ها',
                    link: '/users/access'
                }
            ]
        },*/
        {
            id: 12,
            title: 'فهرست ها',
            link: '/menus',
            icon: faBars,
            has_permission:"menu"
        },
        {
            id: 13,
            title: 'دیدگاه ها',
            link: '/comments',
            icon: faComments,
            has_permission:"comment"
        },
        {
            id: 14,
            title: 'پرسش و پاسخ',
            link: '/faqs',
            icon: faCircleQuestion,
            has_permission:"fag"
        },
        {
            id: 15,
            title: 'لیست قیمت',
            link: '/price-list',
            icon: faList,
            has_permission:"pricelist"
        },
        {
            id: 16,
            title: 'صفحات',
            link: '/pages',
            icon: faFileLines,
            has_permission:"page"
        },
        {
            id: 18,
            title: 'خروجی',
            link: '/export',
            icon: faFileExport,
            has_permission:"export"
        },
        {
            id: 19,
            title: 'تنظیمات',
            link: '#',
            icon: faSliders,
            subMenus: [
                {
                    id: 1,
                    title: 'تنظیمات کلی',
                    link: '/setting/general-setting'
                },
                {
                    id: 3,
                    title: 'انبار ها',
                    link: '/setting/storages'
                },
                {
                    id: 4,
                    title: 'باربری ها',
                    link: '/setting/transports'
                },
            ],
            has_permission:"setting"
        },
        {
            id: 20,
            title: 'قیمت گذاری',
            link: '#',
            icon: faScaleBalanced,
            subMenus: [
                {
                    id: 1,
                    title: 'قوانین قیمت گذاری',
                    link: '/pricing-rules'
                },
                {
                    id: 4,
                    title: 'قوانین به روز رسانی قیمت',
                    link: '/pricing-rules/price-update-rules'
                },
            ],
            has_permission:"pricemanage"
        },
        {
            id: 21,
            title: 'حمل و نقل',
            link: '#',
            icon: faTruckFast,
            subMenus: [
                {
                    id: 1,
                    title: 'کلاس های ارسال',
                    link: '/shipping'
                },
                {
                    id: 2,
                    title: 'کلاس های زمان ارسال',
                    link: '/shipping/delivery-time'
                },
                {
                    id: 3,
                    title: 'کلاس های زمان تحویل حضوری',
                    link: '/shipping/in-person-delivery-time'
                },
                {
                    id: 6,
                    title: 'تنظیمات شروع کلاس زمان تحویل',
                    link: '/shipping/delivery-time-start'
                },
                {
                    id: 7,
                    title: 'تنظیمات تاریخ کلاس زمان تحویل',
                    link: '/shipping/delivery-time-unavailable'
                },
                {
                    id: 5,
                    title: 'راننده ها',
                    link: '/shipping/drivers-setting'
                },
            ],
            has_permission:"shipment setting"
        },
        {
            id: 22,
            title: 'روش های پرداخت',
            link: '/payment-methods',
            icon: faSackDollar,
            has_permission:"payment_method setting"
        },
        {
            id: 23,
            title: 'مدیریت کارمندان',
            link: '#',
            icon: faUserTie,

            subMenus: [
                {
                    id: 1,
                    title: 'کارمندان',
                    link: '/employees'
                },
                {
                    id: 2,
                    title: 'دسترسی ها',
                    link: '/employees/access'
                },
                /*                {
                                    id: 3,
                                    title: 'کارمندان فروشگاه',
                                    link: '/employees-store'
                                }*/
            ],
            has_permission:"employee"
        },
        {
            id: 24,
            title: 'مختصات جغرافیایی',
            link: '/coordinates/states',
            icon: faLocation,
            has_permission:"coordinates setting"
        },
        {
            id: 25,
            title: 'فرم ها',
            link: '/forms',
            icon: faPager,
            has_permission:"form setting"
        },
        {
            id: 26,
            title: 'گزارشات',
            link: '#',
            icon: faChartSimple,

            subMenus: [
            ],
            has_permission:"analytics"
        },
        {
            id: 27,
            title: 'قالب ها',
            link: '/templates',
            icon: faNewspaper,
            has_permission:"appearance"
        },
        {
            id: 29,
            title: 'ادغام سازی',
            link: '#',
            icon: faSync,

            subMenus: [
                {
                    id: 1,
                    title: 'محصولات اسنپ-شاپ',
                    link: '/integrate/snapp-shop'
                },
                {
                    id: 2,
                    title: 'محصولات دیجی-شهر',
                    link: '/integrate/dgshahr-shop'
                },
            ],
            has_permission:"integrate"
        },
    ])

    function getMenu(){
        let arr = []
        let _menu = menu
        _menu.map( item => {
            token.employee.permissions.map( permission => {
                if (permission.name === item.has_permission){
                    arr.push(item)
                }
            } )
        } )
        return arr
    }

    function HasPermission({children, permission}){
        return children;
    }

    return (
        <>
            <div className={`bg-bg-opacity opacity-30 fixed inset-0 z-20 lg:hidden ${sidebarToggle ?'block':'hidden'}`} onClick={onToggleSidebar}></div>
            <div
                className={`side-bar bg-gray-1 h-screen fixed z-30 pb-20 scrollbar-style ${sidebarToggle ? 'max-lg:right-0 lg:w-[250px]' : 'max-lg:right-[-250px] max-lg:w-0 lg:w-[52px]'}`}>
                <ul className="dir-rtl">
                    {getMenu().map((item) => (
                            <li className={`group ${location.pathname === item.link ? 'active' : ''} ${submenuToggled.includes(item.id) ? 'active' : ''}`}
                                key={item.id}>
                                <div className="flex justify-between items-center pl-3 z-10"
                                     onClick={() => toggleSubMenu(item)}>
                                    <Link
                                        onClick={ () => {
                                            if(window.innerWidth < 700) {
                                                onToggleSidebar()
                                            }
                                        } }
                                        className="side-bar-menu-item"
                                        to={item.link} title={item.title}>

                                <span
                                    className={`relative w-max flex items-center ${item.itemsCount ? 'has-items' : ''}`}>
                                    <FontAwesomeIcon
                                        className="w-5 text-gray-3 group-hover:text-purple-2 group-[.active]:text-purple-2 text-2xl"
                                        icon={item.icon}/>
                                        <span className="w-max pr-3 pt-1">{item.title}</span>
                                    {item.itemsCount ? (
                                        <span
                                            className="bg-red-1 text-white rounded mr-1 px-1 text-xs">{item.itemsCount}</span>
                                    ) : ('')}

                                </span>

                                        {item.subMenus ? (
                                            <FontAwesomeIcon
                                                className="text-xs group-hover:text-purple-2 group-[.active]:text-purple-2  text-gray-3 ml-4 mb-1"
                                                icon={faSortDown}/>) : ('')}

                                    </Link>

                                </div>
                                {item.subMenus ? (
                                    <div
                                        className={`sub-menu bg-gray-8 pr-12 ${submenuToggled.includes(item.id) ? 'show' : 'hide'}`}>
                                        <ul className="inner-wrapper">
                                            {item.subMenus.map((submenu, i) => (
                                                <li key={i}
                                                    className={`hover:text-purple-2 ${location.pathname === submenu.link ? 'active text-purple-2' : ''}`}>
                                                    <Link
                                                        className="text-xs py-2.5 block"
                                                        to={submenu.link}>{submenu.title}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : ('')}
                            </li>
                    ))}
                </ul>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    sidebarToggle: state.toggle,
    submenuToggled: state.submenuToggled,
});

const mapDispatchToProps = dispatch => ({
    onToggleSidebar: () => dispatch(toggleSidebar()),
    onToggleSubmenu: (submenuId) => dispatch(toggleSubmenu(submenuId)), // Use the action creator
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);