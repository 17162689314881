import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash, faCopy} from '@fortawesome/free-solid-svg-icons';
import {faEye} from '@fortawesome/free-regular-svg-icons'
import SwitchToggle from "../Utilities/SwitchToggle";
import {getDateAsReadableFormat} from "../../helper/general";
import {Link} from "react-router-dom";
import ApiRoute from "../../helper/api";

function CampaignTable(props) {
    const tableTitles = [
        'عنوان',
        'تاریخ شروع',
        'تاریخ پایان',
        'فعال',
        'وضعیت',
        'شناسه کمپین',
        'یادداشت کمپین',
        '',
    ]
    return (
        <table className="table-auto w-full block lg:table mt-4">
            <thead>
            <tr className="border-b border-b-gray-7">
                {tableTitles.map((item, id) => (
                    <td className="text-13 text-gray-6 pb-2" key={id}>{item}</td>
                ))}
            </tr>
            </thead>
            <tbody>
            {props.tableData.map(tableData => (
                    <tr className={`text-xs py-2`} key={tableData._id}>
                        <td>{tableData.title}</td>
                        <td>{getDateAsReadableFormat(tableData.start_at, true)}</td>
                        <td>{getDateAsReadableFormat(tableData.end_at, true)}</td>
                        <td>
                            {tableData.enabled ?
                                "🟢" :
                                !tableData.enabled ?
                                    "🔴" :
                                    ''
                            }
                        </td>
                        <td>
                            {tableData.is_ready ?
                                (<span className="text-green-1">آماده</span>) :
                                !tableData.is_ready ?
                                    (<span className="text-red-1">غیرفعال</span>) :
                                    ''
                            }
                        </td>
                        <td>{tableData._id}</td>
                        <td>{tableData.description ?? '---'}</td>
                        <td className="flex justify-center items-center py-3">

                            <SwitchToggle onSwitch={ async (c) => {
                                await ApiRoute.campaign.updateEnabled.fn(c,tableData._id)
                                props.setUpdateData(Date.now())
                            } } onOff={tableData.enabled}/>

                            <Link to={`/campaigns/edit?id=${tableData._id}`} className="mx-2" title="مشاهده"><FontAwesomeIcon
                                className="bg-gray-3 text-white p-1 w-4 h-4 rounded" icon={faEye}/></Link>
                            <button className="mx-2" onClick={async () => {
                                if (window.confirm('آیا از حذف این کمپین اطمینان دارید؟')) {
                                    try {
                                        await ApiRoute.campaign.delete.fn(tableData._id)
                                        props.setUpdateData(Date.now())
                                    } catch (error) {
                                        console.error('خطا در حذف کمپین:', error)
                                    }
                                }
                            }} title="حذف کمپین"><FontAwesomeIcon
                                className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                        </td>
                    </tr>
                )
            )}
            </tbody>
        </table>
    )
}

export default CampaignTable