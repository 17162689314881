import Layout from "../../components/Layout"
import {Link} from "react-router-dom";
import SearchBox from "../../components/SearchBox";
import {
    faFilter,
    faHand,
    faSquareCheck,
    faRectangleXmark,
    faCheckDouble,
    faCircleExclamation,
    faTruckMedical,
    faCommentDots,
    faPrint,
    faUser,
    faFileLines,
    faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Pagination from "../../components/Pagination";
import Filter from "../../components/Filter";
import OrderTable from "../../components/Tables/OrderTable";
import FilterBox from "../../components/FilterBox";
import {useEffect, useState} from "react";
import FilterOrder from "../../components/Order/FilterOrder";
import OrderFilterTab from "../../components/Order/OrderFilterTab";
import ApiRoute, {shopId} from "../../helper/api";

function PreOrder() {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'cellphone'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering

    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  res  = await ApiRoute.order.index.fn({
                shop_id: shopId(),
                is_preorder:1,
                state:selectedStatsTab,
                status:selectedStatsTab == 'all' ? '' : selectedStatsTab,
                page : pageNumber,
                product_id: params.get('product_id') ?? '',
            },filterQuery)
            setData(res)
            setIsLoading(false)
        })();
    }, [updateData]);

    // watch searchQuery and update updateData state
    useEffect(() => {
        filterQuery.quick_search = searchQuery;
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    //fetch stats-data from server
    useEffect(() => {
        (async () => {
            const  res  = await ApiRoute.order.stats.fn({
                shop_id: shopId(),
                is_preorder:1,
                quick_search : searchQuery
            },filterQuery)
            setStatsTab(res.results)
        })();
    }, [data]);

    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    // watch filterQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [filterQuery]);

    // watch statsTab and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [selectedStatsTab]);

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);


    const paginationData = data?.results
    const tableData = data?.results?.data ?? []



    return (
        <Layout>
            <div>
                <div className="flex items-center justify-between mb-3">
                    <div className="page-title flex-auto ml-3 md:ml-0">
                        <span className="inline-block bg-white pl-3">پیش سفارشات</span>
                    </div>
                    <Link className="btn-blue w-[200px] text-center " to="/orders/create?pre_order=1"> افزودن پیش سفارش
                        جدید</Link>
                </div>

                <div className="grid md:flex justify-between items-center gap-3">
                    <div className="flex gap-3">
                        <SearchBox
                            SearchIn={true}
                            SetSearchQuery={setSearchQuery}
                            SearchByDefault={`order_key`}
                            SearchByOptions={[
                                {
                                    label: 'شماره سفارش',
                                    value: 'order_key'
                                },
                                {
                                    label: 'شماره همراه',
                                    value: 'cellphone'
                                },
                                {
                                    label: 'کد ملی',
                                    value: 'national_code'
                                },
                                {
                                    label: 'نام و نام خانوادگی',
                                    value: 'customer_name'
                                },
                                {
                                    label: 'شماره حواله',
                                    value: 'transference_number'
                                },
                                {
                                    label: 'نام شرکت',
                                    value: 'official_org_name'
                                },
                                {
                                    label: 'شناسه ملی',
                                    value: 'official_national_number'
                                },
                                {
                                    label: 'شناسه ثبت',
                                    value: 'official_register_number'
                                }
                            ]}/>
                    </div>

                </div>

                <FilterOrder SetFilterQuery={ (s) => {
                    setFilterQuery(s)
                    setUpdateData(Date.now().toString())
                } }/>

                <div className="main-content p-1 mt-3">
                    <OrderFilterTab
                        selectedTab={selectedStatsTab}
                        setSelectedTab={setSelectedStatsTab}
                        options={statsTab}
                    />
                    {/*<Filter filters={filters2}/>*/}
                    <OrderTable isPreOrder={true} setUpdateData={setUpdateData} tableData={tableData}/>
                    <Pagination SetPageNumber={setPageNumber} Data={paginationData}/>
                </div>
            </div>
        </Layout>
    );
}

export default PreOrder;