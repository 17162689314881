import Modal from "../../Utilities/Modal";

export default function InitAddress({setType,setStep}){
    return (
    
            <div className="py-3 px-4">
                    <div className={`flex flex-col gap-1  pb-3`}>
                        <span className="text-18 text-black mt-1 font-medium">
                        آدرس جدید
                    </span>
                        <span className="text-14 text-t-light-gray font-medium">
                      ابتدا استان خود را مشخص کنید.
                    </span>
                    </div>

                    <div className="flex mt-3 gap-4 justify-content-center relative">
                        <div onClick={ () => {
                           /*  addressBody.coordinates = {
                                state: {},
                                city: {},
                                area: {},
                                lat : '',
                                lng : '',
                            }
                            addressBody.address.address = ''
                            setSkipMap(false)
                            setOpenModal('newAddress') */
                            setStep(1)
                            setType('with_map')
                        } } className={`border-2 cursor-pointer hover:bg-purple-1 hover:text-white w-[120px] text-center rounded border-purple-1 p-2 text-purple-1`}>تهران و البرز</div>
                        <div onClick={ () => {
/*                             addressBody.coordinates = {
                                state: {},
                                city: {},
                                area: {},
                                lat : '',
                                lng : '',
                            }
                            addressBody.address.address = ''
                            setSkipMap(true)
                            setOpenModal('newAddressForm') */
                            setStep(2)
                            setType('without_map')
                        } } className={`border-2 cursor-pointer hover:bg-purple-1 hover:text-white w-[120px] text-center rounded border-purple-1 p-2 text-purple-1`}>سایر استان ها</div>
                    </div>

                </div>
     
    )
}