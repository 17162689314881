import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd} from "@fortawesome/free-solid-svg-icons/faAdd";
import React, {useState} from "react";
import FileUpload from "../../FileUpload";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

export default function ImageField({item}){
    const [update,setUpdate] = useState(0)

    return (
        <div className={`w-full mt-5`}>

            <div className={`flex justify-between`}>
                <div className={`text-13`}>{item.label}</div>
            </div>

            <div className={`mt-2`}>
                <input defaultValue={item.value?.url} onInput={ e => {
                    item.value.url = e.target.value
                } } className={`input-x`} placeholder={`لینک`}/>
                <FileUpload defaultFile={item.value?.image && [
                    {
                        source: item.value.image,
                        options: {
                            type: 'local',
                        },
                    },
                ]} setCover={(v) => {
                    item.value.image = v
                    setUpdate(Date.now())
                }}/>

                { ('image_sm' in item.value) && <FileUpload label={'نسخه موبایل بنر را انتخاب کنید'} defaultFile={item.value?.image_sm && [
                    {
                        source: item.value.image_sm,
                        options: {
                            type: 'local',
                        },
                    },
                ]} setCover={(v) => {
                    item.value.image_sm = v
                    setUpdate(Date.now())
                }}/>}

            </div>


        </div>
    )
}