import {getDateAsReadableFormat} from "../../../../helper/general";
import {useEffect, useState} from "react";
import ApiRoute from "../../../../helper/api";

export default function OrderNotes({order, setUpdateState, updateState}){
    const [search , setSearch] = useState('')
    const [type , setType] = useState('')
    const [data , setData] = useState(null)

    useEffect(() => {
        getData()
    }, [type,updateState]);

    async function getData(){
        const res = await ApiRoute.order.note.index.fn(order._id,{
            type: type
        });
        if (res?.status_code === 200) {
            setData(res.results)
        }
    }

    function getBgColor(state){
        if (state === "info"){ return "bg-blue-1" }
        if (state === "warning"){ return "bg-orange-2" }
        if (state === "failed"){ return "bg-red-2" }
        return "bg-gray-3"
    }
    function getOperatorLabel(operator){
        if (operator === "system"){ return "سیستم" }
        if (operator === "employee"){ return "کارمند" }
    }
    return (
        <div className="main-content border border-gray-7 relative rounded-md p-5 mt-4">
            <div className={`font-[16px] font-bold mb-3`}>یادداشت ها</div>
            <div className="flex gap-2">
                <input
                    className="flex flex-auto rounded border border-gray-7 bg-gray-1 py-2 px-3 !mr-0"
                    type="text" onKeyUp={ (e) => {
                        setSearch(e.target.value);
                } } placeholder="جستجو ..."/>
            </div>

            <div className="flex gap-2">
                <select onChange={ (e) => {
                    setType(e.target.value);
                } } className={`input-x`}>
                    <option value={``}>همه</option>
                    <option value={`extra_description`}>توضیحات ویزیتور</option>
                    <option value={`shipment_description`}>توضیحات ارسال</option>
                </select>
            </div>

            { data && data.filter( item => {
                if ( search.length > 0 ) { return item.content.includes(search) }else {return item.content !== ""}
            } ).map( note => <div className={`${getBgColor(note.state)} text-white p-3 rounded mt-3`}>
                <p className="border-b border-b-white pb-3" dangerouslySetInnerHTML={ {__html : note.content} }></p>
                <div className="flex justify-between pt-3">
                    { (note.created_by == "system" || note.created_by == "employee") && <span>توسط: {getOperatorLabel(note.created_by)}</span>}
                    { note?.user?.name && <span> {note.user.name}</span>}
                    {note?.created_at && <span>{getDateAsReadableFormat(note.created_at ,true)}</span>}
                </div>
            </div> ) }


        </div>
    )
}