import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './css/main.css';
import {ToastContainer} from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById('root'));
if (localStorage.getItem('coordinate_cache_key_a') == null){
    localStorage.setItem('coordinate_cache_key_a',Date.now().toString());
}
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
 
