import Layout from "../../../components/Layout";
import CustomMultiSelect from '../../../components/Utilities/CustomMultiSelect'
import React, {useEffect, useState} from "react"
import {Link, useNavigate} from "react-router-dom";
import CategorySelect from "../../../components/Product/Category/CategorySelect";
import BrandSelect from "../../../components/Product/Category/BrandSelect";
import Buttons from "react-multi-date-picker/components/button";
import ApiRoute from "../../../helper/api";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {isOnEditState} from "../../../helper/product";
import TagSelect from "../../../components/Product/TagSelect";
import {Step1} from "../../../components/Product/Step/Step-1";


function CreateProductStep1() {

    return (
        <Layout>
            <Step1/>

        </Layout>
    )

}

export default CreateProductStep1