import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBagShopping, faFile, faLocationPin, faUser} from "@fortawesome/free-solid-svg-icons";
import {formatNumberWithDots, getDateAsReadableFormat, orderPaymentTransactionLabel} from "../../../helper/general";
import Modal from "../../Utilities/Modal";
import {useState} from "react";
import ApiRoute from "../../../helper/api";
import {HasAccess} from "../../../helper/permissions";

export default function OrderFactorSubmitModal({order, closeModal, event}){

    const [text, setText] = useState('')
    async function handleSubmit(){
        await ApiRoute.order.action.fn("factor/submit",order,{
            description : text
        })
        try {
            event()
        }catch (e) {
            
        }
    }
    return (
        <Modal isOpen={true} onClose={closeModal}>
            <HasAccess permission={`factor order`} children={<div>

                <div className="page-title z-1 text-right text-sm">
                    <span className="inline-block bg-white pl-3 pr-1 mb-3 relative z-2">تائید تامین کننده (فاکتور)</span>
                </div>

                <div className={`flex gap-2 w-full flex-col`}>

                    <input onKeyUp={ (e) => { setText(e.target.value) } }
                           className='px-3 py-2 border border-[#dfdfdf] w-full rounded-[8px] shadow flex-auto bg-white'
                           type="text" placeholder="توضیحات (اختیاری)"/>
                    <button onClick={handleSubmit} className={`btn-green py-1 max-w-max rounded`}>ذخیره تغییرات</button>

                </div>

            </div>}/>
        </Modal>
    )
}