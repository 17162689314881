import ApiRoute from "../../../helper/api";
import { useEffect, useState } from "react";
import SelectCoordinates from "../../../components/Coordinates/SelectCoordinates";
import Layout from "../../../components/Layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import MyMap from "../../../components/Customer/Address/MyMap";
import Modal from "../../../components/Utilities/Modal";
import InitAddress from "../../../components/Customer/Address/InitAddress";
import Select from 'react-select';
import SelectCoordinates2 from "../../../components/Coordinates/SelectCoordinates2";

function CreateAddress() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [coordinate, setCoordinate] = useState(null);
  const [data, setData] = useState({});
  const [updateData, setUpdateData] = useState(0);
  const [step, setStep] = useState(0);
  const [type, setType] = useState(0);
  const [areas, setAreas] = useState(null);
  const [address, setAddress] = useState({
    active: true,
    first: true,
    main: true,
    first_name: "",
    last_name: "",
    cellphone: "",
    coordinates: {
      state: {},
      area: null,
      city: {},
      lat: 35.68418487179686,
      lng: 51.438600113581174,
    },
    address: {
      title: "",
      address: "",
      desc: "",
      floor: "",
      house_name: "",
      house_number: "",
      postcode: "",
      unit: "",
    },
  });

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await ApiRoute.customer.single.fn(id);
      setData(res.results);

      setIsLoading(false);

      console.log(data);
    })();
  }, [updateData]);

  useEffect(() => {
    (async () => {
      if (data != null) {
        address.first_name = data?.personal?.first_name;
        address.last_name = data?.personal?.last_name;
        address.cellphone = data?.contacts?.cellphone;
        setAddress(address);
      }
    })();
  }, [data]);

  async function validateAndStoreAddressOnEdge() {
    setIsSending(true);
    await ApiRoute.customer.createAddress.fn(data._id, address, function (res) {
      return navigate("/customers/view/" + data._id);
    });
    console.log(address)
    setIsSending(false);
  }

  return (
    <Layout>
      
        {!isLoading && <>
        <div className="grid grid-cols-2 lg:flex text-[#000] justify-center pt-4 pb-7 xl:pb-12 items-center gap-4 xl:gap-10 text-16">
              <Link className="text-purple-1" to={`/customers/view/` + id}>
                <span>برگشت به کاربر</span>
              </Link>
              <span className="max-lg:col-span-2">
                {data.personal.first_name} {data.personal.last_name} به شماره
                همراه {data.contacts.cellphone}
              </span>
        </div>

        {step === 0 && <InitAddress setType={setType} setStep={setStep} />}

        {step === 1 && <MyMap setLat={35.68418487179686} setLng={51.438600113581174} setStep={setStep} setExportCoordinates={ (results , parsimapData , lat, lng) => {
            console.log(results)
            address.address.address = parsimapData.address
            address.coordinates.lat = lat
            address.coordinates.lng = lng
            address.coordinates.state = results.state
            address.coordinates.city = results.city
            setAreas(results.area)
            setAddress(address)
        } }/>}

        {step === 2 && <div>
            
        <div className="page-title mb-3">
            <span className="inline-block bg-white pl-3">ایجاد آدرس جدید</span>
        </div>

        <div className="main-content xl:w-3/4 mx-auto p-6 lg:p-8 mt-12">
          <div className="form 2xl:w-3/5 mx-auto">


            <div className="grid sm:grid-cols-2 xl:grid-cols-3 justify-items-stretch mb-10 gap-5 lg:gap-10">
              <div>
                <label className="!grid">
                  <span className="text-right mb-3 pr-1">نام</span>
                  <input
                    className="!flex-none !mr-0"
                    defaultValue={address.first_name}
                    onChange={(e) => {
                      address.first_name = e.target.value;
                      setAddress(address);
                    }}
                    type="text"
                    placeholder="نام را وارد کنید"
                  />
                </label>
              </div>
              <div>
                <label className="!grid">
                  <span className="text-right mb-3 pr-1">نام خانوادگی</span>
                  <input
                    className="!flex-none !mr-0"
                    defaultValue={address.last_name}
                    onChange={(e) => {
                      address.last_name = e.target.value;
                      setAddress(address);
                    }}
                    type="text"
                    placeholder="نام خانوادگی را وارد کنید"
                  />
                </label>
              </div>
              <div>
                <label className="!grid">
                  <span className="text-right mb-3 pr-1">شماره همراه</span>
                  <input
                    className="!flex-none !mr-0"
                    defaultValue={address.cellphone}
                    onChange={(e) => {
                      address.cellphone = e.target.value;
                      setAddress(address);
                    }}
                    type="text"
                    placeholder="شماره همراه را وارد کنید"
                  />
                </label>
              </div>

              {type === 'without_map' &&
              <div className="col-span-full">
                <div className="!grid ">
                  <span className="text-right text-[12px] mb-3 pr-1">موقعیت</span>
                  <div>
                  <SelectCoordinates2 setCoordinate={(coordinates) => {
                      address.coordinates.state = coordinates.state[0];
                      address.coordinates.city = coordinates.city[0];
                      address.coordinates.area = coordinates.area[0];
                      console.log(address)
                      setAddress(address);
                    }} defaultValue={address.coordinates} multiple={false}/>
                  </div>
                </div>
              </div>}

              {type === "with_map" && <div className="col-span-full text-[13px] gap-3">
                <div className="flex items-center mb-4">
        
                    
                
                    <div className="w-full">
                    <SelectCoordinates2 setCoordinate={(coordinates) => {
                      address.coordinates.state = coordinates.state[0];
                      address.coordinates.city = coordinates.city[0];
                      address.coordinates.area = coordinates.area[0];
                      console.log(address)
                      setAddress(address);
                    }} defaultValue={address.coordinates} multiple={false}/>
                    </div>
                </div>
              </div>}

              <div className="col-span-full">
                <label className="!grid">
                  <span className="text-right mb-3 pr-1">آدرس</span>
                  <input
                    className="!flex-none !mr-0"
                    defaultValue={address.address.address}
                    onChange={(e) => {
                      address.address.address = e.target.value;
                      setAddress(address);
                    }}
                    type="text"
                  />
                </label>
              </div>
              <div>
                <label className="!grid">
                  <span className="text-right mb-3 pr-1">عنوان آدرس</span>
                  <input
                    className="!flex-none !mr-0"
                    defaultValue={address.address.title}
                    onChange={(e) => {
                      address.address.title = e.target.value;
                      setAddress(address);
                    }}
                    placeholder="مانند خانه یا دفتر اصلی و ..."
                    type="text"
                  />
                </label>
              </div>
              <div>
                <label className="!grid">
                  <span className="text-right mb-3 pr-1">توضیحات آدرس</span>
                  <input
                    className="!flex-none !mr-0"
                    defaultValue={address.address.desc}
                    onChange={(e) => {
                      address.address.desc = e.target.value;
                      setAddress(address);
                    }}
                    placeholder="توضیحات را اینجا بنویسید ..."
                    type="text"
                  />
                </label>
              </div>
              <div>
                <label className="!grid">
                  <span className="text-right mb-3 pr-1">طبقه</span>
                  <input
                    className="!flex-none !mr-0"
                    defaultValue={address.address.floor}
                    onChange={(e) => {
                      address.address.floor = e.target.value;
                      setAddress(address);
                    }}
                    placeholder="اینجا بنویسید ..."
                    type="text"
                  />
                </label>
              </div>
              <div>
                <label className="!grid">
                  <span className="text-right mb-3 pr-1">نام ساختمان</span>
                  <input
                    className="!flex-none !mr-0"
                    defaultValue={address.address.house_name}
                    onChange={(e) => {
                      address.address.house_name = e.target.value;
                      setAddress(address);
                    }}
                    placeholder="اینجا بنویسید ..."
                    type="text"
                  />
                </label>
              </div>
              <div>
                <label className="!grid">
                  <span className="text-right mb-3 pr-1">پلاک</span>
                  <input
                    className="!flex-none !mr-0"
                    defaultValue={address.address.house_number}
                    onChange={(e) => {
                      address.address.house_number = e.target.value;
                      setAddress(address);
                    }}
                    placeholder="اینجا بنویسید ..."
                    type="text"
                  />
                </label>
              </div>
              <div>
                <label className="!grid">
                  <span className="text-right mb-3 pr-1">واحد</span>
                  <input
                    className="!flex-none !mr-0"
                    defaultValue={address.address.unit}
                    onChange={(e) => {
                      address.address.unit = e.target.value;
                      setAddress(address);
                    }}
                    placeholder="اینجا بنویسید ..."
                    type="text"
                  />
                </label>
              </div>
              <div>
                <label className="!grid">
                  <span className="text-right mb-3 pr-1">کد پستی</span>
                  <input
                    className="!flex-none !mr-0"
                    defaultValue={address.address.postcode}
                    onChange={(e) => {
                      address.address.postcode = e.target.value;
                      setAddress(address);
                    }}
                    placeholder="اینجا بنویسید ..."
                    type="text"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center my-8 lg:mt-16">
          <button
            disabled={isSending}
            type="button"
            onClick={(e) => {
              validateAndStoreAddressOnEdge();
            }}
            className="btn-purple w-50"
          >
            ایجاد آدرس
          </button>
        </div></div>}
        </>}
     
    </Layout>
  );
}
export default CreateAddress;
