
import {useState} from "react";
import ApiRoute from "../../helper/api";
import Modal from "../Utilities/Modal";

export default function CreateShopModal({closeModal, event}){

    const [newData, setNewData] = useState({
        title: '',
        order_key:'',
        customer_key:'',
    })
    async function handleSubmit(){
        const res = await ApiRoute.shop.create.fn(newData)
        try {
            event(res)
        }catch (e) {
            
        }
    }
    return (
        <Modal isOpen={true} onClose={closeModal}>
            <div>

                <div className="page-title z-1 text-right text-sm">
                    <span className="inline-block bg-white pl-3 pr-1 mb-3 relative z-2">ایجاد فروشگاه</span>
                </div>

                <div className={`flex gap-2 w-full flex-col`}>

                    <input onKeyUp={ (e) => { newData.title = e.target.value } }
                           className='px-3 py-2 border border-[#dfdfdf] w-full rounded-[8px] shadow flex-auto bg-white' type="text" placeholder="عنوان فروشگاه"/>
                    <input onKeyUp={ (e) => { newData.order_key = e.target.value } }
                           className='px-3 py-2 border border-[#dfdfdf] w-full rounded-[8px] shadow flex-auto bg-white' type="text" placeholder="رنج سفارش مشتریان"/>
                    <input onKeyUp={ (e) => { newData.customer_key = e.target.value } }
                           className='px-3 py-2 border border-[#dfdfdf] w-full rounded-[8px] shadow flex-auto bg-white' type="text" placeholder="رنج والد مشتریان"/>

                    <button onClick={handleSubmit} className={`btn-green max-w-max py-2 mt-2 rounded`}>ایجاد</button>

                </div>

            </div>
        </Modal>
    )
}