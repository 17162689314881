import Layout from "../components/Layout";
import ExportTable from "../components/Tables/ExportTable";

export default function Export() {
    
    const tableData = [
        {
            title: 'خروجی سفارشات',
            dateFrom:'',
            dateTo:'',
            url:'https://media.entekhabcenter.com/forms/app_exportmanager_repository_exportorderrepository_.csv',
            request: true
        },
        {
            title: 'خروجی محصولات',
            dateFrom:'',
            dateTo:'',
            url:'https://media.entekhabcenter.com/forms/app_exportmanager_repository_exportproductrepository_.csv',
            request: false
        },
        {
            title: 'خروجی انبارداری',
            dateFrom:'',
            dateTo:'',
            url:'https://media.entekhabcenter.com/forms/app_exportmanager_repository_exportinventoryrepository_.csv',
            request: false
        },
        {
            title: 'خروجی تحلیل قیمت',
            dateFrom:'',
            dateTo:'',
            url:'https://media.entekhabcenter.com/forms/app_exportmanager_repository_exportinventoryanalysisrepository_.csv',
            request: false
        },
        {
            title: 'خروجی مشتریان',
            dateFrom:'',
            dateTo:'',
            url:'https://media.entekhabcenter.com/forms/app_exportmanager_repository_exportcustomerrepository_.csv',
            request: false
        },
    ]
    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">خروجی ها</span></div>
                <div className="main-content p-1 mt-3">
                    <ExportTable tableData={tableData} />
                </div>
            </div>
        </Layout>
    )
}