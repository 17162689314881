import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../helper/api";
import {getDateAsReadableFormat} from "../helper/general";

function PeriodTime({periods = null , onSelect}){

    const [selectedDate, setSelectedDate] = useState(1)
    const [selectedTime, setSelectedTime] = useState(1)
    const selectDate = (id) =>  setSelectedDate(id)
    const selectTime = (id) =>  setSelectedTime(id)



    return(
        <div className="border border-gray-7 rounded-md p-4">


            <div className="flex overflow-x-auto gap-2 pb-2">
                { periods && Object.keys(periods).map( (item,key) => <div className={(item === selectedDate ? '!text-purple-1 font-bold' : '')+` grid text-center border-b border-b-gray-7 text-xs cursor-pointer group min-w-max px-3`}
                                                           key={key} onClick={() => selectDate(item)}>
                    <span className="group-hover:text-purple-1">{getDateAsReadableFormat(item)}</span>
                </div>) }

            </div>

            <div className="flex mt-5 overflow-x-auto">
                {periods && Object.keys(periods).map(date => {
                    if (date !== selectedDate){return;}
                    return periods[date].map( (period , index) => <button className={`border text-xs py-1.5 px-3 rounded-md border-gray-9 ml-3 hover:border-purple-1 ${period.pick_id === selectedTime ? 'border-purple-1 text-purple-1' : ''}`}
                                   key={index} onClick={() => {
                                       selectTime(period.pick_id)
                                       onSelect(period)
                    }}>
                        ساعت
                        <span className="px-1">{period.start}</span>
                        تا
                        <span className="px-1">{period.end}</span>
                    </button>)
                })}
            </div>

        </div>
    )
}
export default PeriodTime