import Toman from "../../Utilities/Toman";
import {Link} from "react-router-dom";
import CustomerSelect from "../../Customer/CustomerSelect";
import {useEffect, useState} from "react";
import {formatNumberWithDots} from "../../../helper/general";
import {isPrimaryShop} from "../../../helper/api";

export default function Step1({newOrder,setStep,step,setNewOrder,show}){
    const [selectedCustomer , setSelectedCustomer] = useState(newOrder?.customer ?? null)

    return (
        <div hidden={!show}>
            <div className="page-title mb-3"><span className="inline-block bg-white pl-3">سفارش جدید</span></div>

            <div className="hidden lg:flex items-center justify-center text-sm xl:w-3/4 mx-auto mt-10">
                <span className="text-purple-1">اطلاعات مشتری</span>
                <span className="h-0.5 w-20 2xl:w-36 bg-gray-3 block mx-2"></span>
                <span>نحوه ارسال</span>
                <span className="h-0.5 w-20 2xl:w-36 bg-gray-3 block mx-2"></span>
                <span>انتخاب کالا</span>
                <span className="h-0.5 w-20 2xl:w-36 bg-gray-3 block mx-2"></span>
                <span>انتخاب زمان ارسال</span>
                <span className="h-0.5 w-20 2xl:w-36 bg-gray-3 block mx-2"></span>
                <span>ثبت سفارش</span>
            </div>

            <div className="main-content md:w-1/2 xl:w-2/5 2xl:w-1/4 mx-auto py-6 px-5 mt-12">
                <span className="block text-center text-purple-1 mb-6">اطلاعات مشتری</span>
                <div className="form">
                    <CustomerSelect defaultValue={selectedCustomer !== null ? [selectedCustomer] : null} customDefaultValue={selectedCustomer !== null ? [selectedCustomer] : null} assignItem={ (v) => {
                        setSelectedCustomer(v)
                    } }/>
                </div>

                <div className="flex justify-between my-8 text-xs">
                    { isPrimaryShop() && <div>
                        <span className="text-gray-2">نوع مشتری:</span>
                        <span className="text-gray-3 mr-1">
                            {selectedCustomer && selectedCustomer.is_legal ? 'حقوقی' : 'حقیقی'}
                            {selectedCustomer === null && '---'}
                        </span>
                    </div>}
                    {isPrimaryShop() && <div className="flex items-center">
                        <span className="text-gray-2">شارژ کیف پول:</span>
                        <span className="text-gray-3 mr-1">
                            {selectedCustomer && formatNumberWithDots(selectedCustomer.wallet_balance)}
                            {selectedCustomer === null && '---'}
                        </span>
                    </div>}
                </div>

                <div className="flex justify-between">
                    <Link to="/orders" className="btn-gray w-36">بازگشت</Link>
                    {selectedCustomer && <button onClick={() => {
                        newOrder = {}
                        newOrder.customer = selectedCustomer
                        setStep(2)
                        setNewOrder(newOrder)
                    }} className="btn-purple w-36">اطلاعات گیرنده</button>}
                </div>
            </div>

        </div>
    )
}