import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faBagShopping, faStore, faUser} from "@fortawesome/free-solid-svg-icons";
import {faCreditCard} from "@fortawesome/free-regular-svg-icons";
import Toman from "../../Utilities/Toman";
import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../../helper/api";

export default function ShaparakPaymentWidget(){
    const [data,setData] = useState(null)
    useEffect(() => {
        (  async () => {
            const res = await ApiRoute.analytics.widget.shaparakPaymentInfo.fn({
                shop_id : shopId()
            })
            if (res?.status_code === 200){
                setData(res.results)
            }
        } )()
    }, []);
    return (
        <div  className="bg-gradient-orange rounded-md px-3 pt-2 pb-4 text-white grid gap-2">
            <div className="flex justify-between text-lg items-center mb-4">
                <span className="text-13">پرداخت  آنلاین</span>
                <FontAwesomeIcon className="text-lg rounded-full p-3 aspect-square bg-[#ffffff33]"
                                 icon={faCreditCard}/>
            </div>
            <div className="flex justify-between items-center pl-2">
                <span className="text-11">پرداخت آنلاین امروز</span>
                {data ? <span className="text-xs">{data.today_created}</span> : <div className={`x2-loader`}></div>}
            </div>
            <div className="flex justify-between items-center pl-2">
                <span className="text-11">پرداخت آنلاین هفته</span>
                {data ? <span className="text-xs">{data.week_created}</span> : <div className={`x2-loader`}></div>}
            </div>
            <div className="flex justify-between items-center pl-2">
                <span className="text-11">پرداخت آنلاین ماه</span>
                {data ? <span className="text-xs">{data.month_created}</span> : <div className={`x2-loader`}></div>}
            </div>
            <div className="flex justify-between items-center pl-2">
                <span className="text-11">نسبت به هفته قبل</span>
                {data ?  <span className="text-xs">
                                { data.week_percent > 0 && <FontAwesomeIcon className="ml-1" icon={faArrowUp}/>}
                    { data.week_percent < 1 && <FontAwesomeIcon className="ml-1" icon={faArrowDown}/>}
                    {data.week_percent}%
                            </span> : <div className={`x2-loader`}></div>}
            </div>
            <div className="flex justify-between items-center pl-2">
                <span className="text-11">نسبت به ماه قبل</span>
                {data ?  <span className="text-xs">
                                { data.month_percent > 0 && <FontAwesomeIcon className="ml-1" icon={faArrowUp}/>}
                    { data.month_percent < 1 && <FontAwesomeIcon className="ml-1" icon={faArrowDown}/>}
                    {data.month_percent}%
                            </span> : <div className={`x2-loader`}></div>}
            </div>
        </div>
    )
}