import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBarsStaggered, faEdit} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useState} from "react";
import ApiRoute from "../../helper/api";
import {faRemove} from "@fortawesome/free-solid-svg-icons/faRemove";


function NeighborhoodsTable(props) {
    const tableTitles = [
        'نام فارسی',
        'نام انگلیسی',
    ]
    const [selected,setSelected] = useState([])

    function changeSelectedItem(value , name , tableData){
        if (!selected[tableData.uid]){
            selected[tableData.uid] = [];
        }
        selected[tableData.uid][name] = value;
        setSelected(selected)

    }

    async function editHandle(state, city, area){
        try {
            await ApiRoute.coordinate.updateArea.fn(state._id, city.uid , area.uid , {
                faname: selected[area.uid].faname,
                enname: selected[area.uid].enname
            })
            localStorage.setItem('coordinate_cache_key_a',Date.now().toString())
        }catch (e){}
    }

    async function deleteHandle(state, city, area){
        alert('پیاده سازی تمامی حذف ها در مراحل نهایی صورت خواهد گرفت')
    }

    return (
        <table className="coupon-table table-auto w-full mt-4 block sm:table">
            <thead>
            <tr className="border-b border-b-gray-7">
                {tableTitles.map((item, id) => (
                    <td className="text-13 text-gray-6 pb-2" key={id}>{item}</td>
                ))}
                <td></td>
            </tr>
            </thead>
            <tbody>
            {
                props.tableData.map((tableData, id) => {
                        return tableData.cities && tableData.cities.map( city => {

                            if (city.uid == props.city_uid){
                                return city.areas.map( area => <tr className={`text-xs py-2`} key={id}>

                                    <td><input onKeyUp={ (v) => {
                                        changeSelectedItem(v.target.value, 'faname', area);
                                    } } className={`shadow p-1`} defaultValue={area.faname}/></td>
                                    <td><input onKeyUp={ (v) => {
                                        changeSelectedItem(v.target.value, 'enname', area);
                                    } } className={`shadow p-1`} defaultValue={area.enname}/></td>

                                    <td className="flex justify-center items-center py-3">
                                        <button onClick={async () => {await editHandle(tableData,city,area)}} className="mx-2" title="ویرایش"><FontAwesomeIcon
                                            className="bg-green-2 text-white p-1 w-4 h-4 rounded" icon={faEdit}/></button>
                                        <button onClick={async () => {await deleteHandle(tableData,city,area)}} className="mx-2" title="حذف"><FontAwesomeIcon
                                            className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faRemove}/></button>

                                    </td>

                                </tr>)
                            }


                        } )
                    }
                )}
            </tbody>
        </table>
    )
}

export default NeighborhoodsTable