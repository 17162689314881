import Layout from "../../components/Layout";
import React, {useEffect, useState} from "react";
import ImageUploader from "../../components/Utilities/ImageUploader";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { register } from 'swiper/element/bundle';
import {Link, useNavigate} from "react-router-dom";
import { faCheckDouble, faChevronDown, faChevronLeft, faChevronRight, faChevronUp,faTrash} from '@fortawesome/free-solid-svg-icons';
import {Editor} from "@tinymce/tinymce-react";
import ImageSlider from "../../components/Template/Component/ImageSlider";
import IconSlider from "../../components/Template/Component/IconSlider";
import Banner from "../../components/Template/Component/Banner";
import CompactBox from "../../components/Template/Component/CompactBox";
import BlogBox from "../../components/Template/Component/BlogBox";
import TabBox from "../../components/Template/Component/TabBox";
import TopSellProducts from "../../components/Template/Component/TopSellProducts";
import CampaignBox from "../../components/Template/Component/CampaignBox";
import ApiRoute from "../../helper/api";
import templateComponents from "../../helper/template-components";
import {v4 as uuidv4} from "uuid";
import ContentBox from "../../components/Template/Component/ContentBox";
import { toast } from "react-toastify";
import ContentImageBox from "../../components/Template/Component/ContentImageBox";

function EditTemplate() {

    const navigate = useNavigate()
    const [data,setData] = useState(null)
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [components,setComponents] = useState(null)


    const [updateState, setUpdateState] = useState(1)
    const [newComponent, setNewComponent] = useState(null)


    const [componentList, setComponentList] = useState(templateComponents())

    useEffect(() => {
        if( params.get('id') == null ){
            return navigate("/templates")
        }

        (async () => {
            const response = await ApiRoute.template.single.fn( params.get('id'),params.get('as-slug') )
            setData(response.results)
            setComponents(response.results.components)
        })()

    }, []);

    function lastOrder() {
        let lastItem = null;
        components.sort((firstItem, secondItem) => firstItem.order - secondItem.order).map(item => {
            lastItem = item
            return
        })
        if (lastItem?.order) {
            return lastItem.order + 1;
        }
        return 0
    }

    function moveData(data, index, action = 'up') {
        let inx = index - 1
        if (action != 'up') {
            inx = index + 1
        }

        if (index == 0 && action == 'up') {
            return;
        }

        let el = data.order;
        data.order = components[inx].order;

        components.map((item, i) => {
            if (i == (inx)) {
                item.order = el
            }
        })
        setUpdateState(Date.now())
    }

    function reorderComponents() {
        components.sort((a, b) => a.order - b.order).forEach((component, index) => {
            console.log(index)
            component.order = index;
        });
        console.log(components)

        setUpdateState(Date.now());
    }

    async function handleSave() {
       await ApiRoute.template.update.fn(data._id, {
            title: data.title,
            slug: data.slug,
            components: components
       });
    }    
    async function handleReorder() {
        reorderComponents();
        toast.success('چیدمان با موفقیت نرمالایز شد. میتوانید ذخیره کنید')
    }

    return (
        <Layout>
            <div>

                <div className="page-title mb-5 flex justify-between">
                    <span className="inline-block bg-white pl-3">قالب ({data?.title})</span>
                    {data && <div className="pr-2 lg:hidden bg-white">
                        <button onClick={handleSave} className="btn-green text-xs py-2.5 rounded px-8">ذخیره قالب</button>
                    </div>}
                </div>

                {data && <div className={`flex gap-3 items-center`}>
                    <input onKeyUp={ e => { data.title = e.target.value } } className={`input-x`} type={`text`} defaultValue={data.title}/>
                    <input onKeyUp={ e => { data.slug = e.target.value } } className={`input-x text-left`} dir={`ltr`} type={`text`} defaultValue={data.slug}/>
                </div>}

                <div className="page-title mb-3 text-13"><span className="inline-block bg-white pl-3">سکشن ها</span></div>

                <div className="form">
                    <div className="grid grid-cols-1 lg:grid-cols-2 text-xs">
                        <div className="flex flex-auto gap-3">
                            <select onChange={ (e) => {
                                setNewComponent(e.target.value)
                            } } className="!mr-0">
                                <option>سکشن موردنظر را انتخاب کنید</option>
                                {componentList.map( (component) => <option value={component.name} key={component.name}>{component.label}</option> )}
                            </select>
                            <button onClick={ () => {

                                let c = newComponent

                                if (c === null){alert('انتخاب سکشن اجباری است')}

                                templateComponents().map( (ct) => {
                                    if (ct.name === c){
                                        alert(ct.name)
                                        const copiedObject = ct.data;
                                        copiedObject.id = uuidv4()
                                        copiedObject.order = lastOrder()
                                        console.log(copiedObject)
                                        setComponents(prevList => [...prevList, copiedObject]);
                                    }
                                } )


                            } } className="btn-blue-light text-xs py-1.5">افزودن سکشن جدید</button>
                            
                            <button onClick={handleReorder} className="btn-info text-red-4 border-2 border-red-4 text-xs py-2.5 rounded px-2">نرمالایز سازی چیدمان</button>

                        </div>
                        {data && <div className="text-left hidden lg:block">
                            <button onClick={handleSave} className="btn-green text-xs py-2.5 rounded px-8">ذخیره قالب</button>
                        </div>}
                    </div>
                </div>

                {components && components.sort((firstItem, secondItem) => firstItem.order - secondItem.order).map( (component , index) =>  {

                        if (component.component == 'image_slider'){
                            return <ImageSlider key={component.id} remove={ () => {
                                components.splice(index, 1)
                                setUpdateState(Date.now())
                            } } up={ () => {
                                moveData(component,index)
                            } } down={ () => {
                                moveData(component,index,'down')
                            } } component={component}/>
                        }
                        if (component.component == 'icon_slider'){
                            return <IconSlider key={component.id} remove={ () => {
                                components.splice(index, 1)
                                setUpdateState(Date.now())
                            } } up={ () => {
                                moveData(component,index)
                            } } down={ () => {
                                moveData(component,index,'down')
                            } } component={component}/>
                        }
                        if (component.component == 'banner'){
                            return <Banner key={component.id} refresh={ () => {
                                setComponents(components)
                                setUpdateState(Date.now())
                            } } remove={ () => {
                                components.splice(index, 1)
                                setUpdateState(Date.now())
                            } } up={ () => {
                                moveData(component,index)
                            } } down={ () => {
                                moveData(component,index,'down')
                            } } component={component}/>
                        }
                        if (component.component == 'compact_box'){
                            return <CompactBox key={component.id} remove={ () => {
                                components.splice(index, 1)
                                setUpdateState(Date.now())
                            } } up={ () => {
                                moveData(component,index)
                            } } down={ () => {
                                moveData(component,index,'down')
                            } } component={component}/>
                        }
                        if (component.component == 'tabs'){
                            return <TabBox key={component.id} remove={ () => {
                                components.splice(index, 1)
                                setUpdateState(Date.now())
                            } } up={ () => {
                                moveData(component,index)
                            } } down={ () => {
                                moveData(component,index,'down')
                            } } component={component}/>
                        }
                        if (component.component == 'blog'){
                            return <BlogBox key={component.id} remove={ () => {
                                components.splice(index, 1)
                                setUpdateState(Date.now())
                            } } up={ () => {
                                moveData(component,index)
                            } } down={ () => {
                                moveData(component,index,'down')
                            } } component={component}/>
                        }
                        if (component.component == 'top_sell_products'){
                            return <TopSellProducts key={component.id} remove={ () => {
                                components.splice(index, 1)
                                setUpdateState(Date.now())
                            } } up={ () => {
                                moveData(component,index)
                            } } down={ () => {
                                moveData(component,index,'down')
                            } } component={component}/>
                        }
                        if (component.component == 'campaigns'){
                            return <CampaignBox key={component.id} remove={ () => {
                                components.splice(index, 1)
                                setUpdateState(Date.now())
                            } } up={ () => {
                                moveData(component,index)
                            } } down={ () => {
                                moveData(component,index,'down')
                            } } component={component}/>
                        }                        
                        if (component.component == 'content_box'){
                            return <ContentBox key={component.id} remove={ () => {
                                components.splice(index, 1)
                                setUpdateState(Date.now())
                            } } up={ () => {
                                moveData(component,index)
                            } } down={ () => {
                                moveData(component,index,'down')
                            } } component={component}/>
                        }
                        if (component.component == 'content_image_box'){
                            return <ContentImageBox key={component.id} remove={ () => {
                                components.splice(index, 1)
                                setUpdateState(Date.now())
                            } } up={ () => {
                                moveData(component,index)
                            } } down={ () => {
                                moveData(component,index,'down')
                            } } component={component}/>
                        }
                    }
                )}



                { (data && components) && <div className="text-left">
                    <button onClick={handleSave} className="btn-green text-xs py-2.5 rounded px-10">ذخیره قالب</button>
                </div>}

            </div>


        </Layout>
    )
}

export default EditTemplate