import ProductSelect from "../../../Product/ProductSelect";
import Modal from "../../../Utilities/Modal";
import {useState} from "react";
import ApiRoute from "../../../../helper/api";
import CouponSelect from "../../../Common/CouponSelect";
import Toman from "../../../Utilities/Toman";
import {DatePicker} from "zaman";
import moment from "jalali-moment";
import {faNumberToEn} from "../../../../helper/general";
import {toast} from "react-toastify";
import OrderDeliveryTime from "../../Edit/Sidebar/OrderDeliveryTime";

export default function OrderConvertToShippingInPersonModal({order, setUpdateState , closeModal , event = null}){

    const [data , setData] = useState({
        address :null,
        shipping_in_person :true
    })


    async function handleSubmit(){
        if (data.address === null){
            toast.error("انتخاب آدرس ضروری میباشد")
            return;
        }
        const res = await ApiRoute.order.convertAddress.fn(order._id , data.address.id, data)
        if (res?.status_code === 200){
            closeModal()
            setUpdateState(Date.now())
            if (event !== null){
                event()
            }
        }
    }

    return (
            <Modal isOpen={true} onClose={closeModal}>
                <div className="page-title mb-5"><span
                    className="inline-block bg-white pl-3 text-sm text-purple-1">تبدیل سفارش به حضوری</span>
                </div>
                <div className="form">
                    <div className="grid grid-cols-2 lg:sgrid-cols-3 justify-items-stretch mb-5 gap-4">
                        <label className="!grid col-span-full">
                            <span className="text-right mb-3 pr-1">انتخاب آدرس</span>
                            <select onChange={ (e) => {
                                order.shop_info.addresses.map( (item) => {
                                    if (item.id === e.target.value){
                                        data.address = item
                                    }
                                } )

                            } } className="!mr-0">
                                <option value="">انتخاب کنید</option>
                                {order?.shop_info?.addresses && order.shop_info.addresses.map( (item) => <option value={item.id}>{item.address}</option> )}
                            </select>
                        </label>
                    </div>
                    <div className="grid grid-cols-4 items-end gap-4">
                        <div className="col-span-1">
                            <button onClick={handleSubmit} className="btn-purple py-2 w-full">تبدیل به حضوری</button>
                        </div>
                    </div>
                </div>
            </Modal>
    )
}