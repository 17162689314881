import {useRef, useState} from "react";

function CustomerStatsTabFilter({options , selectedTab ,setSelectedTab}){
    const myRef = useRef(null);
    const filters = [
        {
            title: 'همه',
            quantity: options.all,
            name: 'created_none',
            active: true
        }, {
            title: 'مشتریان امروز',
            name: 'created_today',
            quantity: options.today,
            active: false
        }, {
            title: 'مشتریان دیروز',
            name: 'created_yesterday',
            quantity: options.yesterday,
            active: false
        }, {
            title: 'مشتریان هفته جاری',
            name: 'created_week',
            quantity: options.week,
            active: false
        }, {
            title: 'مشتریان ماه جاری',
            name: 'created_month',
            quantity: options.month,
            active: false
        },
    ];

    function active(item , el){
        setSelectedTab(item.name)
    }

    return(
        <>
        <div className="border-b border-b-gray-7 p-3 lg:p-2 max-lg:flex overflow-x-auto" ref={myRef}>
            {filters.map((item, id)=>(
                <button
                    key={id}
                    onClick={ e => {
                        active(item , e)
                    } }
                    className={`text-xs w-max ml-4 whitespace-nowrap${selectedTab == item.name ? ' text-purple-1':''}`} key={id}>
                    {item.title}
                    &nbsp;
                    (
                        {item.quantity}
                    )
                </button>
            ))}
        </div>
        </>
    )
}
export default CustomerStatsTabFilter