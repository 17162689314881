import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBarsStaggered, faEdit} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";


function StatesTable(props) {
    const tableTitles = [
        'کد پارسی مپ',
        'نام فارسی',
        'نام انگلیسی',
        'تعداد شهرها',
    ]
    return (
        <table className="coupon-table table-auto w-full mt-4 block sm:table">
            <thead>
            <tr className="border-b border-b-gray-7">
                {tableTitles.map((item, id) => (
                    <td className="text-13 text-gray-6 pb-2" key={id}>{item}</td>
                ))}
                <td></td>
            </tr>
            </thead>
            <tbody>
            {props.tableData.map((tableData, id) => (
                        <tr className={`text-xs py-2`} key={id}>
                            <td>{tableData.parsimap_code}</td>
                            <td>{tableData.faname}</td>
                            <td>{tableData.enname}</td>
                            <td>{tableData.cities.length}</td>

                            <td className="flex justify-center items-center py-3">
                                <Link className="mx-2" to={`/coordinates/cities?state_faname=${tableData.faname}&state_id=${tableData._id}`} title="مشاهده شهرها"><FontAwesomeIcon
                                    className="bg-purple-2 text-white p-1 w-4 h-4 rounded" icon={faBarsStaggered}/></Link>
                            </td>

                        </tr>
                )
            )}
            </tbody>
        </table>
    )
}

export default StatesTable