import Layout from "../../../components/Layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faFilter,
    faLocationPin, faLock,
    faPrint,
    faSortDown,
    faTimes,
    faTruckFast
} from "@fortawesome/free-solid-svg-icons";
import {faCircleCheck} from "@fortawesome/free-regular-svg-icons";
import SwitchToggle from "../../../components/Utilities/SwitchToggle";
import {useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Modal from "../../../components/Utilities/Modal";
import Toman from "../../../components/Utilities/Toman";
import OrderKeyHeader from "../../../components/Order/Edit/OrderKeyHeader";
import ApiRoute from "../../../helper/api";
import OrderUserInfo from "../../../components/Order/Edit/OrderUserInfo";
import OrderCustomerInfo from "../../../components/Order/Edit/OrderCustomerInfo";
import OrderInfo from "../../../components/Order/Edit/OrderInfo";
import OrderItems from "../../../components/Order/Edit/OrderItems";
import OrderAmounts from "../../../components/Order/Edit/OrderAmounts";
import OrderItemAction from "../../../components/Order/Edit/OrderItemAction";
import OrderPrintStatus from "../../../components/Order/Edit/Sidebar/OrderPrintStatus";
import OrderVisitor from "../../../components/Order/Edit/Sidebar/OrderVisitor";
import OrderPaymentUrl from "../../../components/Order/Edit/Sidebar/OrderPaymentUrl";
import OrderPaymentMethod from "../../../components/Order/Edit/Sidebar/OrderPaymentMethod";
import OrderStatus from "../../../components/Order/Edit/Sidebar/OrderStatus";
import OrderDeliveryTime from "../../../components/Order/Edit/Sidebar/OrderDeliveryTime";
import OrderNotes from "../../../components/Order/Edit/Sidebar/OrderNotes";
import OrderExtraDescriptionModal from "../../../components/Order/Modal/OrderExtraDescriptionModal";
import OrderShipmentDescriptionModal from "../../../components/Order/Modal/OrderShipmentDescriptionModal";
import OrderDefinedReason from "../../../components/Order/Edit/OrderDefinedReason";
import OrderShippingInPersonCustomerInfo from "../../../components/Order/Edit/OrderShippingInPersonCustomerInfo";
import OrderTransferenceSubmitModal from "../../../components/Order/Modal/OrderTransferenceSubmitModal";
import OrderProcessInfo from "../../../components/Order/Edit/Sidebar/OrderProcessInfo";
import OrderLegalInfo from "../../../components/Order/Edit/OrderLegalInfo";
import OrderOperations from "../../../components/Order/Edit/OrderOperations";

export default function OrderEdit() {


    const navigate = useNavigate()
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [updateState, setUpdateState] = useState(0);

    const [data, setData] = useState(null);

    const [order,setOrder] = useState(null);

    const [modal, setModal] = useState(null)

    const [canUserAccess, setCanUserAccess] = useState(true);
    const [liveUser, setLiveUser] = useState(null);

    useEffect(() => {
        getData()
    }, [updateState]);

    useEffect(() => {
        if (data){
            setOrder(data)
        }
    }, [data]);

    useEffect(() => {
        if (data){
            if (canUserAccess){

                //Implementing the setInterval method
                const interval = setInterval(async () => {
                    await ApiRoute.employee.editLock.sync.fn(params.get('id'))
                }, 10000);

                //Clearing the interval
                return () => clearInterval(interval);
            }
        }
    }, [data]);


    async function getData(){
        const response = await ApiRoute.order.single.fn(params.get('id')+"?model=\\App\\OrderManager\\Model\\OrderExpanded")
        setData(response.results)
        setCanUserAccess( response?.meta_data?.edit_lock?.can_user_access ?? true )
        setLiveUser( response?.meta_data?.edit_lock?.live_user ?? null )
    }

    async function editLockTakeOver() {
        await ApiRoute.employee.editLock.takeOver.fn(params.get('id'))
        setCanUserAccess(true)
    }

    function isOrderDisabled() {
        if (order.is_official && order.status !== "waiting"){
            if (order?.metadata?.official_confirmation?.status !== "submitted"){return true;}
        }
        return (order.status == "canceled" || order.status == "refunded");
    }

    return (
        <>
            <Layout>

                {!canUserAccess && <div style={ {
                    position: "fixed",
                    left: 0,
                    right: 0,
                    margin: "auto",
                    top: 0,
                    bottom: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 999999,
                    background: "#00000094",
                    color: "#fff",
                    padding: "5px 20px",
                    display:"grid",
                    placeContent:"center",
                    fontWeight:"bold",
                }}>
                    <div className={`flex flex-col gap-1`}>
                        <div>
                            کاربر "{liveUser?.name ?? 'دیگری'}" در حال کار بر روی این سفارش است
                        </div>
                        <div className={`flex items-center justify-between`}>
                            <div className={`text-[11px] font-normal`}>لطفا چند دقیقه بعد تلاش کنید</div>
                            <button onClick={editLockTakeOver} className={`btn-red py-1 text-[11px] px-1 rounded-lg`}>گرفتن دسترسی</button>
                        </div>
                    </div>
                </div>}

                {order && <div>

                    <OrderKeyHeader order={order} setUpdateState={setUpdateState}/>

                    { (order.status == "canceled" || order.status == "refunded") && <OrderDefinedReason order={order} setUpdateState={setUpdateState}/> }
                    {(order.is_held) && <div className={`fixed items-center flex gap-2 px-4 py-1 top-[20px] right-0 z-[9999] max-w-max m-auto left-0 bg-red-2 text-white rounded`}>
                        <FontAwesomeIcon icon={faLock}/>
                        <span>سفارش توسط {order?.metadata?.held?.by?.name} هولد شده است: {order?.metadata?.held?.desc} </span>
                    </div>}
                    <div className={ ( isOrderDisabled() ? 'pointer-events-none opacity-50' : '') + (order.is_held && 'pointer-events-none') + ` grid grid-cols-1 lg:grid-cols-6 xl:grid-cols-4 gap-3`}>
                        <div className="lg:col-span-4 xl:col-span-3">
                            
                            {order.is_official && <OrderLegalInfo order={order} setUpdateState={setUpdateState}/>}
                            <OrderUserInfo order={order} setUpdateState={setUpdateState}/>
                            { !order.is_shipping_in_person && <OrderCustomerInfo order={order} setUpdateState={setUpdateState}/> }
                            { order.is_shipping_in_person && <OrderShippingInPersonCustomerInfo order={order} setUpdateState={setUpdateState}/> }
                            <OrderInfo order={order} setUpdateState={setUpdateState}/>
                            <OrderItems order={order} setUpdateState={setUpdateState}/>
                            <OrderAmounts order={order} setUpdateState={setUpdateState}/>
                            <OrderItemAction order={order} setUpdateState={setUpdateState}/>
                            {/*order custom notes*/}

                        </div>
                        <div className="lg:col-span-2 xl:col-span-1 text-xs">

                            <div className="main-content border border-gray-7 relative rounded-md p-5 mt-4">
                                <OrderOperations className="stanalone" tableData={order} setUpdateData={setUpdateState}/>
                            </div>

                            <div className="main-content border border-gray-7 relative rounded-md p-5 mt-4">

                                <OrderPrintStatus order={order} setUpdateState={setUpdateState}/>
                                <OrderVisitor order={order} setUpdateState={setUpdateState}/>
                                <OrderPaymentUrl order={order} setUpdateState={setUpdateState}/>
                                <OrderPaymentMethod order={order} setUpdateState={setUpdateState}/>
                                <OrderStatus order={order} setUpdateState={setUpdateState}/>
                                <OrderDeliveryTime
                                    order={order}
                                    setUpdateState={setUpdateState}/>
                            </div>

                            {order.status === "process" && order.is_transference && <OrderProcessInfo order={order} setUpdateState={setUpdateState}/>}

                            <OrderNotes order={order} updateState={updateState} setUpdateState={setUpdateState}/>

                        </div>
                    </div>


                </div>}
            </Layout>
        </>
    )
}