import Toman from "../../Utilities/Toman";
import Modal from "../../Utilities/Modal";
import {useState} from "react";
import ApiRoute, {shopId} from "../../../helper/api";
import {formatNumberWithDots, getDateAsReadableFormat} from "../../../helper/general";
import ProductSelect from "../../Product/ProductSelect";
import SelectCoordinates from "../../Coordinates/SelectCoordinates";
import PeriodTime from "../../PeriodTime";
import {toast} from "react-toastify";

export default function QuickDeliveryTimeCheck({closeModal}){
    const [product , setProduct] = useState(null)
    const [coordinate , setCoordinate] = useState(null)
    const [data , setData] = useState(null)

    async function handleSubmit(){
        if (product === null){
            toast.error('انتخاب محصول الزامی است')
            return;
        }
        if (coordinate === null){
            toast.error('انتخاب موقعیت الزامی است')
            return;
        }
        let products = []
        products.push(product)
        const res = await ApiRoute.shipment.delivery.getDeliveryPeriods.fn({
            products : products,
            address: {
                coordinates: {
                    state: coordinate.state,
                    city: coordinate.city,
                    area: coordinate.area
                }
            },
            shop_id: shopId()
        });
        if (res?.status_code === 200){
            setData(res.results)
        }
    }

    return (
        <Modal isOpen={true} onClose={closeModal}>
            <div className="page-title mb-3"><span className="inline-block bg-white text-xs text-purple-1 pl-3">مشاهده بازه زمانی قابل انتخاب برای کالای خاص</span>
            </div>
            <div className="form grid-form grid grid-cols-1 text-xs gap-3 my-6">
                <div>
                    <label>محصول</label>
                    <ProductSelect multiple={false} assignItem={ (s) => {
                        s.map( (p) => setProduct(p) )
                    } }/>
                </div>
                <div>
                    <label>موقعیت</label>
                    <div className={`mt-2`}>
                        <SelectCoordinates multiple={false} SetCoordinate={ (s) => {
                            setCoordinate({
                                state: s.state[0] ?? {},
                                city: s.city[0] ?? {},
                                area:s.area[0] ?? {},
                            })
                        } }/>
                    </div>
                </div>
            </div>

            {data && <PeriodTime periods={data} onSelect={(d) => {}}/>}

            <div className="flex justify-end mt-4">
                <button onClick={handleSubmit} className="btn-purple px-5 py-2">بررسی وضعیت</button>
            </div>
        </Modal>
    )
}