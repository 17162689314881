import SwitchToggle from "../../Utilities/SwitchToggle";
import ApiRoute from "../../../helper/api";

export default function SwitchField({item}){
    return (
        <div className={`w-full flex items-center gap-3`}>
            <div className={`text-13`}>{item.label}</div>
            <SwitchToggle  onSwitch={ async (c) => {
                item.value = c
            } } onOff={item.value}/>
        </div>
    )
}