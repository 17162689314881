import Layout from "../../components/Layout";
import {Link, useNavigate} from "react-router-dom";
import CampaignTable from "../../components/Tables/CampaignTable";
import Pagination from "../../components/Pagination";
import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../helper/api";
import CreatePageModal from "../../components/Page/CreatePageModal";
import CreateCampaignModal from "../../components/Campaign/CreateCampaignModal";
import {HasAccessComponent} from "../../helper/permissions";

function Campaigns() {

    const navigate = useNavigate()
    const [createModal, setCreateModal] = useState(false)

    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  res  = await ApiRoute.campaign.index.fn({
                shop_id: shopId(),
                quick_search : searchQuery,
                page : pageNumber
            },filterQuery)
            setData(res)
            setIsLoading(false)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);


    return (
        <Layout>
            <div>

                <div className="flex items-center justify-between mb-3">
                    <div className="page-title flex-auto ml-3 md:ml-0">
                        <span className="inline-block bg-white pl-3">کمپین ها</span>
                    </div>
                    <HasAccessComponent permission={`campaigns create`} children={
                        <button onClick={() => {setCreateModal(true)}} className="btn-blue text-center md:hidden"> افزودن کمپین جدید</button>
                    }/>
                </div>
                <HasAccessComponent permission={`campaigns create`} children={
                    <button onClick={() => {setCreateModal(true)}} className="btn-blue text-center hidden md:block mr-auto"> افزودن کمپین جدید</button>
                }/>

                <div className="main-content p-1 mt-3">
                    {data && <CampaignTable setUpdateData={setUpdateData} tableData={tableData}/>}
                    {data && <Pagination SetPageNumber={setPageNumber} Data={paginationData}/>}
                </div>
            </div>


            {createModal && <CreateCampaignModal
                closeModal={ () => {
                    setCreateModal(false);
                } } event={ (res) => {
                setUpdateData(Date.now().toString())
                return navigate("/campaigns/edit?id="+res.results._id)
            } }/>}

        </Layout>
    )
}

export default Campaigns