import Layout from "../../components/Layout";
import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../helper/api";
import {Link, useNavigate} from "react-router-dom";

function CreateCoupon() {
    const navigate = useNavigate()
    const [data, setData] = useState(null)
    const [newData, setNewData] = useState({
        title:"",
        admin_title:"",
        code:"",
        parent_id:"",
        active:false,
    })
    useEffect(() => {
        (async () => {
            const  res  = await ApiRoute.coupon.allParent.fn()
            setData(res.results)
        })();
    },[]);

    async function handleSubmit(){
        const res = await ApiRoute.coupon.create.fn(newData)
        if (res.results.is_parent){
            return navigate("/coupons/view?id="+res.results._id)
        }
        return navigate("/coupons/edit?id="+res.results._id)
    }

    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">ایحاد کد تخفیف</span>
                </div>

                <div className="main-content xl:w-3/4 mx-auto p-8 mt-12">
                    <div className="form">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 justify-items-stretch mb-10 gap-5 xl:gap-10">
                            <div>
                                <label>
                                    کد تخفیف
                                    <input onKeyUp={ (e) => {
                                        newData.code = e.target.value;
                                    } } type="text"/>
                                </label>
                            </div>
                            <div>
                                <label>
                                    عنوان
                                    <input onKeyUp={ (e) => {
                                        newData.title = e.target.value;
                                    } } type="text" placeholder="عنوان کد تخفیف را وارد کنید"/>
                                </label>
                            </div>
                            <div>
                                <label>
                                    عنوان مدیریتی
                                    <input onKeyUp={ (e) => {
                                        newData.admin_title = e.target.value;
                                    } } type="text" placeholder="عنوان مدیریتی را وارد کنید"/>
                                </label>
                            </div>

                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-1 justify-items-stretch gap-5 lg:gap-10">
                            <div><label>
                                زیرگروه
                                <select onChange={ (e) => {
                                    newData.parent_id = e.target.value;
                                } }>
                                    <option value="">بدون زیر گروه</option>
                                    {data && data.map( item => <option value={item._id}>{item.admin_title}</option> )}
                                </select>
                            </label></div>
                        </div>
                        <div className="flex justify-center gap-5 lg:gap-36 mt-10">
                            <Link to={`/coupons`} className="btn-gray w-full lg:w-36">بازگشت</Link>
                            <button onClick={handleSubmit} className="btn-purple w-full lg:w-36">افزودن کد تخفیف</button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CreateCoupon