export default function OrderShippingFilterTab({options , selectedTab ,setSelectedTab}){
    const filters = [
        {
            title: 'همه',
            name:'all',
            quantity: options?.all ?? 0,
            active: true
        },
        {
            title: 'آماده ارسال',
            quantity: options?.ready_to_send ?? 0,
            name: 'ready_to_send'
        },
        {
            title: 'ارسال شده',
            quantity: options?.sent ?? 0,
            name:'sent'
        },
        {
            title: 'ارسال امروز',
            quantity: options?.today_shipment ?? 0,
            name:'today_shipment'
        },
        {
            title: 'ارسال امروز به بعد',
            quantity: options?.till_today_shipment ?? 0,
            name:'till_today_shipment'
        },
        {
            title: 'ارسال هفت روز اخیر',
            quantity: options?.past_seven_days_shipment ?? 0,
            name:'past_seven_days_shipment'
        },
        {
            title: 'ارسال امروز و فردا',
            quantity: options?.today_and_tomorrow_shipment ?? 0,
            name:'today_and_tomorrow_shipment'
        },
        {
            title: 'ارسال فردا',
            quantity: options?.tomorrow_shipment ?? 0,
            name:'tomorrow_shipment'
        },
        {
            title: 'ارسال گذشته',
            quantity: options?.past_shipment ?? 0,
            name:'past_shipment'
        },
    ]

    function active(item , el){
        setSelectedTab(item.name)
    }

    return(
        <>
        <div className="border-b border-b-gray-7 p-3 lg:p-2 max-lg:flex overflow-x-auto">
            {filters.map((item, id)=>(
                <button
                    onClick={ e => {
                        active(item , e)
                    } }
                    className={`text-xs w-max ml-4 whitespace-nowrap${(selectedTab.length < 0 ? 'all' : selectedTab) == item.name?' text-purple-1':''}`} key={id}>
                    {item.title}
                    &nbsp;
                    (
                        {item.quantity}
                    )
                </button>
            ))}
        </div>
        </>
    )
}