import Layout from "../../../components/Layout";
import {Editor} from "@tinymce/tinymce-react";
import {Link} from "react-router-dom";
import ApiRoute, {uploadUrl} from "../../../helper/api";
import React, {useEffect, useRef, useState} from "react";
import Buttons from "react-multi-date-picker/components/button";
import {toast, ToastContainer} from "react-toastify";
import CategorySelect from "../../../components/Product/Category/CategorySelect";

function EditCategory() {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [editor, setEditor] = useState(null);
    const [data, setData] = useState(null);
    const [content, setContent] = useState('');
    const editorRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const [editData,setEditData] = useState({});
    const [updateData,setUpdateData] = useState(0);
    useEffect(() => {
        const getData = async () => {
            const response = await ApiRoute.product.category.single.fn(params.get('id')+"?model=\\App\\CategoryManager\\Model\\CategoryExpanded")
            setData(response.results)
        }
        getData();
    }, [updateData]);

    useEffect(() => {
        if (data){
            setEditData({
                label : data.label,
                slug : data.slug,
                description : data.description,
                parent_id : data?.parent_id ?? null,
                is_shipment_class: data.is_shipment_class,
                template: data?.template ?? null,
                seo : {
                    title : data?.seo?.title ?? '',
                    description : data?.seo?.description ?? '',
                }
            })
            setContent(data.description)
        }

    }, [data]);


    async function handleSubmit() {
        editData.description = editor.getContent()
        setEditData(editData)
        const response = await ApiRoute.product.category.update.fn(params.get('id'),editData)
        if (response.status_code === 200) {
            setUpdateData(Date.now())
        }
    }

    async function createTemplate() {
        if(editData?.label?.length < 5){
            //toast.error("عنوان بیش از حد کوتاه است")
            //return
        }
        setLoading(true)
        await ApiRoute.template.create.fn({
            title:editData.label,
            slug:data._id,
            type:"category"
        })
        editData.template = data._id;
        await handleSubmit()
        setLoading(false)
    }

    async function deleteTemplate() {
        setLoading(true)
        await ApiRoute.template.deleteBySlug.fn(data.template)
        editData.template = null;
        await handleSubmit()
        setLoading(false)
    }


    return (

        <Layout>
            {data && <div>
                <div className="page-title mb-3">
                    <span className="inline-block bg-white pl-3">ویرایش دسته بندی</span>
                    {editData?.template == null && <button disabled={loading} onClick={createTemplate} className="btn-purple w-36 py-1.5 ml-auto float-left">ایجاد قالب</button>}
                    {editData?.template != null && <button disabled={loading} onClick={deleteTemplate} className="btn-purple bg-red-1 w-36 py-1.5 ml-auto float-left">حذف قالب</button>}
                    {editData?.template != null && <Link target="_blank" to={`/templates/edit?id=${data.template}&as-slug=true`} className="btn-purple bg-green-1 w-36 py-1.5 ml-2 float-left">ویرایش قالب</Link>}
                </div>

                <div className="main-content mx-auto p-5 mt-6">
                    <div className="form grid grid-cols-1 xl:grid-cols-4 gap-6 lg:gap-12 justify-items-stretch">
                        <label className="!grid ">
                            <span className="text-right mb-2">عنوان</span>
                            <input onChange={ (e) =>
                            { editData.label = e.target.value; setEditData(editData) } } className="!mr-0" type="text" placeholder="عنوان را وارد کنید"
                                   defaultValue={editData?.label}/>
                        </label>
                        <label className="!grid">
                            <span className="text-right mb-2">نامک</span>
                            <input dir={`ltr`} onChange={ (e) =>
                            { editData.slug = e.target.value; setEditData(editData) } } className="!mr-0 text-left" type="text" placeholder="نامک را وارد کنید"
                                   defaultValue={editData?.slug}/>
                        </label>
                        <label className="!grid">
                            <span className="text-right mb-2">کنترلر حمل و نقل</span>
                            <select defaultValue={editData.is_shipment_class} className="!mr-0" onChange={ (e) => {
                                editData.is_shipment_class = e.target.value
                                setEditData(editData)
                            } }>
                                <option selected={ editData.is_shipment_class == 0 ? 'selected' : '' } value={0}>خیر</option>
                                <option selected={ editData.is_shipment_class == 1 ? 'selected' : '' } value={1}>بله</option>
                            </select>
                        </label>

                        <div className="!grid">
                            <span className="mb-2">مادر</span>
                            <CategorySelect assignItem={(v) => {
                                editData.parent_id = null
                                v.map( (x) => {
                                    editData.parent_id = x._id
                                } )
                            }} multiple={false} customDefaultValue={true} defaultValue={[data.parent]}/>
                        </div>

                    </div>
                </div>
                <div className="main-content mx-auto py-6 px-5 my-5">
                    <Editor
                        tinymceScriptSrc='/tinymce/tinymce.min.js'
                        onInit={(evt, editor) => {
                            setEditor(editor);
                            editorRef.current = editor
                        }}
                        onSetContent={ (evt, editor) => setContent(editor.getContent()) }
                        initialValue={content}
                        init={{
                            images_upload_url: uploadUrl,
                            selector: 'textarea#open-source-plugins',
                            plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion',
                            editimage_cors_hosts: ['picsum.photos'],
                            menubar: 'file edit view insert format tools table help',
                            toolbar: "undo redo | accordion accordionremove | blocks fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl",
                            autosave_ask_before_unload: true,
                            autosave_interval: '30s',
                            autosave_prefix: '{path}{query}-{id}-',
                            autosave_restore_when_empty: false,
                            autosave_retention: '2m',
                            image_advtab: true,
                            rtl_ui:true,
                            directionality : "rtl",
                            rtl: true,
                            importcss_append: true,
                            file_picker_callback: (callback, value, meta) => {
                                /* Provide file and text for the link dialog */
                                if (meta.filetype === 'file') {
                                    callback('https://www.google.com/logos/google.jpg', {text: 'My text'});
                                }

                                /* Provide image and alt text for the image dialog */
                                if (meta.filetype === 'image') {
                                    callback('https://www.google.com/logos/google.jpg', {alt: 'My alt text'});
                                }

                                /* Provide alternative source and posted for the media dialog */
                                if (meta.filetype === 'media') {
                                    callback('movie.mp4', {
                                        source2: 'alt.ogg',
                                        poster: 'https://www.google.com/logos/google.jpg'
                                    });
                                }
                            },
                            templates: [],
                            height: 700,
                            image_caption: true,
                            quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                            noneditable_class: 'mceNonEditable',
                            toolbar_mode: 'sliding',
                            contextmenu: 'link image table',
                            skin: 'oxide',
                            content_css: 'default',
                            content_style: ' @import url(\'https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100;200;300;400;500;600;700;800;900&display=swap\'); body { font-family:"Vazirmatn",Arial,sans-serif; font-size:16px; text-align:right; direction:rtl; } img{ width:100%; height:auto; }'
                        }}
                    />

                    <div>
                        <label className="mt-12 mb-3 block text-13 px-2">عنوان سئو</label>
                        <input onChange={ (e) =>
                        { editData.seo.title = e.target.value; setEditData(editData) } }
                               className="w-full border border-gray-7 rounded outline-0 p-3 text-13" type="text" defaultValue={editData?.seo?.title ?? ''}>
                        </input>
                    </div>
                    <div>
                        <label className="mt-12 mb-3 block text-13 px-2">توضیحات متا محصول</label>
                        <textarea onChange={ (e) =>
                        { editData.seo.description = e.target.value; setEditData(editData) } } defaultValue={editData?.seo?.description ?? ''}
                                  className="w-full border border-gray-7 rounded outline-0 py-4 px-3 text-13" cols="30" rows="4"></textarea>
                    </div>

                    {/*<div className="grid lg:flex items-center gap-4 lg:gap-12 mt-4 px-2 text-xs">
                        <label>
                            بررسی عنوان سئو
                            <input className="mr-2" type="checkbox"/>
                        </label>

                        <label>
                            بررسی متا توضیحات
                            <input className="mr-2" type="checkbox"/>
                        </label>

                        <label>
                            بررسی وضعیت لینک ها
                            <input className="mr-2" type="checkbox"/>
                        </label>
                    </div>*/}

                    <div className="flex justify-center mx-auto mt-10">
                        <Buttons onClick={handleSubmit} className="btn-purple w-36 py-1.5">ویرایش</Buttons>
                    </div>

                </div>


            </div>}

            
        </Layout>
    )
}

export default EditCategory
