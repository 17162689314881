import Layout from "../../components/Layout";
import {Link, useParams} from "react-router-dom";
import Pagination from "../../components/Pagination";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import Toman from "../../components/Utilities/Toman";
import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../helper/api";
import CustomerAddressList from "../../components/Customer/CustomerAddressList";
import CustomerTransaction from "../../components/Customer/CustomerTransaction";
import CustomerIdentityInfo from "../../components/Customer/CustomerIdentityInfo";

function ViewCustomer() {
    const { id} = useParams()

    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState(null)
    const [updateData, setUpdateData] = useState('0')

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const res  = await ApiRoute.customer.single.fn(id)
            setData(res.results)
            setIsLoading(false)
        })();
    },[updateData]);

    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">ویرایش مشتری</span></div>
                { data ? (
                    <div>
                        <CustomerIdentityInfo customer={data}/>
                        <CustomerAddressList customer={data}/>
                        <CustomerTransaction customer={data}/>
                    </div>
                ) : '' }
            </div>
        </Layout>
    )
}

export default ViewCustomer