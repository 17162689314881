function Toman(props){
    return(
        /*<svg className={`mr-0.5 fill-gray-5 ${props.customClass? props.customClass:''}`} xmlns="http://www.w3.org/2000/svg" width="23.386" height="11.357"
             viewBox="0 0 23.386 11.357">
            <path id="Path_74" data-name="Path 74"
                  d="M117.984,167.917a8.2,8.2,0,0,1-2.263-.759,5.881,5.881,0,0,1-2.205-1.842,3.458,3.458,0,0,1-.685-1.858,2.383,2.383,0,0,1,.115-.728q.348-1.189,2.33-1.866a4.242,4.242,0,0,1,1.073-.2.478.478,0,0,1,.4.132.112.112,0,0,1,.033.083q0,.264-1.305.743-1.619.562-1.743,1.116a4.225,4.225,0,0,0-.124.735,2.317,2.317,0,0,0,.577,1.437,5.691,5.691,0,0,0,1.9,1.552,6.378,6.378,0,0,0,3.006.776,4.361,4.361,0,0,0,1.008-.115,2.472,2.472,0,0,0,1.734-1.5,6.5,6.5,0,0,0,.38-2.181,5.8,5.8,0,0,0-.033-.628,25.245,25.245,0,0,0-.429-3.171v-.05a.355.355,0,0,1,.082-.232.324.324,0,0,1,.3-.165.408.408,0,0,1,.247.083.353.353,0,0,1,.141.247c.017.111.036.226.058.347a20.3,20.3,0,0,1,.4,3.6,6.638,6.638,0,0,1-.462,2.512,3.175,3.175,0,0,1-2.213,1.883,5.052,5.052,0,0,1-1.223.149A6.046,6.046,0,0,1,117.984,167.917Zm.661-7.912a.6.6,0,0,1-.149-.412.577.577,0,0,1,.149-.4.483.483,0,0,1,.728,0,.577.577,0,0,1,.149.4.593.593,0,0,1-.149.412.483.483,0,0,1-.728,0Z"
                  transform="translate(-112.83 -159.03)"/>
            <path id="Path_75" data-name="Path 75"
                  d="M183.149,166.748a4.93,4.93,0,0,1-.629-2.734,20.258,20.258,0,0,1,.315-3.523q.236-1.18.629-1.181.355,0,.355.826l-.02.493a18.093,18.093,0,0,0-.3,3.266v.256a4.969,4.969,0,0,0,.256,1.751.726.726,0,0,0,.708.59v.925A1.434,1.434,0,0,1,183.149,166.748Z"
                  transform="translate(-171.957 -159.268)"/>
            <path id="Path_76" data-name="Path 76"
                  d="M198.491,196.768a2.468,2.468,0,0,1-1.928-1.082,2.892,2.892,0,0,1-.758.57,2,2,0,0,1-.955.236v-.925a1.187,1.187,0,0,0,.916-.473,3.034,3.034,0,0,0,.561-.9q.216-.609.591-.609a.939.939,0,0,1,.59.265q.314.266.649.5a8.932,8.932,0,0,1,1.378,1.161.691.691,0,0,1,.217.453.357.357,0,0,1-.039.158.958.958,0,0,1-.434.453,1.519,1.519,0,0,1-.668.2h-.118Zm-1.357-1.948v.02a.984.984,0,0,0,.167.573.961.961,0,0,0,.462.3,3.58,3.58,0,0,0,.629.071A15.212,15.212,0,0,0,197.134,194.82Z"
                  transform="translate(-182.418 -188.343)"/>
            <path id="Path_77" data-name="Path 77"
                  d="M220.1,194.724a.232.232,0,0,1-.092-.194q0-.327.758-1.064a9.228,9.228,0,0,0,2.005-2.393h-.123a2.522,2.522,0,0,1-.788-.112.459.459,0,0,1-.338-.48,3.077,3.077,0,0,1,.879-1.7,2.2,2.2,0,0,1,1.575-.982q.7,0,.7.9a2.081,2.081,0,0,1-.041.409,5.018,5.018,0,0,1-.267,1h.409v.961H223.9a9.274,9.274,0,0,1-2.333,3.028,2.457,2.457,0,0,1-1.247.7A.332.332,0,0,1,220.1,194.724Zm3.222-4.778a4.367,4.367,0,0,0,.347-1,4.241,4.241,0,0,0-.982,1.167h.573Z"
                  transform="translate(-203.764 -183.439)"/>
            <path id="Path_78" data-name="Path 78"
                  d="M250.592,186.72a1.322,1.322,0,0,1-.082-.491,1.161,1.161,0,0,1,.082-.47h.1q1.228-.061,1.279-.511c.033-.3.052-.518.052-.655a4.74,4.74,0,0,0-.2-1.288l-.021-.123a.476.476,0,0,1,.082-.267.484.484,0,0,1,.45-.246.521.521,0,0,1,.286.092.432.432,0,0,1,.183.3,5.674,5.674,0,0,1,.226,1.493,3.346,3.346,0,0,1-.164,1.064q-.348,1.064-2.169,1.1h-.1Z"
                  transform="translate(-229.641 -179.087)"/>
            <path id="Path_79" data-name="Path 79"
                  d="M236.463,166.765a.528.528,0,0,1-.28-.236.518.518,0,0,1-.033-.455.538.538,0,0,1,.206-.274.592.592,0,0,1,.414-.091,11.467,11.467,0,0,1,2.137.44c.717.227,1.043.44.981.637q-.146.457-2.646.049l-.7-.042Z"
                  transform="translate(-217.427 -164.693)"/>
        </svg>*/
        <span>ت</span>
    )
}
export default Toman