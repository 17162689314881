function FilterBox(props) {
    return (
        <div className={`FilterBox ${props.showHide?'show':'hide'}`}>
            <div className="pt-3 pb-4">
                <div className="main-content p-3">
                    <div className="grid-form text-xs grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-9 gap-3 2xl:gap-4 2xl:gap-y-5">
                        <div>
                            <label>
                                <span className="block">استان</span>
                                <select className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه استان ها</option>
                                    <option value="">تهران</option>
                                    <option value="">البرز</option>
                                    <option value="">اردبیل</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">شهر</span>
                                <select className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه شهر ها</option>
                                    <option value="">تهران</option>
                                    <option value="">کرج</option>
                                    <option value="">اردبیل</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">ویزیتور</span>
                                <select className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه ویزیتور ها</option>
                                    <option value="">ویزیتور 1</option>
                                    <option value="">ویزیتور 2</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">وضعیت حواله</span>
                                <select className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه وضعیت ها</option>
                                    <option value="">وضعیت 1</option>
                                    <option value="">وضعیت 2</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">وضعیت فاکتور</span>
                                <select className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه وضعیت ها</option>
                                    <option value="">وضعیت 1</option>
                                    <option value="">وضعیت 2</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">انبار</span>
                                <select className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه انبار ها</option>
                                    <option value="">انبار 1</option>
                                    <option value="">انبار 2</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">باربری</span>
                                <select className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه باربری ها</option>
                                    <option value="">باربری 1</option>
                                    <option value="">باربری 2</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">نوع ثبت سفارش</span>
                                <select className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="">نوع 1</option>
                                    <option value="">نوع 2</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">نوع سفارش</span>
                                <select className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">همه</option>
                                    <option value="">نوع 1</option>
                                    <option value="">نوع 2</option>
                                </select>
                            </label>
                        </div>
                        <div className="lg:col-span-2">
                            <label>
                                بازه ثبت سفارش
                                <div className="date-time !mr-0 mt-1 w-full">
                                    <span className="px-5">از</span>
                                    <input type="datetime-local" className="w-1/3"/>
                                    <span className="px-5">تا</span>
                                    <input type="datetime-local" className="w-1/3"/>
                                </div>
                            </label>
                        </div>
                        <div className="lg:col-span-2">
                            <label>
                                بازه تحویل سفارش
                                <div className="date-time !mr-0 mt-1 w-full">
                                    <span className="px-5">از</span>
                                    <input type="datetime-local" className="w-1/3"/>
                                    <span className="px-5">تا</span>
                                    <input type="datetime-local" className="w-1/3"/>
                                </div>
                            </label>
                        </div>
                        <div className="flex items-end">
                            <button className="btn-orange w-full py-2">اعمال فیلتر</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default FilterBox