import React from 'react';
import Select from 'react-select';


const CustomMultiSelect = ({ options, values, onChange, placeholder }) => {
    return (
        <Select
            options={options}
            isMulti={true} // Enable multi-select
            value={values}
            onChange={onChange}
            placeholder={placeholder}
        />
    );
};

export default CustomMultiSelect;
