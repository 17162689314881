import Layout from "../../components/Layout";
import {Link} from "react-router-dom";
import SearchBox from "../../components/SearchBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import CustomMultiSelect from "../../components/Utilities/CustomMultiSelect";
import {useState} from "react";

function CreatePricingRules() {

    const categories = [{value: '1', label: 'ماشین لباسشویی'}, {value: '2', label: 'ماشین ظرفشویی'}, {
        value: '3',
        label: 'تلویزیون'
    }, {value: '4', label: 'جاروبرقی'}];
    const selectedCategories = [{value: '1', label: 'ماشین لباسشویی'}, {value: '2', label: 'ماشین ظرفشویی'}];
    const [selectedCategory, setSelectedCategory] = useState(selectedCategories);
    const handleMultiSelectCategory = (selectedCategory) => setSelectedCategory(selectedCategory)
    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">قوانین قیمت گذاری</span>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:flex justify-between items-center gap-3">
                    <div className="flex order-2 sm:order-1">
                        <SearchBox SearchIn={true}/>
                        <button className="btn-blue w-12 mr-3"><FontAwesomeIcon icon={faFilter}/></button>
                    </div>
                    <div className="flex justify-end gap-2 order-1 sm:order-2">
                        <Link className="btn-green rounded max-sm:w-1/2 max-lg:py-2" to="#"> افزودن قانون جدید</Link>
                        <Link className="btn-blue max-sm:w-1/2 max-lg:py-2" to="#"> افزودن استثنا قانون</Link>
                    </div>
                </div>

                <div className="main-content p-5 mt-3">
                    <div className="grid grid-cols-1 lg:grid-cols-7 gap-4 lg:gap-8 text-xs">
                        <div>
                            <label className="grid lg:flex items-center gap-3">
                                <span>عنوان</span>
                                <input className="bordered-input-7 flex-auto" type="text"
                                       placeholder="نام قانون را وارد کنید"/>
                            </label>
                        </div>
                        <div className="lg:col-span-6 grid lg:flex items-center gap-3">
                            <label>دسته بندی ها</label>
                            <div className="CustomMultiSelectStyle2 flex-auto">
                            <CustomMultiSelect
                                options={categories}
                                values={selectedCategory}
                                onChange={handleMultiSelectCategory}
                                placeholder="گزینه موردنظر را انتخاب کنید..."
                            />
                            </div>
                            <div className="text-end">
                                <Link className="btn-purple px-8 py-2" to="">برندها</Link>
                            </div>

                        </div>
                    </div>

                    <div className="text-xs text-purple-1 mt-8 lg:mt-4 mb-2">قوانین سایت</div>
                    <div className="bg-gray-9 rounded-md p-3 text-xs">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 2xl:gap-10">
                            <div><label className="flex items-center">
                                <span className="pl-2 text-gray-12">مرز حداقل</span>
                                <input className="bordered-input-7 flex-auto" type="text" />
                            </label></div>
                            <div><label className="flex items-center">
                                <span className="pl-2 text-gray-12">مرز حداکثر</span>
                                <input className="bordered-input-7 flex-auto" type="text" />
                            </label></div>
                            <div><label className="flex items-center">
                                <span className="pl-2 text-gray-12">کف قیمت</span>
                                <input className="bordered-input-7 flex-auto" type="text" />
                            </label></div>
                            <div><label className="flex items-center">
                                <span className="pl-2 text-gray-12">سقف قیمت</span>
                                <input className="bordered-input-7 flex-auto" type="text" />
                            </label></div>
                            <div><label className="flex items-center">
                                <span className="pl-2 text-gray-12">درصد پیش فروش</span>
                                <input className="bordered-input-7 flex-auto" type="text" />
                            </label></div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
                        <div>
                            <div className="text-xs text-purple-1 mt-4 mb-2">فروشگاه جزیره</div>
                            <div className="bg-gray-7 rounded-md p-2 text-xs">
                                <div className="grid grid-cols-1 xl:grid-cols-2 justify-between gap-3 2xl:gap-10">
                                    <div><label className="flex items-center gap-2">
                                        <span className="text-gray-12 w-max">کف قیمت</span>
                                        <input className="bordered-input flex-auto !border-gray-9 w-3/5" type="text" />
                                    </label></div>
                                    <div><label className="flex items-center gap-2">
                                        <span className="text-gray-12 w-max">درصد پیش فروش</span>
                                        <input className="bordered-input flex-auto !border-gray-9 w-2/5" type="text" />
                                    </label></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="text-xs text-purple-1 mt-4 mb-2">فروشگاه جزیره</div>
                            <div className="bg-gray-7 rounded-md p-2 text-xs">
                                <div className="grid grid-cols-1 xl:grid-cols-2 justify-between gap-3 2xl:gap-10">
                                    <div><label className="flex items-center gap-2">
                                        <span className="text-gray-12 w-max">کف قیمت</span>
                                        <input className="bordered-input flex-auto !border-gray-9 w-3/5" type="text" />
                                    </label></div>
                                    <div><label className="flex items-center gap-2">
                                        <span className="text-gray-12 w-max">درصد پیش فروش</span>
                                        <input className="bordered-input flex-auto !border-gray-9 w-2/5" type="text" />
                                    </label></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="text-xs text-purple-1 mt-4 mb-2">فروشگاه جزیره</div>
                            <div className="bg-gray-7 rounded-md p-2 text-xs">
                                <div className="grid grid-cols-1 xl:grid-cols-2 justify-between gap-3 2xl:gap-10">
                                    <div><label className="flex items-center gap-2">
                                        <span className="text-gray-12 w-max">کف قیمت</span>
                                        <input className="bordered-input flex-auto !border-gray-9 w-3/5" type="text" />
                                    </label></div>
                                    <div><label className="flex items-center gap-2">
                                        <span className="text-gray-12 w-max">درصد پیش فروش</span>
                                        <input className="bordered-input flex-auto !border-gray-9 w-2/5" type="text" />
                                    </label></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="text-xs text-purple-1 mt-4 mb-2">فروشگاه جزیره</div>
                            <div className="bg-gray-7 rounded-md p-2 text-xs">
                                <div className="grid grid-cols-1 xl:grid-cols-2 justify-between gap-3 2xl:gap-10">
                                    <div><label className="flex items-center gap-2">
                                        <span className="text-gray-12 w-max">کف قیمت</span>
                                        <input className="bordered-input flex-auto !border-gray-9 w-3/5" type="text" />
                                    </label></div>
                                    <div><label className="flex items-center gap-2">
                                        <span className="text-gray-12 w-max">درصد پیش فروش</span>
                                        <input className="bordered-input flex-auto !border-gray-9 w-2/5" type="text" />
                                    </label></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="text-xs text-purple-1 mt-4 mb-2">فروشگاه جزیره</div>
                            <div className="bg-gray-7 rounded-md p-2 text-xs">
                                <div className="grid grid-cols-1 xl:grid-cols-2 justify-between gap-3 2xl:gap-10">
                                    <div><label className="flex items-center gap-2">
                                        <span className="text-gray-12 w-max">کف قیمت</span>
                                        <input className="bordered-input flex-auto !border-gray-9 w-3/5" type="text" />
                                    </label></div>
                                    <div><label className="flex items-center gap-2">
                                        <span className="text-gray-12 w-max">درصد پیش فروش</span>
                                        <input className="bordered-input flex-auto !border-gray-9 w-2/5" type="text" />
                                    </label></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="text-xs text-purple-1 mt-4 mb-2">فروشگاه جزیره</div>
                            <div className="bg-gray-7 rounded-md p-2 text-xs">
                                <div className="grid grid-cols-1 xl:grid-cols-2 justify-between gap-3 2xl:gap-10">
                                    <div><label className="flex items-center gap-2">
                                        <span className="text-gray-12 w-max">کف قیمت</span>
                                        <input className="bordered-input flex-auto !border-gray-9 w-3/5" type="text" />
                                    </label></div>
                                    <div><label className="flex items-center gap-2">
                                        <span className="text-gray-12 w-max">درصد پیش فروش</span>
                                        <input className="bordered-input flex-auto !border-gray-9 w-2/5" type="text" />
                                    </label></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="text-xs text-purple-1 mt-4 mb-2">فروشگاه جزیره</div>
                            <div className="bg-gray-7 rounded-md p-2 text-xs">
                                <div className="grid grid-cols-1 xl:grid-cols-2 justify-between gap-3 2xl:gap-10">
                                    <div><label className="flex items-center gap-2">
                                        <span className="text-gray-12 w-max">کف قیمت</span>
                                        <input className="bordered-input flex-auto !border-gray-9 w-3/5" type="text" />
                                    </label></div>
                                    <div><label className="flex items-center gap-2">
                                        <span className="text-gray-12 w-max">درصد پیش فروش</span>
                                        <input className="bordered-input flex-auto !border-gray-9 w-2/5" type="text" />
                                    </label></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="text-xs text-purple-1 mt-4 mb-2">فروشگاه جزیره</div>
                            <div className="bg-gray-7 rounded-md p-2 text-xs">
                                <div className="grid grid-cols-1 xl:grid-cols-2 justify-between gap-3 2xl:gap-10">
                                    <div><label className="flex items-center gap-2">
                                        <span className="text-gray-12 w-max">کف قیمت</span>
                                        <input className="bordered-input flex-auto !border-gray-9 w-3/5" type="text" />
                                    </label></div>
                                    <div><label className="flex items-center gap-2">
                                        <span className="text-gray-12 w-max">درصد پیش فروش</span>
                                        <input className="bordered-input flex-auto !border-gray-9 w-2/5" type="text" />
                                    </label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CreatePricingRules