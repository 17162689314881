import Layout from "../../components/Layout";
import SearchBox from "../../components/SearchBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import Filter from "../../components/Filter";
import {Link} from "react-router-dom";
import Pagination from "../../components/Pagination";
import ProductList from "../../components/Tables/ProductTable";
import FilterBox from "../../components/FilterBox";
import {useEffect, useState} from "react";
import ProductFilterStateTab from "../../components/Product/ProductFilterStateTab";
import ProductFilterBox from "../../components/Product/ProductFilterBox";
import ApiRoute, {shopId} from "../../helper/api";

function Products() {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [showFilterBox, setShowFilterBox] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'cellphone'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  res  = await ApiRoute.product.index.fn({
                shop_id: shopId(),
                status:selectedStatsTab,
                page : pageNumber,
                attribute_name:params.get('attribute_name') ?? '',
                attribute_value:params.get('attribute_value') ?? ''
            },filterQuery)
            setData(res)
            setIsLoading(false)

            console.log(data)
        })();
    }, [updateData]);

    // watch searchQuery and update updateData state
    useEffect(() => {
        filterQuery.quick_search = searchQuery
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    //fetch stats-data from server
    useEffect(() => {
        (async () => {
            const  res  = await ApiRoute.product.statusStats.fn({
                shop_id: shopId(),
                attribute_name:params.get('attribute_name') ?? '',
                attribute_value:params.get('attribute_value') ?? ''
            },filterQuery)
            setStatsTab(res.results)
        })();
    }, [data]);

    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    // watch filterQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [filterQuery]);

    // watch statsTab and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [selectedStatsTab]);

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);


    const paginationData = data?.results
    const tableData = data?.results?.data ?? []
    const tableOptions= {rowSatus: true}

    const toggleFilterBox=()=> setShowFilterBox(!showFilterBox)
    return (
        <Layout>
            <div>
                <div className="flex items-center justify-between mb-3">
                    <div className="page-title flex-auto ml-3 md:ml-0">
                        <span className="inline-block bg-white pl-3">محصولات</span>
                    </div>
                    <Link className="btn-blue text-center md:hidden" to="/products/create/step-1"> افزودن کالا جدید</Link>
                </div>

                <div className="grid md:flex justify-between items-center gap-3">
                    <div className="flex gap-3">
                        <SearchBox SetSearchQuery={setSearchQuery}/>
                    </div>
                    <Link className="btn-blue text-center hidden md:block" to="/products/create/step-1">افزودن کالا جدید</Link>
                </div>

                <ProductFilterBox SetFilterQuery={setFilterQuery} showHide={showFilterBox}/>

                {!isLoading ? (
                    <div className="main-content p-1 mt-3">
                        {/*<Filter filters={filters1}/>*/}
                        {/* <Filter filters={filters2}/>*/}

                        <ProductFilterStateTab
                            selectedTab={selectedStatsTab}
                            setSelectedTab={setSelectedStatsTab}
                            options={statsTab}/>

                        <ProductList onSort={ (name , dir) => {
                            filterQuery.auto_sort = []
                            filterQuery.auto_sort.push({
                                name: name,
                                value: dir
                            })
                            console.log(filterQuery)
                            setUpdateData(Date.now().toString())

                        } } setUpdateData={setUpdateData} tableData={tableData}/>
                        <Pagination SetPageNumber={setPageNumber} Data={paginationData} />
                    </div>
                ) : ''}

            </div>
        </Layout>
    )
}

export default Products