import { toast } from "react-toastify"
import ApiRoute from "../../../../helper/api"
import { useState } from "react"

export default function OrderPaymentUrl({order, setUpdateState}){
    const [isLoading, setIsLoading] = useState(false)
    
    const handleSendPaymentLink = async () => {
        setIsLoading(true)
        await ApiRoute.order.sendQuickPaymentLink.fn(order._id)
        setIsLoading(false)
        setUpdateState(Date.now())
    }

    return (
        <div
            className="form flex justify-between items-center pb-3 border-b border-b-gray-7 mt-4">
            <span>لینک پرداخت</span>
            <div className="input-group mr-2">
                <input type="text" className="input text-left !mr-0"
                       defaultValue={order?.metadata?.fast_payment?.short_url}
                       disabled/>
                <button type="button"
                        onClick={handleSendPaymentLink}
                        disabled={isLoading}
                        className="btn btn-green text-sm w-16 rounded-l-md p-2">
                    {isLoading ? 'در حال ارسال...' : 'ارسال'}
                </button>
            </div>
        </div>
    )
}