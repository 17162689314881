import {v4 as uuidv4} from "uuid";
import SwitchToggle from "../Utilities/SwitchToggle";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

export default function ShopBankAccountsEdit({shop}){

    const [update, setUpdate] = useState(0)
    const [newData, setNewData] = useState({
        title:'',
        asan_id: ''
    })
    const add = function(){
        if (newData.title.length < 1) { return;}
        if (shop.bank_account_numbers == null) {
            shop.bank_account_numbers = []
        }

        shop.bank_account_numbers.push({
            title: newData.title,
            asan_id:newData.asan_id,
            active:false,
            id: uuidv4()
        })
        setUpdate(Date.now())
    }

    const remove = function(index){
        shop.bank_account_numbers.splice(index,1)
        setUpdate(Date.now())
    }

    return (
        <div>
            <span className="text-purple-1 text-sm p-2">آیتم های واریز وجه</span>
            <div
                className="text-xs border border-gray-7 rounded-lg pr-4 pl-3 py-3  mb-2 mt-2 items-center before-table-style before-w-4 before-purple">
                <div className="form flex items-center gap-3 border-b border-b-gray-7 pb-3 mb-3">
                    <label className="w-[42%]">
                        نام آیتم
                        <input className="!flex-none w-[60%]" type="text" onKeyUp={ (e) => newData.title = e.target.value }/>
                    </label>
                    <label className="w-[38%]">
                        کد آسان
                        <input className="!flex-none w-[60%]" type="text" onKeyUp={ (e) => newData.asan_id = e.target.value }/>
                    </label>
                    <button onClick={add} className="btn-green w-[20%] rounded py-2">افزودن</button>
                </div>

                <div className="w-full">
                    <div className="grid grid-cols-3 border-b border-b-gray-7 pb-3">
                        <span className="text-center">نام آیتم</span>
                        <span className="text-center">کد آسان</span>
                        <span className="text-center">وضعیت</span>
                    </div>
                    { shop.bank_account_numbers?.length > 0 && shop.bank_account_numbers.map( (item,index) => <div key={index} className="grid grid-cols-1 md:grid-cols-4 gap-3 box-tr-style rounded-md my-2 py-1 px-2">
                        <input type="text"
                               onKeyUp={ (e) => item.title = e.target.value }
                               className={`col-span-2 text-center !py-1 bordered-input text-gray-9`}
                               defaultValue={item.title}
                        />
                        <input placeholder={`کد آسان`} type="number"
                               onKeyUp={ (e) => item.asan_id = e.target.value }
                               className={`text-center !py-1 bordered-input text-gray-9`}
                               defaultValue={item.asan_id}
                        />
                        <div className={`flex items-center gap-3`}>
                            <SwitchToggle  onSwitch={ async (c) => {
                                item.active = c
                            } } onOff={item?.active ?? false}/>
                            <button onClick={() => remove(index)}><FontAwesomeIcon className={`text-red-1 mt-2 text-17`} icon={faTrash}/></button>
                        </div>

                    </div> ) }

                </div>
            </div>
        </div>
    )
}