import {DatePicker} from "zaman";
import moment from "jalali-moment";
import React, {useState} from "react";
import ApiRoute from "../../helper/api";

export default function ExportTable(props) {
    const [from,setFrom] = useState('');
    const [to,setTo] = useState('');
    return (
        <table className="table-auto w-full block lg:table mt-4">
            <tbody>
            {props.tableData.map(tableData => (
                    <tr className={`text-xs py-2`} key={tableData.id}>
                        <td>{tableData.title}</td>
                        <td>
                            {tableData.request ?
                                (
                                    <div className="date-time">
                                        <span className="px-5">از</span>
                                        <DatePicker
                                            onChange={(e) => {
                                                setFrom(moment(e.value).format('YYYY-MM-DD'))
                                            }}
                                        />
                                        <span className="px-5">تا</span>
                                        <DatePicker
                                            onChange={(e) => {
                                                setTo(moment(e.value).format('YYYY-MM-DD'));
                                            }}
                                        />
                                    </div>
                                ) :
                                ''

                            }
                        </td>
                        <td className="text-end pl-5">
                            {tableData.request ?
                                (
                                    <div className="flex justify-end">
                                        <button className="btn-purple ml-2" onClick={ async () => {
                                            await ApiRoute.export.order.fn(from,to)
                                        } }>درخواست</button>
                                        <a className="btn-gray" href={tableData.url}>دانلود</a>
                                    </div>
                                ) :
                                (<a className="btn-green rounded" href={tableData.url}>دانلود</a>)

                            }
                        </td>
                    </tr>
                )
            )}
            </tbody>
        </table>
    )
}