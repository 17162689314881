import SwitchToggle from "../Utilities/SwitchToggle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import Modal from "../Utilities/Modal";
import {v4 as uuidv4} from "uuid";
import CategorySelect from "../Product/Category/CategorySelect";
import SelectCoordinates from "../Coordinates/SelectCoordinates";
import ApiRoute from "../../helper/api";

export default function PrePaymentRule({data,setData}){
    const [update, setUpdate] = useState(0);
    const [isToggled, setToggled] = useState(null);
    const [createModel, setCreateModel] = useState(false);
    const [newData, setNewData] = useState({
        title:'',
        active:false,
        uid:'',
        percent:0,
        category:[],
        state:[],
        city:[],
        area:[]
    });
    const toggleSubgroup = (id) => setToggled(id);
    return (
        <div>

            <div className="flex justify-between items-center mt-10 px-2 py-0.5">
                <span className="block text-right text-sm">قوانین پیش پرداخت</span>
                <button onClick={ () => {
                    setCreateModel(true)
                } } className="btn-purple py-2 w-32 mb-1" type={`button`}>افزودن</button>
            </div>

            {data.prepayment?.length > 0 && data.prepayment.map((item, id) =>
                <div key={id}>
                    <div
                        className="grid grid-cols-2 text-xs items-center rounded border border-gray-7 py-1.5 mb-1.5">
                        <div className="px-5 text-right">{item.title}</div>
                        <div className="px-5 flex items-center justify-end">
                            <SwitchToggle  onSwitch={ (c) => {
                                item.active = !item.active
                            } } onOff={item?.active ?? false}/>
                            <button className="mx-1"
                                    onClick={() => toggleSubgroup(isToggled !== id ? id : null)}>
                                <FontAwesomeIcon
                                    className="bg-blue-2 text-white p-1 w-4 h-4 rounded" icon={faEdit}/>
                            </button>
                            <button className="mx-2" onClick={ (e) => {
                                data.prepayment.splice(id,1)
                                setUpdate(Date.now())
                            } } title="حذف"><FontAwesomeIcon
                                className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                        </div>
                    </div>`
                    {isToggled === id && <div
                        className={`px-5 sub-groups text-xs overflow-visible show py-2`}>
                        <div
                            className="form inner-bordered-box !overflow-visible w-full grid grid-cols-1 lg:grid-cols-4 gap-5 rounded-lg box-tr-style text-xs px-4 td">
                            <div className="lg:col-span-2"><label className="!grid">
                                <span className="px-2 text-right">عنوان</span>
                                <input onInput={ (e) => {
                                    item.title = e.target.value
                                } } className="!mr-0 mt-2" type="text" defaultValue={item.title}/>
                            </label></div>
                            <div className="lg:col-span-2"><label className="!grid">
                                <span className="px-2 text-right">درصد پیش پرداخت</span>
                                <input onInput={ (e) => {
                                    item.percent = parseInt(e.target.value)
                                } } className="text-left !mr-0 mt-2" type="text"
                                       defaultValue={item.percent}/>
                            </label></div>
                            <div className="lg:col-span-4">
                                <CategorySelect multiple={true} defaultValue={item.category} assignItem={ (s) => {
                                    let arr = []
                                    s.map( c => {
                                        arr.push({
                                            label: c.label,
                                            _id: c._id,
                                            slug: c.slug
                                        })
                                    } )
                                    item.category = arr
                                } }/>
                            </div>
                            <div className="lg:col-span-4">
                                <SelectCoordinates defaultValue={{
                                    state: item?.state ?? [],
                                    city: item?.city ?? [],
                                    area: item?.area ?? []
                                }} defaultValueMultiple={true} assignItem={ (s) => {
                                    item.state = s.state
                                    item.city = s.city
                                    item.area = s.area
                                } }/>
                            </div>

                        </div>
                    </div>}
                </div>
            )}

            <Modal isOpen={createModel} onClose={() => {
                setCreateModel(false)
            }}
                   modalWidth="2xl:w-1/4">
                <div className="text-center text-13 mb-4">افزودن قانون پیش پرداخت</div>
                <div className="grid gap-2">
                    <div>

                        <div className={`flex flex-col gap-3`}>
                            <div>
                                <input onInput={ (e) => {
                                    newData.title = e.target.value
                                } } className={`input-x`} placeholder={`عنوان`}/>
                            </div>
                            <div>
                                <input onInput={ (e) => {
                                    newData.uid = uuidv4()
                                    newData.percent = parseInt(e.target.value)
                                } } className={`input-x`} placeholder={`درصد پیش پرداخت`}/>
                            </div>
                            <div>
                                <CategorySelect multiple={true} fixed={true} assignItem={ (s) => {
                                    let arr = []
                                    s.map( c => {
                                        arr.push({
                                            label: c.label,
                                            slug: c.slug,
                                            _id: c._id
                                        })
                                    } )
                                    newData.category = arr
                                } }/>
                            </div>
                            <div>
                                <SelectCoordinates multiple={true} SetCoordinate={ (s) => {
                                    newData.state = s.state
                                    newData.city = s.city
                                    newData.area = s.area
                                } }/>
                            </div>
                        </div>

                        <div className="flex gap-2 mt-4">
                            <button onClick={ () => {
                                data.prepayment.push(newData)
                                setData(data)
                                setCreateModel(false)
                            } } className="btn-green py-1 text-xs">افزودن</button>
                        </div>

                    </div>
                </div>

            </Modal>


        </div>
    )
}