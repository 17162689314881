import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBarsStaggered, faEdit, faSearch} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useState} from "react";
import ApiRoute from "../../helper/api";
import {faRemove} from "@fortawesome/free-solid-svg-icons/faRemove";
import Modal from '../Utilities/Modal';


function CitiesTable(props) {
    const [modalOpen, setModalOpen] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const tableTitles = [
        'کد پارسی مپ',
        'نام فارسی',
        'نام انگلیسی',
        'تعداد مناطق',
    ]
    const [selected,setSelected] = useState([])
    const [currentCity, setCurrentCity] = useState(null);

    function changeSelectedItem(value , name , tableData){
        if (!selected[tableData.uid]){
            selected[tableData.uid] = [];
        }
        selected[tableData.uid][name] = value;
        setSelected(selected)

    }

    async function editHandle(item, data){
      try {
          await ApiRoute.coordinate.updateCity.fn(item._id, data.uid , {
              faname: selected[data.uid].faname,
              enname: selected[data.uid].enname,
              parsimap_code: selected[data.uid].parsimap_code
          })
          localStorage.setItem('coordinate_cache_key_a',Date.now().toString())
      }catch (e){}
    }

    async function deleteHandle(state, city){
        alert('پیاده سازی تمامی حذف ها در مراحل نهایی صورت خواهد گرفت')
    }

    async function handleSearch(text) {
        setIsLoading(true);
        try {
            const response = await fetch(`https://api.parsimap.ir/geocode/forward?key=p197bffbb180644f61a0fe616625b0c1fc26845557&distract=${props.params.get('state_faname')}&search_text=${text}&local_address=true&subdivision=true&request_id=false`);
            const data = await response.json();
            if (data.status === 'OK') {
                setSearchResults(data.results);
            }
        } catch (e) {
            console.error('خطا در جستجو:', e);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
        <table className="coupon-table table-auto w-full mt-4 block sm:table">
            <thead>
            <tr className="border-b border-b-gray-7">
                {tableTitles.map((item, id) => (
                    <td className="text-13 text-gray-6 pb-2" key={id}>{item}</td>
                ))}
                <td></td>
            </tr>
            </thead>
            <tbody>
            {
                props.tableData.map((tableData, stateId) => {
                    return tableData.cities && tableData.cities.map((city, cityId) => (
                        <tr className={`text-xs py-2`} key={`${stateId}-${city.uid}`}>
                            <td><input 
                                value={selected[city.uid]?.parsimap_code || city.parsimap_code}
                                onChange={(v) => {
                                    changeSelectedItem(v.target.value, 'parsimap_code', city);
                                }} 
                                className={`shadow p-1`}
                            />
                                <FontAwesomeIcon onClick={() => {
                                    setSearchText(city.faname);
                                    setCurrentCity(city);
                                    setModalOpen(true);
                                    handleSearch(city.faname);
                                }} className='p-1 hover:cursor-pointer' icon={faSearch}/>
                            </td>
                            <td><input onKeyUp={ (v) => {
                                changeSelectedItem(v.target.value, 'faname', city);
                            } } className={`shadow p-1`} defaultValue={city.faname}/></td>
                            <td><input onKeyUp={ (v) => {
                                changeSelectedItem(v.target.value, 'enname', city);
                            } } className={`shadow p-1`} defaultValue={city.enname}/></td>

                            <td>{city?.areas?.length ?? 0}</td>

                            <td className="flex justify-center items-center py-3">
                                <button onClick={async () => {await editHandle(tableData,city)}} className="mx-2" title="مشاهده محله ها"><FontAwesomeIcon
                                    className="bg-green-2 text-white p-1 w-4 h-4 rounded" icon={faEdit}/></button>
                                <Link className="mx-2" to={`/coordinates/neighborhoods?state_id=${tableData._id}&state_faname=${props.params.get('state_faname')}&city_uid=${city.uid}&city_faname=${city.faname}`} title="مشاهده محله ها"><FontAwesomeIcon
                                    className="bg-purple-2 text-white p-1 w-4 h-4 rounded" icon={faBarsStaggered}/></Link>
                                <button onClick={async () => {await deleteHandle(tableData,city)}} className="mx-2" title="حذف"><FontAwesomeIcon
                                    className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faRemove}/></button>
                            </td>

                        </tr>
                    ))
                }
            )}
            </tbody>
        </table>

        <Modal isOpen={modalOpen} onClose={() => {setModalOpen(false)}}>
            <div className="p-4">
                <h1 className="text-xl mb-4">جستجوی کد پارسی مپ</h1>
                <input 
                    type="text"
                    className="w-full p-2 border rounded mb-4"
                    placeholder="نام منطقه را وارد کنید..."
                    value={searchText}
                    onChange={(e) => {
                        setSearchText(e.target.value);
                        if (e.target.value.length > 2) {
                            handleSearch(e.target.value);
                        }
                    }}
                />
                <div className="max-h-60 overflow-y-auto">
                    {isLoading ? (
                        <div className="text-center py-4">
                            <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-gray-300 border-t-blue-600"></div>
                            <div className="mt-2">در حال جستجو...</div>
                        </div>
                    ) : (
                        searchResults.map((result) => (
                            <div 
                                key={`${result.subdivision?.id}-${result.description}`} 
                                className="p-2 hover:bg-gray-100 cursor-pointer border-b"
                                onClick={() => {
                                    if (currentCity) {
                                        changeSelectedItem(result.subdivision?.id, 'parsimap_code', currentCity);
                                        setModalOpen(false);
                                    }
                                }}
                            >
                                <div>{result.description}</div>
                                <div className="text-sm text-gray-500">
                                    {result.subdivision?.title} - {result.subdivision?.id} - {result.subdivision?.type}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </Modal>

        </>
    )
}

export default CitiesTable