import Layout from "../../components/Layout";
import SwitchToggle from "../../components/Utilities/SwitchToggle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBarsStaggered, faCopy, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {getTimeFromDate} from "../../helper/general";
import ApiRoute from "../../helper/api";
import {Link} from "react-router-dom";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import GeneralForm from "../../components/Coupon/general-form";

function ViewCoupon() {

    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [update, setUpdate] = useState('0');
    const [data, setData] = useState(null);

    const [editData,setEditData] = useState({});

    useEffect(() => {
        getData()
    }, [update]);

    useEffect(() => {
        if (data){
            setEditData({
                title : data.title,
                admin_title : data.admin_title,
                active : data.active,
                code : data.code,
                amount : data.amount,
                type : data.type,
                cost_ceiling : data.cost_ceiling,
                cost_floor : data.cost_floor,
                discount_ceiling : data.discount_ceiling,
                sale_price : data.sale_price,
                start_at : data.start_at,
                end_at : data.end_at,
                categories : data.categories,
                products : data.products,
                limit_use : data.limit_use,
                order_count : data.order_count,
                code_type : data.code_type,
                state : data.state,
                payment_method : data.payment_method,
                api : data.api,
                customers : data?.customers ?? [],
                variants : data.variants,
            })
        }

    }, [data]);


    async function getData(){
        const response = await ApiRoute.coupon.single.fn(params.get('id'),'?model=\\App\\CouponManager\\Model\\CouponParent')
        setData(response.results)
    }

    async function handleSubmit() {
        await ApiRoute.coupon.updateParent.fn(data._id,editData)
    }


    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">مشاهده کد تخفیف</span>
                </div>

                {editData && <GeneralForm editData={editData}/>}

                <div className="flex justify-center">
                    <button onClick={handleSubmit} className="btn-purple">ویرایش کد تخفیف</button>
                </div>

                {editData && <div className="px-3 lg:px-5">
                    <div className="page-title mb-3"><span className="inline-block bg-white pl-3">زیرگروه ها</span>
                    </div>
                    <table className="table-auto w-full mt-4 mx-auto lg:px-5">
                        <tbody>
                        { editData?.variants?.length > 0 && editData.variants.map( (variant) => <tr key={variant._id}>
                            <td>{variant.admin_title}</td>
                            <td className="flex justify-center items-center py-1">
                                <SwitchToggle onSwitch={ async (c) => {
                                    await ApiRoute.coupon.updateEnabled.fn(c,variant._id)
                                } } onOff={variant.active}/>
                                <Link className="mx-2" to={`/coupons/edit?id=${variant._id}`} title="مشاهده"><FontAwesomeIcon
                                    className="bg-gray-3 text-white p-1 w-4 h-4 rounded" icon={faEye}/></Link>
                                <button className="mx-2" onClick={ async () => {
                                    await ApiRoute.coupon.clone.fn(variant._id)
                                    setUpdate(Date.now())
                                } } title="کلون"><FontAwesomeIcon
                                    className="bg-purple-1 text-white p-1 w-4 h-4 rounded" icon={faCopy}/></button>
                                <button className="mx-2 leading-3" onClick={ () => {
                                    alert('پیاده سازی تمامی حذف ها در مراحل نهایی صورت خواهد گرفت')
                                } } title="؟؟؟"><FontAwesomeIcon
                                    className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                            </td>
                        </tr> )}


                        </tbody>
                    </table>
                </div>}

            </div>
        </Layout>
    )
}

export default ViewCoupon