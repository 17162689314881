import {useState} from "react";

export default function AlertStockFilterTab({options , selectedTab ,setSelectedTab}){
    const [active,setActive] = useState('all')
    const filters = [
        {
            title: 'همه',
            name:'all',
            quantity: options?.all ?? 0,
        },
        {
            title: 'سفارشات عادی',
            quantity: options?.default ?? 0,
            name: 'default',
        },
        {
            title: 'پیش سفارشات',
            quantity: options?.preorder ?? 0,
            name:'preorder',
        }
    ]

    function _active(item , el){
        setSelectedTab(item.name)
        setActive(item.name)
    }

    return(
        <>
            <div className="border-b border-b-gray-7 p-3 lg:p-2 max-lg:flex overflow-x-auto">
                {filters.map((item, id)=>(
                    <button
                        onClick={ e => {
                            _active(item , e)
                        } }
                        className={`text-xs w-max ml-4 whitespace-nowrap ${ (item.name === active) && ' text-purple-1' }`} key={id}>
                        {item.title}
                        &nbsp;
                        (
                        {item.quantity}
                        )
                    </button>
                ))}
            </div>
        </>
    )
}