import ApiRoute from "../../../helper/api";
import Swal from "sweetalert2";
import {useEffect, useState} from "react";
import SelectCoordinates from "../../../components/Coordinates/SelectCoordinates";
import Layout from "../../../components/Layout";
import {Link, redirect, useNavigate, useParams} from "react-router-dom";
import CustomerAddressEdit from "../../../components/Customer/CustomerAddressEdit";
import SelectCoordinates2 from "../../../components/Coordinates/SelectCoordinates2";

function EditAddress() {
    const { customer_id , address_id} = useParams()

    return (
        <Layout>
            <CustomerAddressEdit customer_id={customer_id} address_id={address_id}/>
        </Layout>
    )

}
export default EditAddress