
import {useState} from "react";
import ApiRoute from "../../helper/api";
import Modal from "../Utilities/Modal";

export default function CreateCampaignModal({closeModal, event}){

    const [text, setText] = useState('')
    async function handleSubmit(){
        const res = await ApiRoute.campaign.create.fn({
            title : text
        })
        try {
            event(res)
        }catch (e) {
            
        }
    }
    return (
        <Modal isOpen={true} onClose={closeModal}>
            <div>

                <div className="page-title z-1 text-right text-sm">
                    <span className="inline-block bg-white pl-3 pr-1 mb-3 relative z-2">عنوان</span>
                </div>

                <div className={`flex gap-2 w-full md:flex-row flex-col`}>

                    <input onKeyUp={ (e) => { setText(e.target.value) } } className='px-3 py-2 border border-[#dfdfdf] w-full rounded-[8px] shadow flex-auto bg-white' type="text" placeholder="اینجا بنویسد ..."/>
                    <button onClick={handleSubmit} className={`btn-green rounded`}>ایجاد</button>

                </div>

            </div>
        </Modal>
    )
}