import Layout from "../../components/Layout";
import {Link} from "react-router-dom";
import SwitchToggle from "../../components/Utilities/SwitchToggle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faEdit} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";

function InPersonAddressesSetting() {
    const [ediItemData, setEditItemData] = useState(null)
    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span
                    className="inline-block bg-white pl-3">آدرس تحویل حضوری</span>
                </div>
                <div className="main-content mx-auto p-5">
                    <div className="grid grid-cols-1 md:grid-cols-5 2xl:grid-cols-9 gap-3 items-end text-xs mb-6">
                        <div className="md:col-span-2 2xl:col-span-3"><label className="!grid">
                            <span className="lg:text-center w-3/4 mb-3">عنوان</span>
                            <input className="bordered-input w-full" type="text"
                                   value="تهران ، خیابان 17 شهریور ، خیابان آبشار ، خ ایران، پلاک 9"/>
                        </label></div>
                        <div className="md:col-span-2 2xl:col-span-5"><label className="!grid">
                            <span className="lg:text-center w-3/4 mb-3">آدرس</span>
                            <input className="bordered-input w-full" type="text"
                                   value="تهران ، خیابان 17 شهریور ، خیابان آبشار ، خ ایران، پلاک 9"/>
                        </label></div>

                        <Link className="btn-purple py-2 w-full" to="">افزودن آدرس جدید</Link>

                    </div>
                    <div className="border border-gray-7 rounded-md p-3">
                        <table className="table-auto w-full text-xs block sm:table">
                            <thead>
                            <tr>
                                <td className="lg:w-[40%]">عنوان</td>
                                <td className="lg:w-[50%]">آدرس</td>
                                <td className="lg:w-[10%]"></td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <input className={`lg:w-3/4 !py-2 text-center ${ediItemData === 1 ? 'bordered-input text-gray-9' : 'disabled-bg-white'}`} type="text"
                                           value="تهران ، خیابان 17 شهریور ، خیابان آبشار ، خ ایران، پلاک 9"  disabled={ediItemData === 1 ? '' : true}/>
                                </td>
                                <td>
                                    <input className={`lg:w-3/4 !py-2 text-center ${ediItemData === 1 ? 'bordered-input text-gray-9' : 'disabled-bg-white'}`} type="text"
                                           value="تهران ، خیابان 17 شهریور ، خیابان آبشار ، خ ایران، پلاک 9"  disabled={ediItemData === 1 ? '' : true}/>
                                </td>
                                <td>
                                    <div className="flex items-center justify-end pl-5">
                                        <SwitchToggle status={true} onOff={true}/>
                                        {ediItemData === 1 ?
                                            <button className="flex"
                                                onClick={() => setEditItemData(null)}><FontAwesomeIcon
                                                className="bg-green-1 text-white p-1 w-4 h-4 rounded"
                                                icon={faCheckDouble}/>
                                            </button>
                                            :
                                            <button className="flex"
                                                onClick={() => setEditItemData(1)}><FontAwesomeIcon
                                                className="bg-purple-3 text-white p-1 w-4 h-4 rounded" icon={faEdit}/>
                                            </button>
                                        }
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input className={`w-3/4 !py-2 text-center ${ediItemData === 2 ? 'bordered-input text-gray-9' : 'disabled-bg-white'}`} type="text"
                                           value="تهران ، خیابان 17 شهریور ، خیابان آبشار ، خ ایران، پلاک 9"  disabled={ediItemData === 2 ? '' : true}/>
                                </td>
                                <td>
                                    <input className={`w-3/4 !py-2 text-center ${ediItemData === 2 ? 'bordered-input text-gray-9' : 'disabled-bg-white'}`} type="text"
                                           value="تهران ، خیابان 17 شهریور ، خیابان آبشار ، خ ایران، پلاک 9"  disabled={ediItemData === 2 ? '' : true}/>
                                </td>
                                <td>
                                    <div className="flex items-center justify-end pl-5">
                                        <SwitchToggle status={true} onOff={true}/>
                                        {ediItemData === 2 ?
                                            <button className="flex"
                                                onClick={() => setEditItemData(null)}><FontAwesomeIcon
                                                className="bg-green-1 text-white p-1 w-4 h-4 rounded"
                                                icon={faCheckDouble}/>
                                            </button>
                                            :
                                            <button className="flex"
                                                onClick={() => setEditItemData(2)}><FontAwesomeIcon
                                                className="bg-purple-3 text-white p-1 w-4 h-4 rounded" icon={faEdit}/>
                                            </button>
                                        }
                                    </div>

                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default InPersonAddressesSetting