import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck, faCreditCard, faEye} from "@fortawesome/free-regular-svg-icons";
import {
    faBagShopping,
    faBuilding, faCalendarCheck, faCheckCircle, faCheckDouble, faCircleExclamation, faCommentDots,
    faEllipsis,
    faFile, faFileLines,
    faHand,
    faLocationPin, faPrint, faRectangleXmark, faSort, faSquareCheck, faTrashCan, faTruckMedical,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {
    formatNumberWithDots,
    getDateAsReadableFormat,
    hasClickAction,
    orderStatusLabel,
    storeClickAction
} from "../../helper/general";
import OrderSummeryModal from "../Order/Modal/OrderSummeryModal";
import {Link, useNavigate} from "react-router-dom";
import OrderOperations from "../Order/Edit/OrderOperations";

function OrderTable({tableData , setUpdateData, isPreOrder = false , onSort,asStore = false}) {
    const navigate = useNavigate()
    const [showOperations, setShowOperations] = useState(null);
    const toggleShowOperations = (index) => {
        setShowOperations(prevIndex => prevIndex === index ? null : index);
    };

    const [isModalOpen, setIsModalOpen] = useState(null);
    const openModal = (id) => setIsModalOpen(id)

    const [openSummeryModal, setOpenSummeryModal] = useState(null)

    const [expandedDescription, setExpandedDescription] = useState(null);

    const truncateText = (text, maxLength = 50) => {
        if (text.length <= maxLength) return text;
        return text.substring(0, maxLength) + '...';
    }

    useEffect(() => {

    }, [openSummeryModal]);

    const tableTitles = [
        {
            title: 'ش.سفارش',
            sort:true,
            name:"order_key"
        },
        {
            title: 'مشتری'
        },
        {
            title: 'فاکتور',
        },
        {
            title: 'م.حواله',
        },
        {
            title: 'ت.حواله',
        },
        {
            title: 'انبار',
        },
        {
            title: 'ویزیتور',
        },
        {
            title: 'نوع پرداخت',
        },
        {
            title: 'ت.تایید ویزیتور',
        },
        {
            title: 'مانده',
            isPrice: true
        },
        {
            title: 'ت.آ.ارسال',
        },
        {
            title: 'ت.ارسال',
        },
        {
            title: 'ت.تحویل',
            sort:true,
            name:"delivery_time"
        },
        {
            title: 'استان',
        },
        {
            title: 'وضعیت',
            sort:true,
            name:"status"
        },
        {
            title: 'مبلغ کل',
            isPrice: true
        },
        {
            title: 'تاریخ ثبت',
            sort:true,
            name:"created_at"
        }
    ]
    

    const tableOptions = {rowLink: true, rowSatus: true}
    return (
        <>
            <table className="table-auto w-full mt-4 block 2xl:table">
                <thead>
                <tr className="border-b border-b-gray-7">
                    {asStore && <td className={`text-13 relative text-gray-6 pb-2`}>فروشگاه</td>}
                    {tableTitles.map((item, id) => (
                        <td onClick={ () => {
                            if (item.sort !== true) return;
                            let dir = localStorage.getItem("sort_dir") ?? "desc";
                            if ( dir === "desc" ){
                                localStorage.setItem("sort_dir", "asc")
                            }else{
                                localStorage.setItem("sort_dir", "desc")
                            }
                            onSort(item.name , dir)
                        } } className={ (item.sort && 'cursor-pointer text-purple-1') +` text-13 relative text-gray-6 pb-2`} key={id}>
                            {item.title}
                            {item.sort && <FontAwesomeIcon className={`mr-2`} icon={faSort}/>}
                        </td>
                    ))}
                    <td></td>
                </tr>
                </thead>
                <tbody>
                {tableData.map((tableData, id) => (
                        <tr className={`text-xs py-2${tableOptions.rowSatus ? ' before' : ''} ${(tableData?.customer_info?.asan_id > 0) ? ' before checked' : ''}`}
                            key={tableData._id}>
                            {asStore && <td>{tableData.shop_title}</td>}
                            <td className="text-purple-4 relative">
                                {tableData?.metadata?.official_confirmation?.status === "submitted" && <FontAwesomeIcon title={`تایید حقوقی`} className="block mb-1 mx-auto text-blue-3"
                                                                                                                    icon={faCheckCircle}/>}
                                {tableData?.metadata?.accountant_confirmation?.status === "submitted" && <FontAwesomeIcon title={`تایید حسابدار`} className="block mb-1 mx-auto text-blue-3"
                                                                                                                        icon={faCalendarCheck}/>}
                                {tableData?.metadata?.extra_description && <span
                                    className="bg-gray-12 text-white rounded px-5 mx-auto w-max absolute top-[-2px] right-0 left-0">
                                      {tableData?.metadata?.extra_description}
                                  </span>}
                                <Link className={ hasClickAction(`order_edit_${tableData._id}`) && `text-[#dc32d2]` } onClick={ () => {storeClickAction(`order_edit_${tableData._id}`)} } to={`/orders/edit?id=${tableData._id}`}>{tableData.order_key}</Link>
                                {tableData.was_preorder ? (
                                    <div className="prebuy-style">پیش خرید</div>
                                ) : ('')
                                }
                                {tableData?.is_preorder ? (
                                    <div className="prebuy-style">پیش سفارش</div>
                                ) : ('')
                                }
                            </td>
                            <td>
                                {
                                    (tableData.is_official ) ? (
                                            <div>
                                                <FontAwesomeIcon className="block mx-auto text-blue-3"
                                                                 icon={faBuilding}/>
                                                <span className="text-green-1">{
                                                    tableData?.official?.org_name
                                                }</span>
                                            </div>
                                        ) :
                                        (
                                            tableData?.is_shipping_in_person ? (tableData?.customer_info?.personal?.first_name + " " + tableData?.customer_info?.personal?.last_name) :
                                            tableData?.address?.first_name+" "+tableData?.address?.last_name
                                        )
                                }


                            </td>
                            <td>

                                <FontAwesomeIcon icon={faCircleCheck}
                                                 className={`block mx-auto mb-1 ${tableData.factor?.status === "reset" ? 'text-red-1' :
                                                     tableData.factor?.status === "submitted" ? 'text-green-1' : 'text-gray-3'}`}
                                />
                                <FontAwesomeIcon icon={faCircleCheck}
                                                 className={`block mx-auto mb-1 ${tableData.factor?.status === "reset" ? 'text-red-1' :
                                                     tableData.factor?.status === "submitted" ? 'text-green-1' : 'text-gray-3'}`}
                                />
                                <FontAwesomeIcon icon={faCircleCheck}
                                                 className={`block mx-auto mb-1 ${tableData.factor?.status === "reset" ? 'text-red-1' :
                                                     tableData.factor?.status === "submitted" ? 'text-green-1' : 'text-gray-3'}`}
                                />

                            </td>
                            <td>{(tableData.transference?.start === true) ? (
                                    <span className="block border border-orange-1 py-1 rounded">در حال استعلام <br/>وضعیت
                        </span>
                                ) :
                                <div>
                                    {
                                        tableData.transference?.status === "submitted" && <FontAwesomeIcon icon={faCircleCheck} key={id}
                                                                className={`block mx-auto mb-1 text-green-1`}
                                        />
                                    }
                                    <span dangerouslySetInnerHTML={{__html: tableData.transference?.description}}></span>
                                </div>
                            }
                            </td>
                            <td>{tableData.transference?.date && getDateAsReadableFormat(tableData.transference?.date)}</td>
                            <td className={`relative`}>
                                {tableData?.metadata?.inventory_confirmation?.status === "submitted"  && `✅`}
                            </td>
                            <td className={`relative`}>
                                {tableData.operator?.name ?? '--'}
                            </td>
                            <td className={`relative`}>
                                {tableData.payment_method?.title && <button className={`text-blue-4 blue-light-1-tag`} title="مشاهده خلاصه سفارش" onClick={() => openModal(tableData.id)}>
                                    {tableData.payment_method?.title}
                                </button>}
                            </td>
                            <td>{getDateAsReadableFormat(tableData?.transference?.date , false)}</td>
                            <td>{formatNumberWithDots(tableData?.amounts?.remaining)}</td>
                            <td>--</td>
                            <td>{tableData.status_date?.shipment ? getDateAsReadableFormat(tableData.status_date.shipment) : '--'}</td>
                            <td>
                                <div className={`flex flex-col gap-1`}>
                                    <span>{ getDateAsReadableFormat(tableData?.delivery_time?.start_date) }</span>
                                    <span>{tableData?.delivery_time?.start_pick} تا {tableData?.delivery_time?.end_pick}</span>
                                </div>
                            </td>
                            <td>{tableData.address?.coordinates?.state?.faname}</td>
                            <td className="relative">
                                {tableData?.metadata?.shipment_description && (
                                    <div
                                        onClick={() => setExpandedDescription(expandedDescription === tableData._id ? null : tableData._id)}
                                        className={`bg-gray-12 text-white rounded px-5 mx-auto w-max absolute top-[-2px] right-0 left-0 cursor-pointer ${
                                            expandedDescription === tableData._id ? '!w-[360px]' : ''
                                        }`}>
                                        {expandedDescription === tableData._id 
                                            ? tableData.metadata.shipment_description
                                            : truncateText(tableData.metadata.shipment_description)
                                        }
                                    </div>
                                )}
                                {tableData?.is_shipping_in_person && <div
                                    className="bg-[#ee3b3b52] text-[11px] mb-1 text-[#371212] rounded px-5 mx-auto w-max">
                                    تحویل حضوری
                                  </div>}

                                {tableData?.metadata?.canceled_reason && <span
                                    className="bg-gray-12 text-white rounded px-5 mx-auto w-max absolute top-[-2px] right-0 left-0">
                                      {tableData?.metadata?.canceled_reason}
                                  </span>}

                                <span className={tableData.status === 'process' ? 'purple-tag' :
                                    tableData.status === 'pending' ? 'orange-light-3-tag' :
                                        tableData.status === 'approved' ? 'blue-light-2-tag' :
                                            tableData.status === 'shipment' ? 'purple-2-tag' :
                                                tableData.status === 'completed' ? 'green-tag' :
                                                    tableData.status === 'refunded' ? 'gray-tag' :
                                                        tableData.status === 'canceled' ? 'red-tag' :
                                                            tableData.status === 'failed' ? 'gray-2-tag' : 'orange-tag'}>
                            {orderStatusLabel(tableData.status,isPreOrder)}
                        </span>
                            <span className="text-[12px]">{getDateAsReadableFormat(tableData?.metadata?.logico?.started_date , true)}</span>
                            </td>
                            <td>{formatNumberWithDots(tableData.amounts.total)}</td>
                            <td>{ getDateAsReadableFormat(tableData.created_at,true) }</td>

                            <td>
                                <div className="flex justify-end pl-2 gap-3">
                                    <button title="مشاهده خلاصه سفارش" onClick={() => {
                                        storeClickAction(`order_view_${tableData._id}`)
                                        setOpenSummeryModal(tableData)
                                    }}>
                                        <FontAwesomeIcon icon={faEye}
                                                         className={( hasClickAction(`order_view_${tableData._id}`) ? ' !bg-[#2f3133] ' : '' )+`bg-gray-3 hover:bg-purple-1 rounded py-1 px-2 text-white`}/>
                                    </button>

                                    <button onClick={() => toggleShowOperations(tableData.order_key)} title="عملیات">
                                        <FontAwesomeIcon icon={faEllipsis} className="text-lg"/>
                                    </button>
                                </div>
                                <div
                                    className={`fixed w-screen h-screen top-0 left-0 ${showOperations === tableData.order_key ? 'block z-10' : 'hidden'}`}
                                    onClick={() => {
                                        setOpenSummeryModal(null)
                                        toggleShowOperations(null)
                                    }}></div>
                                <div
                                    className={`absolute overflow-y-auto max-h-[300px] left-5 bg-white text-right p-3 leading-8 rounded shadow-box operation-menu ${showOperations === tableData.order_key ? 'show-menu-effect h-auto z-20' : 'h-0 overflow-hidden'}`}>
                                        <OrderOperations
                                            tableData={tableData}
                                            setUpdateData={setUpdateData}
                                        />
                                </div>


                                {tableData.is_held ? (
                                    <div className="hold-style">
                                        <p>
                                            <span className={`text-[#000]`}>سفارش توسط {tableData?.metadata?.held?.by?.name} هولد شده است: <b>{tableData?.metadata?.held?.desc}</b> </span>
                                        </p>
                                    </div>
                                ) : ('')}
                                {
                                    tableData.decision ? (
                                        <span className="decesion-style">
                                مشتری در حال تصمیم گیری است. لطفا اقدامی صورت نگیرد.
                            </span>
                                    ) : ('')
                                }
                            </td>
                        </tr>
                    )
                )}
                </tbody>
            </table>

            {openSummeryModal && <OrderSummeryModal order={openSummeryModal} closeModal={() => {
                setOpenSummeryModal(null)
            }}/>}



        </>
    )
}

export default OrderTable