import ImageUploader from "../../Utilities/ImageUploader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faChevronDown, faChevronUp, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import FileUpload from "../../FileUpload";
import CategorySelect from "../../Product/Category/CategorySelect";
import ComponentTitle from "./Common/ComponentTitle";

export default function TabBox({component , remove , up, down}) {
    const [updateState, setUpdateState] = useState(1)
    const [items, setItems] = useState(component.data)
    const [open, setOpen] = useState(false)

    function sync() {
        setUpdateState(Date.now())
    }

    function lastOrder() {
        let lastItem = null;
        component.data.sort((firstItem, secondItem) => firstItem.order - secondItem.order).map(item => {
            lastItem = item
            return
        })
        if (lastItem?.order) {
            return lastItem.order + 1;
        }
        return 0
    }

    function removeFormData(data, index) {
        component.data.splice(index, 1)
        sync()
    }

    function moveData(data, index, action = 'up') {
        let inx = index - 1
        if (action != 'up') {
            inx = index + 1
        }

        if (index == 0 && action == 'up') {
            return;
        }

        let el = data.order;
        data.order = component.data[inx].order;

        component.data.map((item, i) => {
            if (i == (inx)) {
                item.order = el
            }
        })

        sync()


    }

    function addData() {
        component.data.push({
            'tab_id': "c"+Date.now().toString(),
            'order': lastOrder(),
            'tab_title': "عنوان تب",
            'tab_url': '#o',
            'tab_data': {
                "component": "products_by_category",
                "data": {
                    "request_type": "post",
                    "request_url": "/categories/catalog/slugs",
                    "request_data": {
                        "slugs": [
                            "food-preparation"
                        ]
                    }
                }
            }
        })
        sync()
    }


    return (
        <div className="flex flex-auto my-3">

            <div className="w-full border border-gray-7 rounded p-1 grid gap-2">

                <ComponentTitle component={component} color={`#673ab7`} title={"تب باکس محصولات"} open={open} setOpen={ setOpen } remove={remove} up={up} down={down}/>

                {open && <div>
                    <div className={`p-3 flex flex-col`}>
                        {component.data.sort((firstItem, secondItem) => firstItem.order - secondItem.order).map((item, index) => (<div key={item.tab_id} className={`flex border-b justify-between flex-wrap gap-2`}>

                            <div className={`lg:w-6/12`}>
                                <div className={`flex text-13 gap-3 items-center`}>
                                    <div className={`w-[100px]`}>عنوان</div>
                                    <input className={`input-x`} onChange={ e => {
                                        item.tab_title = e.target.value
                                    } } type={`text`} defaultValue={item.tab_title}/>
                                </div>

                                <div className={`flex text-13 gap-3 items-center`}>
                                    <div className={`w-[100px]`}>دسته ها</div>
                                    <CategorySelect defaultValue={item.tab_data.data.request_data.slugs} assignItem={(v) => {
                                        item.tab_data.data.request_data.slugs = []
                                        v.map( (x) => {
                                            item.tab_data.data.request_data.slugs.push(x.slug)
                                        } )
                                    } }/>
                                </div>

                                <div className={`flex text-13 gap-3 items-center`}>
                                    <div className={`w-[100px]`}>آدرس</div>
                                    <input className={`input-x`} onChange={ e => {
                                        item.tab_url = e.target.value
                                    } } type={`url`} defaultValue={item.tab_url}/>
                                </div>
                            </div>

                            <div className="flex justify-center flex-col text-left">


                                <button className="mx-1" onClick={() => removeFormData(item, index)} title="؟؟؟">
                                    <FontAwesomeIcon
                                        className="bg-red-1 text-white py-1 px-1.5 w-4 h-4 rounded" icon={faTrash}/>
                                </button>

                                <button onClick={() => {
                                    moveData(item, index)
                                }} className="mx-1" title="؟؟؟"><FontAwesomeIcon
                                    className="bg-blue-1 text-white py-1 px-1.5 w-4 h-4 rounded"
                                    icon={faChevronUp}/></button>
                                <button onClick={() => {
                                    moveData(item, index, 'down')
                                }} className="mx-1" title="؟؟؟"><FontAwesomeIcon
                                    className="bg-blue-1 text-white py-1 px-1.5 w-4 h-4 rounded"
                                    icon={faChevronDown}/></button>

                            </div>

                        </div>) )}
                    </div>

                    <select onChange={ (e) => {
                        component.layout = e.target.value
                        sync()
                    } } className={`input-x`}>
                        <option selected={component.layout == 'col-1'} value={`col-1`}>یک از دوازده</option>
                        <option selected={component.layout == 'col-2'} value={`col-2`}>دو از دوازده</option>
                        <option selected={component.layout == 'col-3'} value={`col-3`}>سه از دوازده</option>
                        <option selected={component.layout == 'col-4'} value={`col-4`}>چهار از دوازده</option>
                        <option selected={component.layout == 'col-5'} value={`col-5`}>پنج از دوازده</option>
                        <option selected={component.layout == 'col-6'} value={`col-6`}>نصف صفحه</option>
                        <option selected={component.layout == 'col-7'} value={`col-7`}>هفت از دوازده</option>
                        <option selected={component.layout == 'col-8'} value={`col-8`}>هشت از دوازده</option>
                        <option selected={component.layout == 'col-9'} value={`col-9`}>نه از دوازده</option>
                        <option selected={component.layout == 'col-10'} value={`col-10`}>ده از دوازده</option>
                        <option selected={component.layout == 'col-11'} value={`col-11`}>یازده از دوازده</option>
                        <option selected={component.layout == 'col-12'} value={`col-12`}>تمام صفحه</option>
                    </select>

                    <button onClick={addData} className="btn-blue-light text-xs mx-auto my-4">افزودن تب جدید</button>
                </div>}

            </div>
        </div>
    )
}