import {v4 as uuidv4} from "uuid";
import SwitchToggle from "../Utilities/SwitchToggle";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

export default function ShopShippingInPersonEdit({shop}){
    const [update, setUpdate] = useState(0)
    const [address, setAddress] = useState('')
    const add = function(){
        if (address.length < 1) { return;}
        if (shop.shipping_in_person?.addresses == null) {
            shop.shipping_in_person = {
                addresses : []
            }
        }

        shop.shipping_in_person.addresses.push({
            address: address,
            active:false,
            id: uuidv4()
        })
        setUpdate(Date.now())
    }

    const remove = function(index){
        shop.shipping_in_person.addresses.splice(index,1)
        setUpdate(Date.now())
    }

    return (
        <div>
            <span className="text-purple-1 text-sm p-2">آدرس تحویل حضوری</span>
            <div
                className="text-xs border border-gray-7 rounded-lg pr-4 pl-3 py-3  mb-2 mt-2 items-center before-table-style before-w-4 before-purple">
                <div className=" flex items-center justify-center gap-3 border-b border-b-gray-7 pb-3 mb-3">
                    <input onKeyUp={ (e) => { setAddress(e.target.value) } } placeholder={`آدرس`} className="input-x" type="text"/>
                    <button onClick={add} className="btn-green rounded py-1">افزودن</button>
                </div>

                <div className="w-full flex flex-col gap-1">
                    { shop.shipping_in_person?.addresses?.length > 0 && shop.shipping_in_person.addresses.map( (item,index) => <div key={index} className="flex flex-wrap items-center justify-between gap-3 box-tr-style rounded-md my-2 py-1 px-2">
                        <input type="text"
                               onKeyUp={ (e) => item.address = e.target.value }
                               className={`md:w-[80%] w-full text-center !py-1 bordered-input text-gray-9`}
                               defaultValue={item.address}
                        />
                        <div>
                            <SwitchToggle  onSwitch={ async (c) => {
                                item.active = c
                            } } onOff={item?.active ?? false}/>
                        </div>

                        <button onClick={() => remove(index)}><FontAwesomeIcon className={`text-red-1 mt-2 text-17`} icon={faTrash}/></button>

                    </div> ) }

                </div>
            </div>
        </div>
    )
}