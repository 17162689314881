import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd} from "@fortawesome/free-solid-svg-icons/faAdd";
import React, {useState} from "react";
import FileUpload from "../../FileUpload";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

export default function ImageListField({item}){
    const [update,setUpdate] = useState(0)
    function addSlide(){
        item.value.push({
            url:'http://',
            image:null
        })
        setUpdate(Date.now())
    }

    return (
        <div className={`w-full`}>

            <div className={`flex justify-between`}>
                <div className={`text-13`}>{item.label}</div>
                <FontAwesomeIcon onClick={addSlide} className={`text-[17px] cursor-pointer text-blue-4`} icon={faAdd}/>
            </div>

            <div className={`flex flex-wrap items-start gap-4 mt-4`}>
                {item.value.map( (slide,index) => <div className={`flex rounded border p-2 border-[#eee] flex-col gap-3`} key={index}>
                    <input onKeyUp={ (e) => {
                        slide.value = e.target.value;
                    } } defaultValue={slide.url} className={`input-x shadow-none text-left`} dir={`ltr`} type={`url`}/>
                    <FileUpload defaultFile={slide.image && [
                        {
                            source: slide.image,
                            options: {
                                type: 'local',
                            },
                        },
                    ]} setCover={(v) => {
                        slide.image = v
                        setUpdate(Date.now())
                    }}/>
                    <FontAwesomeIcon onClick={ () => {
                        item.value.splice(index,1)
                        setUpdate(Date.now())
                    } } className={`text-[17px] cursor-pointer text-red-1`} icon={faTrash}/>
                </div> )}
            </div>


        </div>
    )
}