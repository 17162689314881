import CustomMultiSelect from "../Utilities/CustomMultiSelect";
import {useState} from "react";
import CategorySelect from "./Category/CategorySelect";
import BrandSelect from "./Category/BrandSelect";

export default function ProductFilterBox({SetFilterQuery}) {

    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState([]);
    const handleMultiSelectCategory = (_selectedCategory) => {
        setSelectedCategory(_selectedCategory)
    }

    const handleMultiSelectBrand = (selectedBrand) => {
        setSelectedBrand(selectedBrand)
    }

    return (
        <div className={``}>
            <div className="pt-3 pb-4">
                <div className="main-content p-3">
                    <div className="grid-form text-xs grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-9 gap-3 2xl:gap-4 2xl:gap-y-5">
                        <div>
                            <label>
                                <span className="block">دسته بندی</span>

                                <CategorySelect
                                    assignItem={handleMultiSelectCategory}
                                    placeholder="انتخاب دسته"
                                />

                            </label>
                        </div>

                        <div>
                            <label>
                                <span className="block">برند</span>

                                <BrandSelect
                                    assignItem={handleMultiSelectBrand}
                                    placeholder="انتخاب برند"
                                />

                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">ایجاد توسط</span>
                                <select className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">پیشفرض</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">بروزرسانی توسط</span>
                                <select className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">پیشفرض</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                <span className="block">انتشار توسط</span>
                                <select className="bordered-input-7 bg-gray-1 w-full mt-1">
                                    <option value="">پیشفرض</option>
                                </select>
                            </label>
                        </div>

                        <div className="flex items-end">
                            <button onClick={ () => {
                                SetFilterQuery({
                                    category: selectedCategory,
                                    brand:selectedBrand
                                })
                            } } className="btn-orange w-full py-2">اعمال فیلتر</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}