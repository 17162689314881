import React, {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../../helper/api";
import Layout from "../../Layout";
import SearchBox from "../../SearchBox";
import FilterInventory from "../FilterInventory";
import {formatNumberWithDots} from "../../../helper/general";
import InventoryFilterStateTab from "../InventoryFilterStateTab";
import InventoryTable from "../../Tables/InventoryTable";
import Pagination from "../../Pagination";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBars,
    faCartShopping,
    faEdit,
    faEye,
    faFileInvoiceDollar,
    faLineChart,
    faRectangleList,
    faSort
} from "@fortawesome/free-solid-svg-icons";
import {faCircleCheck} from "@fortawesome/free-regular-svg-icons";
import Select from "../../Utilities/Select";
import PriceField from "../../Common/PriceField";
import {Link} from "react-router-dom";
import {MinProductPrice} from "../../Order/Create/Step3";


export default function ShopInventoryList(){
    const [showFilterBox, setShowFilterBox] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    async function getData(){
        setIsLoading(true)
        const  res  = await ApiRoute.product.inventory.index.fn({
            shop_id: shopId(),
            page : pageNumber
        },filterQuery)
        setData(res)
        setIsLoading(false)
    }

    // watch pageNumber and update updateData state
    useEffect(() => {
    }, [pageNumber]);


    const paginationData = data?.results
    const tableData = data?.results?.data ?? []
    const tableTitles = [
        {
            title: 'شناسه آسان',
        },
        {
            title: 'عنوان',
        },
        {
            title: 'قیمت پیشفروش',
        },
        {
            title: 'موجودی پیشفروش',
        },
        {
            title: 'موجودی',
        },
        {
            title: 'انبار ایران',
        },
        {
            title: 'قیمت اصلی سایت',
        },
        {
            title: 'قیمت تخفیف خورده',
        },
        {
            title: 'قیمت فروشگاه',
        },
    ]
    return (
        <div>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">انبارداری</span></div>
                <div className="flex w-full gap-3">
                    <SearchBox widthFull={true} SearchByOptions={[
                        { label: "نام محصول" , value : "title" },
                        { label: "کد آسان" , value : "asan_id" }
                    ]} SearchIn={true} SetSearchQuery={(s) => {
                        if (s.term.length < 3){return;}
                        filterQuery.quick_search = s
                        getData()
                    }} SearchByDefault={`title`}/>
                </div>

                {!isLoading && <div className="main-content p-1 mt-2">

                    <table className="table-auto w-full block mt-4">
                        <thead>
                        <tr className="border-b border-b-gray-7">
                            {tableTitles.map((item, id) => (
                                <td className={` text-13 relative text-gray-6 pb-2`} key={id}>
                                    {item.title}
                                </td>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {tableData?.map(tableData => (
                                <tr className={`text-xs py-2`} key={tableData?.id}>

                                    <td className={`w-[100px]`}>
                                        {tableData?.asan_id}
                                    </td>
                                    <td className={`w-[300px]`}>
                                        {tableData?.title}
                                    </td>
                                    <td className={`w-[100px]`}>
                                        {formatNumberWithDots(tableData?.prices?.presell)}
                                    </td>
                                    <td className={`w-[200px]`}>
                                        {tableData?.srocks?.presell ?? '-'}
                                    </td>
                                    <td className={`w-[100px]`}>
                                        {tableData?.stocks.live}
                                    </td>
                                    <td className={`w-[100px]`}>
                                        {tableData?.stocks.iran}
                                    </td>
                                    <td className={`w-[100px]`}>
                                        {formatNumberWithDots(tableData?.prices.price)}
                                    </td>
                                    <td className={`w-[300px]`}>
                                        {formatNumberWithDots(tableData?.prices.regular)}
                                    </td>
                                    <td className={`w-[100px]`}>
                                        <MinProductPrice product={tableData} setParentUpdate={() => {}}/>
                                    </td>

                                </tr>
                            )
                        )}
                        </tbody>
                    </table>

                    <Pagination SetPageNumber={setPageNumber} Data={paginationData}/>
                </div>}
            </div>
        </div>
    )
}