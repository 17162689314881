import Toman from "../../Utilities/Toman";
import {Link} from "react-router-dom";
import CustomerSelect from "../../Customer/CustomerSelect";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import Layout from "../../Layout";
import SearchBox2 from "../../SearchBox2";
import PeriodTime from "../../PeriodTime";
import ApiRoute, {shopId} from "../../../helper/api";
import {faRefresh} from "@fortawesome/free-solid-svg-icons/faRefresh";

export default function Step4({newOrder,isPreorder = false,setStep,step,setNewOrder,placeOrder,sending}){

    const [periods,setPeriods] = useState(null)
    const [selectedPeriod,setSelectedPeriod] = useState(null)
    useEffect(() => {
        getPeriods()
    }, []);
    async function getPeriods(){
        const res = await ApiRoute.shipment.delivery.getDeliveryPeriods.fn({
            products : newOrder.products,
            address: newOrder.address,
            payment_method: newOrder.payment_method,
            shop_id: shopId(),
            is_preorder:isPreorder
        });
        if (res.status_code === 200){
            setPeriods(res.results)
        }
    }

    return (

            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">سفارش جدید</span></div>
                <div className="hidden lg:flex items-center justify-center text-sm xl:w-3/4 mx-auto mt-10">
                    <span className="text-purple-1">اطلاعات مشتری</span>
                    <span className="h-0.5 w-20 2xl:w-36 bg-purple-1 block mx-2"></span>
                    <span className="text-purple-1">نحوه ارسال</span>
                    <span className="h-0.5 w-20 2xl:w-36 bg-purple-1 block mx-2"></span>
                    <span className="text-purple-1">انتخاب کالا</span>
                    <span className="h-0.5 w-20 2xl:w-36 bg-purple-1 block mx-2"></span>
                    <span className="text-purple-1">انتخاب زمان ارسال</span>
                    <span className="h-0.5 w-20 2xl:w-36 bg-gray-3 block mx-2"></span>
                    <span>ثبت سفارش</span>
                </div>

                <div className="main-content lg:w-4/5 xl:w-3/5 mx-auto p-4 lg:p-8 mt-12">
                    <div className={`flex items-center justify-between`}>
                        <span className="block text-center text-purple-1 mb-6">انتخاب بازه ارسال</span>
                        <button onClick={ () => {
                            getPeriods()
                        } } className="btn-gray">
                            <FontAwesomeIcon icon={faRefresh}/>
                        </button>
                    </div>
                    {/*<div className="form mb-8 lg:w-1/3">
                        <label>
                            تاریخ سفارشی
                            <input type="datetime-local" disabled/>
                        </label>
                    </div>*/}

                    {periods && <PeriodTime periods={periods} onSelect={ (dvt) => {
                      setSelectedPeriod(dvt)
                      newOrder.delivery_time = dvt
                      setNewOrder(newOrder)
                    } }/>}

                    <div className="flex justify-between lg:px-12 gap-4 mt-8">
                        <button onClick={ () => {
                            setStep(3)
                        } } className="btn-gray w-1/2 lg:w-36">بازگشت</button>

                        {selectedPeriod && <button disabled={sending} onClick={ () => {
                            setStep(5)
                            placeOrder()
                        } } className="btn-purple w-1/2 lg:w-36">ثبت سفارش</button>}
                    </div>
                </div>

            </div>
    )
}