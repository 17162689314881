import Layout from "../../components/Layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import SwitchToggle from "../../components/Utilities/SwitchToggle";
import {Editor} from "@tinymce/tinymce-react";
import {Link} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import ApiRoute, {uploadUrl} from "../../helper/api";
import CustomMultiSelect from "../../components/Utilities/CustomMultiSelect";
import PrePaymentRule from "../../components/Payment/PrePaymentRule";

export default function EditMethod() {

    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [editor, setEditor] = useState(null);
    const [data, setData] = useState(null);
    const [content, setContent] = useState('');
    const [links, setLinks] = useState([]);
    const editorRef = useRef(null);

    useEffect(() => {
        getData()
    }, []);

    useEffect(() => {
        if (data){
            setContent(data.desc)
        }

    }, [data]);

    async function getData(){
        const response = await ApiRoute.payment.method.single.fn(params.get('id'))
        setData(response.results)
    }

    async function handleSubmit() {
        data.desc = editor.getContent()
        await ApiRoute.payment.method.update.fn(data._id,data)
    }

     return (
        <Layout>
            {data && <div>
                <div className="page-title mb-3"><span
                    className="inline-block bg-white pl-3">ویرایش {data.title}</span>
                </div>

                <div className="main-content mx-auto p-5 mt-6">
                    <span className="block text-right text-sm p-2">توضیحات</span>
                    <Editor
                        tinymceScriptSrc='/tinymce/tinymce.min.js'
                        onInit={(evt, editor) => {
                            setEditor(editor);
                            editorRef.current = editor
                        }}
                        onSetContent={ (evt, editor) => setContent(editor.getContent()) }
                        initialValue={content}
                        init={{
                            images_upload_url: uploadUrl,
                            selector: 'textarea#open-source-plugins',
                            plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion',
                            editimage_cors_hosts: ['picsum.photos'],
                            menubar: 'file edit view insert format tools table help',
                            toolbar: "undo redo | accordion accordionremove | blocks fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl",
                            autosave_ask_before_unload: true,
                            autosave_interval: '30s',
                            autosave_prefix: '{path}{query}-{id}-',
                            autosave_restore_when_empty: false,
                            autosave_retention: '2m',
                            image_advtab: true,
                            rtl_ui:true,
                            directionality : "rtl",
                            rtl: true,
                            importcss_append: true,
                            file_picker_callback: (callback, value, meta) => {
                                /* Provide file and text for the link dialog */
                                if (meta.filetype === 'file') {
                                    callback('https://www.google.com/logos/google.jpg', {text: 'My text'});
                                }

                                /* Provide image and alt text for the image dialog */
                                if (meta.filetype === 'image') {
                                    callback('https://www.google.com/logos/google.jpg', {alt: 'My alt text'});
                                }

                                /* Provide alternative source and posted for the media dialog */
                                if (meta.filetype === 'media') {
                                    callback('movie.mp4', {
                                        source2: 'alt.ogg',
                                        poster: 'https://www.google.com/logos/google.jpg'
                                    });
                                }
                            },
                            templates: [],
                            height: 700,
                            image_caption: true,
                            quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                            noneditable_class: 'mceNonEditable',
                            toolbar_mode: 'sliding',
                            contextmenu: 'link image table',
                            skin: 'oxide',
                            content_css: 'default',
                            content_style: ' @import url(\'https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100;200;300;400;500;600;700;800;900&display=swap\'); body { font-family:"Vazirmatn",Arial,sans-serif; font-size:16px; text-align:right; direction:rtl; } img{ width:100%; height:auto; }'
                        }}
                    />

                    <PrePaymentRule data={data} setData={setData}/>

                    <div className="flex justify-center mx-auto mt-6 gap-12">
                        <Link to="/payment-methods" className="btn-gray w-36 py-2">لغو</Link>
                        <button onClick={ (e) => {
                            handleSubmit()
                        } } className="btn-green rounded w-36 py-2">ویرایش</button>
                    </div>
                </div>
            </div>}
        </Layout>
    )
}