import {Editor} from "@tinymce/tinymce-react";
import {Link} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import Buttons from "react-multi-date-picker/components/button";
import {toast, ToastContainer} from "react-toastify";
import Layout from "../../components/Layout";
import ApiRoute, {uploadUrl} from "../../helper/api";
import TagSelect from "../../components/Product/TagSelect";
import BrandSelect from "../../components/Product/Category/BrandSelect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faRemove} from "@fortawesome/free-solid-svg-icons/faRemove";
import TemplateSelect from "../../components/Template/TemplateSelect";

export default function EditPriceList() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [loading, setLoading] = useState(false)
    const [editor, setEditor] = useState(null);
    const [data, setData] = useState(null);
    const [content, setContent] = useState('');
    const [links, setLinks] = useState([]);
    const editorRef = useRef(null);

    const [editData,setEditData] = useState({});
    const [updateData,setUpdateData] = useState(0);

    useEffect(() => {
        getData()
    }, [updateData]);

    useEffect(() => {
        if (data){
            setEditData({
                title : data.title,
                slug : data.slug,
                content : data.content,
                tags: data.tags,
                brand: data.brand,
                links:data.links,
                status:data.status,
                template:data?.template ?? null,
                seo : {
                    title : data?.seo?.title ?? '',
                    description : data?.seo?.description ?? '',
                }
            })
            setContent(data.content)
            setLinks(data.links)
        }

    }, [data]);


    async function getData(){
        const response = await ApiRoute.priceList.single.fn(params.get('id'))
        setData(response.results)
    }

    async function handleSubmit() {
            editData.content = editor.getContent()
            editData.links = links
            setEditData(editData)
            const response = await ApiRoute.priceList.update.fn(params.get('id'),editData)
            /*setData(response.results)*/
            //toast.success("با موفقیت ذخیره شد")
    }

    function addLinks() {
        setLinks(links => [...links, {
            title: "سوال #"+Date.now().toString(),
            url: "####"
        }])
    }

    async function createTemplate() {
        if(editData?.title.length < 5){
            //toast.error("عنوان بیش از حد کوتاه است")
            //return
        }
        setLoading(true)
        await ApiRoute.template.create.fn({
            title:editData.title,
            slug:data._id,
            type:"price-list"
        })
        editData.template = data._id;
        console.log(editData)
        await handleSubmit()
        setLoading(false)
        setUpdateData(Date.now())
    }

    async function deleteTemplate() {
        setLoading(true)
        await ApiRoute.template.deleteBySlug.fn(data.template)
        editData.template = null;
        console.log(editData)
        await handleSubmit()
        setLoading(false)
        setUpdateData(Date.now())
    }

    return (

        <Layout>
            {data && <div>
                <div className="page-title mb-3">
                    <Link to={`/price-list`} className="inline-block text-purple-1 bg-white pl-3">برگشت به لیست های قیمت</Link>
                    {editData?.template == null && <button disabled={loading} onClick={createTemplate} className="btn-purple w-36 py-1.5 ml-auto float-left">ایجاد قالب</button>}
                    {editData?.template != null && <button disabled={loading} onClick={deleteTemplate} className="btn-purple bg-red-1 w-36 py-1.5 ml-auto float-left">حذف قالب</button>}
                    {editData?.template != null && <Link target="_blank" to={`/templates/edit?id=${data.template}&as-slug=true`} className="btn-purple bg-green-1 w-36 py-1.5 ml-2 float-left">ویرایش قالب</Link>}
                </div>



                <div className="main-content mx-auto p-5 mt-6">

                    <div className="form grid grid-cols-1 xl:grid-cols-3 gap-6 lg:gap-12 justify-items-stretch">
                        <label className="!grid ">
                            <span className="text-right mb-2">عنوان</span>
                            <input onKeyUp={ (e) =>
                            { editData.title = e.target.value; setEditData(editData) } } className="!mr-0" type="text" placeholder="عنوان را وارد کنید"
                                   defaultValue={editData?.title}/>
                        </label>
                        <label className="!grid">
                            <span className="text-right mb-2">نامک</span>
                            <input dir={`ltr`} onKeyUp={ (e) =>
                            { editData.slug = e.target.value; setEditData(editData) } } className="!mr-0 text-left" type="text" placeholder="نامک را وارد کنید"
                                   defaultValue={editData?.slug}/>
                        </label>
                        <label className="!grid">
                            <span className="text-right mb-2">منتشر شده</span>
                            <select onChange={ (e) => {
                                editData.status = e.target.value; setEditData(editData)
                            } }>
                                <option selected={(editData.status === 'published')} value={`published`}>منتشر شده</option>
                                <option selected={(editData.status === 'draft')} value={`draft`}>پیشنویس</option>
                            </select>
                        </label>
                    </div>


                        <div className="form grid grid-cols-1 my-4 xl:grid-cols-2 gap-6 lg:gap-12 ">
                            <label className="!grid ">
                                <span className="text-right mb-2">برچسب ها</span>
                                <TagSelect defaultValue={editData.tags} assignItem={(v) => {
                                    editData.tags = v
                                    setEditData(editData)
                                }}/>
                            </label>
                            <label className="!grid">
                                <span className="text-right mb-2">برند ها</span>
                                <BrandSelect defaultValue={editData.brand} assignItem={(v) => {
                                    editData.brand = v
                                    setEditData(editData)
                                }}/>
                            </label>
                        </div>



                    <div className="form grid grid-cols-1 my-4 xl:grid-cols-1 gap-6 lg:gap-12 ">
                        <span className="text-right mb-2">لینک ها</span>

                        <div className={`flex flex-col w-full gap-3`}>
                            {links && links.map( (link , index) => <div className={`flex w-full gap-2 items-center`}>
                                <div className={`w-full`}>
                                    <input onChange={ (e) => { links[index].title = e.target.value; setLinks(links) } }
                                           className="!mr-0 text-right w-full"
                                           type="text"
                                           placeholder="عنوان را وارد کنید"
                                           defaultValue={link?.title}/>
                                </div>
                                <div className={`w-full`}>
                                    <input onChange={ (e) => { links[index].url = e.target.value; setLinks(links) } }
                                           className="!mr-0 text-left w-full"
                                           type="text"
                                           dir={`ltr`}
                                           placeholder="آدرس را وارد کنید"
                                           defaultValue={decodeURI(link?.url)}/>
                                </div>
                                <div className={`flex items-center gap-3`}>
                                    {index === (links.length - 1) && <FontAwesomeIcon onClick={addLinks}
                                        className="bg-blue-1 cursor-pointer text-white p-1 w-4 h-4 rounded" icon={faPlus}/>}
                                    <FontAwesomeIcon
                                        onClick={() => setLinks(links.filter((xitem,xi) => xi !== index))}
                                        className="bg-red-1 cursor-pointer text-white p-1 w-4 h-4 rounded" icon={faRemove}/>
                                </div>
                            </div>)}

                            {links?.length === 0 && <FontAwesomeIcon onClick={addLinks} className="bg-blue-1 cursor-pointer text-white p-1 w-4 h-4 rounded" icon={faPlus}/>}
                        </div>


                    </div>

                </div>
                <div className="main-content mx-auto py-6 px-5 my-5">
                    <Editor
                        tinymceScriptSrc='/tinymce/tinymce.min.js'
                        onInit={(evt, editor) => {
                            setEditor(editor);
                            editorRef.current = editor
                        }}
                        onSetContent={ (evt, editor) => setContent(editor.getContent()) }
                        initialValue={content}
                        init={{
                            images_upload_url: uploadUrl,
                            selector: 'textarea#open-source-plugins',
                            plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion',
                            editimage_cors_hosts: ['picsum.photos'],
                            menubar: 'file edit view insert format tools table help',
                            toolbar: "undo redo | accordion accordionremove | blocks fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl",
                            autosave_ask_before_unload: true,
                            autosave_interval: '30s',
                            autosave_prefix: '{path}{query}-{id}-',
                            autosave_restore_when_empty: false,
                            autosave_retention: '2m',
                            image_advtab: true,
                            rtl_ui:true,
                            directionality : "rtl",
                            rtl: true,
                            importcss_append: true,
                            file_picker_callback: (callback, value, meta) => {
                                /* Provide file and text for the link dialog */
                                if (meta.filetype === 'file') {
                                    callback('https://www.google.com/logos/google.jpg', {text: 'My text'});
                                }

                                /* Provide image and alt text for the image dialog */
                                if (meta.filetype === 'image') {
                                    callback('https://www.google.com/logos/google.jpg', {alt: 'My alt text'});
                                }

                                /* Provide alternative source and posted for the media dialog */
                                if (meta.filetype === 'media') {
                                    callback('movie.mp4', {
                                        source2: 'alt.ogg',
                                        poster: 'https://www.google.com/logos/google.jpg'
                                    });
                                }
                            },
                            templates: [],
                            height: 700,
                            image_caption: true,
                            quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                            noneditable_class: 'mceNonEditable',
                            toolbar_mode: 'sliding',
                            contextmenu: 'link image table',
                            skin: 'oxide',
                            content_css: 'default',
                            content_style: ' @import url(\'https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100;200;300;400;500;600;700;800;900&display=swap\'); body { font-family:"Vazirmatn",Arial,sans-serif; font-size:16px; text-align:right; direction:rtl; } img{ width:100%; height:auto; }'
                        }}
                    />

                    <div>
                        <label className="mt-12 mb-3 block text-13 px-2">عنوان سئو</label>
                        <input onChange={ (e) =>
                        { editData.seo.title = e.target.value; setEditData(editData) } }
                               className="w-full border border-gray-7 rounded outline-0 p-3 text-13" type="text" defaultValue={editData?.seo?.title ?? ''}>
                        </input>
                    </div>
                    <div>
                        <label className="mt-12 mb-3 block text-13 px-2">توضیحات متا محصول</label>
                        <textarea onChange={ (e) =>
                        { editData.seo.description = e.target.value; setEditData(editData) } } defaultValue={editData?.seo?.description ?? ''}
                                  className="w-full border border-gray-7 rounded outline-0 py-4 px-3 text-13" cols="30" rows="4"></textarea>
                    </div>

                    {/*<div className="grid lg:flex items-center gap-4 lg:gap-12 mt-4 px-2 text-xs">
                        <label>
                            بررسی عنوان سئو
                            <input className="mr-2" type="checkbox"/>
                        </label>

                        <label>
                            بررسی متا توضیحات
                            <input className="mr-2" type="checkbox"/>
                        </label>

                        <label>
                            بررسی وضعیت لینک ها
                            <input className="mr-2" type="checkbox"/>
                        </label>
                    </div>*/}

                    <div className="flex justify-center mx-auto mt-10">
                        <Buttons onClick={handleSubmit} className="btn-purple w-36 py-1.5">ویرایش</Buttons>
                    </div>

                </div>


            </div>}

            
        </Layout>
    )
}