import Layout from "../../components/Layout";
import ImageUploader from "../../components/Utilities/ImageUploader";
import {Link, useNavigate} from "react-router-dom";
import SwitchToggle from "../../components/Utilities/SwitchToggle";
import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../helper/api";
import TextField from "../../components/Setting/Fields/TextField";
import TextAreaField from "../../components/Setting/Fields/TextAreaField";
import SwitchField from "../../components/Setting/Fields/SwitchField";
import ImageListField from "../../components/Setting/Fields/ImageListField";
import ImageField from "../../components/Setting/Fields/ImageField";

function GeneralSetting() {
    const navigate = useNavigate()
    const [createModal, setCreateModal] = useState(false)

    const [updateData, setUpdateData] = useState('0')
    const [update, setUpdate] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            const  res  = await ApiRoute.setting.index.fn({
                shop_id: shopId(),
                quick_search : searchQuery,
                page : pageNumber
            },filterQuery)
            setData(res)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);


    async function handleUpdate(item){
        await ApiRoute.setting.update.fn(item._id,item)
    }

    return (
        <Layout>
            <div>

                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">تنظیمات کلی</span>
                </div>


                { data && tableData.map( (item) => <div key={item._id}>
                    <div className="text-purple-6 text-xs mt-6 px-3 mb-3">{item.group_label}</div>
                    <div className="main-content mx-auto p-5 before-table-style before-purple before-w-4">
                        <div className="form grid-form">
                            <div className="flex flex-col gap-5">

                                { item.settings && item.settings.map( (setting) => (
                                    setting.input_type == "text" && <TextField item={setting}/> ||
                                    setting.input_type == "textarea" && <TextAreaField item={setting}/> ||
                                    setting.input_type == "switch" && <SwitchField item={setting}/> ||
                                    setting.input_type == "image_list" && <ImageListField item={setting}/> ||
                                    setting.input_type == "image" && <ImageField item={setting}/>
                                ) ) }


                            </div>

                            <div className="flex mt-7 justify-end gap-3">
                                <button onClick={ async () => await handleUpdate(item) } className="btn-purple w-28 py-2">ویرایش</button>
                            </div>

                        </div>
                    </div>
                </div>) }


               {/* <div>
                    <div className="text-purple-6 text-xs mt-6 px-3 mb-3">بنرهای لیست قیمت</div>
                    <div className="main-content mx-auto p-5 before-table-style before-purple before-w-4">
                        <div className="form grid-form">
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 justify-items-stretch mb-5 gap-4 2xl:gap-10">
                                <div className="text-right grid gap-5">
                                    <div className="text-xs text-center max-lg:text-purple-1">بنر اول</div>
                                    <label className="!text-right">
                                        لینک
                                        <input className="w-full text-left" type="text" value="https://entekhabcenter.com"/>
                                    </label>
                                    <ImageUploader height="half"/>

                                </div>
                                <div className="text-right grid gap-5">
                                    <div className="text-xs text-center max-lg:text-purple-1">بنر دوم</div>
                                    <label className="!text-right">
                                        لینک
                                        <input className="w-full text-left" type="text" value="https://entekhabcenter.com"/>
                                    </label>
                                    <ImageUploader height="half"/>

                                </div>
                                <div className="text-right grid gap-5">
                                    <div className="text-xs text-center max-lg:text-purple-1">بنر سوم</div>
                                    <label className="!text-right">
                                        لینک
                                        <input className="w-full text-left" type="text" value="https://entekhabcenter.com"/>
                                    </label>
                                    <ImageUploader height="half"/>

                                </div>
                                <div className="text-right grid gap-5">
                                    <div className="text-xs text-center max-lg:text-purple-1">بنر چهارم</div>
                                    <label className="!text-right">
                                        لینک
                                        <input className="w-full text-left" type="text" value="https://entekhabcenter.com"/>
                                    </label>
                                    <ImageUploader height="half"/>

                                </div>
                                <div className="text-right grid gap-5">
                                    <div className="text-xs text-center max-lg:text-purple-1">بنر پنجم</div>
                                    <label className="!text-right">
                                        لینک
                                        <input className="w-full text-left" type="text" value="https://entekhabcenter.com"/>
                                    </label>
                                    <ImageUploader height="half"/>

                                </div>
                                <div className="flex justify-end items-end gap-3">
                                    <a href="/" className="btn-gray w-28 py-2">لغو</a>
                                    <a href="/" className="btn-purple w-28 py-2">ویرایش</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}

                {/*<div>
                    <div className="text-purple-6 text-xs mt-6 px-3 mb-3">سایر تنظیمات</div>
                    <div className="main-content mx-auto p-5 before-table-style before-purple before-w-4">
                        <div className="form grid-form grid grid-cols-1 sm:grid-cols-2 justify-items-stretch mb-6 gap-3 2xl:gap-6">
                            <div><label>
                                <span>لینک قوانین سبد خرید</span>
                                <input className="w-full text-left" type="text" value="https://entekhabcenter.com"/>
                            </label></div>
                            <div><label>
                                <span>لینک صفحه قوانین پیش فروش</span>
                                <input className="w-full text-left" type="text"
                                       value="https://entekhabcenter.com"/>
                            </label></div>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-6 gap-6 items-center">
                            <div className="border border-gray-7 rounded-md p-2 flex justify-between items-center">
                                <span className="text-xs">نقشه پارسی مپ</span>
                                <SwitchToggle status={true} onOff={true}/>
                            </div>
                            <div className="border border-gray-7 rounded-md p-2 flex justify-between items-center">
                                <span className="text-xs">حمل و نقل رایگان</span>
                                <SwitchToggle status={true} onOff={false}/>
                            </div>
                            <div className="border border-gray-7 rounded-md p-2 flex justify-between items-center">
                                <span className="text-xs">فعال سازی چت گفتینو</span>
                                <SwitchToggle status={true} onOff={true}/>
                            </div>
                            <div className="form xl:col-span-2">
                                <label>
                                    شماره تماس فلوت باتن
                                    <input className="text-left xl:!w-1/3 xl:!flex-none" type="text" value="02134195"/>
                                </label>
                            </div>
                            <div className="flex justify-end gap-3 sm:col-start-2 xl:col-start-6">
                                <a href="/" className="btn-gray w-28 py-2">لغو</a>
                                <a href="/" className="btn-purple w-28 py-2">ویرایش</a>
                            </div>
                        </div>
                    </div>
                </div>*/}

            </div>
        </Layout>
    )
}

export default GeneralSetting