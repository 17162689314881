import Layout from "../../../components/Layout";
import SearchBox from "../../../components/SearchBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Pagination from "../../../components/Pagination";
import ApiRoute, {shopId} from "../../../helper/api";
import CategorySelect from "../../../components/Product/Category/CategorySelect";
import BrandSelect from "../../../components/Product/Category/BrandSelect";

function ManageStock() {

    const navigate = useNavigate()
    const [createModal, setCreateModal] = useState(false)
    const [selected, setSelected] = useState(null)
    const [newData, setNewData] = useState({
        title:'',
        brands:[],
        categories:[],
        mobiles:[]
    })

    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  res  = await ApiRoute.stock.receiver.index.fn({
                shop_id: shopId(),
                quick_search : searchQuery,
                page : pageNumber
            },filterQuery)
            setData(res)
            setIsLoading(false)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    async function handleUpdate(){
        let formData = selected
        await ApiRoute.stock.receiver.update.fn(formData._id, formData);
        setUpdateData(Date.now().toString());
    }

    async function handleSubmit(){
        await ApiRoute.stock.receiver.create.fn(newData);
        setUpdateData(Date.now().toString());
    }

    return (
        <Layout>
            <div>
                <div className="flex items-center justify-between mb-3">
                    <div className="page-title flex-auto ml-3 md:ml-0">
                        <span className="inline-block bg-white pl-3">مدیریت موجودی</span>
                    </div>
                    <Link className="btn-blue text-center md:hidden px-6" to="">افزودن</Link>
                </div>

                <div className="grid md:flex justify-between items-center gap-3">
                    <div className="flex gap-3">
                        <SearchBox SetSearchQuery={setSearchQuery} SearchIn={false}/>
                        {/*<button className="btn-blue w-12" onClick={toggleFilterBox}><FontAwesomeIcon icon={faFilter}/></button>*/}
                    </div>
                    <button className="btn-blue text-center hidden md:block" onClick={ () => {
                        setCreateModal(true)
                        setSelected(null)
                    } }>افزودن</button>
                </div>


                { ( createModal && selected == null) && <div className={`px-0 sub-groups text-xs overflow-hidden show py-2`}>
                    <div
                        className="form inner-bordered-box w-full grid grid-cols-1 lg:grid-cols-2 gap-5 rounded-lg box-tr-style text-xs px-4 td">
                        <label className="!grid">
                            <span className="px-2">عنوان</span>
                            <input onKeyUp={ (e) => {
                                newData.title = e.target.value;
                            } } className="!mr-0 mt-2" type="text"/>
                        </label>
                        <label className="!grid">
                                <span
                                    className="px-2 text-right"> همراه تامین کننده ( شماره ها را با - جدا کنید )</span>
                            <input dir={`ltr`} onKeyUp={ (e) => {
                                newData.mobiles = e.target.value.split("-")
                            } } className="text-left !mr-0 mt-2" type="text"/>
                        </label>
                        <div className="lg:col-span-2">
                            <label className="px-2 pb-2">دسته بندی ها</label>
                            <CategorySelect fixed={true} assignItem={ (v) => {
                                let arr = []
                                v.map( (x) => {
                                    arr.push(x.slug)
                                } )
                                newData.categories = arr
                            } }/>
                        </div>
                        <div className="lg:col-span-2">
                            <label className="px-2 pb-2">برند ها</label>
                            <BrandSelect fixed={true} assignItem={ (v) => {
                                let arr = []
                                v.map( (x) => {
                                    arr.push(x.value)
                                } )
                                newData.brands = arr
                            } }/>
                        </div>
                        <div className="lg:col-span-2 flex justify-center mx-auto gap-5 mt-4">
                            <button className="btn-gray w-28 py-1.5" onClick={() => setCreateModal(false)}>لغو</button>
                            <button className="btn-purple rounded w-28 py-1.5" onClick={handleSubmit}>ایجاد و ذخیره</button>
                        </div>
                    </div>
                </div>}


                {/*<FilterBox showHide={showFilterBox}/>*/}

                <div className="main-content p-3 mt-3">

                    <div className="grid grid-cols-2 text-sm border-b border-b-gray-7 py-3">
                        <div className="col-span-3 text-right">عنوان</div>
                    </div>
                    {data && tableData.map((item,id)=>
                    <div key={id}>
                        <div className="grid grid-cols-2 text-xs items-center rounded box-tr-style py-1.5 my-2 px-3">
                            <div className="text-right">{item.title}</div>
                            <div className="text-left">
                                <button className="mx-1" onClick={() => {
                                    setSelected(item)
                                    setCreateModal(false)
                                } }><FontAwesomeIcon
                                    className="bg-blue-2 text-white p-1 w-4 h-4 rounded" icon={faEdit}/></button>
                                <button className="mx-2" onClick={ () => {
                                    alert('پیاده سازی تمامی حذف ها در مراحل نهایی صورت خواهد گرفت')
                                } } title="حذف"><FontAwesomeIcon
                                    className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                            </div>
                        </div>
                        { selected?._id === item._id && <div className={`px-5 sub-groups text-xs overflow-hidden show py-2`}>
                            <div
                                className="form inner-bordered-box w-full grid grid-cols-1 lg:grid-cols-2 gap-5 rounded-lg box-tr-style text-xs px-4 td">
                                <label className="!grid">
                                    <span className="px-2">عنوان</span>
                                    <input onKeyUp={ (e) => {
                                        selected.title = e.target.value;
                                    } } className="!mr-0 mt-2" type="text" defaultValue={selected.title}/>
                                </label>
                                <label className="!grid">
                                <span
                                    className="px-2 text-right"> همراه تامین کننده ( شماره ها را با - جدا کنید )</span>
                                    <input dir={`ltr`} onKeyUp={ (e) => {
                                        selected.mobiles = e.target.value.split("-")
                                    } } className="text-left !mr-0 mt-2" type="text"
                                           defaultValue={selected.mobiles.join("-")}/>
                                </label>
                                <div className="lg:col-span-2">
                                    <label className="px-2 pb-2">دسته بندی ها</label>
                                    <CategorySelect assignItem={ (v) => {
                                        let arr = []
                                        v.map( (x) => {
                                            arr.push(x.slug)
                                        } )
                                        selected.categories = arr
                                    } } defaultValue={selected.categories}/>
                                </div>
                                <div className="lg:col-span-2">
                                    <label className="px-2 pb-2">برند ها</label>
                                    <BrandSelect defaultValue={selected.brands} assignItem={ (v) => {
                                        let arr = []
                                        v.map( (x) => {
                                            arr.push(x.value)
                                        } )
                                        selected.brands = arr
                                    } }/>
                                </div>
                                <div className="lg:col-span-2 flex justify-center mx-auto gap-5 mt-4">
                                    <button className="btn-gray w-28 py-1.5" onClick={() => setSelected(null)}>لغو</button>
                                    <button className="btn-purple rounded w-28 py-1.5" onClick={handleUpdate}>بروزرسانی</button>
                                </div>
                            </div>
                        </div> }
                    </div>
                    )}

                    {data && <Pagination Data={paginationData} SetPageNumber={setPageNumber}/>}


                </div>
            </div>
        </Layout>
    )
}

export default ManageStock