import React, {useEffect, useRef, useState} from 'react'
import ReactDOM from 'react-dom'

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview'
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.css'
import {axiosConfig, readUploadUrl, uploadUrl} from "../helper/api";

// Register the plugins
registerPlugin(
    FilePondPluginImageExifOrientation, 
    FilePondPluginImagePreview,
    FilePondPluginMediaPreview
)

// Our app
export default function FileUpload({defaultFile = null , setCover , label='فایل ها را اینجا بکشید یا <span class="filepond--label-action">انتخاب کنید</span>' , layout = '', allowMultiple = false , maxFiles = 10}) {
    let [files, setFiles] = useState(defaultFile)
    const headers = (name = null) => {
        const copy = Object.assign({}, axiosConfig.headers);
        delete copy["Content-Type"]
        if (name !== null){
            return axiosConfig.headers[name]
        }
        return copy
    }
    return (
        <>
            <div className={`flex ${ layout == '' ? 'flex-col' : layout} items-center `}>

     {/*           { defaultFile != null ? (
                    <div className={`h-[150px] ${layout == '' ? 'w-[150px] rounded-[10px]' : 'w-full'}  bg-gray-700 overflow-hidden`}>
                        <img className="object-cover w-full h-full" src={defaultFile} alt="1"/>
                    </div>
                ) : '' }*/}

                <div className="w-full">
                    <FilePond
                        files={files}
                        onupdatefiles={setFiles}
                        allowMultiple={allowMultiple}
                        maxFiles={maxFiles}
                        acceptedFileTypes={['image/*', 'video/*']}
                        onprocessfile={  (error, file) => {
                            if (error) {
                                console.error('Error uploading file:', error);
                                return;
                            }
                            let _files = JSON.parse(file.serverId)
                            _files = _files.results
                            for (const _file of _files) {
                                setCover(_file.url)
                            }
                        }}
                        beforeRemoveFile={ (error, file) => {
                            setCover(null)
                        } }
                        server={{
                            process: { 
                                url: uploadUrl, 
                                headers: headers(),
                                chunkUploads: true,
                                chunkSize: 5000000
                            },
                            load: readUploadUrl+"?file="
                        }}
                        name="files" /* sets the file input name, it's filepond by default */
                        labelIdle={label}
                    />
                </div>
            </div>
        </>
    )
}