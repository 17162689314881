import React from "react";

export default function GeneralForm({editData}){
    return (
        <div className="main-content mx-auto p-4 2xl:p-8 my-12">
            <div className="form">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-3 2xl:gap-10 justify-items-stretch">
                    <div>
                        <label>
                            کد تخفیف
                            <input onKeyUp={ (e) => {
                                editData.code = e.target.value;
                            } } type="text" defaultValue={editData.code}/>
                        </label>
                    </div>
                    <div>
                        <label>
                            عنوان
                            <input onKeyUp={ (e) => {
                                editData.title = e.target.value;
                            } } type="text" placeholder="عنوان کد تخفیف را وارد کنید"
                                   defaultValue={editData.title}/>
                        </label>
                    </div>
                    <div>
                        <label>
                            عنوان مدیریتی
                            <input onKeyUp={ (e) => {
                                editData.admin_title = e.target.value;
                            } } className="" type="text" placeholder="عنوان مدیریتی را وارد کنید"
                                   defaultValue={editData.admin_title}/>
                        </label>
                    </div>
                    <div>
                        <label>
                            خروجی ای پی ای
                            <select onChange={ (e) => {
                                editData.api = e.target.value;
                            } } className="!w-2/4" name="" id="">
                                <option selected={editData.api} value={true}>بله</option>
                                <option selected={!editData.api} value={false}>خیر</option>
                            </select>
                        </label>
                    </div>
                    <div>
                        <label>
                            وضعیت کد تخفیف
                            <select onChange={ (e) => {
                                editData.active = e.target.value;
                            } } className="!w-2/4" name="" id="">
                                <option selected={editData.active} value={true}>فعال</option>
                                <option selected={!editData.active} value={false}>غیرفعال</option>
                            </select>
                        </label>
                    </div>
                </div>
            </div>


            {editData?.variants?.length < 1 && <div className="form mt-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-3 2xl:gap-10 justify-items-stretch">
                    <div>
                        <label>
                            مقدار
                            <input onKeyUp={ (e) => {
                                editData.amount = parseInt(e.target.value);
                            } } type="text" defaultValue={editData.amount}/>
                        </label>
                    </div>
                    <div>
                        <label>
                            نوع
                            <select onChange={ (e) => {
                                editData.type = e.target.value;
                            } } className="!w-2/4">
                                <option selected={editData.type === "percent"} value={`percent`}>درصد</option>
                                <option selected={editData.type === "price"} value={`price`}>قیمت</option>
                            </select>
                        </label>
                    </div>

                </div>
            </div>}

        </div>
    )
}