import ProductSelect from "../../../Product/ProductSelect";
import Modal from "../../../Utilities/Modal";
import {useEffect, useState} from "react";
import ApiRoute from "../../../../helper/api";
import DriverSelect from "../../../Common/DriverSelect";
import {toast} from "react-toastify";
import { getDriverPriceFromOrder } from "../../../../helper/general";

export default function OrderShipmentStatusModal({order , closeModal , setMetadata, openModal,loading}){

    const [data , setData] = useState({
        lift:'1',
        description:'',
        driver_price:getDriverPriceFromOrder(order),
        driver:null,
        shipment_meta:'0'

    })

    const needToBeConfirmed = () => {
        if (process.env.NODE_ENV === 'development') return true;
        return order?.factor?.status === "submitted" && 
               order.is_transference && 
               order?.metadata?.inventory_confirmation?.status === "submitted";
    };

    const handleDriverSelect = (selectedDrivers) => {
        setData(prevData => ({
            ...prevData,
            driver: selectedDrivers[0]
        }));
    };

    const handleInputChange = (field, value) => {
        setData(prevData => ({
            ...prevData,
            [field]: value
        }));
    };

    const handleSubmit = () => {
        if (!data.driver) {
            toast.error("انتخاب راننده ضروری است");
            return;
        }
        setMetadata({ ...data, shipment_meta: '1' });
    };

    return (
        <Modal isOpen={openModal} onClose={closeModal}>
            <div className="page-title mb-5"><span
                className="inline-block bg-white pl-3 text-sm text-purple-1">مشخصات ارسال</span></div>
            <div className="form">
                <div className="grid grid-cols-2 lg:grid-cols-3 justify-items-stretch mb-5 gap-4">

                    <div className="!grid col-span-full">
                        <DriverSelect fixed={true} multiple={false} assignItem={handleDriverSelect}/>
                    </div>

                    <label className="!grid col-span-full">
                        <span className="text-right mb-3 pr-1">بالابری</span>
                        <select onChange={e => handleInputChange('lift', e.target.value)} className="!mr-0" value={data.lift}>
                            <option value="1">دارد</option>
                            <option value="0">ندارد</option>
                        </select>
                    </label>                  
                    
                    <div className="!grid col-span-full text-[14px]">
                        <div className="flex gap-1">
                            <span className="text-right mb-3 pr-1">باربری:</span>
                            <span>{order?.transference?.transport?.title ?? '---'}</span>
                        </div>
                    </div>

                    <label className="!grid col-span-full">
                        <span className="text-right mb-3 pr-1">توضیحات ارسال</span>
                        <textarea onChange={e => handleInputChange('description', e.target.value)} value={data.description}/>
                    </label>

                    <label className="!grid col-span-full">
                        <span className="text-right mb-3 pr-1">کرایه راننده</span>
                        <input type="number" value={data.driver_price} onChange={e => handleInputChange('driver_price', e.target.value)}/>
                    </label>

                </div>
                <div className="grid grid-cols-8 items-end gap-4">
                    <div className="col-span-3">
                        { needToBeConfirmed() && 
                            <button 
                                disabled={loading} 
                                onClick={handleSubmit} 
                                className="btn-purple py-2 w-full"
                            >
                                {loading ? "در حال انجام..." : "تغییر وضعیت و ارسال به لوجیکو"}
                            </button>
                        }
                    </div>
                </div>
                { !needToBeConfirmed() && <div className={`alert-red`}>ابتدا باید سفارش به تایید تامین کننده، انبار و ثبت حواله برسد</div>}

            </div>
        </Modal>
    )
}