import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import ApiRoute, {uploadUrl} from "../../../helper/api";
import {toast, ToastContainer} from "react-toastify";
import {isOnEditState} from "../../../helper/product";
import Layout from "../../Layout";
import {Editor} from "@tinymce/tinymce-react";
import Buttons from "react-multi-date-picker/components/button";
import Select from "react-select";
import Modal from "../../Utilities/Modal";
import FileUpload from "../../FileUpload";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faRemove} from "@fortawesome/free-solid-svg-icons/faRemove";
import {v4 as uuidv4} from "uuid";

export function Step4({onEdit = false , defaultProduct = null}){

    const navigate = useNavigate()

    const [product,setProduct] = useState(defaultProduct)

    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [update, setUpdate] = useState(0)

    const [images, setImages] = useState([])
    const [videos, setVideos] = useState([])
    const [cover, setCover] = useState('')


    const [attachments, setAttachments] = useState([])

    useEffect(() => {
        setProduct(defaultProduct)
        setAttachments(defaultProduct?.attachments ?? [])
    },[defaultProduct]);


    return (
        <>
            <div>

                {!onEdit && <div className="page-title mb-3"><span className="inline-block bg-white pl-3">محصول جدید</span>
                </div>}

                <div className={!onEdit ? `main-content mx-auto py-6 px-5 my-5` : ''}>

                    {!onEdit && <span className="text-purple-1 pl-3 mb-6 block text-center">تصاویر و ویدئو</span>}

                    <div className="lg:border border-gray-7 rounded-md lg:p-3 lg:grid grid-cols-1 gap-4">

                        <div className="border border-gray-7 rounded-md p-5 items-end gap-5">
                            <div>

                                <div className={`flex justify-between items-center`}>
                                    <span className="text-16 block text-dark font-bold pb-3 px-1">تصویر شاخص محصول</span>
                                    {attachments.filter((a) => { return a.type === 'thumbnail' }).length < 1 && <div onClick={ () => {
                                        attachments.push({
                                            url:null,
                                            is_new:true,
                                            type:'thumbnail',
                                            attachment_id:Date.now().toString()
                                        })
                                        setAttachments(attachments)
                                        setUpdate(Date.now())
                                    } }><FontAwesomeIcon className={`w-[32px] h-[32px] bg-blue-1 p-1 text-white`}  icon={faPlus}/></div>}


                                </div>

                                <div className={`flex flex-wrap gap-3`}>
                                    {attachments.length > 0 && attachments.filter((a) => { return a.type === 'thumbnail' }).map((attach,id) => <div
                                        className={`w-[200px] border rounded relative`} key={attach.attachment_id}>
                                        <FileUpload defaultFile={attach.url && [
                                            {
                                                source: attach.url,
                                                options: {
                                                    type: 'local',
                                                },
                                            }
                                        ]} setCover={ (v) => {
                                            attach.url = v
                                            if (attach.is_new !== true){
                                                attach.is_edited = true
                                            }
                                            setUpdate(Date.now())
                                        } }/>
                                        <FontAwesomeIcon onClick={ () => {
                                            attachments.map((a,i) => {
                                                if (a.attachment_id == attach.attachment_id){
                                                    attachments.splice(i,1)
                                                    setAttachments(attachments)
                                                    setUpdate(Date.now())
                                                }
                                            })

                                        } } className={`w-[24px] h-[24px] bg-red-1 absolute bottom-0 left-0 p-1 text-white`}  icon={faRemove}/>
                                    </div>)}
                                </div>

                            </div>
                        </div>

                       <div className="border border-gray-7 rounded-md p-5 items-end gap-5">
                            <div>

                                <div className={`flex justify-between items-center`}>
                                    <span className="text-16 block text-dark font-bold pb-3 px-1">گالری تصاویر محصول</span>
                                    <div onClick={ () => {
                                        attachments.push({
                                            url:null,
                                            is_new:true,
                                            type:'gallery',
                                            attachment_id:uuidv4()
                                        })
                                        setAttachments(attachments)
                                        setUpdate(Date.now())
                                    } }><FontAwesomeIcon className={`w-[32px] h-[32px] bg-blue-1 p-1 text-white`}  icon={faPlus}/></div>
                                </div>

                                <div className={`flex flex-wrap gap-3`}>
                                    {attachments.length > 0 && attachments.filter((a) => a.type === 'gallery' ).map((attach,id) => <div
                                        className={`w-[200px] border rounded relative`} key={attach.attachment_id}>
                                        <FileUpload defaultFile={attach.url && [
                                            {
                                                source: attach.url,
                                                options: {
                                                    type: 'local',
                                                },
                                            }
                                        ]} setCover={ (v) => {
                                            attach.url = v
                                            if (attach.is_new !== true){
                                                attach.is_edited = true
                                            }
                                            setUpdate(Date.now())
                                        } }/>
                                        <FontAwesomeIcon onClick={ () => {

                                            attachments.forEach( (a,i) => {
                                                if (a.attachment_id == attach.attachment_id){
                                                    attachments.splice(i,1)
                                                    setAttachments(attachments)
                                                    setUpdate(Date.now())
                                                }
                                            } )

                                        } } className={`w-[24px] h-[24px] bg-red-1 absolute bottom-0 left-0 p-1 text-white`}  icon={faRemove}/>
                                    </div>)}
                                </div>

                            </div>
                        </div>


                        <div className="border border-gray-7 rounded-md p-5 items-end gap-5">
                            <div>

                                <div className={`flex justify-between items-center`}>
                                    <span className="text-16 block text-dark font-bold pb-3 px-1">گالری ویدیو محصول</span>
                                    <div onClick={ () => {
                                        attachments.push({
                                            url:null,
                                            is_new:true,
                                            type:'video',
                                            attachment_id:Date.now().toString()
                                        })
                                        setAttachments(attachments)
                                        setUpdate(Date.now())
                                    } }><FontAwesomeIcon className={`w-[32px] h-[32px] bg-blue-1 p-1 text-white`}  icon={faPlus}/></div>
                                </div>

                                <div className={`flex flex-wrap gap-3`}>
                                    {attachments.length > 0 && attachments.filter((a) => { return a.type === 'video' }).map((attach,id) => <div
                                        className={`w-[200px] border rounded relative`} key={attach.attachment_id}>
                                        <FileUpload defaultFile={attach.url && [
                                            {
                                                source: attach.url,
                                                options: {
                                                    type: 'local',
                                                },
                                            }
                                        ]} setCover={ (v) => {
                                            attach.url = v
                                            if (attach.is_new !== true){
                                                attach.is_edited = true
                                            }
                                            setUpdate(Date.now())
                                        } }/>
                                        <FontAwesomeIcon onClick={ () => {
                                            attachments.map((a,i) => {
                                                if (a.attachment_id == attach.attachment_id){
                                                    attachments.splice(i,1)
                                                    setAttachments(attachments)
                                                    setUpdate(Date.now())
                                                }
                                            })

                                        } } className={`w-[24px] h-[24px] bg-red-1 absolute bottom-0 left-0 p-1 text-white`}  icon={faRemove}/>
                                    </div>)}
                                </div>

                            </div>
                        </div>


                    </div>


                    <div className={!onEdit ? `flex justify-between lg:w-1/3 mx-auto mt-10` : ''}>
                        { !onEdit && <Link to={`/products/edit?product_id=${params.get('product_id')}&step=3`} className="btn-gray w-36 py-1.5">بازگشت</Link> }

                        {onEdit && <button onClick={ async () => {
                            await ApiRoute.product.create.stepFour.fn(params.get('product_id'), {
                                attachments: attachments
                            })
                        } } className="btn-purple w-36 py-1.5">ذخیره سازی</button>}
                        {!onEdit && <button onClick={ async () => {
                            await ApiRoute.product.create.stepFour.fn(params.get('product_id'), {
                                attachments: attachments
                            })
                            return navigate("/products/create/step-5-save?product_id=" + params.get('product_id'))
                        } } className="btn-purple w-36 py-1.5">ذخیره سازی</button>}
                    </div>

                </div>
            </div>


        </>
    )
}