function OrderFilterTab({options , selectedTab ,setSelectedTab}){
    const filters = [
        {
            title: 'همه',
            name:'all',
            quantity: options?.all ?? 0,
            active: true
        },
        {
            title: 'درانتظار بررسی',
            quantity: options?.waiting ?? 0,
            name: 'waiting'
        },        
        {
            title: 'درانتظار پرداخت',
            quantity: options?.pending ?? 0,
            name: 'pending'
        },
        {
            title: 'در حال پردازش',
            quantity: options?.approved ?? 0,
            name:'approved'
        },
        {
            title: 'در حال انجام',
            quantity: options?.process ?? 0,
            name:'process'
        },
        {
            title: 'در حال ارسال',
            quantity: options?.shipment ?? 0,
            name:'shipment'
        },
        {
            title: 'تحویل شده',
            quantity: options?.completed ?? 0,
            name:'completed'
        },
        {
            title: 'ناموفق',
            quantity: options?.failed ?? 0,
            name:'failed'
        },
        {
            title: 'مرجوعی',
            quantity: options?.refunded ?? 0,
            name:'refunded'
        },
        {
            title: 'لغو شده',
            quantity: options?.canceled ?? 0,
            name:'canceled'
        }
    ]

    function active(item , el){
        setSelectedTab(item.name)
    }

    return(
        <>
        <div className="border-b border-b-gray-7 p-3 lg:p-2 max-lg:flex overflow-x-auto">
            {filters.map((item, id)=>(
                <button
                    onClick={ e => {
                        active(item , e)
                    } }
                    className={`text-xs w-max ml-4 whitespace-nowrap${(selectedTab === item.name)?' text-purple-1':''}`} key={id}>
                    {item.title}
                    &nbsp;
                    (
                        {item.quantity}
                    )
                </button>
            ))}
        </div>
        </>
    )
}
export default OrderFilterTab