import React, {useEffect, useState} from 'react';
import Select from 'react-select';

import {faCaretUp, faCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ApiRoute from "../../helper/api";

export default function DriverSelect ({ assignItem, absoluteSelectedStyle= false, multiple = true, customDefaultValue = false, defaultValue = [], placeholder = 'انتخاب راننده' , fixed = false }) {

    const [show,setShow] = useState(false)
    const [term,setTerm] = useState('')
    const [selected,setSelected] = useState([])
    const [data,setData] = useState(null)
    const [myDefaultValue,setMyDefaultValue] = useState([])
    const [updateData,setUpdateData] = useState('1')

    useEffect(() => {
        syncDefaultValue()
    }, [defaultValue]);

    useEffect(() => {
        assignItem(selected)
    }, [selected]);

    useEffect(() => {
        if (defaultValue.length > 0) {
            setSelected([])
        }
    }, [updateData]);


    const syncDefaultValue = async () => {
        if (defaultValue.length > 0){
            if (customDefaultValue){
                setSelected(defaultValue)
            }else {
                try {
                    const res = await ApiRoute.shipment.logico.getBySlugs.fn(defaultValue)
                    setSelected(res.results)
                }catch (e) {
                    console.log(e)
                }
            }

        }else{
            setUpdateData(Date.now().toString())
        }

    }

    const getCategory = async () => {
        const res = await ApiRoute.shipment.logico.index.fn({
            title: term
        })
        setData(res.results.data)
    }

    function has(item){
        for (const selectedElement of selected) {
            if (selectedElement._id === item._id){ return true }
        }
        return false
    }

    async function openMenu(){
        setShow(!show)
        if (data == null){
            await getCategory()
        }
    }

    return (
        <div className={"relative w-full"}>
            <div className={`flex items-center justify-between`}>
                <input
                    onChange={ async (e) => {
                        setShow(true)
                        setTerm(e.target.value)
                        await getCategory()
                    } }
                    className="input-x h-[30px] bg-gray-1 w-full mt-1"
                    placeholder={placeholder}
                    type={"search"}/>
                <div onClick={openMenu} className={`bg-gray-4 flex justify-center items-center text-white w-[30px] h-[30px]`}>
                    <FontAwesomeIcon className={`text-lg text-white`}
                                     icon={faCaretUp}/>
                </div>
            </div>
            {(show && data) && <div className={`${fixed ? 'fixed w-content' : 'absolute'} z-[10] text-13 overflow-auto h-[250px] z-[9999] w-full flex flex-col gap-3 bg-white border border-gray-3 p-2`}>
                {data.map((item) => <div key={item._id}
                                         onClick={ () => {
                                             if (!has(item)){
                                                 if (multiple){
                                                     setSelected(selected => [...selected, item])
                                                 }else{
                                                     setSelected([item])
                                                 }

                                             }
                                             setShow(false)
                                         }}
                                         className={"hover:text-blue-1 cursor-pointer"}>{item.name}</div>)}
            </div>}

            {selected?.length > 0 && <div className={(absoluteSelectedStyle ? 'absolute' : '')+` flex p-2 flex-wrap gap-2 mt-1`}>
            {selected.map((item) => <div onClick={ () => {
                setShow(false)
                setSelected(selected.filter(xitem => xitem._id !== item._id))
            } } className={`text-[11px] flex cursor-pointer justify-between bg-green-2 hover:bg-red-1 text-white rounded px-2`} key={item._id}>
                <div>{item.name}</div>
            </div>)}
        </div>}
        </div>

        /*<Select
            //onMenuOpen={ async () => { alert(1) } }
            options={options}
            isMulti={true} // Enable multi-select
            value={values}
            onChange={onChange}
            styles={customStyles}
            placeholder={placeholder}
        />*/
    );
};