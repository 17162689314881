import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWarehouse} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../../helper/api";

export default function AlertStockStats(){
    const [updateData, setUpdateData] = useState(0)
    const [data, setData] = useState(null)
    useEffect(() => {
        (async () => {
            const  res  = await ApiRoute.stock.alert.stats.fn()
            setData(res?.results?.unread ?? 0)
        })();
    }, [updateData]);

    return (
        <Link to="/alert-stock"
              className='text-red-1 py-1 px-3 rounded-md border border-red-1 bg-pink-2 hover:bg-pink-3 hidden lg:flex'>
            {data}
            {data === null && <div className="x2-loader"></div>}
            <FontAwesomeIcon className='mr-3 mt-0.5' icon={faWarehouse}/>
        </Link>
    )
}