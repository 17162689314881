import Layout from "../../components/Layout";
import CitiesTable from "../../components/Tables/CitiesTable";
import Pagination from "../../components/Pagination";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../helper/api";
import Modal from "../../components/Utilities/Modal";

function Cities() {

    const navigate = useNavigate()
    const [createModal, setCreateModal] = useState(false)

    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [faname, setFaname] = useState('')
    const [enname, setEnname] = useState('')

    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'cellphone'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  res  = await ApiRoute.coordinate.index.fn({
                shop_id: shopId(),
                state_id : params.get('state_id'),
                quick_search : searchQuery,
                page : pageNumber
            },filterQuery)
            setData(res)
            setIsLoading(false)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    async function handleCreate() {
        let state_id = params.get('state_id')
        let city_uid = params.get('city_uid')
        await ApiRoute.coordinate.createCity.fn(state_id , {
            faname: faname,
            enname: enname
        })
        localStorage.setItem('coordinate_cache_key_a',Date.now().toString())
        setTimeout( () => {
            setCreateModal(false)
            setUpdateData(Date.now().toString())
        } , 1000 )
    }

    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><Link className="inline-block bg-white pl-3" to={`/coordinates/states`}>برگشت به استان ها</Link></div>

                <div className={`flex flex-wrap items-center justify-between`}>
                    <span className="text-center block py-3">شهرهای استان {params.get('state_faname')}</span>
                    <button onClick={() => setCreateModal(true)} className="btn-blue text-center" > افزودن</button>
                </div>

                <div className="main-content p-5 mt-3">
                    {data &&  <CitiesTable params={params} tableData={tableData} />}
                </div>
            </div>

            <Modal isOpen={createModal} onClose={() => {setCreateModal(false)}}>
                <div>

                    <div className="page-title z-1 text-right text-sm">
                        <span className="inline-block bg-white pl-3 pr-1 mb-3 relative z-2">ایجاد شهر</span>
                    </div>

                    <div className={`flex gap-2 w-full md:flex-row flex-col`}>

                        <input onKeyUp={ (e) => { setFaname(e.target.value) } } className='px-3 py-2 border border-[#dfdfdf] w-full rounded-[8px] shadow flex-auto bg-white' type="text" placeholder="نام فارسی ..."/>
                        <input onKeyUp={ (e) => { setEnname(e.target.value) } } className='px-3 py-2 border border-[#dfdfdf] w-full rounded-[8px] shadow flex-auto bg-white' type="text" placeholder="نام انگلیسی ..."/>
                        <button onClick={handleCreate} className={`btn-green rounded`}>ایجاد</button>

                    </div>

                </div>
            </Modal>

        </Layout>
    )
}

export default Cities