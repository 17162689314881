import Layout from "../../components/Layout";
import SearchBox2 from "../../components/SearchBox2";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import Toman from "../../components/Utilities/Toman";
import {useEffect, useRef, useState} from "react";
import ApiRoute from "../../helper/api";
import moment from "jalali-moment";
import {faNumberToEn, formatNumberWithDots, getDateAsReadableFormat, getTimeFromDate} from "../../helper/general";
import {DatePicker, TimePicker} from "zaman";
import ProductSelect from "../../components/Product/ProductSelect";
import PriceField from "../../components/Common/PriceField";

function EditCampaign() {

    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [update, setUpdate] = useState(0);
    const [data, setData] = useState(null);

    const [editData,setEditData] = useState({});

    useEffect(() => {
        getData()
    }, []);

    useEffect(() => {
        if (data){
            setEditData({
                title : data.title,
                start_at : data.start_at,
                start_time_at : getTimeFromDate(data.start_at),
                end_at : data.end_at,
                end_time_at : getTimeFromDate(data.end_at),
                enabled:data.enabled,
                start_color:data.start_color,
                end_color:data.end_color,
                url:data?.url ?? '',
                products_prices:data.products_prices,
                products:data.products,
            })
        }

    }, [data]);


    async function getData(){
        const response = await ApiRoute.campaign.single.fn(params.get('id'))
        setData(response.results)
    }

    async function handleSubmit() {
        console.log(editData)
        ///setEditData(editData)
        await ApiRoute.campaign.update.fn(data._id,editData)
    }

    function getPrice(id){
        let price = 0;
        editData.products_prices.map((product,index) => {
            if (product.product == id){
                return price = product.price
            }
        })
        return price
    }

    function changePrice(id,value){
        let price = 0;
        editData.products_prices.map((product,index) => {
            if (product.product == id){
                product.price = parseInt(value)
            }
        })
        return price
    }

    function deleteProduct(id){
        editData.products_prices.map((product,index) => {
            if (product.product == id){
                editData.products_prices.splice(index,1)
            }
        })
        editData.products.map((product,index) => {
            if (product._id == id){
                editData.products.splice(index,1)
            }
        })
        setUpdate(Date.now())

    }

    return (
        <Layout>
            {editData && <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">ویرایش کمپین</span></div>

                {data && <div className="main-content mx-auto p-4 lg:p-8 mt-12">
                    <div className="form">
                        <div
                            className="flex flex-wrap items-center gap-3">

                            <div>
                                <label>
                                    نام کمپین
                                    <input type="text" defaultValue={editData.title} placeholder="نام کمپین را وارد کنید"/>
                                </label>
                            </div>

                            <div>
                                <label>
                                    تاریخ کمپین
                                    <div className="date-time">
                                        <span className="px-5 xl:px-0 2xl:px-5">از</span>
                                        {editData.start_at && <DatePicker
                                            defaultValue={editData.start_at}
                                            onChange={(e) => {
                                                editData.start_at = moment(e.value).format('YYYY-MM-DD');
                                                setEditData(editData)
                                            }}
                                        />}
                                        {editData.start_at && <input type={`time`} defaultValue={getTimeFromDate(editData.start_at)} onChange={ e => {
                                            editData.start_time_at = e.target.value;
                                            setEditData(editData)
                                        } }/>}
                                        <span className="px-5 xl:px-0 2xl:px-5">تا</span>
                                        {editData.end_at && <DatePicker
                                            defaultValue={editData.end_at}
                                            onChange={(e) => {
                                                editData.end_at = moment(e.value).format('YYYY-MM-DD');
                                                setEditData(editData)
                                            }}
                                        />}
                                        {editData.end_at && <input type={`time`} defaultValue={getTimeFromDate(editData.end_at)} onChange={ e => {
                                            editData.end_time_at = e.target.value;
                                            setEditData(editData)
                                        } }/>}
                                    </div>
                                </label>
                            </div>

                            <div>
                                <label>

                                    رنگ پس زمینه
                                    {editData.start_color && <input type="color" onChange={ e => {
                                        editData.start_color = e.target.value;
                                    } } defaultValue={editData.start_color}/>}
                                    {editData.end_color && <input type="color" onChange={ e => {
                                        editData.end_color = e.target.value;
                                    } } defaultValue={editData.end_color}/>}
                                </label>
                            </div>

                            <div>
                                <label>
                                    لینک
                                    <input onKeyUp={ e => {
                                        editData.url = e.target.value;
                                        setEditData(editData)
                                    } } type="text" defaultValue={editData.url} placeholder="لینک مشاهده را وارد کنید"/>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="lg:w-1/2 mx-auto my-4">
                        <p className="text-purple-1 text-center mb-4">افزودن محصول</p>
                        <ProductSelect assignItem={ (selected, setSelected , setF) => {
                            if (selected.length > 0){
                                selected.map((productItem) => {
                                    editData.products_prices.push({
                                        product: productItem._id,
                                        price: 0
                                    })
                                    editData.products.push(productItem)
                                })
                                setUpdate(Date.now())
                                setSelected([])
                                setF('1')
                            }
                        } }/>
                    </div>

                    <table className="table-auto xl:w-3/4 mx-auto mt-4 block lg:table">
                        <thead>
                        <tr className="text-13 text-gray-6">
                            <td className="pb-2">نام کالا</td>
                            <td className="pb-2">موجودی</td>
                            <td className="pb-2">قیمت
                                <div className="inline-flex mr-0.5">
                                    <span className="text-9">(</span>
                                    <Toman/>
                                    <span className="text-9">)</span>
                                </div>
                            </td>
                            <td className="pb-2">قیمت ویژه عادی
                                <div className="inline-flex mr-0.5">
                                    <span className="text-9">(</span>
                                    <Toman/>
                                    <span className="text-9">)</span>
                                </div>
                            </td>
                            <td className="pb-2">قیمت کمپین
                                <div className="inline-flex mr-0.5">
                                    <span className="text-9">(</span>
                                    <Toman/>
                                    <span className="text-9">)</span>
                                </div>
                            </td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>

                        {editData.products && editData.products.map((product,index) => <tr key={product._id} className="text-xs before before-purple">
                            <td className="text-right px-3 py-2 max-lg:pr-5">{product.title}</td>
                            <td>{product.is_in_stock ? "✅" : "❌"}</td>
                            <td>{formatNumberWithDots(product?.calculated_price?.price)}</td>
                            <td>{formatNumberWithDots(product?.calculated_price?.regular)}</td>
                            <td>
                           
                                <PriceField placeholder="قیمت کمپین را وارد کنید"  onChange={ (v) => {
                                        changePrice(product._id,v)
                                    } } defaultValue={getPrice(product._id)}/>
                            </td>
                            <td>
                                <button className="mx-2 flex"
                                        onClick={() => {deleteProduct(product._id)}}
                                        title="؟؟؟"><FontAwesomeIcon
                                    className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                            </td>
                        </tr>)}

                        </tbody>
                    </table>

                    <div className="flex justify-center gap-36 mt-10 mb-28">
                        <button onClick={handleSubmit} className="btn-purple w-36">بروزرسانی</button>
                    </div>
                </div>}

            </div>}
        </Layout>
    )
}

export default EditCampaign