import Layout from "../../components/Layout";
import ApiRoute from "../../helper/api";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ShopSelect from "../../components/Shop/ShopSelect";

function EmployeeNew() {
    const navigate = useNavigate()
    const [role, setRole] = useState(null);
    const [newData, setNewData] = useState({
        username : '',
        password : '',
        re_password : '',
        asan_id : '',
        name : '',
        role : {label:'', name:''},
        shop_id:null,
        permissions : []
    });

    useEffect(() => {
        getRoles()
    }, []);


    async function getRoles(){
        const response = await ApiRoute.employee.role.index.fn()
        setRole(response.results.data)
    }

    async function handleSubmit(){
        const res = await ApiRoute.employee.create.fn(newData)
       if (res?.status_code === 200){
           navigate(`/employees/edit?id=${res.results._id}`)
       }
    }

    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">کارمند جدید</span>
                </div>

                <div className="border border-gray-7 rounded-md lg:w-3/5 xl:w-2/5 mx-auto p-4 grid grid-cols-1 md:grid-cols-2 gap-5 text-xs mt-5 md:mt-10">
                    <div><label className="!grid">
                        نام و نام خانوادگی
                        <input onKeyUp={ (e) => {
                            newData.name = e.target.value;
                        } } className="bordered-input-7 mt-2" type="text"
                               placeholder="نام و نام خانوادگی را وارد کنید"/>
                    </label></div>
                    <div><label className="!grid">
                        نام کاربری
                        <input onKeyUp={ (e) => {
                            newData.username = e.target.value;
                        } } className="bordered-input-7 mt-2" type="email" placeholder="نام کاربری را وارد کنید"/>
                    </label></div>
                    <div><label className="!grid">
                        پسورد
                        <input onKeyUp={ (e) => {
                            newData.password = e.target.value;
                        } } className="bordered-input-7 mt-2" type="password" placeholder="پسورد را وارد کنید"/>
                    </label></div>
                    <div><label className="!grid">
                        تایید پسورد
                        <input onKeyUp={ (e) => {
                            newData.re_password = e.target.value;
                        } } className="bordered-input-7 mt-2" type="password"
                               placeholder="پسورد را دوباره وارد کنید"/>
                    </label></div>
                    <div><label className="!grid">
                        کد آسان
                        <input onKeyUp={ (e) => {
                            newData.asan_id = parseInt(e.target.value);
                        } } className="bordered-input-7 mt-2" type="text"/>
                    </label></div>
                    <div><label className="!grid">
                        گروه کاربری
                        <select onChange={ (e) => {
                            if (e.target.value.length < 1){return}
                            let r = null
                            newData.permissions = []
                            role.map( (_role, i) => {
                                if (_role.name === e.target.value){
                                    r = _role
                                    newData.permissions = _role.permissions
                                }
                            } )
                            newData.role.label = r.label
                            newData.role.name = r.name
                            setNewData(newData)
                        } } className="bordered-input-7 mt-2">
                            <option>انتخاب کنید</option>
                            {role && role.map( (_role) => <option value={_role.name}>{_role.label}</option> )}
                        </select>
                    </label></div>

                    <div className={`grid col-span-full`}>
                        <ShopSelect multiple={false} assignItem={ (s) => {
                            s.map(  x => {
                                newData.shop_id = x._id
                            })

                        } }/>
                    </div>

                    <div className="md:col-span-2 text-center">
                        <button onClick={handleSubmit} className="btn-purple w-max px-10 mx-auto">افزودن کارمند</button>
                    </div>
                </div>

            </div>
        </Layout>
    )
}

export default EmployeeNew