
const Modal = ({ isOpen, onClose, children, modalWidth }) => {
    const modalClasses = isOpen ? 'fixed animation-from-top inset-0 flex items-center justify-center z-40' : 'hidden';
    const overlayClasses = isOpen ? 'fixed inset-0' : 'hidden';

    return (
        <div className={modalClasses}>
            <div className={`modal-overlay bg-bg-opacity z-40 ${overlayClasses}`} onClick={onClose}></div>
            <div className={`modal-container mx-auto shadow-lg z-50 overflow-y-auto rounded-md ${modalWidth?modalWidth:'w-full xl:w-2/5'}`}>
                <div className="modal-content max-h-[500px] overflow-auto bg-white p-3 p-md-5">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
