import Layout from "../../../components/Layout";
import SearchBox from "../../../components/SearchBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faFilter, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Pagination from "../../../components/Pagination";
import FilterBox from "../../../components/FilterBox";
import ApiRoute, {shopId} from "../../../helper/api";
import {toast, ToastContainer} from "react-toastify";
import Select from "react-select";
import AttributeSelect from "../../../components/Product/AttributeSelect";
import Buttons from "react-multi-date-picker/components/button";
import Modal from "../../../components/Utilities/Modal";

function AttributesGroups() {

    const selectedItemStructure = {
        label: '',
        attributes: []
    }

    const [openModal, setOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(selectedItemStructure);
    const [createMode, setCreateMode] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [paginationData, setPaginationData] = useState({});
    const [filterQuery, setFilterQuery] = useState({});

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await ApiRoute.product.attributes.groups.index.fn({
                shop_id: shopId(),
                page: pageNumber
            }, filterQuery);

            if (response?.results) {
                setData(response.results.data || []);
                setPaginationData(response.results || {});
            } else {
                setData([]);
                setPaginationData({});
            }
        } catch (error) {
            toast.error(error.message);
            setData([]);
            setPaginationData({});
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [pageNumber, filterQuery]);

    const handleSubmit = async () => {
        try {
            if (createMode) {
                await ApiRoute.product.attributes.groups.create.fn(selectedItem);
            } else {
                await ApiRoute.product.attributes.groups.update.fn(selectedItem._id, selectedItem);
            }
            await fetchData();
            setOpenModal(false);
            setSelectedItem(selectedItemStructure);
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleDelete = async (item) => {
        try {
            await ApiRoute.product.attributes.groups.remove.fn(item._id);
            await fetchData();
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <Layout>
            <div>
                <div className="flex items-center justify-between mb-3">
                    <div className="page-title flex-auto ml-3 md:ml-0">
                        <span className="inline-block bg-white pl-3">گروه های ویژگی</span>
                    </div>
                </div>

                <div className="grid md:flex justify-between items-center gap-3">
                    <div className="flex gap-3">
                        <SearchBox SetSearchQuery={ (v) => {
                            setFilterQuery({title: v.term})
                        } }/>
                    </div>
                    <button className="btn-blue text-center hidden md:block" onClick={ e => {
                        setCreateMode(true)
                        setOpenModal(true)
                    } }>افزودن</button>
                </div>
                <FilterBox/>

                {data && <div className="main-content p-3 mt-3">

                    <div className="grid grid-cols-11 text-sm border-b border-b-gray-7 py-3">
                        <div className="col-span-3 text-center">عنوان</div>
                        <div className="col-span-5 text-center">تعداد ویژگی ها</div>
                    </div>
                    {data.map((item, id) =>
                        <div key={id}>
                            <div
                                className="grid grid-cols-11 text-xs items-center rounded box-tr-style py-1.5 my-2">
                                <div className="col-span-3 text-center">{item.title}</div>
                                <div className="col-span-5 text-center">{item?.attributes?.length ?? 0}</div>
                                <div className="col-span-3 text-left pl-4">
                                    <button className="mx-1" title="ویرایش"
                                            onClick={() => {
                                                setCreateMode(false)
                                                setOpenModal(true)
                                                setSelectedItem(item)
                                            }}>
                                        <FontAwesomeIcon
                                            className="bg-blue-2 text-white p-1 w-4 h-4 rounded" icon={faEdit}/>
                                    </button>
                                    <button className="mx-2" onClick={ async () => await handleDelete(item) } title="حذف" ><FontAwesomeIcon
                                        className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                                </div>
                            </div>
                        </div>
                    )}

                    {paginationData && Object.keys(paginationData).length > 0 && (
                        <Pagination SetPageNumber={setPageNumber} Data={paginationData} />
                    )}


                </div>}



            </div>


            <Modal isOpen={openModal}>

                <div className="flex justify-between mx-auto mb-5">
                    <h4 className="text-[#000]">
                        {createMode ? 'افزودن ویژگی گروهی' : 'ویرایش ویژگی گروهی'}
                    </h4>
                    <Buttons onClick={ (e) => {setOpenModal(false); setSelectedItem(selectedItemStructure)} } className="btn-gray py-1.5">بستن</Buttons>
                </div>

                <div className={`p-3`}>
                    <div
                        className="grid grid-cols-1 lg:grid-cols-1 text-[14px] gap-5 td items-end">
                        <label className="">
                            <span className="text-right">عنوان</span>
                            <input
                                onChange={ (e) => {
                                    selectedItem.title = e.target.value;
                                } }
                                className="!mr-0 mt-2 rounded p-1 border-gray-3 shadow-sm border w-full" type="text" defaultValue={selectedItem?.title}/>
                        </label>

                        <div className="">
                            <label className="pb-2">ویژگی ها</label>
                            <AttributeSelect assignItem={ async (s) => {
                                if (!selectedItem){return}
                                let arr = []
                                s.map( (a) =>  arr.push(a.name) )
                                selectedItem.attributes = arr
                                setSelectedItem(selectedItem)
                                
                            } } defaultValue={selectedItem?.attributes}/>
                        </div>

                    </div>
                </div>

                <div className="flex justify-between mx-auto mt-5">
                    <Buttons 
                        onClick={handleSubmit} 
                        className="btn-purple w-36 py-1.5"
                    >
                        ذخیره
                    </Buttons>
                </div>
            </Modal>

            
        </Layout>
    )
}

export default AttributesGroups