import Toman from "../../Utilities/Toman";
import Modal from "../../Utilities/Modal";
import {useState} from "react";
import ApiRoute from "../../../helper/api";
import {formatNumberWithDots, getDateAsReadableFormat} from "../../../helper/general";
import DynamicTabComponent from "../../Utilities/DynamicTabComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";

export default function CreateUtm({closeModal}){
    const [search , setSearch] = useState('')
    const [type , setType] = useState('title')
    const [data , setData] = useState(null)

    async function getData(){
        let obj = {}
        if (type === 'title'){
            obj = {
                title : search
            }
        }else{
            obj = {
                asan_id: search
            }
        }
        const res = await ApiRoute.product.inventory.index.fn(obj);
        if (res.status_code === 200){
            setData(res.results.data);
        }
    }

    return (
        <Modal isOpen={true} onClose={closeModal}
               modalWidth="xl:w-1/5">
            <div className="page-title mb-3"><span className="inline-block bg-white text-xs text-purple-1 pl-3">لینک یو تی ام</span>
            </div>
            <DynamicTabComponent>
                <div title="انتخاب">
                    <div className="form grid-form w-[300px] md:w-full text-xs grid gap-5">
                        <div>
                            <label>انتخاب کمپین</label>
                            <select className="w-full">
                                <option value="">کمپین 1</option>
                                <option value="">کمپین 2</option>
                                <option value="">کمپین 3</option>
                            </select>
                        </div>
                        <div>
                            <label>انتخاب مدیوم</label>
                            <select className="w-full">
                                <option value="">مدیوم 1</option>
                                <option value="">مدیوم 2</option>
                                <option value="">مدیوم 3</option>
                            </select>
                        </div>
                        <div>
                            <label>انتخاب سورس</label>
                            <select className="w-full">
                                <option value="">سورس 1</option>
                                <option value="">سورس 2</option>
                                <option value="">سورس 3</option>
                            </select>
                        </div>
                        <div>
                            <label>انتخاب ترم</label>
                            <select className="w-full">
                                <option value="">ترم 1</option>
                                <option value="">ترم 2</option>
                                <option value="">ترم 3</option>
                            </select>
                        </div>
                        <div>
                            <label>انتخاب کانتنت</label>
                            <select className="w-full">
                                <option value="">کانتنت 1</option>
                                <option value="">کانتنت 2</option>
                                <option value="">کانتنت 3</option>
                            </select>
                        </div>
                        <div>
                            <label>لینک شما</label>
                            <input className="w-full" type="text"/>
                        </div>
                    </div>

                    <div className="flex justify-end mt-4">
                        <button className="btn-purple px-5 py-2">ایجاد لینک</button>
                    </div>
                    <div className="flex justify-between items-center rounded-md bg-gray-7 text-xs p-2 mt-4">
                        <button className="bg-purple-1 text-white rounded pt-1 pb-0.5 px-2">
                            <FontAwesomeIcon icon={faCopy}/>
                        </button>
                        <span className="w-4/5">https://entkehabcenter.com/x/x/x/x/x/x/x/x/x/x</span>
                    </div>
                </div>
                <div title="ایجاد">
                    <div className="form grid-form w-[300px] md:w-full text-xs grid gap-5">
                        <div>
                            <label>عنوان کمپین</label>
                            <input className="w-full" type="text"/>
                        </div>
                        <div>
                            <label>عنوان مدیوم</label>
                            <input className="w-full" type="text"/>
                        </div>
                        <div>
                            <label>عنوان سورس</label>
                            <input className="w-full" type="text"/>
                        </div>
                        <div>
                            <label>عنوان ترم</label>
                            <input className="w-full" type="text"/>
                        </div>
                        <div>
                            <label>عنوان کانتنت</label>
                            <input className="w-full" type="text"/>
                        </div>
                    </div>

                    <div className="flex justify-end mt-4">
                        <button className="btn-purple px-5 py-2">ایجاد درخواست</button>
                    </div>
                </div>
            </DynamicTabComponent>
        </Modal>
    )
}