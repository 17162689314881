import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ApiRoute from "../../helper/api";
import {useState} from "react";
import CustomerNaturalInfo from "./identity-info/CustomerNaturalInfo";
import CustomerLegalInfo from "./identity-info/CustomerLegalInfo";

function CustomerIdentityInfo({customer}){
    const MySwal = withReactContent(Swal)
    const [selectedTab , setSelectedTab] = useState(customer.is_legal ? 'legal' : 'natural')
    const [isLoading , setIsLoading] = useState(false)

    return (
        <div className="main-content mx-auto p-5 xlp-8 mt-6 xl:mt-12">
            <div className="form mx-auto">
                <div className="grid grid-cols-2 lg:flex justify-center pt-4 pb-7 xl:pb-12 items-center gap-4 xl:gap-10 text-13">
                    <span className="max-lg:col-span-2">نوع مشتری را انتخاب کنید</span>
                    <div className="flex items-center gap-3">
                        <button onClick={e => { setSelectedTab('natural') }}
                                className={(selectedTab === 'natural' ? ' bg-[#673bb7] text-white ' : ' text-[#673bb7] ')+`border-[#673bb7] border-2 px-3 py-1 rounded`}>حقیقی</button>
                        <button onClick={e => { setSelectedTab('legal') }}
                                className={(selectedTab == 'legal' ? ' bg-[#673bb7] text-white ' : ' text-[#673bb7] ')+`border-[#673bb7] text-[#673bb7] border-2 px-3 py-1 rounded`}>حقوقی</button>
                    </div>
                </div>

                {
                    selectedTab == 'natural' ? (<CustomerNaturalInfo customer={customer}/>) : '' ||
                    selectedTab == 'legal' ? (<CustomerLegalInfo customer={customer}/>) : ''
                }

            </div>
        </div>
    )
}
export default CustomerIdentityInfo