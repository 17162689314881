import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import ApiRoute, {uploadUrl} from "../../../helper/api";
import {toast, ToastContainer} from "react-toastify";
import {isOnEditState} from "../../../helper/product";
import Layout from "../../Layout";
import {Editor} from "@tinymce/tinymce-react";
import Buttons from "react-multi-date-picker/components/button";

export function Step2({onEdit = false , defaultProduct = null}){

    const navigate = useNavigate()

    const [product,setProduct] = useState(defaultProduct)
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [editor, setEditor] = useState(null);
    const [content, setContent] = useState('');
    const [desc, setDesc] = useState('');
    const editorRef = useRef(null);

    const [defaultContent, setDefaultContent] = useState('');
    const [defaultDesc, setDefaultDesc] = useState('');

    useEffect(() => {
       setProduct(defaultProduct)
        console.log(defaultProduct)
    }, [defaultProduct]);

    useEffect(() => {

        setContent(product.content)
        setDesc(product?.metadata?.seo?.desc)

        setDefaultContent(product.content)
        setDefaultDesc(desc)

    }, [product]);


    async function handleSubmit() {
        try {
            const response = await ApiRoute.product.create.stepTwo.fn( params.get('product_id') , {
                "content" : editor.getContent(),
                "description" : desc
            } )
            setProduct(response.results)
            //toast.success("با موفقیت ذخیره شد")
            if (!onEdit){
                return navigate("/products/create/step-3?product_id=" + response.results._id)
            }
        }catch (e) {
            toast.error(e.message)
        }
    }

    return (
        <>
            <div>

                {!onEdit && <div className="page-title mb-3"><span className="inline-block bg-white pl-3">محصول جدید</span></div>}


                {product && <div className={!onEdit ? `main-content mx-auto py-6 px-5 my-5` : ''}>

                    {!onEdit && <span className="text-purple-1 pl-3 mb-6 block text-center">توضیحات محصول</span>}

                    <Editor
                        tinymceScriptSrc='/tinymce/tinymce.min.js'
                        onInit={(evt, editor) => {
                            setEditor(editor);
                            editorRef.current = editor
                        }}
                        onSetContent={ (evt, editor) => setContent(editor.getContent()) }
                        initialValue={product?.content ?? ''}
                        init={{
                            images_upload_url: uploadUrl,
                            selector: 'textarea#open-source-plugins',
                            plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion',
                            editimage_cors_hosts: ['picsum.photos'],
                            menubar: 'file edit view insert format tools table help',
                            toolbar: "undo redo | accordion accordionremove | blocks fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl",
                            autosave_ask_before_unload: true,
                            autosave_interval: '30s',
                            autosave_prefix: '{path}{query}-{id}-',
                            autosave_restore_when_empty: false,
                            autosave_retention: '2m',
                            image_advtab: true,
                            rtl_ui:true,
                            directionality : "rtl",
                            rtl: true,
                            importcss_append: true,
                            file_picker_callback: (callback, value, meta) => {
                                /* Provide file and text for the link dialog */
                                if (meta.filetype === 'file') {
                                    callback('https://www.google.com/logos/google.jpg', {text: 'My text'});
                                }

                                /* Provide image and alt text for the image dialog */
                                if (meta.filetype === 'image') {
                                    callback('https://www.google.com/logos/google.jpg', {alt: 'My alt text'});
                                }

                                /* Provide alternative source and posted for the media dialog */
                                if (meta.filetype === 'media') {
                                    callback('movie.mp4', {
                                        source2: 'alt.ogg',
                                        poster: 'https://www.google.com/logos/google.jpg'
                                    });
                                }
                            },
                            templates: [],
                            height: 700,
                            image_caption: true,
                            quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                            noneditable_class: 'mceNonEditable',
                            toolbar_mode: 'sliding',
                            contextmenu: 'link image table',
                            skin: 'oxide',
                            content_css: 'default',
                            content_style: ' @import url(\'https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100;200;300;400;500;600;700;800;900&display=swap\'); body { font-family:"Vazirmatn",Arial,sans-serif; font-size:16px; text-align:right; direction:rtl; } img{ width:100%; height:auto; }'
                        }}
                    />

                    <label className="mt-12 mb-3 block text-13 px-2">توضیحات متا محصول</label>
                    <textarea defaultValue={desc} onChange={ (e) => { setDesc(e.target.value) } }
                              placeholder={`اینجا وارد کنید ...`}
                              className="w-full border border-gray-7 rounded outline-0 py-4 px-3 text-13" cols="30" rows="4"></textarea>
                    {/*<div className="grid md:flex items-center gap-4 lg:gap-12 mt-4 px-2 text-xs">
                        <label>
                            بررسی عنوان سئو
                            <input className="mr-2" type="checkbox"/>
                        </label>

                        <label>
                            بررسی متا توضیحات
                            <input className="mr-2" type="checkbox"/>
                        </label>

                        <label>
                            بررسی وضعیت لینک ها
                            <input className="mr-2" type="checkbox"/>
                        </label>
                    </div>*/}

                    <div className={onEdit ? 'flex justify-between' : 'flex justify-between lg:w-1/3 mx-auto mt-10'}>

                        {!onEdit && <Link to={`/products/edit?product_id=${params.get('product_id')}&step=1`} className="btn-gray w-36 py-1.5">بازگشت</Link>}

                        { !onEdit && <Buttons onClick={handleSubmit} className="btn-purple w-36 py-1.5">مرحله بعد</Buttons>}
                        { onEdit && <Buttons onClick={handleSubmit} className="btn-purple w-36 py-1.5">ذخیره سازی</Buttons>}

                    </div>

                </div>}

            </div>

        </>)
}