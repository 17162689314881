import React, { useState } from "react";

function Select(props) {
    const [selectedValue, setSelectedValue] = useState(props.initialValue || "true");

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        if (props.onChange) {
            props.onChange(event.target.value);
        }
    };

    return (
        <select
            disabled={props.disabled ?? false}
            className={`border ${selectedValue === "true" ? 'border-green-1' : 'border-gray-7'} rounded p-1`}
            value={selectedValue}
            onChange={handleChange}
        >
            <option value="true">بله</option>
            <option value="false">خیر</option>
        </select>
    );
}

export default Select;
