import Layout from "../../components/Layout";
import CustomMultiSelect from "../../components/Utilities/CustomMultiSelect";
import React, {useEffect, useState} from "react";
import {Editor} from "@tinymce/tinymce-react";
import ImageUploader from "../../components/Utilities/ImageUploader";
import VideoUploader from "../../components/Utilities/VideoUploader";
 import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLightbulb} from "@fortawesome/free-solid-svg-icons";
import {Link, useNavigate} from "react-router-dom";
import {Step1} from "../../components/Product/Step/Step-1";
import {isOnEditState} from "../../helper/product";
import ApiRoute from "../../helper/api";
import {toast, ToastContainer} from "react-toastify";
import {getDateAsReadableFormat} from "../../helper/general";
import {Step2} from "../../components/Product/Step/Step-2";
import {Step3} from "../../components/Product/Step/Step-3";
import {Step4} from "../../components/Product/Step/Step-4";
import {Step5} from "../../components/Product/Step/Step-5";
import {HasAccessComponent} from "../../helper/permissions";



function EditProduct() {

    const navigate = useNavigate()

    const [product,setProduct] = useState({})
    const [loading,setLoading] = useState(true)
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [supplierApproved, setSupplierApproved]=useState(false)
    const [productExpertApproved, setProductExpertApproved]=useState(true)
    const [seoExpertApproved, setSeoExpertApproved]=useState(true)

    const [step, setStep]=useState(null)

    const toggleSupplierApproved = () =>  setSupplierApproved(!supplierApproved)
    const toggleProductExpertApproved = () =>  setProductExpertApproved(!productExpertApproved)
    const toggleSeoExpertApproved = () =>  setSeoExpertApproved(!seoExpertApproved)

    useEffect(() => {

        if( params.get('product_id').length < 1 ){
            return navigate("/products")
        }

        (async () => {
            const response = await ApiRoute.product.single.fn( params.get('product_id') )
            setProduct(response.results)
        })()


    }, []);

    useEffect(() => {

        setLoading(false)

    }, [product]);

    useEffect(() => {

        (async () => {
            const response = await ApiRoute.product.single.fn( params.get('product_id') )
            setProduct(response.results)
        })()

    }, [step]);

    async function publishProduct() {
        const response = await ApiRoute.product.updateStatus.fn( params.get('product_id') , 'publish' )
        setProduct(response.results)
        //toast.success("با موفقیت اعمال شد")
    }

    return (
        <Layout>
            {loading === false && <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">ویرایش محصول</span>
                </div>

                <div className="grid grid-cols-2 lg:flex justify-between mt-5 lg:px-1 mb-8">

                    <div className="max-lg:mb-3 text-xs grid grid-rows-2 gap-2 py-0.5 pr-3 relative before-table-style before-rounded before-red">
                        <div>
                            <span className="text-gray-2">ورود محصول:</span>
                            <span className="text-gray-5 mr-1">{getDateAsReadableFormat(product.created_at,true)}</span>
                        </div>
                        <div>
                            <span className="text-gray-2">توسط:</span>
                            <span className="text-gray-5 mr-1">{product?.metadata?.created_by?.name ?? '---'}</span>
                        </div>
                    </div>

                    <div className="max-lg:mb-3 text-xs grid grid-rows-2 gap-2 py-0.5 pr-3 relative before-table-style before-rounded before-blue">
                        <div>
                            <span className="text-gray-2">ویرایش محصول:</span>
                            <span className="text-gray-5 mr-1">{getDateAsReadableFormat(product.updated_at,true)}</span>
                        </div>
                        <div>
                            <span className="text-gray-2">توسط:</span>
                            <span className="text-gray-5 mr-1">{product?.metadata?.updated_by?.name ?? '---'}</span>
                        </div>
                    </div>

                    <div className="max-lg:mb-3 text-xs grid grid-rows-2 gap-2 py-0.5 pr-3 relative before-table-style before-rounded before-green">
                        <div>
                            <span className="text-gray-2">انتشار محصول:</span>
                            <span className="text-gray-5 mr-1">{ product.publish_at ? getDateAsReadableFormat(product.publish_at,true) : '---'}</span>
                        </div>
                        <div>
                            <span className="text-gray-2">توسط:</span>
                            <span className="text-gray-5 mr-1">{ product?.metadata?.published_by?.name ?? '---' }</span>
                        </div>
                    </div>

                </div>


                <div className="main-content mx-auto py-6 px-5 my-5">
                    <span className="text-purple-1 pl-3 mb-6 block text-center">ویرایش محصول</span>


                    <div className={`flex flex-col gap-3 mt-4`}>

                        <div className="border border-gray-7 p-5 rounded text-[16px] text-[#2c2b88] font-bold grid gap-3 lg:flex justify-between ">
                            <div className={`cursor-pointer`} onClick={ () => {setStep(1)} }>ویرایش عنوان و مشخصات ابتدایی</div>
                        </div>

                        {step === 1 && <Step1 defaultProduct={product} onEdit={true}/>}

                        <div className="border border-gray-7 p-5 rounded text-[16px] text-[#2c2b88] font-bold grid gap-3 lg:flex justify-between ">
                            <div className={`cursor-pointer`} onClick={ () => {setStep(2)} }>ویرایش محتوا و سئو</div>
                        </div>

                        {step === 2 && <Step2 defaultProduct={product} onEdit={true}/>}

                        <div className="border border-gray-7 p-5 rounded text-[16px] text-[#2c2b88] font-bold grid gap-3 lg:flex justify-between ">
                            <div className={`cursor-pointer`} onClick={ () => {setStep(3)} }>ویرایش ویژگی ها</div>
                        </div>

                        {step === 3 && <Step3 defaultProduct={product} onEdit={true}/>}

                        <div className="border border-gray-7 p-5 rounded text-[16px] text-[#2c2b88] font-bold grid gap-3 lg:flex justify-between ">
                            <div className={`cursor-pointer`} onClick={ () => {setStep(4)} }>ویرایش تصاویر و ویدیو ها</div>
                        </div>

                        {step === 4 && <Step4 defaultProduct={product} onEdit={true}/>}

                        <div className="border border-gray-7 p-5 rounded text-[16px] text-[#2c2b88] font-bold grid gap-3 lg:flex justify-between ">
                            <div className={`cursor-pointer`} onClick={ () => {setStep(5)} }>ویرایش مشخصات، هدایا و ویژگی های جانبی</div>
                        </div>

                        {step === 5 && <Step5 defaultProduct={product} onEdit={true}/>}

                    </div>

                </div>

                <HasAccessComponent permission={`publish product`} children={ product.status !== 'publish' && <div className="main-content mx-auto py-6 px-5 my-5">
                    <span className="text-purple-1 pl-3 mb-6 block text-center">تاییدیه و انتشار محصول</span>
                    <p className="text-sm pr-4">
                        <FontAwesomeIcon className="text-lg text-red-1 ml-2" icon={faLightbulb}/>
                        کارشناس محترم؛ تایید شما به منزله چک کردن کامل مشخصات ، توضیحات و سایر موارد مرتب به حوزه کاری
                        شما بوده و مسئولیت کامل آن به عهده شماست. پس از انتشار محصول ، محصول قابل حذف و یا برگشت نیست و
                        تنها قابلیت ویرایش دارد.
                    </p>

                    <div className="border border-gray-7 p-5 rounded text-xs grid gap-3 lg:flex justify-between mt-4">
                        <label>
                            انتشار محصول
                            <button onClick={publishProduct} className="btn-green mr-2 py-2 px-8 rounded text-xs">انتشار</button>
                        </label>
                    </div>
                </div> }/>

            </div>}

            {/*                        <label onClick={toggleSupplierApproved}>
                            تایید تامین کننده کالا
                            <button className={`mr-2 rounded py-2 px-8 text-xs !duration-100 ${supplierApproved? 'btn-green' : 'btn-gray'}`}>تایید</button>
                        </label>
                        <label onClick={toggleProductExpertApproved}>
                            تایید کارشناس محصول گذاری
                            <button className={`mr-2 rounded py-2 px-8 text-xs !duration-100 ${productExpertApproved? 'btn-green' : 'btn-gray'}`}>تایید</button>
                        </label>
                        <label onClick={toggleSeoExpertApproved}>
                            تایید کارشناس محصول گذاری
                            <button className={`mr-2 rounded py-2 px-8 text-xs !duration-100 ${seoExpertApproved? 'btn-green' : 'btn-gray'}`}>تایید</button>
                        </label>*/}

        </Layout>
    )
}

export default EditProduct