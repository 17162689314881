import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import ApiRoute from "../../helper/api";
import Swal from "sweetalert2";
import {useEffect, useRef, useState} from "react";
import withReactContent from "sweetalert2-react-content";

function CustomerAddressList({ customer , customClass = null , selectAble = false , setSelected , selected , editAction }){
    const [isLoading, setIsLoading] = useState(false)
    const id = customer._id
    const [addresses, setAddresses] = useState(null);
    const MySwal = withReactContent(Swal)
    const [update, setUpdate] = useState(0)

    useEffect(() => {
        setAddresses(customer.addresses)
    }, [customer]);

    const refAddress = useRef(null)
    async function destroy(address_id) {

        setIsLoading(true)

        try {
            await ApiRoute.customer.deleteAddress.fn(id , address_id)
        }catch (error) {
            setIsLoading(false)
            return Swal.fire({
                title: "خطا",
                icon: "error",
                text: error,
                confirmButtonText: "باشه"
            });
        }

        Swal.fire({
            title: "آدرس حذف شد",
            icon: "success",
            confirmButtonText: "باشه"
        });

        setIsLoading(false)
    }

    async function active(address_id) {

        setIsLoading(true)

        try {
            await ApiRoute.customer.activeAddress.fn(id , address_id)
        }catch (error) {
            setIsLoading(false)
            return Swal.fire({
                title: "خطا",
                icon: "error",
                text: error,
                confirmButtonText: "باشه"
            });
        }

        Swal.fire({
            title: "آدرس تایید شد",
            icon: "success",
            confirmButtonText: "باشه"
        });

        setIsLoading(false)
    }

    return (
        <div className={customClass !== null ? customClass : 'main-content mx-auto p-5 mt-12'}>
            <div className="flex w-full justify-between mb-10">
                <span className="text-purple-1">نشانی ها</span>
                <Link target={selectAble ? '_blank' : '_self'} className="btn-outline-purple py-1 px-8" to={`/customers/addresses/create/`+id}>افزودن نشانی جدید</Link>
            </div>


            {addresses && addresses.map((item, index) =>
                <div onClick={ () => {
                    if (selectAble){
                        setSelected(item)
                    }
                } } className={ (selectAble && `cursor-pointer `)+(( (selectAble && selected) && item.id === selected?.id) ? 'bg-green-3' : '')+` grid lg:grid-cols-8 text-13 border border-gray-7 rounded-lg py-2 px-6 mb-2 relative items-center before-table-style before-w-3 before-purple`} key={index}>
                    <div className="lg:col-span-6 pt-1">
                        <div className="grid md:grid-cols-2 lg:grid-cols-5">
                            <div>
                                <span className="text-gray-2">گیرنده: </span>
                                <span className="text-gray-3">{item.first_name} {item.last_name}</span>
                            </div>

                            <div>
                                <span className="text-gray-2">شماره تماس: </span>
                                <span className="text-gray-3">{item.cellphone}</span>
                            </div>

                            <div>
                                <span className="text-gray-2">استان: </span>
                                <span className="text-gray-3">{item.coordinates?.state?.faname}</span>
                            </div>

                            <div>
                                <span className="text-gray-2">شهر: </span>
                                <span className="text-gray-3">{item.coordinates?.city?.faname}</span>
                            </div>

                            <div>
                                <span className="text-gray-2">منطقه: </span>
                                <span className="text-gray-3">{item.coordinates?.area?.faname ?? '-'}</span>
                            </div>
                        </div>
                        <div className="my-2">
                            <span className="text-gray-2">آدرس: </span>
                            <span className="text-gray-3">{item.address.address}</span>
                        </div>
                        <div className="grid md:grid-cols-3">
                            <div>
                                <span className="text-gray-2">پلاک: </span>
                                <span className="text-gray-3">{item.address?.house_number}</span>
                            </div>

                            <div>
                                <span className="text-gray-2">طبقه: </span>
                                <span className="text-gray-3">{item.address?.floor}</span>
                            </div>

                            <div>
                                <span className="text-gray-2">کدپستی: </span>
                                <span className="text-gray-3">{item.address?.postcode}</span>
                            </div>


                        </div>

                    </div>
                    <div className="lg:col-span-2 flex justify-end gap-10 pt-3 lg:pt-0">
                        <div className="grid grid-rows-3 py-2">
                            {!editAction && <Link to={`/customers/addresses/edit/${id}/${item.id}`} className="text-blue-1"><FontAwesomeIcon icon={faEdit} title="ویرایش"/></Link>}
                            {editAction && <button onClick={ () => {
                                editAction(item,customer)
                            } } className="text-blue-1"><FontAwesomeIcon icon={faEdit} title="ویرایش"/></button>}
                            { ( (item.first ?? false) || (item.main ?? false)  ) ? '' : (
                                <button
                                    onClick={ e => {
                                        MySwal.fire({
                                            title: <p>ایا مطمن هستید؟</p>,
                                            text: "این عمل غیرقابل برگشت است",
                                            confirmButtonText: "بله حذفش کن",
                                            cancelButtonText: `دست نگه دار`,
                                            showCancelButton: true,
                                        }).then((result) => {
                                            if (result.isConfirmed){
                                                destroy(item.id)
                                                setAddresses(addresses.filter(xitem => xitem.id !== item.id))
                                            }
                                        })
                                    } }
                                    type="button" className="text-red-1" disabled={selectAble}><FontAwesomeIcon icon={faTrash} title="حذف"/></button>
                            ) }
                            <button
                                onClick={ e => {active(item.id)} }
                                className={item.active?'text-green-1':'text-gray-3'} title="تایید"><FontAwesomeIcon icon={faCheckDouble} /></button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default CustomerAddressList