import {useRef, useState} from "react";

export default function ProductFilterStateTab({options , selectedTab ,setSelectedTab}){
    const myRef = useRef(null);
    const filters = [
        {
            title: 'همه',
            quantity: options?.all ?? 0,
            name: '',
            active: true
        }, {
            title: 'منتشر شده',
            name: 'publish',
            quantity: options?.publish ?? 0,
            active: false
        }, {
            title: 'پیشنویس',
            name: 'draft',
            quantity: options?.draft ?? 0,
            active: false
        },
/*        {
            title: 'در انتظار تایید کارشناس',
            name: 'waiting_approved',
            quantity: options?.week ?? 0,
            active: false
        }, {
            title: 'در انتظار تایید سئو',
            name: 'waiting_seo',
            quantity: options?.month ?? 0,
            active: false
        },*/
    ];

    function active(item , el){
        setSelectedTab(item.name)
    }

    return(
        <>
            <div className="border-b border-b-gray-7 p-3 lg:p-2 max-lg:flex overflow-x-auto" ref={myRef}>
                {filters.map((item, id)=>(
                    <button
                        key={id}
                        onClick={ e => {
                            active(item , e)
                        } }
                        className={`text-xs w-max ml-4 whitespace-nowrap${selectedTab == item.name ? ' text-purple-1':''}`} key={id}>
                        {item.title}
                        &nbsp;
                        (
                        {item.quantity}
                        )
                    </button>
                ))}
            </div>
        </>
    )
}