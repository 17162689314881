import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBagShopping, faFile, faLocationPin, faUser} from "@fortawesome/free-solid-svg-icons";
import {formatNumberWithDots, getDateAsReadableFormat, orderPaymentTransactionLabel, stopLoader} from "../../../helper/general";
import Modal from "../../Utilities/Modal";
import {useEffect, useState} from "react";
import ApiRoute from "../../../helper/api";
import TransportSelect from "../../Common/TransportSelect";
import StorageSelect from "../../Common/StorageSelect";
import {HasAccess} from "../../../helper/permissions";

export default function OrderTransferenceSubmitModal({order, closeModal, event}){
    const [defaultStorage, setDefaultStorage] = useState(null)
    const [defaultTransport, setDefaultTransport] = useState(null)
    const [myOrder , setMyOrder] = useState(null)

    const [formData, setFormData] = useState({
        description: '',
        storage:'',
        transport:''
    })

     useEffect(() => {
        ( async () => {
            const response = await ApiRoute.storage.single.fn("6735cfec475b2db6f30c51f3")
            if(response?.status_code === 200){
                setDefaultStorage(response.results)
                formData.storage = response.results
                stopLoader()
            }
        } )()
    }, [])

    useEffect(() => {
        ( async () => {
            const response = await ApiRoute.shipment.transport.single.fn("6735cfec475b2db6f30c51fb")
            if(response?.status_code === 200){
                setDefaultTransport(response.results)
                formData.transport = response.results
                stopLoader()
            }
        } )()
    }, [])

    useEffect(() => {
        ( async () => {
            const response = await ApiRoute.order.single.fn(order+"?model=\\App\\OrderManager\\Model\\OrderExpanded")
            setMyOrder(response.results)
        } )()
    }, []);
    async function handleSubmit(){
        const res = await ApiRoute.order.action.fn("transference/submit",order,formData)
        try {
            event(res)
        }catch (e) {
            
        }
    }
    return (
        <Modal isOpen={true} onClose={closeModal}>
            <HasAccess permission={`submit_transference order`} children={
                <div>

                    <div className="page-title z-1 text-right text-sm">
                        <span className="inline-block bg-white pl-3 pr-1 mb-3 relative z-2">ثبت حواله</span>
                    </div>

                    <div className={`flex gap-2 w-full flex-col`}>

                        <div>
                            <input onKeyUp={ (e) => { formData.description = e.target.value } }
                                   className='px-3 py-2 border border-[#dfdfdf] w-full rounded-[8px] shadow flex-auto bg-white' type="text" placeholder="شرح حواله را اینجا بنویسید ..."/>
                        </div>

                        {defaultStorage && <StorageSelect isActive={true} customDefaultValue={true} defaultValue={[defaultStorage]} multiple={false} fixed={true} assignItem={ (v) => {
                            formData.storage = v
                        } }/>}

                         {defaultTransport && <TransportSelect  isActive={true} multiple={false} fixed={true} 
                         defaultValue={[defaultTransport]} customDefaultValue={true} assignItem={ (v) => {
                            formData.transport = v
                        } }/>}

                        {(myOrder && myOrder?.factor?.status === "submitted") && <button onClick={handleSubmit} className={`btn-green mt-4 max-w-max py-1 rounded`}>ثبت
                            درخواست</button>}
                        { (myOrder && myOrder?.factor?.status !== "submitted") && <div className={`alert-red`}>ابتدا سفارش باید به تایید تامین کننده برسد</div>}

                        {(myOrder === null || defaultStorage === null || defaultTransport === null) && <div className="x2-loader"></div>}

                    </div>

                </div>
            }/>
        </Modal>
    )
}