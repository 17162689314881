import Toman from "../../Utilities/Toman";
import {Link} from "react-router-dom";
import CustomerSelect from "../../Customer/CustomerSelect";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import Layout from "../../Layout";
import SearchBox2 from "../../SearchBox2";
import ProductSelect from "../../Product/ProductSelect";
import {formatNumberWithDots} from "../../../helper/general";
import {toast} from "react-toastify";
import ApiRoute, {isPrimaryShop} from "../../../helper/api";
import PriceField from "../../Common/PriceField";


export function MinProductPrice({product,setParentUpdate}) {
    const [update , setUpdate] = useState(0)
    const [zero , setZero] = useState(null)
    async function getMinPrice(){
        const res = await ApiRoute.price.rule.getProductRuleMinPrice.fn(product._id,(product.prices.regular === 0 ? product.prices.price : product.prices.regular))
        product.min_price = res.results
        setZero(res.results)
        if (!product?.item_value){
            product.item_value = res.results;
        }
        setUpdate(Date.now())
        setParentUpdate(Date.now())
    }

    useEffect(() => {
        getMinPrice()
    }, []);
    return (
        <td>{zero !== null ? formatNumberWithDots(product?.min_price) : (<div className={`x2-loader`}></div>)}</td>
    );
}

export default function Step3({newOrder,setStep,step,setNewOrder}){
    const [update , setUpdate] = useState(0)
    const [error , setError] = useState(false)
    const [products , setProducts] = useState([])

    return (
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">سفارش جدید</span></div>
                <div className="hidden lg:flex items-center justify-center text-sm xl:w-3/4 mx-auto mt-10">
                    <span className="text-purple-1">اطلاعات مشتری</span>
                    <span className="h-0.5 w-20 2xl:w-36 bg-purple-1 block mx-2"></span>
                    <span className="text-purple-1">نحوه ارسال</span>
                    <span className="h-0.5 w-20 2xl:w-36 bg-purple-1 block mx-2"></span>
                    <span className="text-purple-1">انتخاب کالا</span>
                    <span className="h-0.5 w-20 2xl:w-36 bg-gray-3 block mx-2"></span>
                    <span>انتخاب زمان ارسال</span>
                    <span className="h-0.5 w-20 2xl:w-36 bg-gray-3 block mx-2"></span>
                    <span>ثبت سفارش</span>
                </div>

                <div className="main-content md:w-3/5 2xl:w-2/5 mx-auto p-4 lg:p-8 mt-12">

                    <ProductSelect inStock={true} multiple={true} assignItem={ async (x) => {
                        setProducts(x)
                        newOrder.products = x
                    } } />

                    <table className="table-auto w-full mx-auto mt-10 block sm:table">
                        <thead>
                        <tr className="text-13 text-gray-6">
                            <td className="pb-2">نام کالا</td>
                            <td className="pb-2">موجودی</td>
                            <td className="pb-2">{isPrimaryShop() ? 'قیمت' : 'قیمت سایت'}</td>
                            {!isPrimaryShop() && <td className="pb-2">کف قیمت</td>}
                            {!isPrimaryShop() && <td className="pb-2">قیمت</td>}
                            <td className="pb-2">تعداد</td>
                        </tr>
                        </thead>
                        <tbody>

                        {products?.length > 0 && products.map( (product,index) => <tr key={index} className="text-xs before before-purple">
                            <td className="text-right px-3 max-lg:pr-5 max-md:min-w-[180px] py-2">
                                {product?.title}
                            </td>
                            <td>{isPrimaryShop() ? product?.stocks?.web_site : product?.stocks?.live}</td>
                            {isPrimaryShop() && <td>{formatNumberWithDots(product?.calculated_price?.final)}</td>}
                            {!isPrimaryShop() && <td>{formatNumberWithDots(product.prices.regular === 0 ? product.prices.price : product.prices.regular)}</td>}
                            {!isPrimaryShop() && <MinProductPrice product={product} setParentUpdate={setUpdate}/>}
                            {(!isPrimaryShop() && product.min_price) && <td><PriceField min={product.min_price} defaultValue={product.min_price} onChange={ async (v) => {
                                product.item_value = parseInt(v);
                            } }/></td>}

                            <td>{product.stocks.web_site > 0 && <input onKeyUp={ (e) => {
                                if (e.target.value > product.stocks.live){
                                    toast.error("موجودی محصول قابل دسترسی نیست")
                                    e.target.value = product.stocks.live;
                                    return;
                                }
                                product.quantity = e.target.value
                            } } defaultValue={1} className={`input-x`} type={`number`} min={1} max={product.stocks.live}/>}</td>

                        </tr>)}



                        </tbody>
                    </table>

                    <div className="flex justify-between lg:px-12 gap-4 mt-8">
                        <button onClick={ () => {
                            setStep(2)
                        } } className="btn-gray w-1/2 lg:w-36">بازگشت</button>
                        {products?.length > 0 && <button onClick={ async () => {

                            let _break = false
                            products.map( async (product) => {

                                if ( parseInt(product.min_price) === 0){
                                    _break = true;
                                    return;
                                }

                                if ( parseInt(product.item_value) < parseInt(product.min_price)){
                                    _break = true;
                                    return;
                                }

                                const res = await ApiRoute.price.rule.getProductRuleCheck.fn(product._id,product.item_value,product.prices.regular === 0 ? product.prices.price : product.prices.regular)
                                if (res.results !== true){
                                    toast.error(res.results)
                                    _break = true;
                                }
                            } )

                            if (_break){
                                toast.error("قیمت وارده نا معتبر است")
                                setUpdate(Date.now())
                                return;
                            }

                            setNewOrder(newOrder)
                            setStep(4)
                        } } className="btn-purple w-1/2 lg:w-36">انتخاب بازه ارسال</button>}
                    </div>
                </div>

            </div>
    )
}