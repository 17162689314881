import {faPrint} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {startLoader, stopLoader} from "./general";
import {hasAccess} from "./permissions";

function getPrintServiceUrl() {
    return "https://print.entekhabcenter.com"
}
export default function OrderPrintList(order = null){
    const list = [
        {
            title: 'پرینت باربری',
            icon: faPrint,
            hasAccess:hasAccess('barbari_print order'),
            name:'print_transport',
            submit: async (order_id) => {
                startLoader()
                const res = await axios.post(getPrintServiceUrl()+"/auth/8f6df519a2125946820bc34a561164c2",{
                    t: "barbari",
                    o:order_id
                })
                const d = await res.data
                if (d?.status_code === 200) {
                    window.open(getPrintServiceUrl()+'/print?t=barbari&o=' + order_id)
                }
                stopLoader()
            }
        },
        {
            title: 'پرینت تیپاکس',
            icon: faPrint,
            name:'print_tipax',
            hasAccess:hasAccess('tipax_print order'),
            submit: async (order_id) => {
                startLoader()
                const res = await axios.post(getPrintServiceUrl()+"/auth/8f6df519a2125946820bc34a561164c2",{
                    t: "tipax",
                    o:order_id
                })
                const d = await res.data
                if (d?.status_code === 200) {
                    window.open(getPrintServiceUrl()+'/print?t=tipax&o=' + order_id)
                }
                stopLoader()
            }
        },
        {
            title: 'پرینت پست',
            icon: faPrint,
            hasAccess:hasAccess('post_print order'),
            name:'print_post',
            submit: async (order_id) => {
                startLoader()
                const res = await axios.post(getPrintServiceUrl()+"/auth/8f6df519a2125946820bc34a561164c2",{
                    t: "post",
                    o:order_id
                })
                const d = await res.data
                if (d?.status_code === 200) {
                    window.open(getPrintServiceUrl()+'/print?t=post&o=' + order_id)
                }
                stopLoader()
            }
        },
        {
            title: 'پرینت رسمی',
            icon: faPrint,
            name:'print_official',
            hasAccess: hasAccess('official_invoice_print order',['legal_print order']),
            submit: async (order_id) => {
                startLoader()
                const res = await axios.post(getPrintServiceUrl()+"/auth/8f6df519a2125946820bc34a561164c2",{
                    t: "official",
                    o:order_id,
                    pre:0,
                })
                const d = await res.data
                if (d?.status_code === 200) {
                    window.open(getPrintServiceUrl()+'/print?t=official&o=' + order_id)
                }
                stopLoader()
            }
        },
        {
            title: 'پرینت پیش رسمی',
            icon: faPrint,
            name:'print_pre_official',
            hasAccess: hasAccess('official_preinvoice_print order',['legal_print order']),
            submit: async (order_id) => {
                startLoader()
                const res = await axios.post(getPrintServiceUrl()+"/auth/8f6df519a2125946820bc34a561164c2",{
                    t: "pre_official",
                    o:order_id,
                    pre:1,
                })
                const d = await res.data
                if (d?.status_code === 200) {
                    window.open(getPrintServiceUrl()+'/print?t=pre_official&o=' + order_id)
                }
                stopLoader()
            }
        },
    ];

    if( order?.shop_id && order?.shop_id != "6735cfec475b2db6f30c51f2" ){
        list.push({
                title: 'پرینت فاکتور مشتری',
                icon: faPrint,
                name:'print_customer_official',
                hasAccess: hasAccess('official_invoice_print order',['legal_print order']),
                submit: async (order_id) => {
                    startLoader()
                    const res = await axios.post(getPrintServiceUrl()+"/auth/8f6df519a2125946820bc34a561164c2",{
                        t: "customer_invoice",
                        o:order_id,
                        pre:0,
                    })
                    const d = await res.data
                    if (d?.status_code === 200) {
                        window.open(getPrintServiceUrl()+'/print?t=customer_invoice&o=' + order_id)
                    }
                    stopLoader()
                }
            }
        )
        list.push({
                title: 'پرینت پ.فاکتور مشتری',
                icon: faPrint,
                name:'print_customer_pre_official',
                hasAccess: hasAccess('official_invoice_print order',['legal_print order']),
                submit: async (order_id) => {
                    startLoader()
                    const res = await axios.post(getPrintServiceUrl()+"/auth/8f6df519a2125946820bc34a561164c2",{
                        t: "customer_preinvoice",
                        o:order_id,
                        pre:0,
                    })
                    const d = await res.data
                    if (d?.status_code === 200) {
                        window.open(getPrintServiceUrl()+'/print?t=customer_preinvoice&o=' + order_id)
                    }
                    stopLoader()
                }
            }
        )
    }

    function getList(){
        if ( hasAccess('full_access_to_print order') ){
            return list
        }
        let arr = []
        list.filter( i => i.hasAccess === true ).map( i => arr.push(i) )
        return arr
    }
    return getList()
}