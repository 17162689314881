import Layout from "../../../components/Layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Pagination from "../../../components/Pagination";
import SearchBox from "../../../components/SearchBox";
import {Link} from "react-router-dom";
import {faCheckDouble, faEdit} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useRef, useState} from "react";
import ApiRoute from "../../../helper/api";
import {toast} from "react-toastify";
import {getFrontEndUrl} from "../../../helper/general";

function AttributeFeatures(){

    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [data, setData] = useState(null);
    const [term, setTerm] = useState(null);

    const [newData,setNewData] = useState({
        label:'',
        value:''
    });

    useEffect(() => {
        getData()
    }, []);

    async function getData(){
        const response = await ApiRoute.product.attributes.single.fn(params.get('id')+"?model=\\App\\ProductManager\\Model\\AttributesExpanded")
        setData(response.results)
    }

    async function handleSubmit() {
            const response = await ApiRoute.product.attributes.createValue.fn(data.name,newData)
            if (response?.results){
                await getData()
            }
    }

    async function handleUpdate(name, item) {
        await ApiRoute.product.attributes.updateLabel.fn(name,item)
    }


    return(
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3"> ویژگی : {data?.group_label+" - "+data?.label}</span>
                </div>

                <div className="main-content mx-auto p-5 mt-6">
                    <div className="grid grid-cols-1 lg:grid-cols-7 gap-4">

                        <div className="lg:col-span-2 border border-gray-7 rounded-md p-2 pb-4 h-max form">
                            <div className="grid gap-5 justify-items-stretch">
                                <label className="!grid">
                                   <span className="text-right mb-2">ایجاد مقدار جدید</span>
                                    <input onChange={ e => {
                                        newData.label = e.target.value
                                        setNewData(newData)
                                    } } placeholder={`عنوان را وارد کنید`} className="!mr-0" type="text"/>
                                </label>
                                <label className="!grid">
                                    <span className="text-right mb-2">نامک</span>
                                    <input onChange={ e => {
                                        newData.value = e.target.value
                                        setNewData(newData)
                                    } } className="!mr-0 text-left" type="text" placeholder="نامک را وارد کنید"/>
                                </label>
                                <button onClick={handleSubmit} className="btn-green w-max rounded mr-auto py-2">افزودن</button>
                            </div>
                        </div>

                        {data && <div className="lg:col-span-5 border border-gray-7 rounded-md p-2">
                            <div className="flex items-center border border-gray-7 rounded bg-gray-1">
                                <input onChange={ (e) => {
                                    setTerm(e.target.value);
                                } } className='px-3 w-full lg:w-72 py-2 flex-auto bg-gray-1' type="text" placeholder="جستجو..."/>
                            </div>
                            <table className="table-borderd-tr table-auto w-full mt-2 mx-auto block sm:table text-xs">
                                <thead>
                                <tr>

                                    <td>عنوان مشخصات ویژگی</td>
                                    <td>نامک</td>
                                    <td>تعداد استفاده</td>
                                    <td></td>

                                </tr>
                                </thead>
                                <tbody>

                                {data.render_values.map(item => {
                                    if (term){
                                        if (!item.label.includes(term)){ return (<></>) }
                                    }
                                    return (
                                        <tr key={item._id}>
                                            <td><input onKeyUp={ (e) => {
                                                item.label = e.target.value;
                                            } } className="border border-gray-3 p-1 px-3 rounded" type="text" defaultValue={item.label}/></td>
                                            <td>{item.value}</td>
                                            <td><Link to={`/products?attribute_name=${data.name}&attribute_value=${item.value}`}>{item.count}</Link></td>
                                            <td>
                                                <button onClick={() => {
                                                    handleUpdate(data.name, item)
                                                }} className={`text-green-1 text-[16px]`}>
                                                    <FontAwesomeIcon icon={faEdit}/>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}

                                </tbody>
                            </table>
                            {/*<Pagination />*/}
                        </div>}

                    </div>
                </div>


            </div>
        </Layout>
    )
}
export default AttributeFeatures