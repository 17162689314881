import Layout from "../../../components/Layout";
import {Link} from "react-router-dom";
import ImageUploader from "../../../components/Utilities/ImageUploader";
import VideoUploader from "../../../components/Utilities/VideoUploader";
import FileUpload from "../../../components/FileUpload";
import React, {useEffect, useState} from "react";
import ApiRoute from "../../../helper/api";
import {isOnEditState} from "../../../helper/product";
import {Step4} from "../../../components/Product/Step/Step-4";
import {ToastContainer} from "react-toastify";

function CreateProductStep4() {

    return (
        <Layout>
            <Step4/>
            
        </Layout>
    )
}

export default CreateProductStep4