import SwitchToggle from "../Utilities/SwitchToggle";
import {useEffect, useState} from "react";
import CategorySelect from "../Product/Category/CategorySelect";
import BrandSelect from "../Product/Category/BrandSelect";
import SelectCoordinates from "../Coordinates/SelectCoordinates";
import TransportSelect from "../Common/TransportSelect";
import ApiRoute from "../../helper/api";

export default function FilterTabEmployee({selectedTab ,setSelectedTab}) {

    const [data,setData] = useState(null)

    const [filters,setFilters] = useState([])

    useEffect(() => {
        ( async () => {
            const res = await ApiRoute.shop.get.fn({
            },{
                model:"\\App\\ShopManager\\Model\\ExpandedShop"
            })
            if (res?.status_code === 200){
                let tmp = []
                res.results.map( (item) => {
                    tmp.push({
                        title: item.title.replaceAll("(","[").replaceAll(")","]"),
                        quantity: item.employee_count,
                        _id:item._id
                    })
                } )
                setFilters(tmp)
            }
        } )()
    }, []);

    return(
        <>
            <select onChange={ (e) => {
                setSelectedTab(e.target.value)
            } } className={`input-x`}>
                {filters.map((item, id)=>(
                    <option value={item._id}>
                        {item.title}
                        &nbsp;
                        (
                        {item.quantity}
                        )
                    </option>
                ))}
            </select>
        </>
    )
}