import Layout from "../../components/Layout";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../helper/api";
import SearchBox from "../../components/SearchBox";
import PagesTable from "../../components/Tables/PagesTable";
import Pagination from "../../components/Pagination";
import CreatePageModal from "../../components/Page/CreatePageModal";
import Modal from "../../components/Utilities/Modal";
import ProductSelect from "../../components/Product/ProductSelect";
import {formatNumberWithDots, getDateAsReadableFormat} from "../../helper/general";
import {MinProductPrice} from "../../components/Order/Create/Step3";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {faRemove} from "@fortawesome/free-solid-svg-icons/faRemove";

export default function SnappShop(){
    const navigate = useNavigate()
    const [createModal, setCreateModal] = useState(false)
    const [newData, setNewData] = useState({
        snapp_shop_product_id: '',
        product:{}
    })

    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  res  = await ApiRoute.integrate.snappShop.index.fn({
                shop_id: shopId(),
                page : pageNumber
            },filterQuery)
            setData(res)
            setIsLoading(false)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        filterQuery.quick_search = searchQuery
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    const tableTitles = [
        {
            title: 'محصول',
        },
        {
            title: 'شناسه اسنپ',
        },
        {
            title: 'ایجاد',
        }
    ]

    async function handleSubmit(){
       const res = await ApiRoute.integrate.snappShop.create.fn(newData)
       if (res?.status_code === 200){
           setCreateModal(false)
           setNewData({
               snapp_shop_product_id: '',
               product:{}
           })
           setUpdateData(Date.now())
       }
    }
    return (
        <Layout>
            <div>
                <div className="flex items-center justify-between mb-3">
                    <div className="page-title flex-auto ml-3 md:ml-0">
                        <span className="inline-block bg-white pl-3">محصولات اسنپ شاپ</span>
                    </div>
                    <button onClick={() => setCreateModal(true)} className="btn-blue text-center md:hidden"> افزودن محصول جدید</button>
                </div>

                <div className="grid md:flex justify-between items-center gap-3">
                    <div className="flex gap-3">
                        <SearchBox SearchByDefault={'title'} SetSearchQuery={setSearchQuery} SearchIn={false}/>
                    </div>
                    <button onClick={() => setCreateModal(true)} className="btn-blue text-center hidden md:block">افزودن محصول جدید</button>
                </div>

                {!isLoading && <div className="main-content p-1 mt-2">

                    <table className="table-auto w-full block mt-4">
                        <thead>
                        <tr className="border-b border-b-gray-7">
                            {tableTitles.map((item, id) => (
                                <td className={` text-13 relative text-gray-6 pb-2`} key={id}>
                                    {item.title}
                                </td>
                            ))}
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        {tableData?.map(tableData => (
                                <tr className={`text-xs py-2`} key={tableData?.id}>

                                    <td>
                                        {tableData?.product.title}
                                    </td>

                                    <td>
                                        <input onInput={ (e) => {
                                            tableData.snapp_shop_product_id = e.target.value
                                        } } className={`input-x`} defaultValue={tableData?.snapp_shop_product_id}/>
                                    </td>

                                    <td>
                                        {getDateAsReadableFormat(tableData?.created_at)}
                                    </td>

                                    <td>
                                        <div className={`flex items-center gap-3 text-[15px]`}>
                                            <button onClick={ async () => {
                                                await ApiRoute.integrate.snappShop.update.fn(tableData._id, tableData)
                                            } } className={`text-green-1`}>
                                                <FontAwesomeIcon icon={faEdit}/>
                                            </button>
                                            <button onClick={ async () => {
                                                await ApiRoute.integrate.snappShop.delete.fn(tableData._id)
                                                setUpdateData(Date.now())
                                            } } className={`text-red-1`}>
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </button>
                                        </div>
                                    </td>

                                </tr>
                            )
                        )}
                        </tbody>
                    </table>

                    <Pagination SetPageNumber={setPageNumber} Data={paginationData}/>
                </div>}

            </div>

            {createModal &&  <Modal isOpen={createModal} onClose={() => {
                setCreateModal(false)
            }}>
                <div>

                    <div className="page-title z-1 text-right text-sm">
                        <span className="inline-block bg-white pl-3 pr-1 mb-3 relative z-2">افزودن محصول</span>
                    </div>

                    <div className={`flex gap-2 w-full flex-col`}>

                        <input onKeyUp={ (e) => {
                            newData.snapp_shop_product_id = e.target.value;
                        } } className='input-x' type="text" placeholder="شناسه محصول در اسنپ شاپ ..."/>
                        <ProductSelect allowOutStock={true} fixed={true} assignItem={ (s) => {
                            s.map((item) => {
                                newData.product = {
                                    title:item.title,
                                    _id:item._id,
                                    slug:item.slug
                                }
                            })
                        } }/>
                        <button onClick={handleSubmit} className={`btn-green max-w-max rounded`}>افزودن</button>

                    </div>

                </div>
            </Modal>}


        </Layout>
    )
}