import Accordion from "../Utilities/Accordion";

function TimeTable() {

    const weekData = [
        {
            title: 'شنبه',
            date: '1402/01/16',
            allDeliveries: 40,
            remainedDeliveries: 20,
            allSending: 20

        },
        {
            title: 'یکشنبه',
            date: '1402/01/16',
            allDeliveries: 40,
            remainedDeliveries: 20,
            allSending: 20
        },
        {
            title: 'دوشنبه',
            date: '1402/01/16',
            allDeliveries: 40,
            remainedDeliveries: 20,
            allSending: 20
        },
        {
            title: 'سه شنبه',
            date: '1402/01/16',
            allDeliveries: 40,
            remainedDeliveries: 20,
            allSending: 20
        },
        {
            title: 'چهارشنبه',
            date: '1402/01/16',
            allDeliveries: 40,
            remainedDeliveries: 20,
            allSending: 20
        },
        {
            title: 'پنج شنبه',
            date: '1402/01/16',
            allDeliveries: 40,
            remainedDeliveries: 20,
            allSending: 20
        },
        {
            title: 'جمعه',
            date: '1402/01/16',
            allDeliveries: 40,
            remainedDeliveries: 20,
            allSending: 20
        },
    ]
    return (
        <div className="border border-purple-3 p-3 rounded-md overflow-x-auto">
            <div className="border-b border-b-gray-7 flex lg:grid lg:grid-cols-2 pb-3 mb-3 px-10 text-xs">
                <div className="flex gap-10">
                    <span className="w-28 lg:w-1/6 text-center">روزهای هفته</span>
                    <span className="w-28 lg:w-1/6 text-center">تاریخ</span>
                </div>
                <div className="flex justify-end gap-10">
                    <span className="w-28 lg:w-1/6 text-center">کل پیک ها</span>
                    <span className="w-28 lg:w-1/6 text-center">پیک های باقی مانده</span>
                    <span className="w-28 lg:w-1/6 text-center">مجموع ارسال</span>
                </div>
            </div>
            <Accordion>
                {weekData.map((item, id) =>
                    <div key={id}>
                        <div
                            className="flex lg:grid lg:grid-cols-2 text-xs items-center rounded bg-gray-7 py-3 px-10 cursor-pointer">
                            <div className="flex gap-10">
                                <span className="w-28 lg:w-1/6 text-center">{item.title}</span>
                                <span className="w-28 lg:w-1/6 text-center">{item.date}</span>
                            </div>
                            <div className="flex justify-end gap-10">
                                <span className="w-28 lg:w-1/6 text-center">{item.allDeliveries}</span>
                                <span className="w-28 lg:w-1/6 text-center">{item.remainedDeliveries}</span>
                                <span className="w-28 lg:w-1/6 text-center">{item.allSending}</span>
                            </div>
                        </div>
                        <div
                            className={`p-5 text-xs`}>
                            <table className="w-full tr-orange-border">
                                <thead>
                                <tr>
                                    <td>شروع بازه</td>
                                    <td>پایان بازه</td>
                                    <td>کل پیک ها</td>
                                    <td>پیک های باقی مانده</td>
                                    <td>مجموع ارسال</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <input
                                            className="border border-gray-7 rounded py-1.5 text-center mx-auto"
                                            type="number"
                                            value="11"/>
                                    </td>
                                    <td>
                                        <input
                                            className="border border-gray-7 rounded py-1.5 text-center mx-auto"
                                            type="number"
                                            value="14"/>
                                    </td>
                                    <td>40</td>
                                    <td>20</td>
                                    <td>20</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            className="border border-gray-7 rounded py-1.5 text-center mx-auto"
                                            type="number"
                                            value="11"/>
                                    </td>
                                    <td>
                                        <input
                                            className="border border-gray-7 rounded py-1.5 text-center mx-auto"
                                            type="number"
                                            value="14"/>
                                    </td>
                                    <td>40</td>
                                    <td>20</td>
                                    <td>20</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </Accordion>
        </div>
    )
}

export default TimeTable