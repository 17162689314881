import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faEyeSlash, faTrash} from "@fortawesome/free-solid-svg-icons";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import React from "react";

export default function ComponentTitle({title, color="#09cf", setOpen, open, up, down, remove, component}){
    return (
        <div
            style={{background: color}}
            className={`w-full py-2 flex items-center justify-between px-2 text-white text-xs text-center rounded`}>

            <div className="block"> <input onInput={ (e) => {
                component.title = e.target.value
            } } className={`text-[#333] rounded-lg border-none outline-0 p-1 font-bold`} defaultValue={component?.title ?? title}/> <span className={`opacity-50 mr-1`}>({component.layout})</span></div>
            <div className={`flex gap-3 items-center`}>
                <FontAwesomeIcon
                    onClick={ (e) => { setOpen(!open) } }
                    className="bg-[#333] text-white py-1 px-1.5 w-4 h-4 rounded"
                    icon={!open ? faEyeSlash : faEye}/>
                <FontAwesomeIcon
                    onClick={up}
                    className="bg-blue-1 text-white py-1 px-1.5 w-4 h-4 rounded"
                    icon={faChevronUp}/>
                <FontAwesomeIcon
                    onClick={down}
                    className="bg-blue-1 text-white py-1 px-1.5 w-4 h-4 rounded"
                    icon={faChevronDown}/>
                <FontAwesomeIcon
                    onClick={remove}
                    className="bg-red-1 text-white py-1 px-1.5 w-4 h-4 rounded"
                    icon={faTrash}/>
            </div>

        </div>
    )
}