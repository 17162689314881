import React from "react";
import {formatNumberWithCama, formatNumberWithDots} from "../../helper/general";
import CurrencyInput from "react-currency-input-field";

export default function PriceField({onChange , defaultValue , className = '' , min = null , disabled = false}){
    return (
            <CurrencyInput
                disabled={disabled}
                className={`input-x !text-left `+className}
                dir={`ltr`}
                placeholder="مقدار را وارد کنید"
                defaultValue={defaultValue}
                /*suffix={" ت"}*/
                decimalsLimit={3}
                min={min}
                onValueChange={(value, name, values) => onChange(value)}
            />
    )
}