import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
} from "chart.js";
import {Line} from 'react-chartjs-2';
import moment from "jalali-moment";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement);

const PriceChart = ({labels, regularPrices, discountedPriced}) => {
    const dates = labels.map(date => moment(date, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD'))
    const data = {
        // labels: ['7 خرداد', '22 مرداد', '14 شهریور', '1 مهر', '12 آبان','22 اسفند'],
        labels: dates,
        datasets: [
            {
                label: 'قیمت',
                data: regularPrices,
                borderColor: 'rgb(132, 84, 255)',
                fill: false,
            },
            {
                label: 'قیمت تخفیف دار',
                data: [, , , , , ],
                borderColor: 'transparent',
                fill: false,
                backgroundColor: 'red',
            },
        ],
        responsive:true,
        rtl:true
    };
    const options = {
        scales: {
            x: {
                ticks: {
                    font: {
                        family: 'IRANYekanXFaNum',
                        size: 12,
                    },
                },

            },
            y: {
                ticks: {
                    font: {
                        family: 'IRANYekanXFaNum',
                        size: 12,
                    },
                    callback: function (value, index, values) {
                        return  value + ' تومان ';
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                titleFont: {
                    family: 'IRANYekanXFaNum',
                },
                bodyFont: {
                    family: 'IRANYekanXFaNum',
                },
                rtl:true,
            },
            legend: {
                labels: {
                    font: {
                        family: 'IRANYekanXFaNum',
                        size: 12,
                    },
                },
                rtl:true
            },

        },
    };
    return (
        <div>
            <Line data={data} options={options} className="priceChart" />
        </div>

    )

}

export default PriceChart;