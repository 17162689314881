import {orderStatusLabel} from "../../../helper/general";
import {useState} from "react";
import ApiRoute from "../../../helper/api";
import {HasAccessComponent} from "../../../helper/permissions";

export default function OrderDefinedReason({order, setUpdateState,status = null,onSuccess = null}){
    const canceled = ["عدم تحویل کالا درب منزل","خرید از سایت های دیگر","عدم ارسال به موقع","خرید از دیجی","سفارش جدید یا اصلاح","عدم حمل رایگان برای پرداخت در محل","عدم داشتن چک صیادی","عدم تطابق اقساط چک با کالا","عدم تائید اعتبار سنجی","اطلاعات غلط محصول","عدم امکان تحویل کالا درب منزل (شهرستان ها)","آدرس نا معلوم و گنگ","تغییر قیمت از طرف تامین کالا","پیش فاکتور","قیمت بالا","عدم تهیه بموقع کالا توسط تامین کننده","پیشامد فوری","عدم تصمیم نهایی", "عدم موجودی کالا", "قیمت اشتباه", "بررسی سایت", "اقساط اعتباری", "اقساط چک", "عدم پاسخ", "عدم حمل رایگان", "کرایه حمل نامتعارف", "عدم پیگیری ویزیتور", "زمان زیاد انتظار بررسی", "پیگیری کند", "منصرف شدن", "عدم اعتماد", "عدم پرداخت در محل", "ناموجودی رنگ دلخواه", "زمان تحویل بالا", "عدم تحویل به موقع کالا", "ارسال کالا اشتباه", "لغو بی دلیل مشتری", "تحویل حضوری از فروشگاه خودمان", "ارور پرداخت انلاین", "نداشتن رمز دوم", "ثبت سفارش اشتباه کالا", "سفارش تکراری", "محدوديت 100 م درگاه","کسری بودجه","خريد حضوري از شهر خود","عدم تفاهم خرید"];
    const refunded = ["مغایرت محصول با مشخصات سایت","عدم پاسخگویی مشتری","آدرس نا معلوم و گنگ","کسری بودجه","عدم ارسال و تحویل به موقع","ایراد ظاهری کالا یا بسته بندی","عدم توافق هزینه های بالابری","عدم تهیه بموقع کالا توسط تامین کننده", "برخورد نامناسب واحد حمل", "کرایه اضافه و هزینه نامتعارف اعلامی", "قیمت بالای محصول", "منصرف شدن بدون دلیل", "درخواست کالای جایگزین", "درخواست محصول قیمت مناسب تر", "برخورد نامناسب کارشناس فروش", "برخورد نامناسب کال سنتر", "عدم پرداخت در محل", " ارسال کالای اشتباه" ,"ایراد فنی دستگاه","جوابگو نبودن زمان ارسال"];
    const [data,setData] = useState(null)
    let options = []

    if (order.status == "canceled" || status == "canceled") {
        options = canceled;
    }
    if (order.status == "refunded" || status == "refunded"){
        options = refunded;
    }


    async function handleSubmit(){
        const res = await ApiRoute.order.action.fn("canceled-refunded",order._id,{
            reason: data,
            status: status !== null ? status : order.status
        })
        if (res?.status_code == 200){
            if (onSuccess !== null){
                onSuccess()
            }
            setUpdateState(Date.now())
        }
    }

    return (
        <div className="form">
            <div className="grid grid-cols-1 lg:sgrid-cols-1 justify-items-stretch mb-5 gap-4">
                <label className="!grid">
                    <span className="text-right mb-3 pr-1">دلیل</span>
                    <select onChange={ (e) => {
                        setData(e.target.value)
                    } } className="!mr-0">
                        <option value="">انتخاب کنید</option>
                        {options.map( (item) => <option selected={item === order.metadata[order.status+'_reason']} value={item}>{item}</option> )}
                    </select>
                </label>
                <HasAccessComponent permission={`reason_canceled_refunded order`} children={
                    <div>
                        <button disabled={data === null || data === ""} onClick={handleSubmit} className={`btn-green py-1 rounded`}>ثبت و دخیره</button>
                    </div>
                }/>
            </div>
        </div>
    )
}