import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useState} from "react";

function SearchBox({SetSearchQuery = null, SearchByOptions = null , SearchIn = false , SearchByDefault = "" , widthFull = false}) {
    const [searchTerm, setSearchTerm] = useState('')
    const [searchBy, setSearchBy] = useState('')
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch(event)
        }
    }
    function handleSearch(e){
        if (SetSearchQuery != null){
            SetSearchQuery({
                term : searchTerm,
                by: (searchBy === '' ? SearchByDefault : searchBy)
            })
        }
    }
    return (
            <div className={(widthFull && 'w-full')+` flex items-center border border-gray-7 rounded bg-gray-1`}>
                <input onKeyDown={handleKeyDown} onInput={ (e) => {
                    setSearchTerm(e.target.value)
                } } className='px-3 w-full lg:w-72 flex-auto bg-gray-1' type="text" placeholder="جستجو..."/>
                 {SearchIn===true ?
                    <select onChange={ (e) => {
                        setSearchBy(e.target.value)
                    } } className='ml-2 text-xs text-gray-5 bg-gray-1'>
                        {
                            SearchByOptions ? SearchByOptions.map( (item,index) => (
                            <option key={index} value={item.value}>{item.label}</option>
                            )) : ''
                        }
                    </select>
                    :
                    <>
                    </>
                }

                <button onClick={handleSearch} className='btn-green rounded-l'><FontAwesomeIcon icon={faMagnifyingGlass}/></button>
            </div>
    )
}

export default SearchBox;