import Layout from "../../components/Layout";
import TimeReportTable from "../../components/Tables/TimeReportTable";

function SendDeliveryTimeReport() {
    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span
                    className="inline-block bg-white pl-3">گزارش تحویل حضوری</span>
                </div>
                <div className="lg:flex items-center gap-2 mb-2 text-xs">
                    <span className="max-lg:block max-lg:p-2">انتخاب بازه نمایش</span>
                    <div className="date-time">
                        <span className="px-1 lg:px-5">از</span>
                        <input type="datetime-local"/>
                        <span className="px-1 lg:px-5">تا</span>
                        <input type="datetime-local"/>
                    </div>
                </div>
                <div className="main-content mx-auto p-5">
                    <div className="grid grid-cols-2 lg:grid-cols-6 gap-3 items-end text-xs mb-3 border-b border-b-gray-7">
                        <span className="text-center mb-3">عنوان</span>
                        <span className="lg:col-start-3 text-center mb-3">استان</span>
                    </div>
                    <div className="grid grid-cols-2 lg:grid-cols-6 gap-3 items-end text-xs mb-6 text-gray-3">
                        <span className="text-center mb-3">عنوان بازه ارسالی</span>
                        <span className="lg:col-start-3 text-center mb-3">تهران</span>
                    </div>
                    <TimeReportTable/>
                </div>
                <div className="main-content mx-auto p-5">
                    <div className="grid grid-cols-4 gap-3 items-end text-xs mb-3 border-b border-b-gray-7">
                        <span className="text-center mb-3">عنوان</span>
                        <span className="text-center mb-3">استان</span>
                    </div>
                    <div className="grid grid-cols-4 gap-3 items-end text-xs mb-6 text-gray-3">
                        <span className="text-center mb-3">عنوان</span>
                        <span className="text-center mb-3">البرز</span>
                    </div>
                    <TimeReportTable/>
                </div>
                <div className="main-content mx-auto p-5">
                    <div className="grid grid-cols-4 gap-3 items-end text-xs mb-3 border-b border-b-gray-7">
                        <span className="text-center mb-3">عنوان</span>
                        <span className="text-center mb-3">استان</span>
                    </div>
                    <div className="grid grid-cols-4 gap-3 items-end text-xs mb-6 text-gray-3">
                        <span className="text-center mb-3">مابقی</span>
                        <span className="col-span-3 text-center mb-3">چهار محال بختیاری،چهار محال بختیاری،چهار محال بختیاری،چهار محال بختیاری،چهار محال بختیاری،چهار محال بختیاری،چهار محال بختیاری،چهار محال بختیاری،چهار محال بختیاری،چهار محال بختیاری،چهار محال بختیاری،</span>
                    </div>
                    <TimeReportTable/>
                </div>


            </div>
        </Layout>
    )
}

export default SendDeliveryTimeReport