import {Link, useParams} from "react-router-dom";
import SelectCoordinates from "../../../components/Coordinates/SelectCoordinates";
import Layout from "../../../components/Layout";
import {useEffect, useState} from "react";
import ApiRoute from "../../../helper/api";
import Swal from "sweetalert2";

export default function DepositWallet(){
    const {customer_id} = useParams()
    const [isLoading , setIsLoading] = useState(true)
    const [data, setData] = useState({})
    const [updateData, setUpdateData] = useState(0)

    const [deposit , setDeposit] = useState({
        amount : 0,
        type:'PaymentGatewayWalletShopCredit',
        description:''
    })
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const res  = await ApiRoute.customer.single.fn(customer_id)
            setData(res.results)
            setIsLoading(false)
        })();
    },[updateData]);

    async function makeTransaction(){
        setIsLoading(true)

        try {
            await ApiRoute.customer.depositWallet.fn(deposit.type, customer_id , deposit)
        }catch (error){
            setIsLoading(false)
            return Swal.fire({
                title: "خطا",
                icon: "error",
                text: error,
                confirmButtonText: "باشه"
            });
        }

        Swal.fire({
            title: "تراکنش انجام شد",
            icon: "success",
            confirmButtonText: "باشه"
        });
        setIsLoading(false)
    }
    return (
        <Layout>
            <div>

                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">شارژ کیف پول</span></div>

                        { !isLoading ? (
                            <div className="main-content xl:w-3/4 mx-auto p-6 lg:p-8 mt-12">
                                <div className="form 2xl:w-3/5 mx-auto">

                                    <div className="grid grid-cols-2 lg:flex text-[#000] justify-center pt-4 pb-7 xl:pb-12 items-center gap-4 xl:gap-10 text-16">
                                        <Link className="text-purple-1" to={`/customers/view/`+customer_id}><span>برگشت به کاربر</span></Link>
                                        <span className="max-lg:col-span-2">{data.personal.first_name} {data.personal.last_name} به شماره همراه  {data.contacts.cellphone}</span>

                                    </div>

                                    <div className="grid sm:grid-cols-2 xl:grid-cols-2 justify-items-stretch mb-10 gap-5 lg:gap-10">
                                    <div><label className="!grid">
                                        <span className="text-right mb-3 pr-1">مقدار به تومان</span>
                                        <input className="!flex-none !mr-0"
                                               defaultValue={deposit.amount}
                                               onChange={ e => {
                                                   deposit.amount = e.target.value
                                                   setDeposit(deposit)
                                               } }
                                               type="text" placeholder="نام را وارد کنید"/>
                                    </label></div>
                                    <div><label className="!grid">
                                        <span className="text-right mb-3 pr-1">نوع</span>
                                        <select defaultValue={deposit.type}  onChange={ e => {
                                            deposit.type = e.target.value
                                            setDeposit(deposit)
                                        } }>
                                            <option value="PaymentGatewayWalletGiftCredit">هدیه</option>
                                            <option value="PaymentGatewayWalletShopCredit">واریز فروشگاه</option>
                                        </select>
                                    </label></div>
                                    {/*<div><label className="!grid">
                                        <span className="text-right mb-3 pr-1">توضیحات</span>
                                        <input className="!flex-none !mr-0"
                                               defaultValue={deposit.description}
                                               onChange={ e => {
                                                   deposit.description = e.target.value
                                                   setDeposit(deposit)
                                               } }
                                               type="text" placeholder="توضیحات را وارد کنید"/>
                                    </label></div>*/}

                                </div>

                                </div>
                            </div>
                        ) : '' }

                { !isLoading ? (
                        <div className="flex justify-center my-8 lg:mt-16">
                            <button
                                disabled={isLoading}
                                onClick={ e => {
                                    makeTransaction()
                                }}
                                type="button" className="btn-purple w-50">ایجاد تراکنش</button>
                        </div>) : '' }


            </div>
        </Layout>
    )
}