import EmployeeSelect from "../../../Common/EmployeeSelect";
import {HasAccessComponent} from "../../../../helper/permissions";
import {useState} from "react";
import ApiRoute from "../../../../helper/api";

export default function OrderVisitor({order, setUpdateState}){
    const [emp,setEmp] = useState(null)
    async function handleUpload(){
        console.log(emp+" | "+order._id)
        const res = await ApiRoute.order.updateOperator.fn(order._id,emp)
        if (res?.status_code === 200){
            setUpdateState(Date.now())
        }
    }
    function Button(){
        return <button onClick={handleUpload} type="submit"
                       className="btn  mt-3 btn-green text-13 py-1 w-16 rounded p-2">ویرایش
        </button>
    }
    return (
        <div
            className="flex-col justify-between items-center pb-3 border-b border-b-gray-7 mt-4">
            <span>ویزیتور ({order?.operator?.name})</span>
            <EmployeeSelect asRole="order_operator" customDefaultValue={true} multiple={false} assignItem={ (s) => {
                s.map( employee => {
                    setEmp(employee._id)
                } )
            } }/>
            { !order.is_transference && <HasAccessComponent permission={`change_operator order`} children={<Button/>}/> }
            { order.is_transference && <HasAccessComponent permission={`change_operator_after_transference order`} children={<Button/>}/> }
        </div>
    )
}