import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faEye, faTrash} from "@fortawesome/free-solid-svg-icons";
import SwitchToggle from "../../components/Utilities/SwitchToggle";
import {Link} from "react-router-dom";
import {useState} from "react";
import { v4 as uuidv4 } from 'uuid';
import ApiRoute from "../../helper/api";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

function TimeTable({deliveryItem}) {
    const MySwal = withReactContent(Swal)
    const [update,setUpdate] = useState(0);
    const weekData = [
        {title: 'شنبه'},
        {title: 'یکشنبه'},
        {title: 'دوشنبه'},
        {title: 'سه شنبه'},
        {title: 'چهارشنبه'},
        {title: 'پنج شنبه'},
        {title: 'جمعه'},
    ]
    const [isToggled, setToggled] = useState(null);
    const toggleSubgroup = (id) => setToggled(id);

    function addPick(item){
        item.picks.push({
            uid:uuidv4(),
            start:12,
            end:16,
            order_capacity:10
        })
        setUpdate(Date.now())
    }

    function deletePick(item,index){
        item.picks.splice(index,1)
        setUpdate(Date.now())
    }

    async function showPickStatus(pick){
        const res = await ApiRoute.shipment.delivery.getDeliveryPickCapacity.fn(pick.uid);
        const used = res?.results ?? 0
        const cap = pick.order_capacity
        if (cap === 0){
            MySwal.fire({
                title: 'پیک غیر فعال است',
                cancelButtonText: `بستن`,
                confirmButtonText: "متوجه شدم",
                showCancelButton: false,
            })
            return
        }
        let active = true;
        if (used >= cap){
            active = false;
        }
        MySwal.fire({
            title: active ? 'پیک فعال است' : 'پیک غیر فعال است',
            html: `تعداد پیک استفاده شده : ${used}
<br>
تعداد پیک تعیین شده : ${cap}`,
            cancelButtonText: `بستن`,
            confirmButtonText: "متوجه شدم",
            showCancelButton: false,
        })
    }

    return (
        <div className="border border-blue-2 rounded-md p-3">
            {/*<div className="border-b border-b-gray-7 pb-3 mb-3 px-10 text-sm">عنوان</div>*/}
            {deliveryItem.periods && deliveryItem.periods.map((item, id) =>
                <div key={id}>
                    <div
                        className="grid grid-cols-2 text-xs items-center rounded bg-gray-7 py-1.5 mb-1.5 cursor-pointer"
                        onClick={() => toggleSubgroup(isToggled !== id ? id : null)}>
                        <div className="px-5 py-2 text-right">{item.day_label}</div>
                        <div className="px-3 flex items-center justify-end">
                            <button onClick={ () => {
                                deliveryItem.periods.splice(id,1)
                                setUpdate(Date.now())
                            } } title="حذف"><FontAwesomeIcon
                                className="bg-red-1 text-white p-1 w-4 h-4 ml-5 rounded" icon={faTrash}/></button>
                        </div>
                    </div>
                    <div
                        className={`lg:px-5 sub-groups text-xs overflow-hidden  ${isToggled === id ? 'show py-2' : ''}`}>
                        <div
                            className="inner-bordered-box !border-0 w-full text-xs lg:px-4 max-lg:!pt-0">
                            <table className="table-auto tr-orange-border w-full block sm:table px-2 mx-auto">
                                <thead>
                                <tr>
                                    <td>شروع بازه</td>
                                    <td>پایان بازه</td>
                                    <td>تعداد پیک تحویل</td>
                                    <td></td>
                                </tr>
                                </thead>
                                <tbody>

                                {item.picks && item.picks.map( (pick,pick_index) => <tr key={pick_index}>
                                    <td className="!px-3"><input onKeyUp={ (e) => pick.start = parseInt(e.target.value) } className="bordered-input text-center w-full xl:w-48" type="number"
                                                                 defaultValue={pick.start}/></td>
                                    <td className="!px-3"><input onKeyUp={ (e) => pick.end = parseInt(e.target.value) } className="bordered-input text-center w-full xl:w-48" type="number"
                                                                 defaultValue={pick.end}/></td>
                                    <td className="!px-3"><input onKeyUp={ (e) => pick.order_capacity = parseInt(e.target.value) } className="bordered-input text-center w-full xl:w-48" type="number"
                                                                 defaultValue={pick.order_capacity}/></td>
                                    <td>
                                        <div className="flex items-center gap-3 justify-end py-1">
                                            {/*<SwitchToggle status={true} onOff={true}/>*/}
                                            <button onClick={() => {
                                                showPickStatus(pick)
                                            }} className={`btn-gray text-13 py-1 flex items-center gap-1`}>
                                                <span>وضعیت بازه</span>
                                                <FontAwesomeIcon icon={faEye}/>
                                            </button>
                                            <button className={`flex items-center`} onClick={ () => {
                                                deletePick(item,pick_index)
                                            } } title="حذف"><FontAwesomeIcon
                                                className="bg-red-1 text-white p-1 w-4 h-4 ml-5 rounded" icon={faTrash}/></button>
                                        </div>
                                    </td>
                                </tr>)}

                                </tbody>
                            </table>

                            <div className="flex justify-center">
                                <button className="btn-purple py-2 w-36 mt-1" onClick={() => {
                                    addPick(item)
                                }}>افزودن بازه جدید</button>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TimeTable