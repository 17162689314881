
import {useState} from "react";
import ApiRoute from "../../helper/api";
import Modal from "../Utilities/Modal";
import BrandSelect from "../Product/Category/BrandSelect";
import CategorySelect from "../Product/Category/CategorySelect";

export default function CreatePriceRuleModal({closeModal, ruleId, event}){

    const [text, setText] = useState('')
    const [cats, setCats] = useState([])
    async function handleSubmit(){
        const res = await ApiRoute.price.rule.create.fn({
            title : text,
            categories: cats
        })
        try {
            event(res)
        }catch (e) {
            
        }
    }
    return (
        <Modal isOpen={true} onClose={closeModal}>
            <div>

                <div className="page-title z-1 text-right text-sm">
                    <span className="inline-block bg-white pl-3 pr-1 mb-3 relative z-2">ایجاد قانون</span>
                </div>

                <div className={`flex gap-2 w-full flex-col`}>

                    <input onKeyUp={ (e) => { setText(e.target.value) } } className='px-3 py-2 border border-[#dfdfdf] w-full rounded-[8px] shadow flex-auto bg-white' type="text" placeholder="عنوان را بنویسد ..."/>
                    <CategorySelect fixed={true} assignItem={ (v) => {
                       let arr = []
                        v.map( (x) => {
                            arr.push(x.slug)
                        } )
                        setCats(arr)
                    } }/>
                    <button onClick={handleSubmit} className={`btn-green w-24 rounded`}>ایجاد</button>

                </div>

            </div>
        </Modal>
    )
}