import React from "react";
import {connect} from "react-redux";

function ContentWrapper({children, sidebarToggle}){
    return(
        <div className={`content-wrapper mt-[60px] pt-4 px-3 pb-[200px] w-full  dir-rtl left-0 scrollbar-style max-lg:px-4 ${sidebarToggle? 'lg:mr-[255px]':'lg:mr-[55px]'}`}
        >
            {children}
        </div>
    )
}
const mapStateToProps = state => ({
    sidebarToggle: state.toggle,
});

export default connect(mapStateToProps)(ContentWrapper);