import ProductSelect from "../../../Product/ProductSelect";
import Modal from "../../../Utilities/Modal";
import React, {useState} from "react";
import ApiRoute from "../../../../helper/api";
import CouponSelect from "../../../Common/CouponSelect";
import Toman from "../../../Utilities/Toman";
import {DatePicker} from "zaman";
import moment from "jalali-moment";
import {faNumberToEn} from "../../../../helper/general";
import PriceField from "../../../Common/PriceField";
import {hasAccess} from "../../../../helper/permissions";
import TimePicker from 'react-time-picker'
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

export default function OrderItemAdditionalRentModal({order, setUpdateState , closeModal}){

    const [sending, setSending] = useState(false)
    const [data , setData] = useState({
        name : 'کرایه مازاد',
        amount : ''
    })

    async function handleSubmit(){
        setSending(true)
        const res = await ApiRoute.order.item.addAdditionalRent.fn(order._id , data)
        if (res?.status_code === 200){
            setUpdateState(Date.now())
            closeModal()
        }
        setSending(false)
    }

    return (
        <Modal isOpen={true} onClose={closeModal}>
            <div className="page-title mb-5"><span
                className="inline-block bg-white pl-3 text-sm text-purple-1">کرایه مازاد</span>
            </div>
            <div className="form">
                <div className="grid grid-cols-2 lg:grid-cols-2 justify-items-stretch mb-5 gap-4">
                    <label className="!grid">
                        <span className="text-right mb-3 pr-1">نام</span>
                        <input defaultValue={data.name} onKeyUp={ e => {
                            data.name = e.target.value
                        } } type="text" className="!mr-0 w-full"/>
                    </label>
                    
                    <label className="!grid">
                                    <span className="flex items-center mb-3">
                                    <span className="text-right pr-1">مبلغ</span>
                                    </span>
                        <PriceField className={`!mr-0`} onChange={ (v) => {
                            data.amount = parseInt(v)
                        } }/>
                    </label>
                </div>
                <div className="grid grid-cols-4 items-end gap-4">

                    <div className="col-span-1">
                        <button disabled={sending} onClick={handleSubmit} className="btn-purple py-2 w-full">افزودن</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}