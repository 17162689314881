import Layout from "../../components/Layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../components/Pagination";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../helper/api";
import SwitchToggle from "../../components/Utilities/SwitchToggle";
import ShopSelect from "../../components/Shop/ShopSelect";

export default function LogicoSetting() {
    const navigate = useNavigate()
    const [createModal, setCreateModal] = useState(false)
    const [newData, setNewData] = useState({
        name: '',
        phone:'',
        send_customer_info:false,
        shipping_in_person:false,
        logico_id:'',
        shop_id:''
    })

    const [updateData, setUpdateData] = useState('0')
    const [update, setUpdate] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            const  res  = await ApiRoute.shipment.logico.index.fn({
                shop_id: shopId(),
                quick_search : searchQuery,
                page : pageNumber
            },filterQuery)
            setData(res)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    async function handleUpdate(item){
        await ApiRoute.shipment.logico.update.fn(item._id, item)
    }

    async function handleCreate(){
        await ApiRoute.shipment.logico.create.fn(newData)
        setUpdateData(Date.now().toString())
    }

    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span
                    className="inline-block bg-white pl-3">راننده ها</span>
                </div>
                <div className="text-xs text-purple-1 mt-6 mb-3 px-2">اضافه کردن راننده</div>
                <div className="main-content mx-auto p-5">
                    <div
                        className="flex items-center text-13 flex-wrap gap-5">
                        <div className={`md:w-[200px] w-full`}><label>
                            نام راننده
                            <input className="w-full input-x" onKeyUp={ (e) => {
                                newData.name = e.target.value;
                            } } type="text"/>
                        </label></div>
                        <div className={`md:w-[200px] w-full`}>
                            <label>فروشگاه</label>
                            <ShopSelect absoluteSelectedStyle={true} multiple={false} assignItem={ (s) => {
                                s.map( (a) => {
                                    newData.shop_id = a._id
                                } )
                            } }/>
                        </div>
                        <div className={`md:w-[200px] w-full`}><label>
                            شماره تماس راننده
                            <input className="w-full input-x" onKeyUp={ (e) => {
                                newData.phone = e.target.value;
                            } } type="text"/>
                        </label></div>
                        <div className={`md:w-[200px] w-full`}><label>
                            شناسه ( ای دی )
                            <input className="w-full input-x text-center" onKeyUp={ (e) => {
                                newData.logico_id = e.target.value;
                            } } type="text"/>
                        </label></div>
                        <div className={`md:w-[200px] pt-[15px] flex items-center gap-2 w-full`}><label>
                            راننده حضوری</label>
                            <SwitchToggle onSwitch={ async (c) => {
                                newData.shipping_in_person = c;
                            } } onOff={newData.shipping_in_person}/>
                        </div>
                        <div className="sm:col-start-2 xl:col-start-3 text-left">
                            <button onClick={handleCreate} className="btn-purple w-32 xl:w-full py-2">افزودن</button>
                        </div>
                    </div>

                    <div className="text-xs text-purple-1 mt-8 mb-3 px-2">لیست رانندگان </div>
                    <div className="border border-blue-1 p-3 rounded-md">
                        {data && <table className="table-borderd-tr table-auto table-td-150 w-full mt-2 mx-auto block xl:table">
                            <thead>
                            <tr className="text-xs">
                                <td>نام راننده</td>
                                <td>شماره تماس راننده</td>
                                <td className="w-[12%]">شناسه لوجیکو ( ای دی )</td>
                                <td className="w-[12%]">فروشگاه</td>
                                <td className="w-[13%]">ارسال اطلاعات مشتری</td>
                                <td className="w-[13%]">راننده حضوری</td>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody>

                                {tableData.map( (item,index) => <tr key={item._id}
                                    className="form grid-form text-xs border border-gray-7 rounded-md p-1">
                                    <td className="pr-1 pl-5"><input onKeyUp={ (e) => {
                                        item.name = e.target.value;
                                    } } className="w-full !mt-0" type="text" defaultValue={item.name}/></td>
                                    <td className="pl-5"><input onKeyUp={ (e) => {
                                        item.phone = e.target.value;
                                    } } className="w-full !mt-0 text-left" dir={`ltr`} type="text" defaultValue={item.phone}/></td>
                                    <td className="pl-5"><input onKeyUp={ (e) => {
                                        item.logico_id = e.target.value;
                                    } } className="w-full !mt-0 text-left" dir={`ltr`} type="text" defaultValue={item.logico_id}/></td>
                                    <td className="pl-5">
                                        <ShopSelect multiple={false} customDefaultValue={true} defaultValue={item.shop ? [item.shop] : []} assignItem={ (s) => {
                                            s.map( (a) => {
                                                item.shop_id = a._id
                                            } )
                                        } }/>
                                    </td>
                                    <td className="pl-5">
                                        <SwitchToggle onSwitch={ async (c) => {
                                            item.send_customer_info = c
                                        } } onOff={item.send_customer_info}/>
                                    </td>
                                    <td className="pl-5">
                                        <SwitchToggle onSwitch={ async (c) => {
                                            item.shipping_in_person = c
                                        } } onOff={item.shipping_in_person}/>
                                    </td>
                                    <td className="flex justify-center items-center pt-2">
                                        <button className="mx-2 leading-3 " onClick={ async () => await handleUpdate(item) } title="؟؟؟"><FontAwesomeIcon
                                            className="bg-blue-2 text-white p-1 w-4 h-4 rounded" icon={faEdit}/></button>

                                        <button className="mx-2" type={`button`} onClick={ () => {
                                            alert('پیاده سازی تمامی حذف ها در مراحل نهایی صورت خواهد گرفت')
                                        } } title="؟؟؟"><FontAwesomeIcon
                                            className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                                    </td>
                                </tr> )}

                            </tbody>
                        </table>}
                    </div>
                    { data && <Pagination Data={paginationData} SetPageNumber={setPageNumber}/>}
                </div>
            </div>
        </Layout>
    )
}