import Layout from "../../components/Layout";
import SearchBox from "../../components/SearchBox";
import {Link} from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useEffect, useRef, useState} from "react";
import ApiRoute from "../../helper/api";

function AccessEdit() {
    const MySwal = withReactContent(Swal)
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [searchTerm, setSearchTerm] = useState('');
    const [update, setUpdate] = useState(0);
    const [data, setData] = useState(null);
    const [role, setRole] = useState(null);
    const [permissionHold, setPermissionHold] = useState(null);
    const [permission, setPermission] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);

    const [editData,setEditData] = useState({});

    const presRef = useRef(null)

    useEffect(() => {
        getData()
        getRoles()
        getPermissions()
    }, []);

    useEffect(() => {
        if (data){
            setEditData({
                name : data.name,
                label : data.label,
                permissions : data.permissions
            })
        }

    }, [data]);


    async function getData(){
        const response = await ApiRoute.employee.role.single.fn(params.get('id'))
        setData(response.results)
    }

    async function getRoles(){
        const response = await ApiRoute.employee.role.index.fn()
        setRole(response.results.data)
    }

    async function getPermissions(){
        const response = await ApiRoute.employee.permission.getGroupByTitle.fn()
        setPermissionHold(response.results)
        setPermission(response.results)
    }

    async function handleSubmit() {
        await ApiRoute.employee.role.update.fn(data._id,editData)
    }

    function hasPermission(name){
        let permissions = editData.permissions
        let r = false
        permissions.map( (permission) => {
            if (permission.name === name){
                r = true
            }
        })
        return r
    }
    return (
        <Layout>
            {data && <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">ویرایش دسترسی</span>
                </div>

                <div className="border border-gray-7 rounded-md mx-auto p-4 text-xs mt-4">
                    <div className="grid gap-3 lg:flex justify-between items-center border-b border-b-gray-7 pb-3">
                        <span className="text-13">
                            دسترسی:
                        <span className="text-gray-3 mr-2">{editData.label}</span>
                        </span>
                        <div className="flex items-center">
                            <SearchBox SetSearchQuery={ (c) => {
                                setSearchTerm(c.term)
                            }}/>
                        </div>
                    </div>


                    { permission && Object.keys(permission).map((per,i) => {
                        return (
                            <div key={per}>
                                <div className="page-title my-3 text-purple-1"><span
                                    className="inline-block bg-white pl-3">{per}</span>
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 mb-10 gap-3">

                                    { permission[per].map( (item,index) => {
                                        if ( searchTerm !== '' && !item.label.includes(searchTerm) ){ return ''; }
                                        return (
                                            <div key={index} className="border border-gray-7 rounded flex justify-between py-1 px-1.5">
                                                <label className="checkbox-wrapper">
                                                    <span>{item.label}</span>
                                                    <input
                                                        onChange={ (e) => {
                                                            if (e.target.checked){
                                                                let found = false;
                                                                editData.permissions.map( (_item,_index) => {
                                                                    if ( _item.name === item.name ){
                                                                        found = true
                                                                    }
                                                                })
                                                                if (!found){
                                                                    editData.permissions.push(item)
                                                                }
                                                            }else{
                                                                editData.permissions.map( (_item,_index) => {
                                                                    if ( _item.name === item.name ){
                                                                        editData.permissions.splice(_index,1)
                                                                    }
                                                                })
                                                            }

                                                            setEditData(editData)
                                                        } }
                                                        defaultChecked={hasPermission(item.name)}
                                                        type="checkbox"/>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        )
                                    }) }

                                </div>
                            </div>
                        )
                    }) }


                    <div className="col-span-4 flex justify-center mx-auto gap-5 mt-4">
                        <Link to="/employees/access" className="btn-gray w-28 py-1.5">لغو</Link>
                        <button onClick={handleSubmit} className="btn-purple rounded w-28 py-1.5">ویرایش</button>
                    </div>

                </div>
            </div>}
        </Layout>
    )
}

export default AccessEdit