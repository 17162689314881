import ImageUploader from "../../Utilities/ImageUploader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faChevronDown, faChevronUp, faEyeSlash, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import FileUpload from "../../FileUpload";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import ComponentTitle from "./Common/ComponentTitle";

export default function ImageSlider({component , remove , up, down}) {
    const [updateState, setUpdateState] = useState(1)
    const [open, setOpen] = useState(false)

    function sync() {
        setUpdateState(Date.now())
    }

    function lastOrder() {
        let lastItem = null;
        component.data.sort((firstItem, secondItem) => firstItem.order - secondItem.order).map(item => {
            lastItem = item
            return
        })
        if (lastItem?.order !== undefined && lastItem?.order !== null) {
            return lastItem.order + 1;
        }
        return 0
    }

    function addData() {
        component.data.push({
            'id': Date.now().toString(),
            'order': lastOrder(),
            'alt': 'تست ...',
            'url': 'https://#',
            'image': null,
            'image_sm': null
        })
        sync()
    }

    function removeFormData(data, index) {
        component.data.splice(index, 1)
        sync()
    }

    function moveData(data, index, action = 'up') {
        let inx = index - 1
        if (action != 'up') {
            inx = index + 1
        }

        if (index == 0 && action == 'up') {
            return;
        }

        let el = data.order;
        data.order = component.data[inx].order;

        component.data.map((item, i) => {
            if (i == (inx)) {
                item.order = el
            }
        })

        sync()
    }


    return (
        <div className="flex my-3 flex-auto">

            <div className="w-full border border-gray-7 rounded p-1 grid gap-2">

                <ComponentTitle component={component} title={"اسلایدر"} open={open} setOpen={ setOpen } remove={remove} up={up} down={down}/>

                {open && <div>
                    {component.data.sort((firstItem, secondItem) => firstItem.order - secondItem.order).map((data, index) =>
                        <div key={data.id} className="border border-gray-7 p-3 rounded">
                            <div className="grid lg:grid-cols-9 gap-4 lg:gap-10 items-center text-xs">

                                <div className="lg:col-span-5 grid grid-cols-1 lg:grid-cols-2 gap-4">
                                    <div>
                                        <FileUpload defaultFile={data.image && [
                                            {
                                                source: data.image,
                                                options: {
                                                    type: 'local',
                                                },
                                            },
                                        ]} setCover={(v) => {
                                            data.image = v
                                            sync()
                                        }}/>
                                        <span className="text-xs block pt-3 text-center">اسلایدر دسکتاپ</span>
                                    </div>
                                    <div>
                                        <FileUpload defaultFile={data.image_sm && [
                                            {
                                                source: data.image_sm,
                                                options: {
                                                    type: 'local',
                                                },
                                            },
                                        ]} setCover={(v) => {
                                            data.image_sm = v
                                            sync()
                                        }}/>
                                        <span className="text-xs block pt-3 text-center">اسلایدر موبایل</span>
                                    </div>

                                </div>

                                <div className="lg:col-span-3 grid grid-rows-2 gap-8 lg:pb-6">
                                    <label className="!flex flex-auto items-center gap-3">
                                        <span>عنوان اسلایدر</span>
                                        <input onKeyUp={e => {
                                            data.alt = e.target.value
                                        }} defaultValue={data.alt} className="bordered-input flex flex-auto"
                                               type="text"/>
                                    </label>
                                    <label className="!flex flex-auto items-center gap-3">
                                        <span>لینک اسلایدر</span>
                                        <input onKeyUp={e => {
                                            data.url = e.target.value
                                        }} defaultValue={data.url} className="bordered-input flex flex-auto"
                                               type="text"/>
                                    </label>
                                </div>

                                <div className="flex justify-center lg:grid lg:grid-cols-1 gap-1 text-left">


                                    <button className="mx-1" onClick={() => removeFormData(data, index)} title="؟؟؟">
                                        <FontAwesomeIcon
                                            className="bg-red-1 text-white py-1 px-1.5 w-4 h-4 rounded" icon={faTrash}/>
                                    </button>

                                    <button onClick={() => {
                                        moveData(data, index)
                                    }} className="mx-1" title="؟؟؟"><FontAwesomeIcon
                                        className="bg-blue-1 text-white py-1 px-1.5 w-4 h-4 rounded"
                                        icon={faChevronUp}/></button>
                                    <button onClick={() => {
                                        moveData(data, index, 'down')
                                    }} className="mx-1" title="؟؟؟"><FontAwesomeIcon
                                        className="bg-blue-1 text-white py-1 px-1.5 w-4 h-4 rounded"
                                        icon={faChevronDown}/></button>

                                </div>

                            </div>
                        </div>)}

                    <select onChange={ (e) => {
                        component.layout = e.target.value
                        sync()
                    } } className={`input-x`}>
                        <option selected={component.layout == 'col-1'} value={`col-1`}>یک از دوازده</option>
                        <option selected={component.layout == 'col-2'} value={`col-2`}>دو از دوازده</option>
                        <option selected={component.layout == 'col-3'} value={`col-3`}>سه از دوازده</option>
                        <option selected={component.layout == 'col-4'} value={`col-4`}>چهار از دوازده</option>
                        <option selected={component.layout == 'col-5'} value={`col-5`}>پنج از دوازده</option>
                        <option selected={component.layout == 'col-6'} value={`col-6`}>نصف صفحه</option>
                        <option selected={component.layout == 'col-7'} value={`col-7`}>هفت از دوازده</option>
                        <option selected={component.layout == 'col-8'} value={`col-8`}>هشت از دوازده</option>
                        <option selected={component.layout == 'col-9'} value={`col-9`}>نه از دوازده</option>
                        <option selected={component.layout == 'col-10'} value={`col-10`}>ده از دوازده</option>
                        <option selected={component.layout == 'col-11'} value={`col-11`}>یازده از دوازده</option>
                        <option selected={component.layout == 'col-12'} value={`col-12`}>تمام صفحه</option>
                    </select>

                    <button onClick={addData} className="btn-blue-light text-xs mx-auto my-4">افزودن اسلایدر جدید</button>
                </div>}

            </div>
        </div>
    )
}