import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../helper/api";
import {Link} from "react-router-dom";
import SearchBox from "../SearchBox";
import FilterOrder from "./FilterOrder";
import OrderFilterTab from "./OrderFilterTab";
import OrderTable from "../Tables/OrderTable";
import Pagination from "../Pagination";
import OrderCustomStateFilterTab from "./OrderCustomStateFilterTab";


export default function OrderList({asStore = false}){
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'cellphone'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering

    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')
    const [selectedCustomStatsTab , setSelectedCustomStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            let objparams = {
                shop_id: shopId(),
                is_preorder:0,
                state:selectedStatsTab,
                status:selectedStatsTab == 'all' ? '' : selectedStatsTab,
                custom_status:selectedCustomStatsTab == 'all' ? '' : selectedCustomStatsTab,
                page : pageNumber,
                product_id: params.get('product_id') ?? '',
                customer_id: params.get('customer_id') ?? '',
                non_primary_shop:asStore ? 1 : 0,
            }
            if (asStore){
                objparams.shop_id = '';
            }
            const  res  = await ApiRoute.order.index.fn(objparams,filterQuery)
            setData(res)
            setIsLoading(false)
        })();
    }, [updateData]);

    // watch searchQuery and update updateData state
    useEffect(() => {
        filterQuery.quick_search = searchQuery;
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    //fetch stats-data from server
    useEffect(() => {
        (async () => {
            let objparams = {
                shop_id: shopId(),
                non_primary_shop:asStore ? 1 : 0,
                quick_search : searchQuery
            }
            if (asStore){
                objparams.shop_id = '';
            }
            const  res  = await ApiRoute.order.stats.fn(objparams,filterQuery)
            setStatsTab(res.results)
        })();
    }, [data]);

    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    // watch filterQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [filterQuery]);

    // watch statsTab and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [selectedStatsTab]);

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);


    const paginationData = data?.results
    const tableData = data?.results?.data ?? []



    return (
        <div>
            <div>
                <div className="flex items-center justify-between mb-3">
                    <div className="page-title flex-auto ml-3 md:ml-0">
                        {!asStore && <span className="inline-block bg-white pl-3">سفارشات</span>}
                        {asStore && <span className="inline-block bg-white pl-3">سفارشات فروشگاه ها</span>}
                    </div>
                    {!asStore && <Link onClick={() => {
                        sessionStorage.removeItem('new_order')
                    }} className="btn-blue text-center md:hidden" to="/orders/create"> افزودن سفارش
                        جدید</Link>}
                </div>

                {params.get('customer_id') && <div className="flex items-center justify-between mb-6">
                    <div className="page-title flex-auto ml-3 md:ml-0">
                        <span className="inline-block bg-white text-red-1 font-semibold pl-3">سفارشات مشتری به شناسه <Link className="text-blue-1 font-semibold" to={`/customers/view/${params.get('customer_id')}`}>{params.get('customer_id')}</Link>
                        <Link className="btn-blue text-center py-1 mr-3 px-1 w-auto" to={`/orders`}>حذف فیلتر</Link>
                        </span>
                        
                    </div>
                </div>}

                {params.get('product_id') && <div className="flex items-center justify-between mb-6">
                    <div className="page-title flex-auto ml-3 md:ml-0">
                        <span className="inline-block bg-white text-red-1 font-semibold pl-3">سفارشات محصول  <Link className="text-blue-1 font-semibold" to={`/products/edit?product_id=${params.get('product_id')}`}>{params.get('product_title')}</Link>
                        <Link className="btn-blue text-center py-1 mr-3 px-1 w-auto" to={`/orders`}>حذف فیلتر</Link>
                        </span>
                        
                    </div>
                </div>}

                <div className="grid md:flex justify-between items-center gap-3">
                    <div className="flex gap-3">
                        <SearchBox
                            SearchIn={true}
                            SetSearchQuery={setSearchQuery}
                            SearchByDefault={`order_key`}
                            SearchByOptions={[
                                {
                                    label: 'شماره سفارش',
                                    value: 'order_key'
                                },
                                {
                                    label: 'شماره همراه',
                                    value: 'cellphone'
                                },
                                {
                                    label: 'کد ملی',
                                    value: 'national_code'
                                },
                                {
                                    label: 'نام و نام خانوادگی',
                                    value: 'customer_name'
                                },
                                {
                                    label: 'شماره حواله',
                                    value: 'transference_number'
                                },
                                {
                                    label: 'نام شرکت',
                                    value: 'official_org_name'
                                },
                                {
                                    label: 'شناسه ملی',
                                    value: 'official_national_number'
                                },
                                {
                                    label: 'شناسه ثبت',
                                    value: 'official_register_number'
                                }
                            ]}/>
                    </div>
                    {!asStore && <Link onClick={ () => {
                        sessionStorage.removeItem('new_order')
                    } } className="btn-blue text-center hidden md:block" to="/orders/create">افزودن سفارش
                        جدید</Link>}
                </div>

                <FilterOrder asStore={asStore}
                SetFilterQuery={ (s) => {
                    setFilterQuery(s)
                    setUpdateData(Date.now().toString())
                } }/>

                <div className="main-content p-1 mt-3">
                    <OrderFilterTab
                        selectedTab={selectedStatsTab}
                        setSelectedTab={setSelectedStatsTab}
                        options={statsTab}
                    />
                    {/*<OrderCustomStateFilterTab
                        selectedTab={selectedCustomStatsTab}
                        setSelectedTab={setSelectedCustomStatsTab}
                        options={statsTab}
                    />*/}
                    {/*<Filter filters={filters2}/>*/}
                    <OrderTable
                        asStore={asStore}
                        onSort={ (name , dir) => {
                            filterQuery.auto_sort = []
                            filterQuery.auto_sort.push({
                                name: name,
                                value: dir
                            })
                            console.log(filterQuery)
                            setUpdateData(Date.now().toString())

                        } }
                        setUpdateData={setUpdateData} tableData={tableData}/>
                    <Pagination SetPageNumber={setPageNumber} Data={paginationData}/>
                </div>
            </div>
        </div>
    );
}