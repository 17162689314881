import Layout from "../../components/Layout";
import SearchBox from "../../components/SearchBox";
import {Link} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import ApiRoute from "../../helper/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/free-solid-svg-icons/faRefresh";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import useToken from "../../useToken";
import {updateVersion} from "../../helper/general";

function EmployeeEdit() {
    const { token, resetToken, setToken } = useToken();
    const MySwal = withReactContent(Swal)
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [searchTerm, setSearchTerm] = useState('');
    const [update, setUpdate] = useState(0);
    const [data, setData] = useState(null);
    const [role, setRole] = useState(null);
    const [permissionHold, setPermissionHold] = useState(null);
    const [permission, setPermission] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);

    const [editData,setEditData] = useState({});

    const presRef = useRef(null)

    useEffect(() => {
        getData()
        getRoles()
        getPermissions()
    }, []);

    useEffect(() => {
        if (data){
            setEditData({
                username : data.username,
                asan_id : data.asan_id,
                name : data.name,
                role : data.role,
                permissions : data.permissions
            })
        }

    }, [data]);


    async function getData(){
        const response = await ApiRoute.employee.single.fn(params.get('id'))
        setData(response.results)
    }

    async function getRoles(){
        const response = await ApiRoute.employee.role.index.fn()
        setRole(response.results.data)
    }

    async function getPermissions(){
        const response = await ApiRoute.employee.permission.getGroupByTitle.fn()
        setPermissionHold(response.results)
        setPermission(response.results)
    }

    async function handleSubmit() {
        const res = await ApiRoute.employee.update.fn(data._id,editData)
        if(res.status_code === 200){
            if (token.employee._id === res.results._id){
                token.employee = res.results;
                setToken(token)
            }
        }
    }

    function hasPermission(name){
        let permissions = editData.permissions
        let r = false
        permissions.map( (permission) => {
            if (permission.name === name){
                r = true
            }
        })
        return r
    }

    return (
        <Layout>
            {data && <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">ویرایش کارمند</span>
                </div>

                <button onClick={ async () => {
                    const res = await ApiRoute.auth.loginWithId.fn(data._id)
                    if (res?.status_code === 200){
                        setToken(res.results)
                        window.location.replace("/")
                        localStorage.setItem("update",updateVersion())
                    }
                } } className="btn-green text-13 mt-1 rounded py-1.5">ورود به عنوان کاربر</button>

                <div className="border border-gray-7 rounded-md mx-auto p-4 grid grid-cols-1 md:grid-cols-3 xl:grid-cols-7 gap-5 text-xs mt-10">
                    <div><label className="!grid">
                        نام و نام خانوادگی
                        <input defaultValue={editData.name} onKeyUp={ (e) => {
                            editData.name = e.target.value;
                        } } className="bordered-input-7 mt-2" type="text"
                               placeholder="نام و نام خانوادگی را وارد کنید"/>
                    </label></div>
                    <div><label className="!grid">
                        نام کاربری
                        <input defaultValue={editData.username} onKeyUp={ (e) => {
                            editData.username = e.target.value;
                        } } className="bordered-input-7 mt-2" type="email" placeholder="نام کاربری را وارد کنید"/>
                    </label></div>
                    <div><label className="!grid">
                        پسورد
                        <input onKeyUp={ (e) => {
                            editData.password = e.target.value;
                        } } className="bordered-input-7 mt-2" type="password" placeholder="اگر نمیخواهید تغییر دهید، رها کنید"/>
                    </label></div>
                    <div><label className="!grid">
                        کد آسان
                        <input defaultValue={editData.asan_id} onChange={(e) => { editData.asan_id = parseInt(e.target.value) }} className="bordered-input-7 mt-2" type="text"/>
                    </label></div>
                    {editData && <div><label className="!grid">
                        گروه کاربری
                        <select onChange={e => {
                            let v = e.target.value.split('|')
                            editData.role = {
                                label: v[1],
                                name: v[0],
                            }
                        }} className="bordered-input-7 mt-2">
                            {role && role.map(role => <option selected={role?.name === editData?.role?.name}
                                                              value={role?.name + "|" + role?.label}>{role?.label}</option>)}
                        </select>
                        <button onClick={() => {
                            MySwal.fire({
                                title: <p>ایا مطمن هستید؟</p>,
                                confirmButtonText: "بله ریست کن",
                                cancelButtonText: `دست نگه دار`,
                                showCancelButton: true,
                            }).then((result) => {
                                if (result.isConfirmed) {

                                    editData.permissions = []

                                    role.map((_role, i) => {
                                        if (_role.name === editData.role.name) {
                                            editData.permissions = _role.permissions
                                        }
                                    })

                                    setPermission(null)
                                    setEditData(editData)
                                    setUpdate(Date.now())

                                    setTimeout(() => {
                                        setPermission(permissionHold)
                                    }, 1000)

                                    Swal.fire({
                                        title: "ریست شد",
                                        text: 'جهت ذخیره نهایی روی ویرایش کلیک کنید',
                                        icon: "success",
                                        confirmButtonText: "باشه"
                                    });
                                }
                            })
                        }} className="btn-purple text-13 mt-1 rounded py-1.5">
                            <span>ریست دسترسی ها بر اساس گروه</span>
                        </button>
                    </label></div>}
                </div>
                <div className="border border-gray-7 rounded-md mx-auto p-4 text-xs mt-4">
                    <div className="flex justify-between items-center border-b border-b-gray-7 pb-3">
                        <span className="text-13">دسترسی کارمندان</span>
                        <div className="flex items-center">
                            <SearchBox SetSearchQuery={ (c) => {
                                setSearchTerm(c.term)
                            }}/>
                        </div>
                    </div>

                    { permission && Object.keys(permission).map((per,i) => {
                        return (
                            <div key={per}>
                                <div className="page-title my-3 text-purple-1"><span
                                    className="inline-block bg-white pl-3">{per}</span>
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 mb-10 gap-3">

                                    { permission[per].map( (item,index) => {
                                        if ( searchTerm !== '' && !item.label.includes(searchTerm) ){ return ''; }
                                        return (
                                            <div key={index} className="border border-gray-7 rounded flex justify-between py-1 px-1.5">
                                                <label title={item.name} className="checkbox-wrapper">
                                                    <span>{item.label}</span>
                                                    <input
                                                        onChange={ (e) => {
                                                            if (e.target.checked){
                                                                let found = false;
                                                                editData.permissions.map( (_item,_index) => {
                                                                    if ( _item.name === item.name ){
                                                                        found = true
                                                                    }
                                                                })
                                                                if (!found){
                                                                    editData.permissions.push(item)
                                                                }
                                                            }else{
                                                                editData.permissions.map( (_item,_index) => {
                                                                    if ( _item.name === item.name ){
                                                                        editData.permissions.splice(_index,1)
                                                                    }
                                                                })
                                                            }

                                                            setEditData(editData)
                                                        } }
                                                        defaultChecked={hasPermission(item.name)}
                                                        type="checkbox"/>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        )
                                    }) }

                                </div>
                            </div>
                        )
                    }) }


                    <div className="col-span-4 flex justify-center mx-auto gap-5 mt-4">
                        {/*<Link to="/employees" className="btn-gray w-28 py-1.5">لغو</Link>*/}
                        <button onClick={handleSubmit} className="btn-purple rounded w-28 py-1.5">ویرایش</button>
                    </div>

                </div>
            </div>}
        </Layout>
    )
}

export default EmployeeEdit