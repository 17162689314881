import React, {useEffect, useRef, useState} from "react";
import {MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents} from "react-leaflet";
import {setPosition} from "leaflet/src/dom/DomUtil";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {toast} from "react-toastify";
import ApiRoute from "../../../helper/api";


const MyMap = ({setExportCoordinates,setLat = 51.338097 , setLng = 35.699739 , forceUpdate = null, setStep = null}) => {



    if (setLat && setLat.toString().length < 1 || setLng.toString().length < 1 == null){
        setLng = 51.338097;
        setLat = 35.699739;
    }
    const [openModal,setOpenModal] = useState(false)

    const [myLat,setMyLat] = useState(setLat)
    const [myLng,setMyLng] = useState(setLng)

    const [fuck,setFuck] = useState([setLat, setLng])
    const [searchAct,setSearchAct] = useState(false)

    const [position,setPosition] = useState([setLat, setLng])

    const [coordinates,setCoordinates] = useState({})
    const [searchResult,setSearchResult] = useState([])
    const [searchPopup,setSearchPopup] = useState(false)

    const [disableConfirm,setDisableConfirm] = useState(true)
    const [loading,setLoading] = useState(false)


    useEffect(() => {

        setMyLat(setLat)
        setMyLng(setLng)
        setPosition([myLat, myLng])

        console.log([myLat, myLng])

    }, [setLat,setLng,forceUpdate]);

    function DragMarker() {
        const map = useMapEvents({
            dragend: (e) => {
                setCoordinates({
                    lat: e.target.getCenter().lat,
                    lng:e.target.getCenter().lng
                })
                setDisableConfirm(false)
                console.log(coordinates)
              /*  console.log("mapCenter", e.target.getCenter());
                console.log("map bounds", e.target.getBounds());*/
            }
        });
        return null;
    }

    async function search(val){
        const x = await fetch(`https://api.parsimap.ir/geocode/address?key=p197bffbb180644f61a0fe616625b0c1fc26845557&district=1001&search_text=${val}`)
        const d = await x.json()
        setSearchResult(d.results)
        setSearchPopup(true)
        console.log(searchResult)
    }

    async function confirmSearchItem(item) {
        const geo_location = item.geo_location.center
        const lng = geo_location.lng
        const lat = geo_location.lat
        setFuck([lat,lng])
        setSearchAct(true)
        setSearchPopup(false)
        setDisableConfirm(false)
    }

    function CheckPosition(){
        const map = useMap()
        useEffect(() => {
            if (searchAct){
                console.log(fuck)
                map.flyTo({lat:fuck[0] , lng:fuck[1]},map.getZoom())
                setSearchAct(false)
                setDisableConfirm(false)
            }
        }, []);
        return null
    }

    async function confirmLocation() {

        setLoading(true)
        const lng = coordinates.lng
        const lat = coordinates.lat
        const parsimap = await fetch(`https://api.parsimap.ir/geocode/reverse?key=p197bffbb180644f61a0fe616625b0c1fc26845557&location=${lng},${lat}&local_address=true&approx_address=true&subdivision=true&plate=false&request_id=false`)
        const parsimapData = await parsimap.json()


        const stateId = parsimapData.subdivisions?.ostan?.id
        const cityId = parsimapData.subdivisions?.shahr?.id
        const cityName = parsimapData.subdivisions?.shahr?.title
      
        const confirmMap = await ApiRoute.coordinate.parsiMap.fn(stateId,cityId,cityName)
        console.log(confirmMap)
        const confirmMapData = confirmMap
        const s = await setExportCoordinates(confirmMapData?.results , parsimapData , lat, lng)
        setOpenModal(false)
        setLoading(false)
        if (setStep){
            setStep(2)
        }
    }

    return (
       <>


           <div className="py-3 px-1">

                       <div className={`relative w-full`}>

                           <input
                               onChange={ (e) => { search(e.target.value) } }
                               type={`search`}
                               placeholder={`جستجوی آدرس`}
                               className={`bg-white absolute lg:text-16 text-14 border-0 shadow-md top-[10px] z-[501] left-0 right-0 m-auto rounded w-[90%] p-3`}/>

                           {(searchPopup && searchResult?.length > 0) && <div className={`absolute text-13 lg:text-16 bg-white text-t-gray z-[501] w-[90%] m-auto left-0 right-0 rounded shadow-md top-[50px] overflow-auto text-right`}>
                               {searchResult.map( (item,i) => <div className={`p-2 cursor-pointer hover:text-black flex items-center gap-1`} onClick={ () => {confirmSearchItem(item)} } key={i}>
                                   <span className={`icon-location text-m-dark-red`}></span>
                                   <span>{item.description}</span>
                               </div> )}
                           </div>}



                           <div onClick={confirmLocation} className={(disableConfirm ? `pointer-events-none` : ``)+` w-[24px] h-[30px] left-0 right-0 top-0 bottom-0 m-auto absolute z-[500]`}>
                               <div className="w-[20px] h-[20px] bg-purple-1 shadow-lg rounded-full"></div>
                           </div>

                           <MapContainer className={`w-full lg:h-[550px] h-[550px]`} center={position} zoom={13} scrollWheelZoom={true}>

                               <TileLayer
                                   attribution=''
                                   url={`https://api.parsimap.ir/tile/parsimap-streets-v11-raster/{z}/{x}/{y}?key=p197bffbb180644f61a0fe616625b0c1fc26845557`}
                               />
                               <DragMarker />
                               <CheckPosition />
                           </MapContainer>
                       </div>

                       <div className={`w-full text-center`}>
                        
                           <button
                               disabled={disableConfirm}
                               onClick={confirmLocation}
                               className={` ${ loading ? 'loading' : '' } py-2 w-full px-5 border border-border-color rounded-lg text-16 font-medium text-white bg-purple-1 hover:shadow-sm hover:bg-purple-4 float-left ml-0`}>
                               تایید آدرس
                           </button>
                       </div>
           </div>
       </>
    )
}

export default MyMap;