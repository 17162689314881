import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";

const Accordion = ({ children, arrowIcon, customClsses }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className="w-full max-w-m">
            {React.Children.map(children, (child, index) => (
                <div key={index} className="mb-2 w-full">
                    <div
                        className={`flex items-center justify-between cursor-pointer rounded-md transition-colors duration-300 ease-in-out ${customClsses?customClsses:''}`}
                        onClick={() => handleToggle(index)}
                    >
                        {arrowIcon?
                            <FontAwesomeIcon
                                className={`text-gray-9 text-sm ml-2 transition-transform transform ${
                                    activeIndex === index ? '-rotate-90' : 'rotate-0'
                                }`}
                                icon={faCaretLeft}
                            />
                            :''
                        }

                        <span className="text-xs flex-auto">{child.props.children[0]}</span>
                    </div>
                    <div
                        className={`px-2 transition-max-height duration-300 ease-in-out ${
                            activeIndex === index ? 'max-h-40 overflow-hidden max-lg:overflow-visible' : 'max-h-0 overflow-hidden'
                        }`}
                    >
                        <div className="text-gray-700">{child.props.children[1]}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Accordion;
