import Layout from "../components/Layout";
import SearchBox from "../components/SearchBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import Filter from "../components/Filter";
import InventoryTable from "../components/Tables/InventoryTable";
import Pagination from "../components/Pagination";
import FilterBox from "../components/FilterBox";
import {useEffect, useState} from "react";
import ApiRoute, {isPrimaryShop, shopId} from "../helper/api";
import FilterInventory from "../components/Inventory/FilterInventory";
import {formatNumberWithDots} from "../helper/general";
import InventoryFilterStateTab from "../components/Inventory/InventoryFilterStateTab";
import PrimaryInventoryList from "../components/Inventory/List/PrimaryInventoryList";
import ShopInventoryList from "../components/Inventory/List/ShopInventoryList";

export default function Inventory() {
    return (
        <Layout>
            {isPrimaryShop() && <PrimaryInventoryList/>}
            {!isPrimaryShop() && <ShopInventoryList/>}
        </Layout>
    )
}