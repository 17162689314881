import CustomMultiSelect from "../../Utilities/CustomMultiSelect";
import Modal from "../../Utilities/Modal";
import React, {useEffect, useState} from "react";
import ApiRoute from "../../../helper/api";
import {toast} from "react-toastify";
import {DatePicker} from "zaman";
import moment from "jalali-moment";
import {getTimeFromDate} from "../../../helper/general";
import {HasAccessComponent} from "../../../helper/permissions";

export default function EditModel({product , closeModal}){
    const [code, setCode] = useState('')
    const [selectedSendMethod, setSelectedSendMethod] = useState([]);

    const [onSale, setOnSale] = useState({
        start:product?.on_sale?.start?.length < 1 ? null : product?.on_sale?.start,
        start_time_at:product?.on_sale?.start?.length < 1 ? null : moment(product?.on_sale?.start).format('HH:mm:ss'),
        end:product?.on_sale?.end?.length < 1 ? null : product?.on_sale?.end,
        end_time_at:product?.on_sale?.end?.length < 1 ? null : moment(product?.on_sale?.end).format('HH:mm:ss')
    });
    const SendMethods = [
        {value: 'free-shipping', label: 'ارسال رایگان'},
        {value: 'free-shipping-tehran-and-karaj', label: 'ارسال رایگان تهران و کرج'}
    ];




    useEffect(() => {
        console.log(onSale)
        for (let cat of product.categories){
            for (const catElement of SendMethods) {
                if (catElement.value === cat){
                    setSelectedSendMethod(catElement)
                }
            }
        }

    }, []);

    async function updateProductCode() {
        let _code = code;
        if (code.length < 1){
            _code = product.code
        }
        const res = await ApiRoute.product.inventory.updateProductCode.fn(product._id,_code)
        //toast.success("با موفقیت ویرایش شد")
    }

    async function updateOnSale() {
        await ApiRoute.product.inventory.updateOnSale.fn(product._id,onSale)
    }

    const handleMultiSelectSendMethods = async (selectedSendMethod) => {
        setSelectedSendMethod(selectedSendMethod)

        let arr = []
        for (const sendMethod of selectedSendMethod) {
            arr.push(sendMethod.value)
        }
        setSelectedSendMethod()

        const res = await ApiRoute.product.inventory.updateShipping.fn(product._id, {
            category: arr
        })
    }

    return (
        <Modal isOpen={true} onClose={closeModal}
               modalWidth="2xl:w-[80%] h-auto scrollbar-style dir-rtl">
            <div className="page-title z-1 mb-3 text-13"><span className="inline-block bg-white pl-3 z-2 relative">اطلاعات اضافی</span>
            </div>
            <div className="border border-gray-7 rounded-md p-3 text-xs">
                <div className="px-1">{product.title}</div>
                <div className="border my-3 border-gray-7 rounded-md p-3 grid gap-5">
                    <div className="flex justify-between">
                        <div>
                            <span className="text-gray-12 ml-1">برند کالا:</span>
                            <span className="text-gray-5">{product?.brand}</span>
                        </div>
                        <div>
                            <span className="text-gray-12 ml-1">رنگ:</span>
                            <span className="text-gray-5">{product.color}</span>
                        </div>
                    </div>

                    <div>
                        <div className="text-gray-12 mb2">ویرایش شناسه:</div>
                        <div
                            className="flex items-center justify-between border border-gray-7 bg-gray-1 rounded-md">
                            <input defaultValue={product.product_id} onKeyUp={ (e) => setCode(e.target.value) } className="flex flex-auto rounded-r-md px-2 bg-none" type="text"
                                   placeholder="شناسه کالا را وارد کنید"/>
                            <HasAccessComponent permission={`licence_id inventory`} children={<button onClick={updateProductCode} className="bg-purple-1 text-white px-3 py-2 rounded-l-md">بروزرسانی</button>}/>
                        </div>
                    </div>

                    <HasAccessComponent permission={`free_shipment inventory`} children={<div>
                        <div className="text-gray-12 mb-2">ارسال رایگان</div>
                        <div className="CustomMultiSelectStyle2">
                            <CustomMultiSelect
                                options={SendMethods}
                                values={selectedSendMethod}
                                onChange={handleMultiSelectSendMethods}
                                placeholder="دسته های موردنظر را انتخاب کنید..."
                            />
                        </div>
                    </div>}/>

                </div>


                <div className={`bordered-input-7`}>

                    <HasAccessComponent permission={`sale_date inventory`} children={
                        <div className="flex justify-between">
                            <div>
                                <span className="text-gray-12 ml-1">تاریخ شروع:</span>
                                <div className="text-gray-5 gap-2 flex items-center">
                                    <div className={`input-x`}>
                                        <DatePicker
                                            className={`z-[150]`}
                                            defaultValue={onSale.start ?? undefined}
                                            onChange={(e) => {
                                                onSale.start = moment(e.value).format('YYYY-MM-DD');
                                            }}
                                        />
                                    </div>
                                    <input type={`time`} className={`input-x`} defaultValue={getTimeFromDate(onSale.start)} onChange={ e => {
                                        onSale.start_time_at = e.target.value;
                                    } }/>
                                </div>
                            </div>
                            <div>
                                <span className="text-gray-12 ml-1">تاریخ پایان:</span>
                                <div className="text-gray-5 gap-2 flex items-center">
                                    <div className={`input-x`}>
                                        <DatePicker
                                            className={`z-[150]`}
                                            defaultValue={onSale.end ?? undefined}
                                            onChange={(e) => {
                                                onSale.end = moment(e.value).format('YYYY-MM-DD');
                                            }}
                                        />
                                    </div>
                                    <input type={`time`} className={`input-x`} defaultValue={getTimeFromDate(onSale.end)} onChange={ e => {
                                        onSale.end_time_at = e.target.value;
                                    } }/>
                                </div>
                            </div>
                        </div>
                    }/>

                    <button onClick={updateOnSale} className={`btn-green py-1 px-3 rounded`}>ذخیره سازی</button>

                </div>

            </div>
        </Modal>
    )
}