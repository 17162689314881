import React, {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../../helper/api";
import {toast, ToastContainer} from "react-toastify";
import Layout from "../../../components/Layout";
import SearchBox from "../../../components/SearchBox";
import FilterBox from "../../../components/FilterBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../../components/Pagination";
import Modal from "../../../components/Utilities/Modal";
import Buttons from "react-multi-date-picker/components/button";
import AttributeSelect from "../../../components/Product/AttributeSelect";
import CategorySelect from "../../../components/Product/Category/CategorySelect";


export default function AttributesProxy() {

    const [openModal, setOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [createMode, setCreateMode] = useState(false);

    const [data,setData] = useState(null)
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [isLoading, setIsLoading] = useState(true)

    const selectedItemStructure = {
        label: '',
        attributes: [],
        slugs: []
    }

    const [pageNumber, setPageNumber] = useState(1)
    const [paginationData, setPaginationData] = useState([])
    const [updateData, setUpdateData] = useState('0')
    const [filterQuery, setFilterQuery] = useState({})

    useEffect(() => {
        if (createMode){setSelectedItem(selectedItemStructure) }
    }, [createMode]);

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  response  = await ApiRoute.product.attributes.proxy.index.fn({
                shop_id: shopId(),
                page : pageNumber
            },filterQuery)

            setData(response.results.data)
            setPaginationData(response.results)

            setIsLoading(false)

        })();
    }, [updateData]);

    useEffect(() => {
        getData()
    }, []);

    async function getData(){
        const response = await ApiRoute.product.attributes.proxy.index.fn()
        setData(response.results.data)
        setPaginationData(response.results)
    }

    async function handleCreate(){
        try {
            await ApiRoute.product.attributes.proxy.create.fn(selectedItem )
            //toast.success("با موفقیت انجام شد")
        }catch (e) {
            toast.error(e)
        }

        await getData()
    }

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);

    async function handleUpdate() {
        try {
            await ApiRoute.product.attributes.proxy.update.fn(selectedItem._id, selectedItem )
            //toast.success("با موفقیت انجام شد")
        }catch (e) {
            toast.error(e)
        }

        await getData()
    }

    async function handleDelete(item) {
        try {
            await ApiRoute.product.attributes.proxy.remove.fn(item._id)
            //toast.success("با موفقیت انجام شد")
        }catch (e) {
            toast.error(e)
        }

        await getData()
    }

    return (
        <Layout>
            <div>
                <div className="flex items-center justify-between mb-3">
                    <div className="page-title flex-auto ml-3 md:ml-0">
                        <span className="inline-block bg-white pl-3">پراکسی ویژگی</span>
                    </div>
                </div>

                <div className="grid md:flex justify-between items-center gap-3">
                    <div className="flex gap-3">
                        <SearchBox SetSearchQuery={ (v) => {
                            setFilterQuery({title: v.term})
                            setUpdateData(Date.now().toString())
                        } }/>
                    </div>
                    <button className="btn-blue text-center hidden md:block" onClick={ e => {
                        setCreateMode(true)
                        setOpenModal(true)
                    } }>افزودن</button>
                </div>
                <FilterBox/>

                {data && <div className="main-content p-3 mt-3">

                    <div className="grid grid-cols-2 text-sm border-b border-b-gray-7 py-3">
                        <div className="col-span-3 text-center">عنوان</div>
                    </div>
                    {data.map((item, id) =>
                        <div key={id}>
                            <div
                                className="grid grid-cols-2 text-xs items-center rounded box-tr-style py-1.5 my-2">
                                <div className="col-span-3 text-center">{item.title}</div>
                                <div className="col-span-3 text-left pl-4">
                                    <button className="mx-1" title="ویرایش"
                                            onClick={() => {
                                                setCreateMode(false)
                                                setOpenModal(true)
                                                setSelectedItem(item)
                                            }}>
                                        <FontAwesomeIcon
                                            className="bg-blue-2 text-white p-1 w-4 h-4 rounded" icon={faEdit}/>
                                    </button>
                                    <button className="mx-2" onClick={ async () => await handleDelete(item) } title="حذف" ><FontAwesomeIcon
                                        className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                                </div>
                            </div>
                        </div>
                    )}

                    <Pagination SetPageNumber={setPageNumber} Data={paginationData}/>


                </div>}



            </div>


            <Modal isOpen={openModal}>

                <div className="flex justify-between mx-auto mb-5">
                    <h4 className="text-[#000]">
                        {createMode ? 'افزودن پراکسی' : 'ویرایش پراکسی'}
                    </h4>
                    <Buttons onClick={ (e) => {setOpenModal(false); setSelectedItem(selectedItemStructure)} } className="btn-gray py-1.5">بستن</Buttons>
                </div>

                <div className={`p-3`}>
                    <div
                        className="grid grid-cols-1 lg:grid-cols-1 text-[14px] gap-5 td items-end">
                        <label className="">
                            <span className="text-right">عنوان</span>
                            <input
                                onChange={ (e) => {
                                    selectedItem.title = e.target.value;
                                } }
                                className="!mr-0 mt-2 rounded p-1 border-gray-3 shadow-sm border w-full" type="text" defaultValue={selectedItem?.title}/>
                        </label>

                        <div className="">
                            <label className="pb-2">دسته ها</label>
                            <CategorySelect assignItem={ async (s) => {
                                if (!selectedItem){return}
                                let arr = []
                                s.map( (a) =>  arr.push(a.slug) )
                                selectedItem.slugs = arr
                                setSelectedItem(selectedItem)
                            } } defaultValue={selectedItem?.slugs}/>
                        </div>

                        <div className="">
                            <label className="pb-2">ویژگی ها</label>
                            <AttributeSelect assignItem={ async (s) => {
                                if (!selectedItem){return}
                                let arr = []
                                s.map( (a) =>  arr.push(a.name) )
                                selectedItem.attributes = arr
                                setSelectedItem(selectedItem)
                            } } defaultValue={selectedItem?.attributes}/>
                        </div>

                    </div>
                </div>

                <div className="flex justify-between mx-auto mt-5">
                    <Buttons onClick={ async () => {
                        if (createMode){ await handleCreate()}else{ await handleUpdate()}
                    } } className="btn-purple w-36 py-1.5">ذخیره</Buttons>
                </div>
            </Modal>

            
        </Layout>
    )
}