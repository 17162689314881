import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useState} from "react";
import ApiRoute from "../../../helper/api";
import {DatePicker} from "zaman";
import moment from "jalali-moment";
import {faNumberToEn} from "../../../helper/general";

function CustomerNaturalInfo({customer}){
    const MySwal = withReactContent(Swal)
    const [isLoading , setIsLoading] = useState(false)

    const [generalInfo , setGeneralInfo] = useState({
        first_name: customer.personal.first_name,
        last_name: customer.personal.last_name,
        national_code: customer.personal.national_code,
        emergency_phone: customer.contacts.emergency_phone,
        age: customer.personal.age,
        username: customer.username,
        password:''
    })

    function resetAsanId() {
        MySwal.fire({
            title: <p>ایا مطمن هستید؟</p>,
            text: "این عمل غیرقابل برگشت است",
            confirmButtonText: "انجام بده",
            cancelButtonText: `دست نگه دار`,
            showCancelButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {

                await ApiRoute.customer.updateAsanId.fn(customer._id)

            }
        })
    }

    async function sendToServer(e) {
        setIsLoading(true)
        await ApiRoute.customer.updateGeneral.fn(customer._id , generalInfo )
        setIsLoading(false)
    }
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 justify-items-stretch xl:mb-10 gap-4 xl:gap-10">
            <div><label className="!grid">
                <span className="text-right mb-3 pr-1">نام </span>
                <input className="!flex-none !mr-0" type="text"
                       onChange={ e => {
                           generalInfo.first_name = e.target.value;
                           setGeneralInfo(generalInfo)
                       } }
                       defaultValue={generalInfo.first_name}
                       placeholder="نام  را وارد کنید"/>
            </label></div>
            <div><label className="!grid">
                <span className="text-right mb-3 pr-1">نام خانوادگی</span>
                <input className="!flex-none !mr-0" type="text"
                       onChange={ e => {
                           generalInfo.last_name = e.target.value;
                           setGeneralInfo(generalInfo)
                       } }
                       defaultValue={generalInfo.last_name}
                       placeholder="نام خانوادگی را وارد کنید"/>
            </label></div>
            <div><label className="!grid">
                <span className="text-right mb-3 pr-1">کد ملی</span>
                <input className="!flex-none !mr-0" type="text"
                       onChange={ e => {
                           generalInfo.national_code = e.target.value;
                           setGeneralInfo(generalInfo)
                       } }
                       defaultValue={generalInfo.national_code}
                       placeholder="کد ملی را وارد کنید"/>
            </label></div>
            <div><label className="!grid">
                <span className="text-right mb-3 pr-1">شماره همراه</span>
                <input className="!flex-none !mr-0 text-left" type="text"
                       defaultValue={customer.contacts.cellphone}
                       placeholder="09xxx" disabled/>
            </label></div>
            <div><label className="!grid">
                <span className="text-right mb-3 pr-1">شماره تماس اضطارای</span>
                <input className="!flex-none !mr-0 text-left" type="text"
                       onChange={ e => {
                           generalInfo.emergency_phone = e.target.value;
                           setGeneralInfo(generalInfo)
                       } }
                       defaultValue={generalInfo.emergency_phone}
                       placeholder="09xxx"/>
            </label></div>
            <div><label className="!grid">
                <span className="text-right mb-3 pr-1">تاریخ تولد</span>
                <DatePicker
                    defaultValue={ generalInfo.age ? moment.from(generalInfo.age, 'fa', 'YYYY/MM/DD').format('YYYY/MM/DD') : null }
                    onChange={(e) => {
                        generalInfo.age = faNumberToEn(moment(e.value).toDate().toLocaleDateString('fa'));
                        setGeneralInfo(generalInfo)
                    } }
                />
            </label></div>
            <div><label className="!grid">
                <span className="text-right mb-3 pr-1">رمز عبور</span>
                <input className="!flex-none !mr-0" type="text"
                       onChange={ e => {
                           generalInfo.password = e.target.value;
                           setGeneralInfo(generalInfo)
                       } }
                       defaultValue=''
                       placeholder="رمز عبور را وارد کنید"/>
            </label></div>
            <div><label className="!grid">
                <span className="text-right mb-3 pr-1">شماره آسان</span>
                <input className="!flex-none !mr-0" type="text" defaultValue={customer.asan_id}
                       placeholder="شماره آسان را وارد کنید"/>
            </label></div>
            <div className="self-end">
                <button onClick={resetAsanId} className="btn-blue-light py-2 w-full">باز نشانی کد آسان</button>
            </div>
            <div className="self-end xl:col-start-6">
                <button onClick={ e => { sendToServer(e) } } disabled={isLoading} className="btn-green rounded py-2 w-full">ویرایش اطلاعات</button>
            </div>
        </div>
    )
}
export default CustomerNaturalInfo