import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {isOnEditState} from "../../../helper/product";
import ApiRoute from "../../../helper/api";
import {toast, ToastContainer} from "react-toastify";
import Layout from "../../Layout";
import CategorySelect from "../Category/CategorySelect";
import TagSelect from "../TagSelect";
import Buttons from "react-multi-date-picker/components/button";

export function Step1({onEdit = false , defaultProduct = null}){

    const navigate = useNavigate()

    const [product,setProduct] = useState(defaultProduct)
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedTag, setSelectedTag] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState([]);
    const [editState, setEditState] = useState(false);

    const [defaultCategory, setDefaultCategory] = useState([]);
    const [defaultTag, setDefaultTag] = useState([]);


    const [data , setData] = useState({
        title : 'عنوان محصول',
        category:selectedCategory,
        brand:selectedBrand,
        tag:selectedTag,
        slug:'',
        subtitle:'',
        product_id:'',
        sku:"ENTC-"+Date.now().toString()
    })

    const handleCategory = (_item) => {
        setSelectedCategory(_item)
    }
    const handleBrand = (_item) => {
        setSelectedBrand(_item)
    }
    const handleTag = (_item) => {
        setSelectedTag(_item)
    }

    useEffect(() => {
        setProduct(defaultProduct)
        setEditState(true)
    }, [defaultProduct]);

    useEffect(() => {

        if (onEdit === true){
            data.title = product?.title
            data.slug = product?.slug
            data.subtitle = product?.metadata?.subtitle ?? ''
            data.sku = product?.sku ?? ''
            data.tag = product?.tags ?? []
            data.category = product?.categories ?? []
            data.product_id = product?._id
            setData(data)

            setDefaultCategory(product?.categories ?? [])
            setDefaultTag(product?.tags ?? [])
        }

    }, [editState,product]);

    useEffect(() => {
        data.category = selectedCategory
        data.tag = selectedTag
        data.brand = selectedBrand
        setData(data)
    }, [selectedBrand,selectedCategory,selectedTag]);


    function isValidData(){
        console.log(data)
        console.log(data)
        console.log(data)
        if (data.title.length < 1){
            toast.error("عنوان ضروری میباشد")
            return false;
        }

        if (data.slug.length < 1){
            toast.error("نامک ضروری میباشد")
            return false;
        }

        if (data.category.length < 1){
            toast.error("دسته بندی ضروری میباشد")
            return false;
        }


        return true;
    }

    async function handleSubmit() {

        if (!isValidData()){
            return;
        }

        try {
            const response = await ApiRoute.product.create.stepOne.fn(data)
            //toast.success("محصول ایجاد شد")
            navigate("/products/create/step-2?product_id=" + response.results._id)
        }catch (e) {

        }
    }

    async function handleUpdate() {

        if (!isValidData()){
            return;
        }
        try {
            const response = await ApiRoute.product.create.stepOne.fn(data)
            //toast.success("محصول بروزرسانی شد")
        }catch (e) {

        }
    }

    return (
        <>
            <div>

                {!onEdit && <div className="page-title mb-3"><span className="inline-block bg-white pl-3">محصول جدید</span>
                </div>}

                <div className={( onEdit ? '' : 'main-content lg:w-1/2 mx-auto py-6 px-5 my-12' )}>

                    {!onEdit && <span className="text-purple-1 pl-3 mb-6 block text-center">اطلاعات اولیه کالا</span>}

                    <div className="form">

                        <label className="mb-4">
                            <span className="w-14 text-right">نام کالا</span>
                            <input onChange={ (e) => {
                                data.title = e.target.value;
                                setData(data)
                            } } type="text" defaultValue={product?.title}/>
                        </label>

                        <label className="mb-4">
                            <span className="w-14 text-right">دسته کالا</span>
                            <CategorySelect defaultValue={defaultCategory} assignItem={handleCategory}/>
                        </label>

                        <label className="mb-4">
                            <span className="w-14 text-right">نامک</span>
                            <input onChange={ (e) => {
                                data.slug = e.target.value;
                                setData(data)
                            } } dir={"ltr"} className="text-left font-sans" type="text" defaultValue={data.slug}/>
                        </label>
                        <label className="mb-4">
                            <span className="w-14 text-right">زیرنویس</span>
                            <input onChange={ (e) => {
                                data.subtitle = e.target.value;
                                setData(data)
                            } } className="text-left font-sans" type="text"
                                   defaultValue={data.subtitle}/>
                        </label>
                        <label className="mb-4">
                            <span className="w-14 text-right">SKU</span>
                            <input readOnly={true} className="text-left bg-gray-1 font-sans" type="text" defaultValue={data.sku}/>
                        </label>
                        <label className="mb-4">
                            <span className="w-14 text-right">برچسب</span>
                            <div className="flex-auto mr-5">
                                <TagSelect defaultValue={defaultTag} assignItem={handleTag}/>
                            </div>
                        </label>

                    </div>
                    <div className="flex justify-between mt-6">

                        {!onEdit && <Link to="/products" className="btn-gray w-36 py-1.5">
                            برگشت
                        </Link>}

                        { !onEdit && <Buttons onClick={handleSubmit} className="btn-purple w-36 py-1.5">مرحله بعد</Buttons> }
                        { onEdit && <Buttons onClick={handleUpdate} className="btn-purple w-36 py-1.5">ذخیره سازی</Buttons> }

                    </div>
                </div>
            </div>

        </>
    )
}