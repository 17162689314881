import Layout from "../components/Layout"
import SearchBox from "../components/SearchBox";
import {faFilter} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Pagination from "../components/Pagination";
import Faq from "../components/Faq";
import FilterBox from "../components/FilterBox";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ApiRoute, {shopId} from "../helper/api";

function Faqs() {
    const navigate = useNavigate()
    const [createModal, setCreateModal] = useState(false)

    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  res  = await ApiRoute.product.question.index.fn({
                shop_id: shopId(),
                page : pageNumber,
                model:'\\App\\QuestionManager\\Model\\QuestionExpanded'
            },filterQuery)
            setData(res)
            setIsLoading(false)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        filterQuery.quick_search = searchQuery
        setUpdateData(Date.now().toString())
    }, [searchQuery]);
    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">پرسش و پاسخ</span></div>
                <div className="flex gap-3">
                    <SearchBox SetSearchQuery={setSearchQuery} SearchIn={false}/>
                </div>
                <div className="lg:border border-gray-7 rounded-md lg:p-3 mt-4 grid gap-4">
                    { data && tableData.map( (item) => <Faq setUpdateData={setUpdateData} item={item} hasAnswer={true}/> ) }
                    <Pagination/>
                </div>
            </div>
        </Layout>
    );
}

export default Faqs;