import Toman from "../../Utilities/Toman";
import Modal from "../../Utilities/Modal";
import {useState} from "react";
import ApiRoute from "../../../helper/api";
import {formatNumberWithDots, getDateAsReadableFormat} from "../../../helper/general";
import ProductSelect from "../../Product/ProductSelect";
import CouponSelect from "../CouponSelect";

export default function QuickCouponCheck({closeModal}){
    const [product , setProduct] = useState(null)
    const [coupon , setCoupon] = useState(null)
    const [data , setData] = useState(null)

    async function handleSubmit(){
        const res = await ApiRoute.coupon.quickCheck.fn({
            product: product,
            coupon: coupon
        })
        if (res?.status_code === 200){
            setData(res.results);
        }
    }

    return (
        <Modal isOpen={true} onClose={closeModal}>
            <div className="page-title mb-3"><span className="inline-block bg-white text-xs text-purple-1 pl-3">مشاهده قیمت کالا پس از اعمال کد تخفیف</span>
            </div>

            <div className="form grid-form grid grid-cols-1 text-xs gap-3 my-6">

                <ProductSelect multiple={true} assignItem={ (s) => {
                    s.map( (product) => {
                        setProduct(product)
                    } )
                } }/>

                <CouponSelect multiple={true} assignItem={ (s) => {
                    s.map( (c) => {
                        setCoupon(c)
                    } )
                } }/>

            </div>

            {data && data.map( (item,index) => <div key={index} className="border border-gray-7 bg-gray-1 rounded-md p-3 text-xs sm:flex justify-between">
                <div className="flex items-center">
                    <span className="text-gray-12">قیمت کالا:</span>
                    <span className="text-gray-5 px-1">{formatNumberWithDots(item.couponObject.products[0]['calculated_price']['final'] ?? 0)}</span>
                    <Toman/>
                </div>
                <div className="flex items-center">
                    <span className="text-gray-12">میزان تخفیف:</span>
                    <span className="text-gray-5 px-1">{formatNumberWithDots(item.offedPriceAbsolute)}</span>
                    <Toman/>
                </div>
                <div className="flex items-center">
                    <span className="text-gray-12">قیمت بعد از تخفیف:</span>
                    <span className="text-gray-5 px-1">{formatNumberWithDots(parseInt(item.couponObject.products[0]['calculated_price']['final']) - parseInt(item.offedPriceAbsolute))}</span>
                    <Toman/>
                </div>
            </div>)}

            <div className="flex justify-end mt-4">
                <button onClick={handleSubmit} className="btn-purple px-5 py-2">بررسی وضعیت</button>
            </div>

        </Modal>
    )
}