import Layout from "../../components/Layout"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import InputRange from "../../components/InputRange";

function CommentEdit() {
    const [positiveItems, setPositiveItems] = useState(['قیمت مناسب', 'ارسال سریع و به موقع', 'صدای خوب']);
    const [negativeItems, setNegativeItems] = useState(['قیمت مناسب', 'ارسال سریع و به موقع', 'صدای خوب']);

    const [inputPositiveValue, setInputPositiveValue] = useState('');
    const [inputNegativeValue, setInputNegativeValue] = useState('');
    const handleInputPositiveChange = (event) => {
        // Update the state with the new input value
        setInputPositiveValue(event.target.value);
    };
    const handleInputNegativeChange = (event) => {
        // Update the state with the new input value
        setInputNegativeValue(event.target.value);
    };
    const positiveItemsInsert = (e) => {
        e.preventDefault()
        setPositiveItems([...positiveItems, inputPositiveValue])
        setInputPositiveValue('')
    }
    const negativeItemsInsert = (e) => {
        e.preventDefault()
        setNegativeItems([...negativeItems, inputNegativeValue])
        setInputNegativeValue('')
    }
    const handleDeletePositiveItem = (itemToDelete) => {
        // Use the filter function to create a new array without the item to delete
        const updatedItems = positiveItems.filter(item => item !== itemToDelete);

        // Update the state with the new array
        setPositiveItems(updatedItems);
    };
    const handleDeleteNegativeItem = (itemToDelete) => {
        // Use the filter function to create a new array without the item to delete
        const updatedItems = negativeItems.filter(item => item !== itemToDelete);

        // Update the state with the new array
        setNegativeItems(updatedItems);
    };


    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">دیدگاه</span></div>

                <div className="border border-gray-7 rounded-md p-3 mt-4">
                    <div className="bg-gray-1 border border-gray-7 rounded-md p-3 text-xs">
                        <div className="grid grid-cols-4 p-2">
                            <span className="text-purple-1">ویرایش دیدگاه</span>
                            <p className="col-span-3">کارمند گرامی، لطفا نظرات کاربران را تنها از نظر املا و شئونات
                                اخلاقی بررسی کنید و تغییری در دیدگاه مشتریان اعمال نکنید</p>
                        </div>
                        <div className="comment-form grid grid-cols-8 gap-5 mt-5">
                            <div className="col-span-6">
                                <input className="border border-gray-7 rounded bg-gray-11 px-3 py-2 w-full" type="text"
                                       value="من که میگم بخرید ، پشیمون نمیشید"/>
                                <div className="grid grid-cols-4 gap-12">
                                    <div>
                                        <form onSubmit={positiveItemsInsert}
                                              className="border border-gray-7 rounded bg-gray-11 px-3 py-2 w-full flex justify-between mt-5">
                                            <input
                                                type="text"
                                                value={inputPositiveValue}
                                                onChange={handleInputPositiveChange}
                                                className="bg-none"
                                                placeholder="نکات مثبت"/>
                                            <button className="text-17">+</button>
                                        </form>
                                        <form onSubmit={negativeItemsInsert}
                                              className="border border-gray-7 rounded bg-gray-11 px-3 py-2 w-full flex justify-between mt-5">
                                            <input
                                                type="text"
                                                value={inputNegativeValue}
                                                onChange={handleInputNegativeChange}
                                                className="bg-none"
                                                placeholder="نکات منفی"/>
                                            <button className="text-17">+</button>
                                        </form>
                                    </div>

                                    <div className="col-start-3 pt-4">
                                        {positiveItems.map((item, id) => (
                                            <div className="flex items-center py-1" key={id}>
                                                <button className="delete-btn ml-2"
                                                        onClick={() => handleDeletePositiveItem(item)}>
                                                    <FontAwesomeIcon icon={faTimes}/>
                                                </button>
                                                <span className="text-green-1 text-xl ml-2 leading-3">+</span>
                                                <span className="text-sm leading-3 pb-1">{item}</span>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pt-4">
                                        {negativeItems.map((item, id) => (
                                            <div className="flex items-center py-1">
                                                <button className="delete-btn ml-2"
                                                        onClick={() => handleDeleteNegativeItem(item)}
                                                ><FontAwesomeIcon icon={faTimes}/>
                                                </button>
                                                <span className="text-red-1 text-xl ml-2 leading-3">-</span>
                                                <span className="text-sm leading-3 pb-1">{item}</span>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                                <textarea rows="4"
                                          className="border border-gray-7 rounded bg-gray-11 p-3 w-full leading-6 mt-8 resize-none"
                                          value="به نظرم واقعا کیفیتش فوق العاده اس همینطور قیمتش من خودم خیلی تحقیق کردم تو این بازه قیمتی واقعا به صرفه است

                                به نظرم واقعا کیفیتش فوق العاده اس همینطور قیمتش من خودم خیلی تحقیق کردم تو این بازه قیمتی واقعا به صرفه استبه نظرم واقعا کیفیتش فوق العاده اس همینطور قیمتش من خودم خیلی تحقیق کردم تو این بازه قیمتی واقعا به صرفه است به نظرم واقعا کیفیتش فوق العاده اس همینطور قیمتش من خودم خیلی تحقیق کردم تو این بازه قیمتی واقعا به صرفه استبه نظرم واقعا کیفیتش فوق العاده اس همینطور قیمتش من خودم خیلی تحقیق کردم تو این بازه قیمتی واقعا به صرفه است
                                "></textarea>
                                <div className="grid grid-cols-3 p-3">
                                    <div className="flex items-center gap-5">
                                        <span>
                                            خرید این محصول را پیشنهاد می کنم
                                        </span>

                                        <label className="radio-wrapper">
                                            <input type="radio" name="recommend" checked/>
                                            بله
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="radio-wrapper">
                                            <input type="radio" name="recommend"/>
                                            خیر
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-5">
                                        <span>
                                            خریدار این کالا هستم
                                        </span>

                                        <label className="radio-wrapper">
                                            <input type="radio" name="buyer"/>
                                            بله
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="radio-wrapper">
                                            <input type="radio" name="buyer" checked/>
                                            خیر
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="images flex items-end justify-end gap-1">
                                        <img src="/images/img-view.png" className="rounded-lg" width="30" height="30"
                                             alt="user images"/>
                                        <img src="/images/img1.png" className="rounded-lg" width="30" height="30"
                                             alt="user images"/>
                                        <img src="/images/img1.png" className="rounded-lg" width="30" height="30"
                                             alt="user images"/>
                                        <img src="/images/img1.png" className="rounded-lg" width="30" height="30"
                                             alt="user images"/>
                                    </div>
                                </div>

                            </div>

                            <div className="col-span-2 grid gap-4">
                                <div>
                                    <span className="block pb-2">کیفیت ساخت</span>
                                    <InputRange/>
                                </div>
                                <div>
                                    <span className="block pb-2">ارزش خرید به نسبت قیمت</span>
                                    <InputRange/>
                                </div> <div>
                                <span className="block pb-2">نوآوری</span>
                                <InputRange/>
                            </div> <div>
                                <span className="block pb-2">امکانات و قابلیت ها</span>
                                <InputRange/>
                            </div> <div>
                                <span className="block pb-2">سهولت استفاده</span>
                                <InputRange/>
                            </div> <div>
                                <span className="block pb-2">پشتیبانی و گارانتی</span>
                                <InputRange/>
                            </div>
                            </div>
                            <div className="col-span-7 text-center mt-5">
                                <button className="btn-purple px-10 mx-auto">ذخیره دیدگاه</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default CommentEdit;