import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import ApiRoute from "../../helper/api";
import { name } from 'react-date-object/calendars/julian';

export default function AttributeSelect({ assignItem, defaultValue = [], placeholder = 'انتخاب ویژگی' }) {
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        const loadDefaultValues = async () => {
            if (defaultValue.length > 0) {
                try {
                    const response = await ApiRoute.product.attributes.getAttributesByNames.fn(defaultValue);
                    const formattedResults = response.results.map(item => ({
                        value: item._id,
                        label: item.label,
                        name: item.name,
                        _id: item._id
                    }));
                    assignItem(response.results);
                    setSelected(formattedResults);
                } catch (e) {
                    console.error(e);
                    assignItem([]);
                    setSelected([]);
                }
            } else {
                assignItem([]);
                setSelected([]);
            }
        };

        loadDefaultValues();
    }, [defaultValue]);

    const loadOptions = async (inputValue) => {
        try {
            const res = await ApiRoute.product.attributes.index.fn({
                label: inputValue
            });
            return res.results.data.map(item => ({
                value: item._id,
                label: item.label,
                name: item.name,
                _id: item._id
            }));
        } catch (e) {
            console.error(e);
            return [];
        }
    };

    const handleChange = (selectedOptions) => {
        const selectedItems = selectedOptions || [];
        setSelected(selectedItems);
        assignItem(selectedItems.map(option => ({
            _id: option._id,
            label: option.label,
            name: option.name
        })));
    };

    return (
        <AsyncSelect
            isMulti
            menuPosition='fixed'
            cacheOptions
            defaultOptions
            value={selected}
            loadOptions={loadOptions}
            onChange={handleChange}
            placeholder={placeholder}
            className="w-full"
            classNamePrefix="select"
            noOptionsMessage={() => "موردی یافت نشد"}
            loadingMessage={() => "در حال جستجو..."}
        />
    );
}