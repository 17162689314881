import Toman from "../../Utilities/Toman";
import Modal from "../../Utilities/Modal";
import {useState} from "react";
import ApiRoute from "../../../helper/api";
import {formatNumberWithDots, getDateAsReadableFormat} from "../../../helper/general";

export default function QuickInventoryList({closeModal}){
    const [search , setSearch] = useState('')
    const [type , setType] = useState('title')
    const [data , setData] = useState(null)

    async function getData(){
        let obj = {}
        if (type === 'title'){
            obj = {
                title : search
            }
        }else{
            obj = {
                asan_id: search
            }
        }
        const res = await ApiRoute.product.inventory.index.fn(obj);
        if (res.status_code === 200){
            setData(res.results.data);
        }
    }

    return (
        <Modal isOpen={true} onClose={closeModal}
               modalWidth="2xl:w-3/5">
            <div className="page-title mb-3"><span className="inline-block bg-white text-xs text-purple-1 pl-3">جستجو سریع انبارداری</span>
            </div>
            <div className="form grid-form grid grid-cols-5 text-xs gap-3 my-6 items-end">
                <div className="col-span-2">
                    <label>نام محصول</label>
                    <input onKeyUp={ (e) => {
                        setSearch(e.target.value);
                    } } className="w-full" type="text"/>
                </div>
                <div className="max-lg:col-span-2">
                    <label>بر اساس</label>
                    <select  onChange={ (e) => {
                        setType(e.target.value);
                    } } className="w-full">
                        <option value="title">نام محصول</option>
                        <option value="asan_id">کد اسان</option>
                    </select>
                </div>
                <button onClick={getData} className="btn-purple px-5 py-2">جستجو</button>
            </div>
            <table className="table-auto w-full mt-4 text-xs block lg:table">
                <thead>
                <tr>
                    <td>ش.آسان</td>
                    <td>پیش.ف
                        <div className="inline-flex items-center mr-0.5">
                            <span className="text-9">(</span>
                            <Toman/>
                            <span className="text-9">)</span>
                        </div>
                    </td>
                    <td>م.پیش.ف</td>
                    <td>نام محصول</td>
                    <td>موجودی</td>
                    <td>انبار ایران</td>
                    <td>قیمت مرجع
                        <div className="inline-flex items-center mr-0.5">
                            <span className="text-9">(</span>
                            <Toman/>
                            <span className="text-9">)</span>
                        </div>
                    </td>
                    <td>قیمت اصلی
                        <div className="inline-flex items-center mr-0.5">
                            <span className="text-9">(</span>
                            <Toman/>
                            <span className="text-9">)</span>
                        </div>
                    </td>
                    <td>قیمت ویژه
                        <div className="inline-flex items-center mr-0.5">
                            <span className="text-9">(</span>
                            <Toman/>
                            <span className="text-9">)</span>
                        </div>
                    </td>
                    <td>قیمت فوری
                        <div className="inline-flex items-center mr-0.5">
                            <span className="text-9">(</span>
                            <Toman/>
                            <span className="text-9">)</span>
                        </div>
                    </td>
                    <td>ت.شروع</td>
                    <td>ت.پایان</td>
                </tr>
                </thead>
                <tbody>

                    {data && data.map( item => <tr>
                        <td>{item.asan_id}</td>
                        <td>{formatNumberWithDots(item.calculated_price.presell)}</td>
                        <td>{formatNumberWithDots(item.stocks.presell)}</td>
                        <td>{item.title}</td>
                        <td>{item.stocks.web_site}</td>
                        <td>{item.stocks.iran}</td>
                        <td>{formatNumberWithDots(item?.prices?.reference)}</td>
                        <td>{formatNumberWithDots(item?.prices?.price)}</td>
                        <td>{formatNumberWithDots(item?.prices?.regular)}</td>
                        <td>{formatNumberWithDots(item?.prices?.sale)}</td>
                        <td>{getDateAsReadableFormat(item?.on_sale?.start)}</td>
                        <td>{getDateAsReadableFormat(item?.on_sale?.end)}</td>
                    </tr> )}

                </tbody>
            </table>
        </Modal>
    )
}