import {Provider} from 'react-redux';
import React, {useEffect, useState} from 'react';
import TopBar from "../components/TopBar";
import SideBar from "./SideBar";
import store from "./ReduxComponents/store";
import ContentWrapper from "./ContentWrapper";
import {ToastContainer} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTruckLoading} from "@fortawesome/free-solid-svg-icons";

function Layout({children}) {
    return (

        <Provider store={store}>
                <TopBar/>
                <div className="flex">
                    <SideBar />
                    <ContentWrapper children={children}/>
                    <ToastContainer/>
                    <div style={{display:"none"}} id={`loading-x`}>
                        <div className="xloader"></div>
                    </div>
                </div>
        </Provider>

    );
}

export default Layout;
