import ProductSelect from "../../../Product/ProductSelect";
import Modal from "../../../Utilities/Modal";
import {useState} from "react";
import ApiRoute from "../../../../helper/api";

export default function OrderItemProductModal({order, setUpdateState , closeModal}){

    const [productId , setProductId] = useState()
    const [productQuantity , setProductQuantity] = useState(1)

    async function handleSubmit(){
        const res = await ApiRoute.order.item.addProduct.fn(order._id , productId,{
            quantity:productQuantity
        })
        if (res?.status_code === 200){
            setUpdateState(Date.now())
            closeModal()
        }
    }

    return (
        <Modal isOpen={true} onClose={closeModal}>
            <div className="page-title mb-5"><span
                className="inline-block bg-white pl-3 text-sm text-purple-1">افزودن محصول</span></div>
            <div className="form">
                <div className="flex flex-col text-sm mb-5 gap-4">
                    <div>
                        <ProductSelect inStock={true} fixed={true} assignItem={(s) => {
                            s.map( (item) => {
                                setProductId(item._id)
                            } )
                        }}/>
                    </div>
                    <div>
                        <span className="text-right mb-3 pr-1">تعداد</span>
                        <input onKeyUp={ (e) => {
                            setProductQuantity(e.target.value)
                        } } defaultValue={1} dir={`ltr`} min={1} placeholder={`تعداد را وارد کنید`} type="number" className="!mr-0 text-left w-full"/>
                    </div>
                    <div>
                        <button onClick={handleSubmit} className="btn-purple py-2 w-full">افزودن</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}