import Layout from "../../../components/Layout";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import ApiRoute, {uploadUrl} from "../../../helper/api";
import {Editor} from "@tinymce/tinymce-react";
import Buttons from "react-multi-date-picker/components/button";
import {toast, ToastContainer} from "react-toastify";
import {isOnEditState} from "../../../helper/product";
import {Step2} from "../../../components/Product/Step/Step-2";

function CreateProductStep2() {

    const [product,setProduct] = useState({})
    const [loading,setLoading] = useState(true)
    const search = window.location.search;
    const params = new URLSearchParams(search);

    useEffect(() => {

        (async () => {
            const response = await ApiRoute.product.single.fn( params.get('product_id') )
            setProduct(response.results)
        })()


    }, []);


    return (
        <Layout>
            {product &&  <Step2 defaultProduct={product}/>}

        </Layout>
    )
}

export default CreateProductStep2