import React, {useEffect, useState, useCallback} from 'react';
import Select from 'react-select';
import ApiRoute from "../../helper/api";
import debounce from 'lodash/debounce';

export default function TransportSelect ({ assignItem, isActive = null, multiple = true, customDefaultValue = false, defaultValue = [], placeholder = 'انتخاب باربری' }) {
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialLoaded, setIsInitialLoaded] = useState(false);
    
    useEffect(() => {
        const initializeDefaultValue = async () => {
            if (defaultValue?.length > 0) {
                if (customDefaultValue) {
                    // اگر defaultValue کامل باشد، مستقیم استفاده می‌کنیم
                    const formattedValues = defaultValue.map(item => ({
                        ...item,
                        value: item._id,
                        label: item.title
                    }));
                    setSelectedOptions(formattedValues);
                } else {
                    // اگر فقط آیدی‌ها ارسال شده باشند، اطلاعات کامل را از سرور می‌گیریم
                    try {
                        setIsLoading(true);
                        const res = await ApiRoute.shipment.transport.getBySlugs.fn(defaultValue);
                        if (res?.results) {
                            const formattedValues = res.results.map(item => ({
                                ...item,
                                value: item._id,
                                label: item.title
                            }));
                            setSelectedOptions(formattedValues);
                        }
                    } catch (error) {
                        console.error('Error loading default values:', error);
                    } finally {
                        setIsLoading(false);
                    }
                }
            }
        };

        initializeDefaultValue();
    }, [defaultValue, customDefaultValue]);

    const loadOptions = async (inputValue = '') => {
        try {
            setIsLoading(true);
            const searchValue = String(inputValue || '');
            
            const res = await ApiRoute.shipment.transport.index.fn({
                title: searchValue,
                active: isActive
            });
            
            if (!res?.results?.data) {
                console.error('Invalid API response format:', res);
                return;
            }

            const newOptions = res.results.data.map(item => ({
                ...item,
                value: item._id,
                label: item.title
            }));
            setOptions(newOptions);
            setIsInitialLoaded(true);
        } catch (error) {
            console.error('Error loading options:', error);
            setOptions([]);
        } finally {
            setIsLoading(false);
        }
    };

    const debouncedLoadOptions = debounce((inputValue) => {
        if (inputValue) loadOptions(inputValue);
    }, 500);

    const handleInputChange = (inputValue, { action }) => {
        if (action === 'input-change') {
            debouncedLoadOptions(inputValue);
        }
        return inputValue;
    };

    const handleChange = (selected) => {
        setSelectedOptions(selected || []);

        const cleanSelected = selected ? 
            (multiple ? 
                selected.map(({ label, value, ...rest }) => {
                    const cleaned = { ...rest };
                    delete cleaned.label;
                    delete cleaned.value;
                    return cleaned;
                }) : 
                (() => {
                    const cleaned = { ...selected };
                    delete cleaned.label;
                    delete cleaned.value;
                    return cleaned;
                })()
            ) : null;

        assignItem(multiple ? cleanSelected : cleanSelected);

        if (!selected || (Array.isArray(selected) && selected.length === 0)) {
            loadOptions();
        }
    };

    const customStyles = {
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999
        }),
        control: (base) => ({
            ...base,
            minHeight: '30px',
            fontSize: '13px'
        }),
        valueContainer: (base) => ({
            ...base,
            fontSize: '13px'
        }),
        option: (base) => ({
            ...base,
            fontSize: '13px'
        }),
        singleValue: (base) => ({
            ...base,
            fontSize: '13px'
        }),
        multiValue: (base) => ({
            ...base,
            fontSize: '13px'
        }),
        input: (base) => ({
            ...base,
            fontSize: '13px'
        }),
        menu: (base) => ({
            ...base,
            fontSize: '13px'
        })
    };

    return (
        <Select
            options={options}
            value={selectedOptions}
            onChange={handleChange}
            onMenuOpen={() => !isInitialLoaded && loadOptions()}
            onInputChange={handleInputChange}
            isMulti={multiple}
            styles={customStyles}
            placeholder={placeholder}
            className="w-full"
            isSearchable={true}
            isClearable={true}
            filterOption={null}
            isLoading={isLoading}
            noOptionsMessage={() => 'موردی یافت نشد'}
            getOptionValue={(option) => option._id}
            getOptionLabel={(option) => option.title}
            menuPortalTarget={document.body}
            menuPosition="fixed"
        />
    );
}