import {orderStatusLabel, orderStatusList} from "../../../../helper/general";
import {useEffect, useState} from "react";
import ApiRoute from "../../../../helper/api";
import OrderShipmentStatusModal from "../../Modal/Edit/OrderShipmentStatusModal";
import {toast} from "react-toastify";
import OrderDefinedReason from "../OrderDefinedReason";
import Modal from "../../../Utilities/Modal";
import {hasAccess} from "../../../../helper/permissions";
import StorageSelect from "../../../Common/StorageSelect";
import TransportSelect from "../../../Common/TransportSelect";

export default function OrderProcessInfo({order, setUpdateState}){
    const [update, setUpdate] = useState(0);
    const [formData, setFormData] = useState({
        description: order?.transference?.description,
        transport: order?.transference?.transport
    });

    async function handleUpdate(){
        await ApiRoute.order.action.fn("transference/update",order._id,formData)
        setUpdateState(Date.now())
    }
    
    return (

        <div className="main-content border border-gray-7 relative rounded-md p-5 mt-4">
            <div className={`font-[16px] font-bold mb-3`}>اطلاعات حواله و باربری</div>

            <div>
                
            <div className={`flex gap-2 w-full flex-col`}>

                <div>
                    <input defaultValue={order?.transference?.description} onKeyUp={ (e) => {
                        formData.description = e.target.value
                    } }
                        className='px-3 py-2 border border-[#dfdfdf] w-full rounded-[8px] shadow flex-auto bg-white' type="text" placeholder="شرح حواله را اینجا بنویسید ..."/>
                </div>

                <input className="input-x bg-gray-7" type="text" readOnly value={order?.transference?.storage?.title}/>

                <TransportSelect customDefaultValue={true} defaultValue={[formData.transport]} isActive={true} multiple={false} assignItem={ (v) => {
                        formData.transport = v
                    } }/>

                 <button onClick={handleUpdate} className={`btn-green mt-4 max-w-max py-1 rounded`}>بروزرسانی</button>


                </div>

            </div>

        </div>

    )
}