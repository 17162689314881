import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStore} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import ApiRoute, {isPrimaryShop, shopId} from "../../helper/api";
import {formatNumberWithDots} from "../../helper/general";

export default function ShopInfo(){
    const [data,setData] = useState(null)
    useEffect(() => {
        (  async () => {
            const res = await ApiRoute.analytics.widget.shopInfo.fn({
                shop_id : shopId()
            })
            if (res?.status_code === 200){
                setData(res.results)
            }
        } )()
    }, []);
    return (
        <div className="main-content purple-shadow p-3">
            <div className="grid gap-3">
                <div className="flex justify-between text-lg items-center mb-4">
                    <span className="text-13">اطلاعات فروشگاه</span>
                    <FontAwesomeIcon className="text-lg rounded-full p-3 aspect-square bg-[#ffffff33]"
                                     icon={faStore}/>
                </div>
                <div className="flex justify-between items-center pl-2">
                    <span className="text-11">تعداد کل سفارشات</span>
                    {data ? <span className="text-xs">{formatNumberWithDots(data.order_count)}</span> : <div className={`x2-loader`}></div>}
                </div>
                <div className="flex justify-between items-center pl-2">
                    <span className="text-11">تعداد کل مشتریان</span>
                    {data ? <span className="text-xs">{formatNumberWithDots(data.customer_count)}</span> : <div className={`x2-loader`}></div>}
                </div>
                {isPrimaryShop() && <div className="flex justify-between items-center pl-2">
                    <span className="text-11">تعداد کل محصولات</span>
                    {data ? <span className="text-xs">{formatNumberWithDots(data.product_count)}</span> : <div className={`x2-loader`}></div>}
                </div>}
                {isPrimaryShop() && <div className="flex justify-between items-center pl-2">
                    <span className="text-11">تعداد دسته ها</span>
                    {data ? <span className="text-xs">{formatNumberWithDots(data.category_count)}</span> : <div className={`x2-loader`}></div>}
                </div>}
                {isPrimaryShop() && <div className="flex justify-between items-center pl-2">
                    <span className="text-11">تعداد فروشگاه ها</span>
                    {data ? <span className="text-xs">{formatNumberWithDots(data.shop_count)}</span> : <div className={`x2-loader`}></div>}
                </div>}
            </div>
        </div>
    )
}