export default function templateComponents(){ return [
    {
        label:"اسلایدر",
        name:"image_slider",
        data:{
            "id": Date.now().toString(),
            "layout": "col-12",
            "component": "image_slider",
            "data": [
                {
                    "id":Date.now().toString(),
                    "order":0,
                    "alt": "انتخاب سنتر - عنوان تست",
                    "url": "#3",
                    "image": "https://media.entekhabcenter.com/entc-front/slider-img.webp",
                    "image_sm": "https://media.entekhabcenter.com/entc-front/slider-img.webp"
                }
            ],
            "order": 0
        }
    },
    {
        label:"کمپین",
        name:"campaigns",
        data: {
            "id": Date.now().toString(),
            "layout": "col-12",
            "component": "campaigns",
            "data": {
                "request_type": "get",
                "request_url": "/campaigns/659417897f3a30e0b10ebeb3",
                "campaign_id": "659417897f3a30e0b10ebeb3"
            },
            "attributes": {
                "title": "بهترین قیمت",
                "icon": null,
                "image": null,
                "desc": "توضیحات اضافی",
                "url": "#url"
            },
            "order": 0
        }
    },
    {
        label:"آیکون اسلایدر",
        name:"icon_slider",
        data: {
            "id": Date.now().toString(),
            "layout": "col-12",
            "component": "icon_slider",
            "data": [
                {
                    "id":Date.now().toString(),
                    "order":0,
                    "alt": "انتخاب سنتر - عنوان تست",
                    "url": "#1",
                    "image": "https://media.entekhabcenter.com/entc-front/fest1.webp",
                    "title": "تست عنوان"
                }
            ],
            "attributes": {
                "bg": "#eeeeee"
            },
            "order": 0
        },
    },
    {
        label: "بنر",
        name: "banner",
        data: {
            "id": Date.now().toString(),
            "layout": "col-12",
            "component": "banner",
            "data": [
                {
                    "id":Date.now().toString(),
                    "order":0,
                    "alt": "انتخاب سنتر - عنوان تست",
                    "url": "#1",
                    "image": null,
                    "background_color": "#efe4aa"
                }
            ],
            "order": 0
        }
    },
    {
        label: "محصولات پر فروش",
        name: "top_sell_products",
        data: {
            "id": Date.now().toString(),
            "layout": "col-12",
            "component": "top_sell_products",
            "data": {
                "request_type": "get",
                "request_url": "/products/top-sell"
            },
            "attributes": {
                "title": "پرفروش ترین محصولات",
                "icon": "",
                "desc": "شما هم به جمع خریداران محصولات محبوب ما بپیوندید",
                "url": "#url"
            },
            "order": 0
        }
    },
    {
        label: "تب محصولات",
        name: "tabs",
        data:{
            "id": Date.now().toString(),
            "layout": "col-12",
            "component": "tabs",
            "data": [
                {
                    "order":0,
                    "tab_id": "c"+Date.now().toString(),
                    "tab_title": "محصولات تست یکم",
                    "tab_url": "#",
                    "tab_data": {
                        "component": "products_by_category",
                        "data": {
                            "request_type": "post",
                            "request_url": "/categories/catalog/slugs",
                            "request_data": {
                                "slugs": [
                                    "food-preparation"
                                ]
                            }
                        }
                    }
                }
            ],
            "order": 0
        }
    },
    {
        label: "کپسول بنر",
        name:"compact_box",
        data: {
            "id":Date.now().toString(),
            "layout": "col-12",
            "component": "compact_box",
            "data": [
                {
                    "id":Date.now().toString(),
                    "order":0,
                    "alt": "انتخاب سنتر - عنوان تست",
                    "url": "#1",
                    "image": "https://media.entekhabcenter.com/entc-front/05.webp"
                }
            ],
            "attributes": {
                "bg": "#333333",
                "url": "#url"
            },
            "order": 0
        }
    },
    {
        label: "پست های وبلاگ",
        name:"blog",
        data: {
            "id": Date.now().toString(),
            "layout": "col-12",
            "component": "blog",
            "data": {
                "request_type": "get",
                "request_url": "/blog"
            },
            "attributes": {
                "title": "مجله انتخاب",
                "icon": "#url",
                "desc": "بزرگترین بانک اطلاعاتی لوازم خانگی",
                "url": "#url"
            },
            "order": 0
        }
    },
    {
        label: "بلوک متن",
        name:"content_box",
        data: {
            "id": Date.now().toString(),
            "layout": "col-12",
            "component": "content_box",
            "data": {
                "content": "<p>متن تست</p>"
            },
            "attributes": {
                "bg": null,
                "color": null,
            },
            "order": 0
        }
    },
    {
        label: "بلوک متن با تصویر",
        name:"content_image_box",
        data: {
            "id": Date.now().toString(),
            "layout": "col-12",
            "component": "content_image_box",
            "data": {
                "content": "<p>متن تست</p>",
                "image" : null
            },
            "attributes": {
                "bg": null,
                "color": null,
                "order": '',
                "content_center": '',
                "image_center": '',
                "as_video": '',
            },
            "order": 0
        }
    }
]}
