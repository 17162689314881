export default function CommentFilterTab({options , selectedTab ,setSelectedTab}){
    const filters = [
        {
            title: 'درانتظار',
            quantity: options?.waiting ?? 0,
            name: 'waiting'
        },
        {
            title: 'تایید شده',
            quantity: options?.approved ?? 0,
            name:'approved'
        },
        {
            title: 'زباله',
            quantity: options?.trash ?? 0,
            name:'trash'
        },
    ]

    function active(item , el){
        setSelectedTab(item.name)
    }

    return(
        <>
        <div className="border-b border-b-gray-7 p-3 lg:p-2 max-lg:flex overflow-x-auto">
            {filters.map((item, id)=>(
                <button
                    onClick={ e => {
                        active(item , e)
                    } }
                    className={`text-xs w-max ml-4 whitespace-nowrap${selectedTab === item.name?' text-purple-1':''}`} key={id}>
                    {item.title}
                    &nbsp;
                    (
                        {item.quantity}
                    )
                </button>
            ))}
        </div>
        </>
    )
}