import React, { useState, useRef } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpFromBracket} from "@fortawesome/free-solid-svg-icons/faArrowUpFromBracket";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

function VideoUploader() {
    const [video, setVideo] = useState(null);
    const videoRef = useRef(null);

    const handleVideoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setVideo(URL.createObjectURL(file));
            if (videoRef.current) {
                videoRef.current.load();
            }
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            setVideo(URL.createObjectURL(file));
            if (videoRef.current) {
                videoRef.current.load();
            }
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const selectFile = () => {
        document.getElementById('videoInput').click();
    };

    const removeVideo = () => {
        setVideo(null);
    };

    return (
        <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={selectFile}
            className="w-full h-auto aspect-square rounded-md flex justify-center items-center bg-gray-7 cursor-pointer relative"
        >
            <input
                type="file"
                id="videoInput"
                accept="video/*"
                style={{ display: 'none' }}
                onChange={handleVideoChange}
            />
            {video ? (
                <div>
                    <video
                        ref={videoRef}
                        src={video}
                        controls
                        style={{ maxWidth: '100%' }}
                    />
                    <button
                        className="absolute text-xs bg-red-1 text-white rounded-full w-4 h-4 flex justify-center items-center aspect-square top-2 right-2"
                        onClick={removeVideo}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
            ) : (
                <div>
                    <FontAwesomeIcon className="text-3xl text-white" icon={faArrowUpFromBracket} />
                </div>
            )}
        </div>
    );
}

export default VideoUploader;
