import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faLocationPin, faTruckDroplet, faWalking} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import {DatePicker} from "zaman";
import {faNumberToEn} from "../../../helper/general";
import moment from "jalali-moment";
import Toman from "../../Utilities/Toman";
import Modal from "../../Utilities/Modal";
import CustomerAddressList from "../../Customer/CustomerAddressList";
import ApiRoute from "../../../helper/api";

export default function OrderShippingInPersonCustomerInfo({order, setUpdateState}){
    const [modal, setModal] = useState(null);
    const [selectedAddress , setSelectedAddress] = useState(order?.address)
    const address = order.address

    async function handleSubmit(){
        const res = await ApiRoute.order.shipment.updateAddress.fn(order._id,selectedAddress.id,{
            address : selectedAddress,
            customer_id: order.customer_id
        })
        if(res?.status_code === 200){
            setUpdateState(Date.now())
            setModal(false)
        }
    }

    return (
        <div>
            <div
                className="main-content before-table-style before-purple border border-gray-7 relative rounded-md p-5 mt-4">
                <div className="page-title mb-3">
                                <span className="inline-block bg-white pl-3 text-sm">
                                    تحویل حضوری
                                <FontAwesomeIcon className="mr-2 text-green-1" icon={faWalking}/>
                                </span>
                </div>
                <div
                    className="grid sm:grid-cols-2 xl:flex justify-between gap-3 lg:gap-5 text-xs mt-5">
                    <div>
                        <span className="text-gray-2">آدرس:</span>
                        <span className="text-gray-5 mr-1">{address.address}</span>
                    </div>
                </div>
                {/*<div
                    className="grid lg:flex justify-between items-center lg:grid-cols-2 gap-3 lg:gap-5 text-xs mt-5">

                    <div className="max-lg:text-left">
                        <button onClick={() => {
                            setModal(true)
                        }} className="btn-outline-gray">تغییر نشانی حضوری</button>
                    </div>

                </div>*/}
            </div>

            <Modal isOpen={modal} onClose={() => {
                setModal(false)
            }}>
                <div className="page-title mb-5"><span
                    className="inline-block bg-white pl-3 text-sm text-purple-1">تغییر آدرس</span>
                </div>
                <div className="form">
                    <CustomerAddressList setSelected={ (x) => {
                        setSelectedAddress(x)
                    } } selectAble={true} selected={selectedAddress} customClass={`main-content !shadow-none mx-auto p-0 mt-12`} customer={order.customer}/>

                    <button onClick={handleSubmit} className={`btn-green mt-3 py-1 text-13 rounded`}>بروزرسانی</button>

                </div>
            </Modal>

        </div>
    )
}