import Layout from "../../components/Layout"
import OrderList from "../../components/Order/OrderList";

function Orders() {
    return (
        <Layout>
            <OrderList/>
        </Layout>
    )
}

export default Orders;