import Layout from "../../components/Layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import CustomMultiSelect from "../../components/Utilities/CustomMultiSelect";
import {useState} from "react";

function CreateBrandRule() {
    const brands = [{value: '1', label: 'دوو'}, {value: '2', label: 'ال جی'}, {
        value: '3',
        label: 'سامسونگ'
    }, {value: '4', label: 'بکو'},];
    const selectedBrands = [{value: '1', label: 'دوو'}];
    const [selectedBrand, setSelectedBrand] = useState(selectedBrands);
    const handleMultiSelectBrand = (selectedBrand) => setSelectedBrand(selectedBrand)

    const data = [
        {
            title: 'دوو',
        }, {
            title: 'دوو',
        }, {
            title: 'دوو',
        }, {
            title: 'دوو',
        }, {
            title: 'دوو',
        }, {
            title: 'دوو',
        }, {
            title: 'دوو',
        }, {
            title: 'دوو',
        },
    ]
    const [isToggled, setToggled] = useState(null);

    const toggleSubgroup = (id) => setToggled(id);
    return (
        <Layout>
            <div>
                <div className="flex items-center justify-between mb-3">
                    <div className="page-title flex-auto ml-3 md:ml-0">
                        <span className="inline-block bg-white pl-3">قوانین قیمت گذاری</span>
                    </div>
                    <Link className="btn-blue-light text-center py-1.5 px-3 w-max md:hidden" to=""> افزودن قانون جدید</Link>
                </div>
                <Link className="btn-blue-light text-center hidden md:block mr-auto" to=""> افزودن قانون جدید</Link>


                <div className="main-content p-3 mt-3">

                    {data.map((item, id) =>
                        <div key={id}>
                            <div
                                className="flex justify-between text-xs items-center rounded box-tr-style py-1.5 px-5 my-2">
                                <div className="cursor-pointer"
                                     onClick={() => toggleSubgroup(isToggled !== id ? id : null)}>{item.title}</div>

                                <a href="/" title="؟؟؟"><FontAwesomeIcon
                                    className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></a>

                            </div>
                            <div
                                className={`px-5 sub-groups text-xs overflow-hidden ${isToggled === id ? 'show py-2' : ''}`}>
                                <div className="inner-bordered-box !border-none">
                                    <div className="flex items-center">
                                        <span className="pl-4">برند</span>
                                        <div className="CustomMultiSelectStyle2 flex-auto">
                                            <CustomMultiSelect
                                                options={brands}
                                                values={selectedBrand}
                                                onChange={handleMultiSelectBrand}
                                                placeholder="برند موردنظر را انتخاب کنید..."
                                            />
                                        </div>
                                    </div>
                                    <div className="text-xs text-purple-1 mb-2 mt-8 lg:mt-4">قوانین سایت</div>
                                    <div className="bg-purple-7 border border-purple-8 rounded-md p-3 text-xs">
                                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 2xl:gap-10">
                                            <div><label className="flex items-center">
                                                <span className="pl-2 text-gray-12">مرز حداقل</span>
                                                <input className="bordered-input-7 flex-auto" type="text"/>
                                            </label></div>
                                            <div><label className="flex items-center">
                                                <span className="pl-2 text-gray-12">مرز حداکثر</span>
                                                <input className="bordered-input-7 flex-auto" type="text"/>
                                            </label></div>
                                            <div><label className="flex items-center">
                                                <span className="pl-2 text-gray-12">کف قیمت</span>
                                                <input className="bordered-input-7 flex-auto" type="text"/>
                                            </label></div>
                                            <div><label className="flex items-center">
                                                <span className="pl-2 text-gray-12">سقف قیمت</span>
                                                <input className="bordered-input-7 flex-auto" type="text"/>
                                            </label></div>
                                            <div><label className="flex items-center">
                                                <span className="pl-2 text-gray-12">درصد پیش فروش</span>
                                                <input className="bordered-input-7 flex-auto" type="text"/>
                                            </label></div>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
                                        <div>
                                            <div className="text-xs text-purple-1 mt-4 mb-2">فروشگاه جزیره</div>
                                            <div className="bg-gray-7 rounded-md p-2 text-xs">
                                                <div className="grid grid-cols-1 xl:grid-cols-2 justify-between gap-3 2xl:gap-10">
                                                    <div><label className="flex items-center gap-2">
                                                        <span className="text-gray-12 w-max">کف قیمت</span>
                                                        <input className="bordered-input flex-auto !border-gray-9 w-3/5" type="text" />
                                                    </label></div>
                                                    <div><label className="flex items-center gap-2">
                                                        <span className="text-gray-12 w-max">درصد پیش فروش</span>
                                                        <input className="bordered-input flex-auto !border-gray-9 w-2/5" type="text" />
                                                    </label></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="text-xs text-purple-1 mt-4 mb-2">فروشگاه جزیره</div>
                                            <div className="bg-gray-7 rounded-md p-2 text-xs">
                                                <div className="grid grid-cols-1 xl:grid-cols-2 justify-between gap-3 2xl:gap-10">
                                                    <div><label className="flex items-center gap-2">
                                                        <span className="text-gray-12 w-max">کف قیمت</span>
                                                        <input className="bordered-input flex-auto !border-gray-9 w-3/5" type="text" />
                                                    </label></div>
                                                    <div><label className="flex items-center gap-2">
                                                        <span className="text-gray-12 w-max">درصد پیش فروش</span>
                                                        <input className="bordered-input flex-auto !border-gray-9 w-2/5" type="text" />
                                                    </label></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="text-xs text-purple-1 mt-4 mb-2">فروشگاه جزیره</div>
                                            <div className="bg-gray-7 rounded-md p-2 text-xs">
                                                <div className="grid grid-cols-1 xl:grid-cols-2 justify-between gap-3 2xl:gap-10">
                                                    <div><label className="flex items-center gap-2">
                                                        <span className="text-gray-12 w-max">کف قیمت</span>
                                                        <input className="bordered-input flex-auto !border-gray-9 w-3/5" type="text" />
                                                    </label></div>
                                                    <div><label className="flex items-center gap-2">
                                                        <span className="text-gray-12 w-max">درصد پیش فروش</span>
                                                        <input className="bordered-input flex-auto !border-gray-9 w-2/5" type="text" />
                                                    </label></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="text-xs text-purple-1 mt-4 mb-2">فروشگاه جزیره</div>
                                            <div className="bg-gray-7 rounded-md p-2 text-xs">
                                                <div className="grid grid-cols-1 xl:grid-cols-2 justify-between gap-3 2xl:gap-10">
                                                    <div><label className="flex items-center gap-2">
                                                        <span className="text-gray-12 w-max">کف قیمت</span>
                                                        <input className="bordered-input flex-auto !border-gray-9 w-3/5" type="text" />
                                                    </label></div>
                                                    <div><label className="flex items-center gap-2">
                                                        <span className="text-gray-12 w-max">درصد پیش فروش</span>
                                                        <input className="bordered-input flex-auto !border-gray-9 w-2/5" type="text" />
                                                    </label></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="text-xs text-purple-1 mt-4 mb-2">فروشگاه جزیره</div>
                                            <div className="bg-gray-7 rounded-md p-2 text-xs">
                                                <div className="grid grid-cols-1 xl:grid-cols-2 justify-between gap-3 2xl:gap-10">
                                                    <div><label className="flex items-center gap-2">
                                                        <span className="text-gray-12 w-max">کف قیمت</span>
                                                        <input className="bordered-input flex-auto !border-gray-9 w-3/5" type="text" />
                                                    </label></div>
                                                    <div><label className="flex items-center gap-2">
                                                        <span className="text-gray-12 w-max">درصد پیش فروش</span>
                                                        <input className="bordered-input flex-auto !border-gray-9 w-2/5" type="text" />
                                                    </label></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="text-xs text-purple-1 mt-4 mb-2">فروشگاه جزیره</div>
                                            <div className="bg-gray-7 rounded-md p-2 text-xs">
                                                <div className="grid grid-cols-1 xl:grid-cols-2 justify-between gap-3 2xl:gap-10">
                                                    <div><label className="flex items-center gap-2">
                                                        <span className="text-gray-12 w-max">کف قیمت</span>
                                                        <input className="bordered-input flex-auto !border-gray-9 w-3/5" type="text" />
                                                    </label></div>
                                                    <div><label className="flex items-center gap-2">
                                                        <span className="text-gray-12 w-max">درصد پیش فروش</span>
                                                        <input className="bordered-input flex-auto !border-gray-9 w-2/5" type="text" />
                                                    </label></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="text-xs text-purple-1 mt-4 mb-2">فروشگاه جزیره</div>
                                            <div className="bg-gray-7 rounded-md p-2 text-xs">
                                                <div className="grid grid-cols-1 xl:grid-cols-2 justify-between gap-3 2xl:gap-10">
                                                    <div><label className="flex items-center gap-2">
                                                        <span className="text-gray-12 w-max">کف قیمت</span>
                                                        <input className="bordered-input flex-auto !border-gray-9 w-3/5" type="text" />
                                                    </label></div>
                                                    <div><label className="flex items-center gap-2">
                                                        <span className="text-gray-12 w-max">درصد پیش فروش</span>
                                                        <input className="bordered-input flex-auto !border-gray-9 w-2/5" type="text" />
                                                    </label></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="text-xs text-purple-1 mt-4 mb-2">فروشگاه جزیره</div>
                                            <div className="bg-gray-7 rounded-md p-2 text-xs">
                                                <div className="grid grid-cols-1 xl:grid-cols-2 justify-between gap-3 2xl:gap-10">
                                                    <div><label className="flex items-center gap-2">
                                                        <span className="text-gray-12 w-max">کف قیمت</span>
                                                        <input className="bordered-input flex-auto !border-gray-9 w-3/5" type="text" />
                                                    </label></div>
                                                    <div><label className="flex items-center gap-2">
                                                        <span className="text-gray-12 w-max">درصد پیش فروش</span>
                                                        <input className="bordered-input flex-auto !border-gray-9 w-2/5" type="text" />
                                                    </label></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )}

                </div>
            </div>
        </Layout>
    )
}

export default CreateBrandRule