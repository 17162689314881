import Layout from "../../components/Layout";
import React, {useEffect, useState} from 'react';
import CustomMultiSelect from '../../components/Utilities/CustomMultiSelect'
import ApiRoute from "../../helper/api";
import GeneralForm from "../../components/Coupon/general-form";
import AdvanceForm from "../../components/Coupon/advance-form";


function EditCoupon() {

    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [update, setUpdate] = useState(0);
    const [data, setData] = useState(null);

    const [editData,setEditData] = useState({});

    useEffect(() => {
        getData()
    }, []);

    async function getData(){
        const response = await ApiRoute.coupon.single.fn(params.get('id'),'?model=\\App\\CouponManager\\Model\\CouponRender')
        setData(response.results)
    }

    async function handleSubmit() {
        await ApiRoute.coupon.update.fn(data._id,data)
    }

    return (
        <Layout>
            {data && <div>
                <div>
                    <div className="page-title mb-3"><span className="inline-block bg-white pl-3">ویرایش کد تخفیف</span>
                    </div>

                    <GeneralForm editData={data}/>

                    <AdvanceForm editData={data}/>

                </div>
                <div className="flex justify-center">
                    <button onClick={handleSubmit} className="btn-purple">ویرایش کد تخفیف</button>
                </div>
            </div>}
        </Layout>
    )
}

export default EditCoupon