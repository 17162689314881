import Layout from "../../components/Layout";
import SearchBox from "../../components/SearchBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import CustomerTable from "../../components/Tables/CustomerTable";
import {Link} from "react-router-dom";
import Pagination from "../../components/Pagination";
import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../helper/api";
import CustomerFilterBox from "../../components/Customer/CustomerFilterBox";
import CustomerStatsTabFilter from "../../components/Customer/CustomerStatsTabFilter";

function Customers() {

    const [showFilterBox, setShowFilterBox] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'cellphone'
    })
    const [filterQuery, setFilterQuery] = useState({})
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  res  = await ApiRoute.customer.index.fn({
                shop_id: shopId(),
                created_at:selectedStatsTab,
                page : pageNumber
            },filterQuery)
            setData(res)
            setIsLoading(false)
        })();
    }, [updateData]);

    //fetch stats-data from server
    useEffect(() => {
        (async () => {
            const  res  = await ApiRoute.customer.stats.fn({
                shop_id: shopId(),
            },filterQuery)
            setStatsTab(res.results)
        })();
    }, [data]);

    // watch searchQuery and update updateData state
    useEffect(() => {
        filterQuery.quick_search = searchQuery
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    // watch filterQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [filterQuery]);

    // watch statsTab and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [selectedStatsTab]);

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);


    const tableData = data?.results?.data ?? []
    const paginationData = data?.results
    const tableOptions= {rowSatus: true}

    const toggleFilterBox=()=> setShowFilterBox(!showFilterBox)
    return (
        <Layout>
            <div>
                <div className="flex items-center justify-between mb-3">
                    <div className="page-title flex-auto ml-3 md:ml-0">
                        <span className="inline-block bg-white pl-3">مشتریان</span>
                    </div>
                    <Link className="btn-blue text-center md:hidden" to="/customers/create"> افزودن مشتری جدید</Link>
                </div>

                <div className="grid md:flex justify-between items-center gap-3">
                    <div className="flex gap-3">
                        <SearchBox
                            SearchByOptions={ [
                                {
                                    label: 'شماره همراه',
                                    value: 'cellphone'
                                },
                                {
                                    label: 'کد اسان',
                                    value: 'asan_id'
                                },
                                {
                                    label: 'کد ملی',
                                    value: 'national_code'
                                },
                                {
                                    label: 'نام مشتری',
                                    value: 'name'
                                }
                            ] }
                            SearchByDefault="cellphone"
                            SearchIn={true}
                            SetSearchQuery={setSearchQuery} />
                        <button className="btn-blue w-12" onClick={toggleFilterBox}><FontAwesomeIcon icon={faFilter}/></button>
                    </div>
                    <Link className="btn-blue text-center hidden md:block" to="/customers/create">افزودن مشتری جدید</Link>
                </div>
                <CustomerFilterBox showHide={showFilterBox} SetFilterQuery={setFilterQuery}/>

                {!isLoading ? (
                    <div className="main-content p-1 mt-3">
                        { statsTab !== null ? (
                            <CustomerStatsTabFilter selectedTab={selectedStatsTab} setSelectedTab={setSelectedStatsTab} options={statsTab} />
                        ) : '' }
                        <CustomerTable tableData={tableData} tableOptions={tableOptions} />
                        <Pagination SetPageNumber={setPageNumber} Data={paginationData} />
                    </div>
                ) : ''}
            </div>
        </Layout>
    )
}

export default Customers