import {useState} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare, faPencil, faTimesSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import {getDateAsReadableFormat} from "../helper/general";
import ApiRoute from "../helper/api";
import {toast} from "react-toastify";

function Comment({comment,setUpdateData}) {
    const [showActionMenu, setShowActionMenu] = useState(false)
    const menuToggle = () => {
        setShowActionMenu(!showActionMenu);
    }

    function score(){
        const count = Object.keys(comment.reviews).length
        let score = 0
        Object.entries(comment.reviews).map( item => {score = score + item[1]} )
        return score / count
    }

    async function approve(){
        await ApiRoute.comments.approved.fn(comment._id)
        setUpdateData(Date.now())
    }
    async function trash(){
        await ApiRoute.comments.trash.fn(comment._id)
        setUpdateData(Date.now())
    }

    return (
        <>
            <div className={`fixed w-screen h-screen ${showActionMenu ? 'block' : 'hidden'}`}
                 onClick={menuToggle}></div>
            <div className="comment border border-gray-7 rounded-md p-2">
                <div className="grid xl:flex text-xs items-center gap-4">
                    <img src={comment.product.attachments[0].url} width="57"/>
                    <div className="w-full">
                        <div
                            className="grid sm:grid-cols-2 xl:flex gap-3 w-full justify-between items-center border-b border-b-gray-7 pb-2 text-13">
                            <div className="sm:col-span-2">{comment.product.title}</div>
                            <div className="text-gray-10">
                                <span className="bg-green-1 text-white rounded px-2 py-0.5 ml-5">{score()}</span>
                                <span>{getDateAsReadableFormat(comment.created_at)}</span>
                            </div>
                            <div className="text-gray-10">
                                <span> مشتری: {comment.customer_name} </span>
                                {comment.is_buyer && <span className="bg-green-1 text-white rounded px-2 py-0.5 mr-5"> خریدار </span>}
                            </div>

                            <Link to={`/customers/view/${comment.customer_id}`} className="text-gray-10 hover:text-purple-3">
                                شناسه: {comment.customer_id}
                            </Link>
                            <div className="flex gap-5 items-center justify-end max-sm:flex-row-reverse">

                                {comment?.status === "waiting" && <span
                                    className="text-orange-2 border border-orange-2 px-2 py-1 rounded-md bg-orange-3">در انتظار</span>}
                                {comment?.status === "trash" && <span
                                    className="text-orange-2 border border-red-2 px-2 py-1 rounded-md bg-red-2">زباله</span>}

                                <div className="relative">
                                    {comment?.status === "waiting" && <button onClick={() => {
                                        approve()
                                    }} className="hover:text-purple-1">
                                        <FontAwesomeIcon className="ml-2" icon={faCheckSquare}/>
                                        تایید
                                    </button>}
                                    {(comment?.status !== "trash") && <button onClick={() => {
                                        trash()
                                    }} className="hover:text-red-1">
                                        <FontAwesomeIcon className="ml-2" icon={faTrash}/>
                                        رد کردن
                                    </button>}
                                </div>

                            </div>
                        </div>
                        <span className="text-11 block pt-4 lg:pt-1">
                                    عنوان: {comment.title}
                                </span>
                    </div>
                </div>
                <div className="text-xs leading-5 my-4" dangerouslySetInnerHTML={{__html:comment.content}}></div>
                <div className="grid sm:grid-cols-2 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-8 gap-5 py-2">
                    <div>
                        {comment?.pros?.length > 0 && comment.pros.map( (item,id) => <div key={id} className="flex items-center py-1">
                            <span className="text-green-1 text-xl ml-2 leading-3">+</span>
                            <span className="text-sm leading-3 pb-1">{item}</span>
                        </div> )}
                    </div>
                    <div>
                        {comment?.cons?.length > 0 && comment.cons.map( (item,id) => <div key={id} className="flex items-center py-1">
                            <span className="text-red-1 text-xl ml-2 leading-3">-</span>
                            <span className="text-sm leading-3 pb-1">{item}</span>
                        </div> )}
                    </div>

                    <div className="xl:col-start-4 2xl:col-start-6">
                        <div className="flex items-center justify-between sm:justify-start gap-3 lg:justify-between py-1 text-gray-10 text-xs">
                            <span>کیفیت ساخت</span>
                            <span className="bg-green-1 text-white rounded px-2 py-0.5">{comment.reviews.build_quality}</span>
                        </div>
                        <div className="flex items-center justify-between sm:justify-start gap-3 lg:justify-between py-1 text-gray-10 text-xs">
                            <span>ارزش خرید به نسبت قیمت</span>
                            <span className="bg-green-1 text-white rounded px-2 py-0.5">{comment.reviews.price_value}</span>
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center justify-between sm:justify-start gap-3 lg:justify-between py-1 text-gray-10 text-xs">
                            <span>سهولت استفاده</span>
                            <span className="bg-green-1 text-white rounded px-2 py-0.5">{comment.reviews.guide_ease_of_use}</span>
                        </div>
                        <div className="flex items-center justify-between sm:justify-start gap-3 lg:justify-between py-1 text-gray-10 text-xs">
                            <span>پشتیبانی و گارانتی</span>
                            <span className="bg-green-1 text-white rounded px-2 py-0.5">{comment.reviews.services_warranty}</span>
                        </div>
                    </div>
                    {/*<div className="images flex items-end justify-end gap-1">
                        <img src="/images/img-view.png" className="rounded-lg" width="30" height="30"
                             alt="user images"/>
                        <img src="/images/img1.png" className="rounded-lg" width="30" height="30"
                             alt="user images"/>
                        <img src="/images/img1.png" className="rounded-lg" width="30" height="30"
                             alt="user images"/>
                        <img src="/images/img1.png" className="rounded-lg" width="30" height="30"
                             alt="user images"/>
                    </div>*/}
                </div>
            </div>
        </>
    );
}

export default Comment;