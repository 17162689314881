import SwitchToggle from "../../../Utilities/SwitchToggle";
import ApiRoute from "../../../../helper/api";
import {HasAccessComponent} from "../../../../helper/permissions";

export default function OrderPrintStatus({order, setUpdateState}){
    return (
       <HasAccessComponent permission={`customer_print order`} children={
           <div className="flex justify-between pb-3 border-b border-b-gray-7 mt-4">
               <span>نمایش پیش فاکتور در پنل کاربری مشتری</span>
               <SwitchToggle  onSwitch={ async (c) => {
                   await ApiRoute.order.action.fn("pre_print/allow",order._id)
               } } onOff={order?.metadata?.show_print_pre_invoice ?? false}/>
           </div>
       }/>
    )
}