import ImageUploader from "../../Utilities/ImageUploader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faChevronDown, faChevronUp, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import FileUpload from "../../FileUpload";
import ComponentTitle from "./Common/ComponentTitle";

export default function CampaignBox({component , remove , up, down}) {
    const [updateState, setUpdateState] = useState(1)
    const [items, setItems] = useState(component.data)
    const [open, setOpen] = useState(false)

    function sync() {
        setUpdateState(Date.now())
    }

    function lastOrder() {
        let lastItem = null;
        component.data.sort((firstItem, secondItem) => firstItem.order - secondItem.order).map(item => {
            lastItem = item
            return
        })
        if (lastItem?.order) {
            return lastItem.order + 1;
        }
        return 0
    }

    function removeFormData(data, index) {
        component.data.splice(index, 1)
        sync()
    }

    function moveData(data, index, action = 'up') {
        let inx = index - 1
        if (action != 'up') {
            inx = index + 1
        }

        if (index == 0 && action == 'up') {
            return;
        }

        let el = data.order;
        data.order = component.data[inx].order;

        component.data.map((item, i) => {
            if (i == (inx)) {
                item.order = el
            }
        })

        sync()


    }


    return (
        <div className="flex flex-auto my-3">

            <div className="w-full border border-gray-7 rounded p-1 grid gap-2">

                <ComponentTitle component={component} color={`#e91e63`} title={"کمپین"} open={open} setOpen={ setOpen } remove={remove} up={up} down={down}/>


                {open && <div>
                    <select onChange={ (e) => {
                        component.layout = e.target.value
                        sync()
                    } } className={`input-x`}>
                        <option selected={component.layout == 'col-1'} value={`col-1`}>یک از دوازده</option>
                        <option selected={component.layout == 'col-2'} value={`col-2`}>دو از دوازده</option>
                        <option selected={component.layout == 'col-3'} value={`col-3`}>سه از دوازده</option>
                        <option selected={component.layout == 'col-4'} value={`col-4`}>چهار از دوازده</option>
                        <option selected={component.layout == 'col-5'} value={`col-5`}>پنج از دوازده</option>
                        <option selected={component.layout == 'col-6'} value={`col-6`}>نصف صفحه</option>
                        <option selected={component.layout == 'col-7'} value={`col-7`}>هفت از دوازده</option>
                        <option selected={component.layout == 'col-8'} value={`col-8`}>هشت از دوازده</option>
                        <option selected={component.layout == 'col-9'} value={`col-9`}>نه از دوازده</option>
                        <option selected={component.layout == 'col-10'} value={`col-10`}>ده از دوازده</option>
                        <option selected={component.layout == 'col-11'} value={`col-11`}>یازده از دوازده</option>
                        <option selected={component.layout == 'col-12'} value={`col-12`}>تمام صفحه</option>
                    </select>


                    <div className={`flex text-13 gap-3 items-center`}>
                        <div className={`w-[100px]`}>شناسه کمپین</div>
                        <input className={`input-x`} onChange={ e => {
                            component.data.campaign_id = e.target.value
                            component.data.request_url = `/campaigns/${e.target.value}`
                        } } type={`text`} defaultValue={component.data.campaign_id}/>
                    </div>

                    <div className={`flex text-13 gap-3 items-center`}>
                        <div className={`w-[100px]`}>عنوان</div>
                        <input className={`input-x`} onChange={ e => {
                            component.attributes.title = e.target.value
                        } } type={`text`} defaultValue={component.attributes.title}/>
                    </div>

                    <div className={`flex text-13 gap-3 items-center`}>
                        <div className={`w-[100px]`}>توضیحات</div>
                        <input className={`input-x`} onInput={ e => {
                            component.attributes.desc = e.target.value
                        } } type={`text`} defaultValue={component.attributes.desc}/>
                    </div>

                    <div className={`flex text-13 gap-3 items-center`}>
                        <div className={`w-[100px]`}>آدرس</div>
                        <input className={`input-x`} onInput={ e => {
                            component.attributes.url = e.target.value
                        } } type={`text`} defaultValue={component?.attributes?.url}/>
                    </div>

                    <div className={`mt-4 text-13 gap-3 w-full items-center`}>
                        <div className={`w-[100px]`}>تصویر عنوان</div>
                        <FileUpload defaultFile={component?.attributes?.image && [
                            {
                                source: component?.attributes?.image,
                                options: {
                                    type: 'local',
                                },
                            },
                        ]} setCover={(v) => {
                            component.attributes.image = v
                            sync()
                        }}/>
                    </div>

                    <div className={`mt-4 text-13 gap-3 w-full items-center`}>
                        <div className={`w-[100px]`}>تصویر ایکون</div>
                        <FileUpload defaultFile={component?.attributes?.icon && [
                            {
                                source: component?.attributes?.icon,
                                options: {
                                    type: 'local',
                                },
                            },
                        ]} setCover={(v) => {
                            component.attributes.icon = v
                            sync()
                        }}/>
                    </div>

                    <div className={`mt-4 text-13 gap-3 w-full items-center`}>
                        <div className={`w-[100px]`}>تصویر عنوان موبایل</div>
                        <FileUpload defaultFile={component?.attributes?.image_sm && [
                            {
                                source: component?.attributes?.image_sm,
                                options: {
                                    type: 'local',
                                },
                            },
                        ]} setCover={(v) => {
                            component.attributes.image_sm = v
                            sync()
                        }}/>
                    </div>

                </div>}


            </div>
        </div>
    )
}