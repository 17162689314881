import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {faCaretUp, faCircle, faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ApiRoute, {isPrimaryShop} from "../../helper/api";
import {toast} from "react-toastify";

export default function ProductSelect ({
                                           assignItem,
                                           defaultValueAsNative = null,
                                           defaultValueAs = "slug",
                                           defaultValue = [],
                                           placeholder = 'انتخاب محصول' ,
                                           fixed = false ,
                                           multiple = false,
                                           live=true,
                                           allowOutStock=false,
                                           inStock = false
}) {

    const [show,setShow] = useState(false)
    const [term,setTerm] = useState('')
    const [termType,setTermType] = useState('title')
    const [selected,setSelected] = useState([])
    const [data,setData] = useState(null)
    const [myDefaultValue,setMyDefaultValue] = useState([])
    const [updateData,setUpdateData] = useState('1')

    useEffect(() => {
        syncDefaultValue()
    }, [defaultValue]);

    useEffect(() => {
        assignItem(selected , setSelected, setUpdateData)
    }, [selected]);

    useEffect(() => {
        if (defaultValue.length > 0) {
            setSelected([])
        }
    }, [updateData]);


    const syncDefaultValue = async () => {

        console.log(defaultValueAsNative)
        if (defaultValueAsNative !== null && defaultValueAsNative !== undefined && defaultValueAsNative.length > 0) {
            setSelected(defaultValueAsNative)
        }

        if (defaultValue.length > 0){
            try {
                let res = []
                if (defaultValueAs === "slug"){
                    res = await ApiRoute.product.getBySlugs.fn(defaultValue)
                }else if(defaultValueAs === "id"){
                    res = await ApiRoute.product.getByIds.fn(defaultValue)
                }
                setSelected(res.results)
            }catch (e) {
                console.log(e)
            }
        }else{
            setUpdateData(Date.now().toString())
        }

    }

    const getCategory = async () => {
        let obj = {
            /*status: 'publish'*/
        };
        if (termType === 'title'){
            obj.title = term
        }else{
            obj.asan_id = term
        }
        const res = await ApiRoute.product.index.fn(obj)
        setData(res.results.data)
    }

    function has(item){
        for (const selectedElement of selected) {
            if (selectedElement._id === item._id){ return true }
        }
        return false
    }

    async function openMenu(){
        setShow(!show)
        if (live){
            await getCategory()
        }else{
            if (data == null){
                await getCategory()
            }
        }
    }

    return (
        <div className={"relative w-full"}>
            <div className={`flex items-center gap-1 justify-between`}>
                <div className={`w-full mt-1 gap-0 flex items-center`}>
                    <button onClick={ async () => {
                        setData(null)
                        setShow(true)
                        await getCategory()
                    } } className={`btn-red py-0 h-[35px]`}><FontAwesomeIcon icon={faSearch}/></button>
                    <input
                        onKeyUp={ async (e) => {
                            setTerm(e.target.value)
                        } }
                        className="input-x h-[35px] bg-gray-1"
                        placeholder={placeholder}
                        type={"search"}/>
                    <select onChange={ (e) => {
                        setTermType(e.target.value)
                    } } className={`input-x !w-[90px] h-[35px] bg-gray-1`}>
                        <option value={`title`}>عنوان</option>
                        <option value={`asan_id`}>کد آسان</option>
                    </select>
                </div>
                <div onClick={openMenu} className={`bg-gray-4 flex justify-center items-center text-white w-[30px] h-[30px]`}>
                    <FontAwesomeIcon className={`text-lg text-white`}
                                     icon={faCaretUp}/>
                </div>
            </div>
            {(show && data) && <div className={`${fixed ? 'fixed w-content' : 'absolute'} z-[10] overflow-auto h-[250px] z-[9999] w-full flex flex-col gap-3 bg-white border border-gray-3 p-2`}>
                {data.map((item) => <div key={item._id}
                                         onClick={ () => {
                                             if ( (item?.stocks?.live > 0) ){
                                                 if (!has(item)){
                                                     if (multiple === true){
                                                         setSelected(selected => [...selected, item])
                                                     }else{
                                                         setSelected([item])
                                                     }

                                                 }
                                                 setShow(false)
                                             }else{
                                                 if (allowOutStock){
                                                     if (!has(item)){
                                                         if (multiple === true){
                                                             setSelected(selected => [...selected, item])
                                                         }else{
                                                             setSelected([item])
                                                         }

                                                     }
                                                     setShow(false)
                                                 }else{
                                                     toast.error("محصول مورد نظر ناموجود است")
                                                 }
                                             }
                                         }}
                                         className={"hover:text-blue-1 cursor-pointer"}>
                    {isPrimaryShop() && <div className={`flex flex-wrap gap-2 justify-between text-13`}>
                        <div>
                            {item.title}
                            {item?.stocks?.live < 1 && (<span className={`text-red-2 mr-1`}>(ناموجود)</span>)}
                        </div>
                        <div className={`flex items-center gap-2`}>
                            <div className={`text-green-2`}>م ({item.stocks.web_site})</div>
                            <div className={`text-blue-4`}> م.پ ({item.stocks.presell})</div>
                        </div>
                    </div>}
                    {!isPrimaryShop() && <div className={`flex flex-wrap gap-2 justify-between text-13`}>
                        <div>
                            {item.title}
                            {item?.stocks?.live < 1 && (<span className={`text-red-2 mr-1`}>(ناموجود)</span>)}
                        </div>
                        <div className={`flex items-center gap-2`}>
                            <div className={`text-green-2`}>م ({item.stocks.live})</div>
                            <div className={`text-green-2`}>ق.س ({item.prices.regular === 0 ? item.prices.price : item.prices.regular})</div>
                        </div>
                    </div>}
                </div>)}
                {data.length < 1 && <div>محصولی یافت نشد</div>}
            </div>}

            {selected?.length > 0 && <div className={`flex p-2 flex-wrap gap-2 mt-1`}>
                {selected.map((item) => <div onClick={ () => {
                    setShow(false)
                    setSelected(selected.filter(xitem => xitem._id !== item._id))
                } } className={`text-[11px] flex cursor-pointer justify-between bg-green-2 hover:bg-red-1 text-white rounded px-2`} key={item._id}>
                    <div>{item.title}</div>
                </div>)}
            </div>}
        </div>

    );
};