import {getDateAsReadableFormat} from "../../helper/general";
import ApiRoute from "../../helper/api";
import { Link } from "react-router-dom";

function AlertStockTable(props) {
    const tableTitles = [
        'نام کالا',
        'دسته',
        'برند',
        'توسط',
        'از طریق',
        'تاریخ',
        'وضعیت',
    ]
    return (
        <table className="table-auto w-full block lg:table mt-4">
            <thead>
            <tr className="border-b border-b-gray-7">
                {tableTitles.map((item, id) => (
                    <td className="text-13 text-gray-6 pb-2" key={id}>{item}</td>
                ))}
                <td></td>
            </tr>
            </thead>
            <tbody>
            {props.tableData.map(tableData => (
                    <tr className={`text-xs py-2`} key={tableData.id}>
                        <td>
                            <Link className="hover:underline" target='_blank' to={`/inventory?id=${tableData.product.id}`}>{tableData.product.title}</Link>
                        </td>
                        <td>{tableData.product_categories.filter( item => item.slug != "product" ).map( item => <p>{item.label}</p> )}</td>
                        <td>{tableData.product_brands.map( item => <p>{item.label}</p> )}</td>
                        <td>
                            {tableData.user_type == "employee" ? "کارمند" : "مشتری"}
                            {(tableData.user_type == "employee" && tableData.user.name) && ` (${tableData.user.name}) ` }
                        </td>
                        <td>
                            {tableData.type == "inventory" && "انبارداری"}
                            {tableData.type == "operator" && "سفارش پنل"}
                            {tableData.type == "customer" && "سفارش سایت"}
                        </td>
                        <td>{getDateAsReadableFormat(tableData.created_at,true)}</td>
                        <td>
                            {tableData.status === "unread" && "خوانده نشده"}
                            {tableData.status === "read" && "خوانده شده"}
                            {tableData.status === "waiting" && "در انتظار"}
                        </td>
                        <td className="text-end pl-5">
                            <div className="flex">
                                <button 
                                    onClick={ async () => {
                                        await ApiRoute.stock.alert.updateStatus.fn("waiting",tableData._id)
                                        props.setUpdateData(Date.now())
                                    }} 
                                    className={`btn-gray py-2 mr-2 text-xs w-max ${tableData.status === "waiting" ? "bg-yellow-1" : ""}`}
                                >
                                    در انتظار
                                </button>
                                <button 
                                    onClick={ async () => {
                                        await ApiRoute.stock.alert.updateStatus.fn("read",tableData._id)
                                        props.setUpdateData(Date.now())
                                    }} 
                                    className={`btn-gray py-2 mr-2 text-xs w-max ${tableData.status === "read" ? "bg-green-1" : ""}`}
                                >
                                    خوانده شده
                                </button>
                                <button 
                                    onClick={ async () => {
                                        await ApiRoute.stock.alert.updateStatus.fn("unread",tableData._id)
                                        props.setUpdateData(Date.now())
                                    }} 
                                    className={`btn-gray py-2 mr-2 text-xs w-max ${tableData.status === "unread" ? "bg-red-1" : ""}`}
                                >
                                    خوانده نشده
                                </button>
                                <button 
                                    onClick={ async () => {
                                        if (window.confirm('آیا از حذف این مورد اطمینان دارید؟')) {
                                            await ApiRoute.stock.alert.delete.fn(tableData._id)
                                            props.setUpdateData(Date.now())
                                        }
                                    }} 
                                    className={`btn-gray bg-red-2 py-2 mr-2 text-xs w-max`}
                                >
                                    حذف
                                </button>
                            </div>
                        </td>
                    </tr>
                )
            )}
            </tbody>
        </table>
    )
}

export default AlertStockTable