import {getDateAsReadableFormat} from "../../../../helper/general";
import {useEffect, useState} from "react";
import StorageSelect from "../../../Common/StorageSelect";
import TransportSelect from "../../../Common/TransportSelect";
import Modal from "../../../Utilities/Modal";
import PeriodTime from "../../../PeriodTime";
import ApiRoute, {shopId} from "../../../../helper/api";
import {HasAccessComponent} from "../../../../helper/permissions";

export default function OrderDeliveryTime({order, title = 'زمان ارسال', event = null, inlineMode = false,  setUpdateState, upd = 0 , onCLoseModal = null , hideButton = false , modalInit = false}){
    const [modal, setModal] = useState(modalInit)
    const [periods,setPeriods] = useState(null)
    const [selectedPeriod,setSelectedPeriod] = useState(null)
    const [loading,setLoading] = useState(false)

    useEffect(() => {
        getPeriods()
    },[]);

    async function getPeriods(){
        setPeriods(null)
        setLoading(true)
        let products = []
        order.items.filter( (item) => item.item_type === "product" ).map( (item) => {
            products.push(item.item_product)
        } )
        order.items.filter( (item) => item?.item_sub_type === "private_product" ).map( (item) => {
            products.push(item)
        } )
        const res = await ApiRoute.shipment.delivery.getDeliveryPeriods.fn({
            products : products,
            address: order.address,
            payment_method: order?.payment_method?.name,
            shop_id: shopId(),
            is_preorder:order?.is_preorder ?? false,
            is_shipping_in_person:order?.is_shipping_in_person ?? false,
            customer_id:order.customer_id
        });
        if (res.status_code === 200){
            setPeriods(res.results)
        }
        setLoading(false)
    }

    async function handleUpdate(){
        let e = selectedPeriod
        e.customer_id = order.customer_id
        const res = await ApiRoute.order.shipment.updateDeliveryTime.fn(order._id, e);
        if (res?.status_code === 200){
            setUpdateState(Date.now());
            setModal(false)
            if (event !== null){
                event()
            }
        }
    }

    return (
        <div>

            {hideButton === false && <div className="justify-between items-center mt-4">
                <span>{title}</span>
                <div className="">
                    {order?.delivery_time?.start_date && <div className={`flex mt-3 gap-1`}>
                        <span>{getDateAsReadableFormat(order.delivery_time?.start_date)}</span>
                        <span>{order.delivery_time.start_pick} تا {order.delivery_time.end_pick}</span>
                    </div>}
                    <button onClick={() => {
                        setModal(true)
                        getPeriods()
                    }} className={`btn mt-1 text-sm w-full py-1 btn-blue-light`}>انتخاب زمان ارسال
                    </button>
                </div>
            </div>}

            {inlineMode && <div>

                <div className="page-title z-1 flex justify-between text-right text-sm">
                    <div className="inline-block bg-white pl-3 pr-1 mb-3 relative z-2">{title}</div>
                    {loading === true && <div className="x2-loader"></div>}
                </div>

                <div className={`flex gap-3 w-full flex-col`}>
                    {periods && <PeriodTime periods={periods} onSelect={(s) => {
                        setSelectedPeriod(s)
                    }}/>}
                    <HasAccessComponent permission={`change_delivery_time order`} children={
                        periods && <button disabled={selectedPeriod === null} onClick={handleUpdate} className={`btn btn-green text-13 py-1 max-w-max rounded`}>تایید و ذخیره</button>
                    }/>
                </div>

            </div>}

            <Modal isOpen={modal} onClose={() => {
                setModal(false)
                if (onCLoseModal !== null){
                    onCLoseModal()
                }
            }}>
                <div>

                    <div className="page-title z-1 flex justify-between text-right text-sm">
                        <div className="inline-block bg-white pl-3 pr-1 mb-3 relative z-2">زمان ارسال</div>
                        {loading === true && <div className="x2-loader"></div>}
                    </div>

                    <div className={`flex gap-3 w-full flex-col`}>
                        {periods && <PeriodTime periods={periods} onSelect={(s) => {
                            setSelectedPeriod(s)
                        }}/>}


                        <HasAccessComponent permission={`change_delivery_time order`} children={
                            periods && <button disabled={selectedPeriod === null} onClick={handleUpdate} className={`btn btn-green text-13 py-1 max-w-max rounded`}>تایید و ذخیره</button>
                        }/>
                    </div>

                </div>
            </Modal>

        </div>
    )
}