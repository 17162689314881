import {Link, useNavigate} from "react-router-dom";
import SelectCoordinates from "../Coordinates/SelectCoordinates";
import {useEffect, useState} from "react";
import ApiRoute from "../../helper/api";
import MyMap from "./Address/MyMap";
import Select from 'react-select';
import SelectCoordinates2 from "../Coordinates/SelectCoordinates2";

export default function CustomerAddressEdit({customer_id,address_id,modalAction,modalUpdate,asModal = false}){

    const [addressId , setAddressId] = useState(address_id)
    const [customerId , setCustomerId] = useState(customer_id)
    const [areas, setAreas] = useState(null) // اضافه کردن این خط

    const navigate = useNavigate();
    const [isLoading , setIsLoading] = useState(true)
    const [showMap, setShowMap] = useState(false)
    const [coordinate  , setCoordinate] = useState(null)
    const [data, setData] = useState({})
    const [updateData, setUpdateData] = useState(0)
    const [address , setAddress] = useState({
        active:true,
        first:true,
        main:true,
        first_name : '',
        last_name : '',
        cellphone: '',
        coordinates : {
            state: {},
            area:{},
            city: {},
            lat:123456789,
            lng:123456789
        },
        address: {
            title:'',
            address: '',
            desc:'',
            floor:'',
            house_name:'',
            house_number:'',
            postcode:'',
            unit:''
        }
    })

    useEffect(() => {
        setAddressId(address_id)
        setCustomerId(customer_id)
        setUpdateData(Date.now())
    }, [address_id,customer_id,modalUpdate]);

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const res  = await ApiRoute.customer.single.fn(customerId)
            setData(res.results)
            setIsLoading(false)
        })();
    },[updateData]);

    useEffect(() => {
        (async () => {
            if (data != null && data.addresses ){

                for (const item of data.addresses) {
                    if (item.id == addressId){
                        setAddress(item)
                    }
                }

            }
        })();
    },[data]);

    async function validateAndStoreAddressOnEdge() {
        setIsLoading(true)

        await ApiRoute.customer.updateAddress.fn(customerId, addressId , address)

        setIsLoading(false)

        if (asModal){
            modalAction()
        }
    }

    // تابع جدید برای بررسی استان‌های مجاز
    const isAllowedProvince = (coordinates) => {
        const allowedProvinces = ['Tehran', 'Alborz'];
        return coordinates?.state?.enname && allowedProvinces.includes(coordinates.state.enname);
    };

    return (
        <div>

            <div className="page-title mb-3"><span className="inline-block bg-white pl-3">ویرایش آدرس</span></div>

            {isLoading ? "" : (
                <>

                    <div className={!asModal ? 'main-content xl:w-3/4 mx-auto p-6 lg:p-8 mt-12' : ''}>

                    
                        <div className={`form `+(!asModal ? '2xl:w-3/5 mx-auto' : '')}>

                            <div className="grid grid-cols-2 lg:flex text-[#000] justify-center pt-4 pb-7 xl:pb-12 items-center gap-4 xl:gap-10 text-16">
                                {!asModal && <Link className="text-purple-1" to={`/customers/view/`+customerId}><span>برگشت به کاربر</span></Link>}
                                <span className="max-lg:col-span-2">{data.personal.first_name} {data.personal.last_name} به شماره همراه  {data.contacts.cellphone}</span>
                            </div>

                            {!showMap && isAllowedProvince(address.coordinates) && (
                                <button onClick={() => setShowMap(true)} className="btn-purple mt-0 mb-4 py-1 w-full">
                                    تغییر موقعیت مکانی
                                </button>
                            )}

                            {showMap && <MyMap setLat={address.coordinates?.lat ?? 35.68418487179686} setLng={address.coordinates?.lng ?? 51.438600113581174} setExportCoordinates={ (results , parsimapData , lat, lng) => {
                                                console.log(results)
                                                address.address.address = parsimapData.address
                                                address.coordinates.lat = lat
                                                address.coordinates.lng = lng
                                                address.coordinates.state = results.state
                                                address.coordinates.city = results?.city ?? {}
                                                address.coordinates.area = null
                                                setAreas(results.area)
                                                setAddress(address)
                                                setShowMap(false)
                                        } }/>}

                            {!showMap && <div className="grid sm:grid-cols-2 xl:grid-cols-3 justify-items-stretch mb-10 gap-5 lg:gap-10">
                                <div><label className="!grid">
                                    <span className="text-right mb-3 pr-1">نام</span>
                                    <input className="!flex-none !mr-0"
                                           defaultValue={address.first_name}
                                           onChange={ e => {
                                               address.first_name = e.target.value
                                               setAddress(address)
                                           } }
                                           type="text" placeholder="نام را وارد کنید"/>
                                </label></div>
                                <div><label className="!grid">
                                    <span className="text-right mb-3 pr-1">نام خانوادگی</span>
                                    <input className="!flex-none !mr-0"
                                           defaultValue={address.last_name}
                                           onChange={ e => {
                                               address.last_name = e.target.value
                                               setAddress(address)
                                           } }
                                           type="text"
                                           placeholder="نام خانوادگی را وارد کنید"/>
                                </label></div>
                                <div><label className="!grid">
                                    <span className="text-right mb-3 pr-1">شماره همراه</span>
                                    <input className="!flex-none !mr-0"
                                           defaultValue={address.cellphone}
                                           onChange={ e => {
                                               address.cellphone = e.target.value
                                               setAddress(address)
                                           } }
                                           type="text" placeholder="شماره همراه را وارد کنید"/>
                                </label></div>

                                {!isAllowedProvince(address.coordinates) && <div className="col-span-full"><div className="!grid">
                                    <span className="text-right mb-0 text-[12px] pr-1">موقعیت</span>
                                    <div>
                                      
                                    <SelectCoordinates2 setCoordinate={(coordinates) => {
                                            address.coordinates.state = coordinates.state[0];
                                            address.coordinates.city = coordinates.city[0];
                                            address.coordinates.area = coordinates.area[0];
                                            setAddress(address);
                                        }} defaultValue={address.coordinates} multiple={false}/>
                                        
                                    </div>
                                </div></div>}
                                
                                {isAllowedProvince(address.coordinates) && <div className="col-span-full text-[13px] gap-3">
                                    <div className="flex items-center mb-4">
        
                    

                    <div className="w-full">
                   
                        <SelectCoordinates2 setCoordinate={(coordinates) => {
                            address.coordinates.state = coordinates.state[0];
                            address.coordinates.city = coordinates.city[0];
                            address.coordinates.area = coordinates.area[0];
                            setAddress(address);
                          }} defaultValue={address.coordinates} multiple={false}/>

                    </div>
                </div>
              </div>}

                                <div className="col-span-full"><label className="!grid">
                                    <span className="text-right mb-3 pr-1">آدرس</span>
                                    <input className="!flex-none !mr-0"
                                           defaultValue={address.address.address}
                                           onChange={ e => {
                                               address.address.address = e.target.value
                                               setAddress(address)
                                           } }
                                           type="text"/>
                                </label></div>
                                <div><label className="!grid">
                                    <span className="text-right mb-3 pr-1">عنوان آدرس</span>
                                    <input className="!flex-none !mr-0"
                                           defaultValue={address.address.title}
                                           onChange={ e => {
                                               address.address.title = e.target.value
                                               setAddress(address)
                                           } }
                                           placeholder="مانند خانه یا دفتر اصلی و ..."
                                           type="text"/>
                                </label></div>
                                <div><label className="!grid">
                                    <span className="text-right mb-3 pr-1">توضیحات آدرس</span>
                                    <input className="!flex-none !mr-0"
                                           defaultValue={address.address.desc}
                                           onChange={ e => {
                                               address.address.desc = e.target.value
                                               setAddress(address)
                                           } }
                                           placeholder="توضیحات را اینجا بنویسید ..."
                                           type="text"/>
                                </label></div>
                                <div><label className="!grid">
                                    <span className="text-right mb-3 pr-1">طبقه</span>
                                    <input className="!flex-none !mr-0"
                                           defaultValue={address.address.floor}
                                           onChange={ e => {
                                               address.address.floor = e.target.value
                                               setAddress(address)
                                           } }
                                           placeholder="اینجا بنویسید ..."
                                           type="text"/>
                                </label></div>
                                <div><label className="!grid">
                                    <span className="text-right mb-3 pr-1">نام ساختمان</span>
                                    <input className="!flex-none !mr-0"
                                           defaultValue={address.address.house_name}
                                           onChange={ e => {
                                               address.address.house_name = e.target.value
                                               setAddress(address)
                                           } }
                                           placeholder="اینجا بنویسید ..."
                                           type="text"/>
                                </label></div>
                                <div><label className="!grid">
                                    <span className="text-right mb-3 pr-1">پلاک</span>
                                    <input className="!flex-none !mr-0"
                                           defaultValue={address.address.house_number}
                                           onChange={ e => {
                                               address.address.house_number = e.target.value
                                               setAddress(address)
                                           } }
                                           placeholder="اینجا بنویسید ..."
                                           type="text"/>
                                </label></div>
                                <div><label className="!grid">
                                    <span className="text-right mb-3 pr-1">واحد</span>
                                    <input className="!flex-none !mr-0"
                                           defaultValue={address.address.unit}
                                           onChange={ e => {
                                               address.address.unit = e.target.value
                                               setAddress(address)
                                           } }
                                           placeholder="اینجا بنویسید ..."
                                           type="text"/>
                                </label></div>
                                <div><label className="!grid">
                                    <span className="text-right mb-3 pr-1">کد پستی</span>
                                    <input className="!flex-none !mr-0"
                                           defaultValue={address.address.postcode}
                                           onChange={ e => {
                                               address.address.postcode = e.target.value
                                               setAddress(address)
                                           } }
                                           placeholder="اینجا بنویسید ..."
                                           type="text"/>
                                </label></div>
                            </div>}

                        </div>
                    </div>
                    <div className="flex justify-center my-8 lg:mt-16">
                        <button
                            disabled={isLoading}
                            type="button" onClick={
                            e => {
                                validateAndStoreAddressOnEdge()
                            }
                        } className="btn-purple w-50">بروزرسانی آدرس</button>
                    </div>
                </>
            )}


        </div>
    )
}