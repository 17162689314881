import React, {useEffect, useState} from "react";
import ApiRoute from "../../../helper/api";
import Layout from "../../../components/Layout";
import {ToastContainer} from "react-toastify";
import {Step3} from "../../../components/Product/Step/Step-3";

function CreateProductStep3() {

    const [product,setProduct] = useState({})
    const [loading,setLoading] = useState(true)
    const search = window.location.search;
    const params = new URLSearchParams(search);

    useEffect(() => {

        (async () => {
            const response = await ApiRoute.product.single.fn( params.get('product_id') )
            setProduct(response.results)
        })()


    }, []);


    return (
        <Layout>
            {product &&  <Step3 defaultProduct={product}/>}

        </Layout>
    )

}

export default CreateProductStep3