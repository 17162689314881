import Layout from "../components/Layout";
import FormsTable from "../components/Tables/FormsTable";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../helper/api";

function Forms() {
    const navigate = useNavigate()
    const [createModal, setCreateModal] = useState(false)
    const [newData, setNewData] = useState({
        name: '',
        phone:'',
        send_customer_info:false,
        shipping_in_person:false,
        logico_id:'',
        shop_id:''
    })

    const [updateData, setUpdateData] = useState('0')
    const [update, setUpdate] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            const  res  = await ApiRoute.form.index.fn({
                model:"\\App\\ّFormManager\\Model\\FormExpanded",
                shop_id: shopId(),
                quick_search : searchQuery,
                page : pageNumber
            },filterQuery)
            setData(res)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);


    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">فرم ها</span></div>

                <div className="main-content p-5 mt-3">
                    {tableData && <FormsTable tableData={tableData}/>}
                 </div>
            </div>
        </Layout>
    )
}

export default Forms