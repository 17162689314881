import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBagShopping, faFile, faLocationPin, faUser} from "@fortawesome/free-solid-svg-icons";
import {formatNumberWithDots, getDateAsReadableFormat, orderPaymentTransactionLabel} from "../../../helper/general";
import Modal from "../../Utilities/Modal";
import React, {useState} from "react";
import ApiRoute from "../../../helper/api";
import PriceField from "../../Common/PriceField";

export default function OrderDirectDiscountModal({order , item, closeModal, event}){

    const [text, setText] = useState(null)
    const [desc, setDesc] = useState('')
    async function handleSubmit(){
        await ApiRoute.order.item.directDiscount.fn(order._id,item.id,{
            amount : text,
            description : desc
        })
        try {
            event()
        }catch (e) {
            
        }
    }
    return (
        <Modal isOpen={true} onClose={closeModal}>
            <div>

                <div className="page-title z-1 text-right text-sm">
                    <span className="inline-block bg-white pl-3 pr-1 mb-3 relative z-2">تخفیف خطی</span>
                </div>

                <div className={`flex gap-2 w-full flex-col`}>

                    <PriceField className={`!mr-0`} onChange={ (v) => {
                        setText(parseInt(v))
                    } }/>
                    <input onKeyUp={ (e) => { setDesc(e.target.value) } }

                           className='input-x' type="text" placeholder="توضیحات را بنویسد ..."/>
                    <button onClick={handleSubmit} className={`btn-green w-[100px] py-1 max-h-max rounded`}>ذخیره</button>

                </div>

            </div>
        </Modal>
    )
}