import React, { useState } from 'react';

const DynamicTabComponent = ({ children }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    const tabs = React.Children.toArray(children);

    return (
        <div>
            <div className="flex justify-center gap-3 text-xs">
                {tabs.map((tab, index) => (
                    <button

                        key={index}
                        onClick={() => handleTabClick(index)}
                        className={`border border-gray-7 rounded py-1 px-5 ${activeTab === index ? 'active border-purple-1 text-purple-1' : ''}`}
                    >
                        {tab.props.title}
                    </button>
                ))}
            </div>
            <div>{tabs[activeTab]}</div>
        </div>
    );
};

export default DynamicTabComponent;
