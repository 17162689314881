import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faBars,
    faCheckDouble,
    faCircle,
    faCopy,
    faEye,
    faFileCirclePlus,
    faSort,
    faSortDown,
    faSortUp,
    faTrash,
    faUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
import Toman from "../Utilities/Toman";
import React, {useState} from "react";
import {
    formatNumberWithDots,
    getDateAsReadableFormat,
    getFrontEndUrl,
    hasClickAction,
    storeClickAction
} from "../../helper/general";
import moment from "jalali-moment";
import ApiRoute from "../../helper/api";
import {faSortAsc} from "@fortawesome/free-solid-svg-icons/faSortAsc";
import {faSortDesc} from "@fortawesome/free-solid-svg-icons/faSortDesc";

function ProductList({tableData,setUpdateData,onSort}) {
    const [tableTitles,setTableTitles] = useState([
        {title:'نام کالا'},
        {title:'SKU'},
        {
            title:'وضعیت',
            name:"status",
            dir:"desc",
            sort:true
        },
        {
            title:'ایجاد',
            name:"created_at",
            sort:true
        },
        {
            title: 'انتشار',
            name:"publish_at",
            sort: true
        },
        {
            title:'بروزرسانی',
            name:"updated_at",
            sort:true
        }
    ])
    const [update , setUpdate] = useState(0)
    async function handleClone(product) {
        await ApiRoute.product.clone.fn(product._id)
        setUpdateData(Date.now().toString())
    }

    return (
        <table className="table-auto w-full block lg:table mt-4">
            <thead>
            <tr className="border-b border-b-gray-7">
                {tableTitles.map((item, id) => (
                    <td onClick={ () => {
                        if (item.sort !== true) return;
                        let dir = localStorage.getItem("sort_dir") ?? "desc";
                        if ( dir === "desc" ){
                            localStorage.setItem("sort_dir", "asc")
                        }else{
                            localStorage.setItem("sort_dir", "desc")
                        }
                        onSort(item.name , dir)
                    } } className={ (item.sort && 'cursor-pointer text-purple-1') +` text-13 relative text-gray-6 pb-2`} key={id}>
                       {item.title}
                        {item.sort && <FontAwesomeIcon className={`mr-2`} icon={faSort}/>}
                    </td>
                ))}
                <td></td>
            </tr>
            </thead>
            <tbody>
            {tableData.map(_tableData => (
                    <tr className={`text-[15px] py-2`} key={_tableData.id}>
                        <td><span className="text-right inline-table">
                            <Link className={( hasClickAction(`product_edit_${_tableData._id}`) ? ' text-purple-1 ' : '' )+`hover:text-purple-1 font-normal`} onClick={ () => { storeClickAction(`product_edit_${_tableData._id}`) } } to={`/products/edit?product_id=${_tableData._id}`} title="مشاهده و ویرایش">
                                {_tableData.title}
                                {_tableData.status === "draft" ? (<i className={`text-red-2 mx-1`}>(پیشنویس)</i>) : ''}
                            </Link>
                        </span>
                        </td>
                        <td>{_tableData.sku}</td>
                        <td>
                            {_tableData.status == "publish" && <FontAwesomeIcon className={`text-lg text-green-1`}
                                                                                icon={faCircle}/>}
                            {_tableData.status == "draft" && <FontAwesomeIcon className={`text-lg text-yellow-2`}
                                                                              icon={faCircle}/>}
                            {_tableData.status == "trash" && <FontAwesomeIcon className={`text-lg text-red-1`}
                                                                              icon={faCircle}/>}
                        </td>
                        <td>
                            {getDateAsReadableFormat(_tableData.created_at)} <br/>
                            {_tableData.metadata?.created_by?.name ?? '--'}
                        </td>
                        <td>
                            {_tableData?.publish_at == null ? '--' : getDateAsReadableFormat(_tableData.publish_at)} <br/>
                            {_tableData.metadata?.published_by?.name ?? '--'}
                        </td>
                        <td>
                            {getDateAsReadableFormat(_tableData.updated_at)} <br/>
                            {_tableData.metadata?.updated_by?.name ?? '--'}
                        </td>
                        <td>
                            <div className="flex">

                                {/*<a className="mx-1" href="/" title="؟؟؟"><FontAwesomeIcon
                                    className="bg-purple-4 text-white p-1 w-4 h-4 rounded" icon={faCheckDouble}/></a>*/}

                                {/*<a className="mx-1" href="/" title="؟؟؟"><FontAwesomeIcon
                                    className="bg-blue-1 text-white p-1 w-4 h-4 rounded" icon={faFileCirclePlus}/></a>*/}

                                <Link onClick={ () => {
                                    storeClickAction(`product_edit_${_tableData._id}`)
                                } } className="mx-1" to={`/products/edit?product_id=${_tableData._id}`} title="مشاهده و ویرایش"><FontAwesomeIcon
                                    className={( hasClickAction(`product_edit_${_tableData._id}`) ? ' !bg-[#2f3133] ' : '' )+`bg-gray-5 text-white p-1 w-4 h-4 rounded`} icon={faEye}/></Link>


                                { _tableData.status == 'publish' && <a className="mx-1" href={getFrontEndUrl('/'+_tableData.slug)} title="نمایش در سایت"><FontAwesomeIcon
                                    className="bg-green-1 text-white p-1 w-4 h-4 rounded" icon={faUpRightFromSquare}/></a>}

                                <button className="mx-1" onClick={ async () => await handleClone(_tableData)} title="کلون"><FontAwesomeIcon
                                    className="bg-purple-2 text-white p-1 w-4 h-4 rounded" icon={faCopy}/></button>

                                <a className="mx-1" href="/" title="لاگ ها"><FontAwesomeIcon
                                    className="bg-gray-5 text-white p-1 w-4 h-4 rounded" icon={faBars}/></a>

                 {/*               <a className="mx-1" href="/" title="؟؟؟"><FontAwesomeIcon
                                    className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></a>*/}

                            </div>

                        </td>
                    </tr>
                )
            )}
            </tbody>
        </table>
    )
}

export default ProductList