import Layout from "../../components/Layout";
import {Link, useNavigate} from "react-router-dom";
import SearchBox from "../../components/SearchBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import CouponsTable from "../../components/Tables/CouponsTable";
import Pagination from "../../components/Pagination";
import FilterBox from "../../components/FilterBox";
import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../helper/api";

function Coupon() {
    const navigate = useNavigate()
    const [createModal, setCreateModal] = useState(false)

    const [isLoading, setIsLoading] = useState(true)
    const [updateData, setUpdateData] = useState('0')
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  res  = await ApiRoute.coupon.index.fn({
                shop_id: shopId(),
                page : pageNumber,
                model:'\\App\\CouponManager\\Model\\CouponParent'
            },filterQuery)
            setData(res)
            setIsLoading(false)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        filterQuery.quick_search = searchQuery
        setUpdateData(Date.now().toString())
    }, [searchQuery]);
    return (
        <Layout>
            <div>
                <div className="flex items-center justify-between mb-3">
                    <div className="page-title flex-auto ml-3 md:ml-0">
                        <span className="inline-block bg-white pl-3">کد تخفیف</span>
                    </div>
                    <Link className="btn-blue text-center md:hidden" to="/coupons/create"> افزودن کد تخفیف جدید</Link>
                </div>

                <div className="grid md:flex justify-between items-center gap-3">
                    <div className="flex gap-3">
                        <SearchBox SearchIn={false} SetSearchQuery={setSearchQuery}/>
                    </div>
                    <Link className="btn-blue text-center hidden md:block" to="/coupons/create">افزودن کد تخفیف جدید</Link>
                </div>


                <div className="main-content p-1 mt-3">
                    {data && <CouponsTable setUpdateData={setUpdateData} tableData={tableData}/>}
                    {data && <Pagination Data={paginationData} SetPageNumber={setPageNumber}/>}
                </div>
            </div>
        </Layout>
    )
}

export default Coupon