import Layout from "../../components/Layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faEdit} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import SwitchToggle from "../../components/Utilities/SwitchToggle";
import {Link} from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ApiRoute from "../../helper/api";
import FileUpload from "../../components/FileUpload";
import { v4 as uuidv4 } from 'uuid';
import ShopShippingInPersonEdit from "../../components/Shop/ShopShippingInPersonEdit";
import ShopBankAccountsEdit from "../../components/Shop/ShopBankAccountsEdit";
import ShopMainEdit from "../../components/Shop/ShopMainEdit";
import ShopSmsEdit from "../../components/Shop/ShopSmsEdit";
function EditStore() {

    const MySwal = withReactContent(Swal)
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [update, setUpdate] = useState(0);
    const [updateQuery, setUpdateQuery] = useState(0);
    const [data, setData] = useState(null);

    const [editData,setEditData] = useState(null);


    useEffect(() => {
        getData()
    }, [updateQuery]);

    useEffect(() => {
        if (data){
            setEditData(data)
        }
    }, [data]);


    async function getData(){
        const response = await ApiRoute.shop.single.fn(params.get('id'))
        setData(response.results)
    }

    async function handleSubmit() {
        await ApiRoute.shop.update.fn(data._id,editData)
    }

    return (
        <Layout>
            {
                editData && <div>

                    <div className="page-title mb-3"><span className="inline-block bg-white pl-3">ویرایش فروشگاه</span></div>

                    <div className="form grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-10 gap-4 xl:gap-10">

                        <div className="max-sm:col-span-1 max-lg:col-span-2">
                            <label className="relative">
                                نام فروشگاه
                                <input type="text" onKeyUp={ (e) => {
                                    editData.title = e.target.value;
                                } } defaultValue={editData.title}/>
                            </label>
                        </div>

                        <div><label>
                            شماره والد مشتریان
                            <input type="text" defaultValue={editData.customer_key} disabled/>
                        </label></div>
                        <div><label>
                            رنج جاری شماره سفارش
                            <input type="text" defaultValue={editData.order_key} disabled/>
                        </label></div>
                    </div>

                    <ShopMainEdit shop={editData} data={data}/>


                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-3 2xl:gap-5 mt-5">

                        <ShopBankAccountsEdit shop={editData}/>
                        <ShopShippingInPersonEdit shop={editData}/>

                    </div>
  

                    <ShopSmsEdit shop={editData} notification_templates={editData.order_notification_templates}/>

                    <button onClick={handleSubmit} className={`btn-purple fixed bottom-3 py-2 rounded left-4 z-10`}>ذخیره تغییرات</button>

                </div>
            }
        </Layout>
    )
}

export default EditStore