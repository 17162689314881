import CustomMultiSelect from "../../../components/Utilities/CustomMultiSelect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import CategorySelect from "../../../components/Product/Category/CategorySelect";
import BrandSelect from "../../../components/Product/Category/BrandSelect";
import ApiRoute from "../../../helper/api";

function RuleItem({item}) {
    async function handleUpdate(){
        await ApiRoute.price.timing.update.fn(item._id , item)
    }
    return(
        <tr>
            <td>
                <div className="flex-auto pl-3 max-lg:w-[250px]">
                    <CategorySelect assignItem={ (v) => {
                        v.map( (x) => {
                            item.category = x.slug
                        } )
                    } } customDefaultValue={true} defaultValue={[item.render_category]}/>
                </div>
            </td>
            <td>
                <div className="flex-auto max-lg:w-[250px]">
                    <BrandSelect customDefaultValue={true} defaultValue={[item.render_brand]} assignItem={ (v) => {
                        v.map( (x) => {
                            item.brand = x.value
                        } )
                    } }/>
                </div>
            </td>
            <td>
                <div className="form">
                    <input onKeyUp={ (e) => {
                        item.expiration = parseInt(e.target.value)
                    } } className="text-center" type="text" defaultValue={item.expiration}/>
                </div>
            </td>
            <td>
                <div className="flex gap-3">
                    <button
                        onClick={handleUpdate}
                        className="rounded border border-gray-9 text-gray-9 bg-gray-7 p-1.5 leading-3 px-3 text-sm hover:bg-purple-2 hover:text-white">
                        <FontAwesomeIcon icon={faEdit}/>
                    </button>
                    <button
                        onClick={ () => {
                            alert('پیاده سازی تمامی حذف ها در مراحل نهایی صورت خواهد گرفت')
                        } }
                        className="rounded border border-gray-9 text-gray-9 bg-gray-7 p-1.5 leading-3 px-3 text-sm hover:bg-purple-2 hover:text-white">
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                </div>
            </td>
        </tr>
    )
}
export default RuleItem