import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faCheck,faCopy, faEye, faTimes, faTrash, faUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons';
import {getDateAsReadableFormat, getFrontEndUrl} from "../../helper/general";
import {Link} from "react-router-dom";

function PagesTables(props) {
    const tableTitles = [
        'عنوان',
        'وضعیت',
        'آخرین بروزرسانی',
    ]
    return (
        <table className="table-auto w-full block lg:table mt-4">
            <thead>
            <tr className="border-b border-b-gray-7">
                {tableTitles.map((item, id) => (
                    <td className="text-13 text-gray-6 pb-2" key={id}>{item}</td>
                ))}
                <td></td>
            </tr>
            </thead>
            <tbody>
            {props.tableData.map(tableData => (
                    <tr className={`text-xs py-2`} key={tableData.id}>
                        <td>{tableData.title}</td>
                        <td>{tableData.status === 'publish' ? 'منشتر شده' : 'پیش نویس'}</td>
                        <td>{getDateAsReadableFormat(tableData.updated_at)}</td>
                        <td>
                            <div className="flex">
                                <Link className="mx-1" to={`/pages/edit?id=${tableData._id}`} title="؟؟؟"><FontAwesomeIcon
                                    className="bg-gray-3 text-white p-1 w-4 h-4 rounded" icon={faEye}/></Link>

                                <a className="mx-1" href={getFrontEndUrl('/page/'+tableData.slug)}><FontAwesomeIcon
                                    className="bg-blue-1 text-white p-1 w-4 h-4 rounded" icon={faUpRightFromSquare}/></a>

                                <button className="mx-1" onClick={ () => {
                                    alert('پیاده سازی تمامی حذف ها در مراحل نهایی صورت خواهد گرفت')
                                } } title="؟؟؟"><FontAwesomeIcon
                                    className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                            </div>
                        </td>
                    </tr>
                )
            )}
            </tbody>
        </table>
    )
}

export default PagesTables