import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../../helper/api";
import {toast, ToastContainer} from "react-toastify";
import Layout from "../../../components/Layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import {faCircle, faEdit, faExternalLink, faTrash} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../../components/Pagination";
import SearchBox from "../../../components/SearchBox";
import {getFrontEndUrl} from "../../../helper/general";

function Categories(){
    const navigate = useNavigate()

    const [data,setData] = useState(null)
    const [loading,setLoading] = useState(true)
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [isLoading, setIsLoading] = useState(true)

    const [newData , setNewData] = useState({
        slug:'',
        label : '',
        is_shipment_class:0
    })

    const [pageNumber, setPageNumber] = useState(1)
    const [paginationData, setPaginationData] = useState([])
    const [updateData, setUpdateData] = useState('0')
    const [filterQuery, setFilterQuery] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'cellphone'
    })
    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const  response  = await ApiRoute.product.category.index.fn({
                shop_id: shopId(),
                page : pageNumber,
                model:'\\App\\CategoryManager\\Model\\CategoryExpanded'
            },{
                quick_search : searchQuery
            })

            setData(response.results.data)
            setPaginationData(response.results)

            setIsLoading(false)

            console.log(data)
        })();
    }, [updateData]);

    useEffect(() => {
        getData()
    }, []);

    async function getData(){
        const response = await ApiRoute.product.category.index.fn({model:'\\App\\CategoryManager\\Model\\CategoryExpanded'})
        setData(response.results.data)
        setPaginationData(response.results)
    }

    async function createData(){
        try {
            await ApiRoute.product.category.create.fn(newData)
            await getData()
            //toast.success("با موفقیت افزوده شد")
        }catch (e) {
            alert(e)
            toast.error(e)
        }

    }

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);

    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    return(
        <Layout>
            <div>
                <div className="page-title mb-3"><span className="inline-block bg-white pl-3">دسته بندی محصولات</span>
                </div>

                <div className="main-content mx-auto p-5 mt-6">
                    <div className="grid grid-cols-1 lg:grid-cols-7 gap-4">
                        <div className="lg:col-span-2 border border-gray-7 rounded-md p-2 pb-4 h-max form">
                            <div className="grid gap-5 justify-items-stretch">
                                <label className="!grid">
                                    <span className="text-right mb-2">عنوان</span>
                                    <input onChange={ (e) => {
                                        newData.label = e.target.value;
                                        setNewData(newData)
                                    } } className="!mr-0" type="text" placeholder="عنوان را وارد کنید"/>
                                </label>
                                <label className="!grid">
                                    <span className="text-right mb-2">نامک</span>
                                    <input onChange={ (e) => {
                                        newData.slug = e.target.value;
                                        setNewData(newData)
                                    } } className="!mr-0 text-left" type="text" placeholder="نامک را وارد کنید"/>
                                </label>
                                 <label className="!grid">
                                   <span className="text-right mb-2">کنترلر حمل و نقل</span>
                                    <select className="!mr-0" onChange={ (e) => {
                                        newData.is_shipment_class = e.target.value
                                        setNewData(newData)
                                    } }>
                                        <option value={0}>خیر</option>
                                        <option value={1}>بله</option>
                                    </select>
                                </label>
                                <button onClick={createData} className="btn-green w-max rounded mr-auto py-2">افزودن</button>
                            </div>
                        </div>
                         <div className="lg:col-span-5 border border-gray-7 rounded-md p-2">
                             <SearchBox SetSearchQuery={setSearchQuery}/>
                            <table className="table-borderd-tr table-auto w-full mt-2 mx-auto block lg:table text-xs">
                                <thead>
                                <tr>
                                    <td>عنوان</td>
                                    <td>نامک</td>
                                    <td>حمل و نقل</td>
                                    <td>تعداد محصول</td>
                                    <td></td>
                                </tr>
                                </thead>
                                <tbody>

                                { data && data.map( (item) => <tr key={item._id}>
                                    <td>{item.label}</td>
                                    <td>{item.slug}</td>
                                    <td>{item.is_shipment_class ? <FontAwesomeIcon className={`text-lg text-green-1`}
                                                                                   icon={faCircle}/> : <FontAwesomeIcon className={`text-lg text-red-1`}
                                                                                                                        icon={faCircle}/>}</td>
                                    <td>{item.product_count}</td>
                                    <td className="flex justify-center items-center py-1">
                                        <Link className="mx-2 leading-3 my-1" to={`/products/edit-category?id=${item._id}`} title="ویرایش"><FontAwesomeIcon
                                            className="bg-gray-3 text-white p-1 w-4 h-4 rounded" icon={faEye}/></Link>
                                        <a target={`_blank`} className="mx-2 leading-3 my-1" href={getFrontEndUrl('/product-category/'+item.slug)} title="مشاهده"><FontAwesomeIcon
                                            className="bg-blue-1 text-white p-1 w-4 h-4 rounded" icon={faExternalLink}/></a>
                                        <button className="mx-2 leading-3 my-1" onClick={ () => {
                                            alert('پیاده سازی تمامی حذف ها در مراحل نهایی صورت خواهد گرفت')
                                        } } title="حذف"><FontAwesomeIcon
                                            className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                                    </td>
                                </tr>) }

                                </tbody>
                            </table>
                             { data && <Pagination SetPageNumber={setPageNumber} Data={paginationData} /> }
                        </div>
                    </div>
                </div>


            </div>

            

        </Layout>
    )
}
export default Categories