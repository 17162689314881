import Layout from "../../../components/Layout";
import Step1 from "../../../components/Order/Create/Step1";
import Step2 from "../../../components/Order/Create//Step2";
import Step3 from "../../../components/Order/Create//Step3";
import Step4 from "../../../components/Order/Create//Step4";
import {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../../helper/api";
import useToken from "../../../useToken";
import {useNavigate} from "react-router-dom";

export default function OrderCreate(){
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [newOrder , setNewOrder] = useState({})
    const [step , setStep] = useState(1)
    const [sending, setSending] = useState(false)
    const navigate = useNavigate()
    const token = useToken()

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', handleEndConcert)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', handleEndConcert)
        }
    }, [])
    const alertUser = e => {
        e.preventDefault()
        e.returnValue = ''
    }
    const handleEndConcert = async () => {
        sessionStorage.removeItem('new_order')
    }

    const getNewOrder = function (){
        if (sessionStorage.getItem('new_order')){
            return JSON.parse(sessionStorage.getItem('new_order'))
        }
        return {}
    }

    function storeNewOrder(x){
        setNewOrder(x)
        sessionStorage.setItem('new_order',JSON.stringify(x))
    }

    async function placeOrder(){
        setSending(true)
        newOrder.employee_id = token.token.employee._id
        newOrder.shop_id = shopId()
        newOrder.is_preorder = params.get('pre_order')
        const res = await ApiRoute.order.create.fn(newOrder)
        if (res?.status_code === 200){
            sessionStorage.removeItem('new_order')
            if (res?.results?._id){
                navigate("/orders/edit?id="+res.results._id)
            }else{
               navigate("/orders/edit?id="+res.results)
            }

        }
        setSending(false)
    }

    return (
        <Layout>
            <Step1 show={(step == 1)} step={step} setStep={setStep} setNewOrder={storeNewOrder} newOrder={getNewOrder()}/>
            {step === 2 && <Step2 step={step} setStep={setStep} setNewOrder={storeNewOrder} newOrder={getNewOrder()}/>}
            {step === 3 && <Step3 step={step} setStep={setStep} setNewOrder={storeNewOrder} newOrder={getNewOrder()}/>}
            {step > 3 && <Step4 isPreorder={params.get('pre_order') === '1'} sending={sending} step={step} setStep={setStep} setNewOrder={storeNewOrder} newOrder={getNewOrder()} placeOrder={ () => {
                placeOrder()
            } }/>}
        </Layout>
    )
}