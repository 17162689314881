import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import ApiRoute, {uploadUrl} from "../../../helper/api";
import {toast, ToastContainer} from "react-toastify";
import {isOnEditState} from "../../../helper/product";
import Layout from "../../Layout";
import {Editor} from "@tinymce/tinymce-react";
import Buttons from "react-multi-date-picker/components/button";
import Select from "react-select";
import Modal from "../../Utilities/Modal";
import {hasAccess, HasAccessComponent} from "../../../helper/permissions";
import AttributeSelect from "../../Common/AttributeSelect";

export function Step3({onEdit = false , defaultProduct = null}){

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)

    const [product,setProduct] = useState(defaultProduct)
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [options , setOptions] = useState([])
    const [attributeGroupOptions , setAttributeGroupOptions] = useState([])

    const [attributes, setAttributes] = useState(null);

    const [openModal, setOpenModal] = useState(false)
    const [openAttributesModal, setOpenAttributesModal] = useState(false)
    const [openSingleAttributesModal, setOpenSingleAttributesModal] = useState(false)
    const [selectedAttributeGroup, setSelectedAttributeGroup] = useState(null)
    const [selectedAttributeSingle, setSelectedAttributeSingle] = useState(null)

    const [newAttributeValues, setNewAttributeValues] = useState({
        label : '',
        value:'',
        attribute_name: '',
    })

    useEffect(() => {
        setProduct(defaultProduct)
    },[defaultProduct]);

    useEffect(() => {

        if (product.attributes){
            let x = {};
            Object.keys(product.attributes).map(function (attribute) {
                let _attribute = product.attributes[attribute]
                x[_attribute.group_label] = []
            })

            Object.keys(product.attributes).map(function (attribute) {
                let _attribute = product.attributes[attribute]
                x[_attribute.group_label].push(_attribute)
            })
            setAttributes(x)

            setIsLoading(false)
        }

    },[product]);


    async function handleSubmit() {
        try {
            let __attributes = {}
            Object.keys(attributes).map(function (attribute) {
                let _attribute = attributes[attribute]
                for (const attributeElement of _attribute) {
                    __attributes[attributeElement.name] = attributeElement
                }
            })
            const response = await ApiRoute.product.create.stepThree.fn( params.get('product_id') , {
                attributes : __attributes
            } )
            setProduct(response.results)
            //toast.success("با موفقیت ذخیره شد")
            if (!onEdit){
                return navigate("/products/create/step-4?product_id=" + response.results._id)
            }
        }catch (e) {
            toast.error(e.message)
        }

    }

    async function handleNewValueAttribute() {
        try {
            const response = await ApiRoute.product.attributes.createValue.fn( newAttributeValues.attribute_name , newAttributeValues )
            //toast.success("با موفقیت ذخیره شد")
            setOpenModal(false)
        }catch (e) {
            toast.error(e.message)
        }
    }

    async function handleImportAttribute() {
        try {
            const response = await ApiRoute.product.attributes.importFromAttributeGroup.fn( params.get('product_id') , selectedAttributeGroup.value )
            //toast.success("با موفقیت ذخیره شد")
            setProduct(response.results)
            setOpenAttributesModal(false)
        }catch (e) {
            toast.error(e.message)
        }
    }


    return (
        <>
            <div>

                {!onEdit && <div className="page-title mb-3"><span className="inline-block bg-white pl-3">محصول جدید</span>
                </div>}

                {product && <div className={!onEdit ? `main-content mx-auto py-6 px-5 my-5` : ''}>


                    <div className="flex justify-between mx-auto mb-5">

                        {!onEdit && <span className="text-purple-1 pl-3 block text-center">مشخصات فنی محصول</span>}

                        <Buttons onClick={ () => {
                            setOpenAttributesModal(true)
                        } } className="btn-orange py-1.5">افزودن ویژگی گروهی</Buttons>

                        <Buttons onClick={ () => {
                            setOpenSingleAttributesModal(true)
                        } } className="btn-blue py-1.5">افزودن ویژگی تکی</Buttons>

                    </div>


                    {attributes && Object.keys(attributes).map((groupLabel) =>
                        <div className="border border-gray-7 rounded-md p-3">

                            { (groupLabel && groupLabel !== 'null') && <span className="text-xs block px-2 pb-3">{groupLabel}</span>}

                            { attributes[groupLabel].map( (_a,index) => {
                                return (
                                    <div className="border border-gray-7 rounded-md p-2">
                                        <div className="flex justify-between items-center pb-1.5">
                                            <span className="text-xs">{_a.label}</span>

                                            <HasAccessComponent permission={`add_single_attribute product`} children={
                                                <button
                                                    onClick={ e => {
                                                        newAttributeValues.attribute_name = _a.name;
                                                        setNewAttributeValues(newAttributeValues)
                                                        setOpenModal(true)
                                                    } }
                                                    className="btn-green rounded py-1">افزودن</button>
                                            }/>

                                        </div>
                                        <div className="CustomMultiSelectStyle2">
                                            <Select
                                                options={options}
                                                isMulti={true}
                                                value={attributes[groupLabel][index].values}
                                                onChange={(selected) => {
                                                    const newAttributes = JSON.parse(JSON.stringify(attributes));
                                                    newAttributes[groupLabel][index].values = selected;
                                                    setAttributes(newAttributes);
                                                }}
                                                placeholder={'انتخاب کنید'}
                                                onMenuOpen={async () => {
                                                    setOptions([]);
                                                    const response = await ApiRoute.product.attributes.byName.fn(_a.name);
                                                    setOptions(response.results.data);
                                                }}
                                                onInputChange={(inputValue, { action }) => {
                                                    if (action === 'input-change') {
                                                        const searchValue = typeof inputValue === 'string' ? inputValue : '';
                                                        ( async () => {
                                                            const response = await ApiRoute.product.attributes.byName.fn(_a.name,searchValue);
                                                            setOptions(response.results.data);
                                                        } )()
                                                    }
                                                }}
                                            />
                                        </div>

                                    </div>
                                )
                            }) }

                        </div>
                    )}




                    <div hidden={!hasAccess('add_single_attribute product')} className={!onEdit ? `flex justify-between lg:w-1/3 mx-auto mt-10` : ''}>
                        { !onEdit && <Link to={`/products/edit?product_id=${params.get('product_id')}&step=2`} className="btn-gray w-36 py-1.5">بازگشت</Link> }

                        <Buttons onClick={handleSubmit} className="btn-purple w-36 py-1.5">
                            { onEdit ? "ذخیره سازی" : "مرحله بعد" }
                        </Buttons>
                    </div>

                </div>}

            </div>



            {/*new attribute value*/}
            <Modal isOpen={openModal} onClose={ () => {
                setOpenModal(false)
            } }>

                <HasAccessComponent permission={`add_single_attribute product`} children={ <div className="flex justify-between mx-auto mb-5">
                    <h4 className="text-[#000]">افزودن ویژگی تکی</h4>
                    <Buttons onClick={ (e) => {setOpenModal(false)} } className="btn-gray py-1.5">بستن</Buttons>
                </div> }/>

                <div className={`p-3`}>
                    <div className={`flex flex-col gap-3`}>

                        <div>
                            <label className={`w-full`}>
                                <input onChange={ (e) => {
                                    newAttributeValues.label = e.target.value;
                                    setNewAttributeValues(newAttributeValues)
                                } } dir={"rtl"}
                                       placeholder={`نام ویژگی جدید (به فارسی)`}
                                       className="text-right w-full p-1 shadow border border-gray-8 rounded font-sans"
                                       type="text"/>
                            </label>
                        </div>

                        <div>
                            <label className={`w-full`}>
                                <input onChange={ (e) => {
                                    newAttributeValues.value = e.target.value;
                                    setNewAttributeValues(newAttributeValues)
                                } } dir={"rtl"}
                                       placeholder={`نام ویژگی جدید (به انگلیسی)`}
                                       className="text-right w-full p-1 shadow border border-gray-8 rounded font-sans"
                                       type="text"/>
                            </label>
                        </div>

                    </div>
                </div>

                <div className="flex justify-between mx-auto mt-5">
                    <Buttons onClick={handleNewValueAttribute} className="btn-purple w-36 py-1.5">افزودن</Buttons>
                </div>

            </Modal>

            {/*import attributes */}
            <Modal isOpen={openAttributesModal} onClose={ () => {
                setOpenAttributesModal(false)
            } }>

                <div className="flex justify-between mx-auto mb-5">
                    <h4 className="text-[#000]">افزودن ویژگی گروهی</h4>
                    <Buttons onClick={ (e) => {setOpenAttributesModal(false)} } className="btn-gray py-1.5">بستن</Buttons>
                </div>

                <div className={`p-3`}>
                    <div className={`flex flex-col gap-3`}>

                        <div>
                            <Select
                                options={attributeGroupOptions}
                                isMulti={false}
                                value={selectedAttributeGroup}
                                onChange={(selected) => {
                                    setSelectedAttributeGroup(selected)
                                }}
                                placeholder={'گروه ویژگی را انتخاب کنید'}
                                onMenuOpen={ async () => {
                                    setAttributeGroupOptions([])
                                    const response = await ApiRoute.product.attributes.groups.get.fn()
                                    const list = [];
                                    for (const listElement of response.results) {
                                        list.push({
                                            label: listElement.title,
                                            value: listElement._id
                                        })
                                    }
                                    setAttributeGroupOptions(list)
                                } }
                            />
                        </div>

                    </div>
                </div>

                <div className="flex justify-between mx-auto mt-5">
                    <Buttons onClick={handleImportAttribute} className="btn-purple w-36 py-1.5">افزودن</Buttons>
                </div>
            </Modal>

            <Modal isOpen={openSingleAttributesModal} onClose={ () => {
                setOpenSingleAttributesModal(false)
            } }>

                <div className="flex justify-between mx-auto mb-5">
                    <h4 className="text-[#000]">افزودن ویژگی</h4>
                    <Buttons onClick={ (e) => {setOpenSingleAttributesModal(false)} } className="btn-gray py-1.5">بستن</Buttons>
                </div>

                <div className={`p-3`}>
                    <div className={`flex flex-col gap-3`}>

                        <div>
                            <AttributeSelect onChange={(selected) => {
                                setSelectedAttributeSingle(selected?._id)
                            }}  />
                        </div>

                    </div>
                </div>

                <div className="flex justify-between mx-auto mt-5">
                    <Buttons onClick={async () => {
                        try {
                            const response = await ApiRoute.product.attributes.importFromAttribute.fn( params.get('product_id') , selectedAttributeSingle )
                            //toast.success("با موفقیت ذخیره شد")
                            setProduct(response.results)
                            setOpenSingleAttributesModal(false)
                        }catch (e) {
                            toast.error(e.message)
                        }
                    }} className="btn-purple w-36 py-1.5">افزودن</Buttons>
                </div>
            </Modal>

        </>
    )
}