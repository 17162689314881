import Layout from "../../components/Layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../components/Pagination";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ApiRoute, {shopId} from "../../helper/api";
import SwitchToggle from "../../components/Utilities/SwitchToggle";
import ShopSelect from "../../components/Shop/ShopSelect";
import SelectCoordinates from "../../components/Coordinates/SelectCoordinates";
import CategorySelect from "../../components/Product/Category/CategorySelect";

export default function DeliveryDateClassStart() {
    const navigate = useNavigate()
    const [createModal, setCreateModal] = useState(false)
    const [newData, setNewData] = useState({
        title: '',
        start:0,
        state:null,
        city:null,
        category:null
    })

    const [updateData, setUpdateData] = useState('0')
    const [update, setUpdate] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [data, setData] = useState({})
    const [searchQuery, setSearchQuery] = useState({
        term: '',
        by: 'title'
    })
    const [filterQuery, setFilterQuery] = useState({})

    // state tab for filtering
    const [statsTab , setStatsTab] = useState(null)
    const [selectedStatsTab , setSelectedStatsTab] = useState('')

    //fetch data from server and update data /| based on updateData state
    useEffect(() => {
        (async () => {
            const  res  = await ApiRoute.shipment.deliveryStartAt.index.fn({
                shop_id: shopId(),
                quick_search : searchQuery,
                page : pageNumber
            },filterQuery)
            setData(res)
        })();
    }, [updateData]);

    const paginationData = data?.results
    const tableData = data?.results?.data ?? []

    // watch pageNumber and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [pageNumber]);
    // watch searchQuery and update updateData state
    useEffect(() => {
        setUpdateData(Date.now().toString())
    }, [searchQuery]);

    async function handleUpdate(item){
        await ApiRoute.shipment.deliveryStartAt.update.fn(item._id, item)
    }
    async function handleDelete(item){
        await ApiRoute.shipment.deliveryStartAt.delete.fn(item._id)
        setUpdateData(Date.now().toString())
    }
    async function handleCreate(){
        await ApiRoute.shipment.deliveryStartAt.create.fn(newData)
        setUpdateData(Date.now().toString())
    }

    return (
        <Layout>
            <div>
                <div className="page-title mb-3"><span
                    className="inline-block bg-white pl-3">تنظیمات شروع کلاس زمان تحویل</span>
                </div>
                <div className="text-xs text-purple-1 mt-6 mb-3 px-2">اضافه کردن شرط</div>
                <div className="main-content mx-auto p-5">
                    <div
                        className="flex items-center text-13 flex-wrap gap-5">
                        <div className={`md:w-[200px] w-full`}><label>
                            عنوان
                            <input className="w-full input-x" onKeyUp={ (e) => {
                                newData.title = e.target.value;
                            } } type="text"/>
                        </label></div>
                        <div className={`md:w-[200px] w-full`}>
                            تعداد روز ها
                            <input className="w-full input-x" onInput={ (e) => {
                                newData.start = parseInt(e.target.value);
                            } } type="text"/>
                        </div>
                        <div className={`md:w-[200px] w-full`}>
                            <label className={`mb-2`}>استان / شهر</label>
                            <SelectCoordinates multiple={true} SetCoordinate={ (coordinates) => {
                                newData.state = coordinates.state
                                newData.city = coordinates.city
                            } }/>
                        </div>
                        <div className={`md:w-[200px] pt-[15px] flex-col gap-2 w-full`}>
                            <label>دسته ها</label>
                            <CategorySelect assignItem={ (s) => {
                                let arr = []
                                s.map( a => {
                                    arr.push({
                                        label:a.label,
                                        slug:a.slug
                                    })
                                } )
                                newData.category = arr
                            } }/>
                        </div>
                        <div className="sm:col-start-2 xl:col-start-3 text-left">
                            <button onClick={handleCreate} className="btn-purple w-32 xl:w-full py-2">افزودن</button>
                        </div>
                    </div>

                    <div className="text-xs text-purple-1 mt-8 mb-3 px-2">لیست شروط </div>
                    <div className="border border-blue-1 p-3 rounded-md">
                        {data && <table className="table-borderd-tr table-auto table-td-150 w-full mt-2 mx-auto block xl:table">
                            <thead>
                            <tr className="text-xs">
                                <td>عنوان</td>
                                <td>تعداد روز</td>
                                <td>موقعیت</td>
                                <td>دسته</td>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody>

                                {tableData.map( (item,index) => <tr key={item._id}
                                    className="form grid-form text-xs border border-gray-7 rounded-md p-1">

                                    <td className="pr-1 pl-5"><input onKeyUp={ (e) => {
                                        item.title = e.target.value;
                                    } } className="w-full !mt-0" type="text" defaultValue={item.title}/></td>

                                    <td className="pl-5"><input onKeyUp={ (e) => {
                                        item.start = parseInt(e.target.value);
                                    } } className="w-full !mt-0 text-left" dir={`ltr`} min={0} type="number" defaultValue={item.start}/></td>

                                    <td className="pl-5">
                                        <SelectCoordinates defaultValueMultiple={true} defaultValue={{
                                            state:item.state,
                                            city:item.city
                                        }} multiple={true} SetCoordinate={ (s) => {
                                            item.state = s.state
                                            item.city = s.city
                                        } }/>
                                    </td>

                                    <td className="pl-5">
                                        <CategorySelect customDefaultValue={true} defaultValue={item.category} assignItem={ (s) => {
                                            let arr = []
                                            s.map( a => {
                                                arr.push({
                                                    label:a.label,
                                                    slug:a.slug
                                                })
                                            } )
                                            item.category = arr
                                        } }/>
                                    </td>

                                    <td className="flex justify-center items-center pt-2">
                                        <button className="mx-2 leading-3 " onClick={ async () => await handleUpdate(item) } title="؟؟؟"><FontAwesomeIcon
                                            className="bg-blue-2 text-white p-1 w-4 h-4 rounded" icon={faEdit}/></button>

                                        <button className="mx-2" type={`button`} onClick={ async () => await handleDelete(item) } title="؟؟؟"><FontAwesomeIcon
                                            className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></button>
                                    </td>
                                    
                                </tr> )}

                            </tbody>
                        </table>}
                    </div>
                    { data && <Pagination Data={paginationData} SetPageNumber={setPageNumber}/>}
                </div>
            </div>
        </Layout>
    )
}