import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faTrash,
    faCopy,
    faUpRightFromSquare, faCheck, faTimes
} from '@fortawesome/free-solid-svg-icons';
import {faEye} from '@fortawesome/free-regular-svg-icons'
import {getDateAsReadableFormat, getFrontEndUrl} from "../../helper/general";
import {Link} from "react-router-dom";

function PriceListTable(props) {
    const tableTitles = [
        'عنوان',
        'وضعیت',
        'آخرین بروز رسانی',
    ]
    return (
        <table className="coupon-table table-auto w-full mt-4 block lg:table">
            <thead>
            <tr className="border-b border-b-gray-7">
                {tableTitles.map((item, id) => (
                    <td className="text-13 text-gray-6 pb-2" key={id}>{item}</td>
                ))}
                <td></td>
            </tr>
            </thead>
            <tbody>
            {props.tableData.map((tableData, id) => (
                    <tr className={`text-xs py-2`} key={id}>
                        <td>{tableData.title}</td>
                        <td>
                            {tableData.status ? 'منتشر شده' : 'پیش نویس'}
                        </td>
                        <td>{getDateAsReadableFormat(tableData.updated_at)}</td>
                        <td>
                            <div className="flex justify-center items-center py-3">
                                <Link className="mx-2" to={`/price-list/edit?id=${tableData._id}`} title="ویرایش"><FontAwesomeIcon
                                    className="bg-gray-3 text-white p-1 w-4 h-4 rounded" icon={faEye}/></Link>
                                <a target={`_blank`} className="mx-2" href={getFrontEndUrl('/'+tableData.slug)} title="مشاهده در سایت"><FontAwesomeIcon
                                    className="bg-blue-1 text-white p-1 w-4 h-4 rounded" icon={faUpRightFromSquare}/></a>
{/*                                <a className="mx-2" href="/" title="؟؟؟"><FontAwesomeIcon
                                    className="bg-purple-1 text-white p-1 w-4 h-4 rounded" icon={faCopy}/></a>*/}
                                {/*<a className="mx-2" href="/" title="؟؟؟"><FontAwesomeIcon
                                    className="bg-red-1 text-white p-1 w-4 h-4 rounded" icon={faTrash}/></a>*/}
                            </div>
                        </td>

                    </tr>
                )
            )}
            </tbody>
        </table>
    )
}

export default PriceListTable