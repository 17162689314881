import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import ApiRoute, {shopId} from "../../../helper/api";
import {faCaretUp, faCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function BrandSelect ({ assignItem, customDefaultValue = false, defaultValue = [], placeholder = 'انتخاب برند' , fixed = false }) {

    const [show,setShow] = useState(false)
    const [term,setTerm] = useState('')
    const [selected,setSelected] = useState([])
    const [data,setData] = useState(null)


    useEffect(() => {
        syncDefaultValue()
    }, [defaultValue]);

    useEffect(() => {
        assignItem(selected)
    }, [selected]);

    const syncDefaultValue = async () => {
        if (defaultValue.length > 0){
            if (customDefaultValue){
                setSelected(defaultValue)
            }else {
                try {
                    const res = await ApiRoute.product.brand.getByValue.fn({
                        value : defaultValue
                    })
                    setSelected(res.results)
                }catch (e) {
                    console.log(e)
                }
            }
        }

    }

    const getData = async () => {
        const res = await ApiRoute.product.brand.index.fn({
            label: term
        })
        setData(res.results)
    }

    function has(item){
        for (const selectedElement of selected) {
            if (selectedElement.value === item.value){ return true }
        }
        return false
    }

    async function openMenu(){
        setShow(!show)
        if (data == null){
            await getData()
        }
    }

    return (
        <div className={"relative w-full"}>
            <div className={`flex items-center justify-between`}>
                <input
                    onChange={ async (e) => {
                        setShow(true)
                        setTerm(e.target.value)
                        await getData()
                    } }
                    className="bordered-input-7 h-[30px] bg-gray-1 w-full mt-1"
                    placeholder={placeholder}
                    type={"search"}/>
                <div onClick={openMenu} className={`bg-gray-4 flex justify-center items-center text-white w-[30px] h-[30px]`}>
                    <FontAwesomeIcon className={`text-lg text-white`}
                                     icon={faCaretUp}/>
                </div>
            </div>
            {(show && data) && <div className={(fixed ? ' fixed max-w-max ' : ' absolute')+" text-center overflow-auto h-[250px] z-[9999] w-full flex flex-col gap-3 bg-white border border-gray-3 p-2"}>
                {data.map((item) => <div key={item._id}
                                         onClick={ () => {
                                             if (!has(item)){
                                                 setSelected(selected => [...selected, item])
                                             }
                                             setShow(false)
                                         }}
                                         className={"hover:text-blue-1 cursor-pointer"}>{item.label}</div>)}
            </div>}

            <div className={`flex flex-wrap p-2 gap-2 mt-1`}>
                {selected.map((item,xindex) => <div onClick={ () => {
                    setShow(false)
                    setSelected(selected.filter(xitem => xitem.value !== item.value))
                } } className={`text-[11px] flex cursor-pointer justify-between bg-green-2 hover:bg-red-1 text-white rounded px-2`} key={xindex}>
                    <div>{item.label}</div>
                </div>)}
            </div>
        </div>

    );
};